import React from 'react';
import { useIntl } from 'react-intl';
import cn from 'classnames';
import { bool, string } from 'prop-types';

import ReducedLogo from 'components/common/Brand/ReducedLogo';
import Tag from 'components/common/Tag/Tag';

const PodcastTag = ({ withBackground, className }) => {
  const intl = useIntl();

  return (
    <div className={cn('original', { full: withBackground }, className)}>
      <ReducedLogo isTransparent={withBackground} />
      <Tag
        label={intl.formatMessage({ id: 'content.podcast' })}
        className="original-tag"
        centeredContent
        isTransparent
      />
    </div>
  );
};

PodcastTag.propTypes = {
  withBackground: bool,
  className: string
};

export default PodcastTag;
