import React from 'react';
import CancelIcon from 'assets/icons/Cancel/Cancel.png';
import { useModal } from 'hooks';
import Modal from 'components/common/Modal/Modal';
import { zIndexModalThird } from 'styles/common/_constants.scss';

import DeleteModal from 'components/DeleteModal/DeleteModal';

const ManageAccount = () => {
  const { toggle, isShowing } = useModal();
  return (
    <div className="manage-account">
      <div className="delete-row" onClick={toggle}>
        <img src={CancelIcon} className="cancel-icon" alt="cancel" />
        Delete my account
      </div>
      <Modal hide={toggle} isShowing={isShowing} topModal zIndex={zIndexModalThird}>
        <DeleteModal />
      </Modal>
    </div>
  );
};

export default ManageAccount;
