import { useSelector } from 'react-redux';
import { useLoading } from '@rootstrap/redux-tools';
import { createVideoAds } from 'state/actions/videoadsAction';

export default () => {
  return {
    uploadingvideoads: useSelector(({ videoads }) => videoads),
    loading: useLoading(createVideoAds)
  };
};
