import Button from 'components/common/Button/Button';
import Input from 'components/common/Input/Input';
import Loading from 'components/common/Loading';
import { ALLOWED_TIME_ZONES, imageSizes } from 'constants/constants';
import {
  useForm,
  useNavigation,
  useTextInputProps,
  useToast,
  useValidation,
  useSession
} from 'hooks';
import { eventValidation } from 'utils/constraints';
import React, { memo, useEffect, useRef, useState } from 'react';
import { checkImageDimensions, routeWithProps } from 'utils/helpers';
import ImageCropperModal from 'components/common/ImageCropper/ImageCropperModal';
import useCreateEvent from 'hooks/event/useCreateEvent';
import { some } from 'lodash';
import { routesPaths } from 'constants/routesPaths';
import EventService from 'services/eventService';
import parseError from 'utils/parseError';
import useUpdateEvent from 'hooks/event/useUpdateEvent';
import UserService from 'services/userService';

const fields = {
  title: 'title',
  description: 'description',
  event_date: 'event_date',
  duration: 'duration',
  timezone: 'timezone',
  event_time: 'event_time',
  members_price: 'members_price',
  non_members_price: 'non_members_price',
  web_cover_image: 'web_cover_image',
  mobile_cover_image: 'mobile_cover_image',
  record_event: 'record_event',
  notify_subscribers: 'notify_subscribers'
};

const AddGoLiveForm = ({ eventId, isGolive }) => {
  const {
    user: { id: celebrityId }
  } = useSession();
  const { goToWithSearch, goTo } = useNavigation();
  const desktopCoverRef = useRef(null);
  const mobileCoverRef = useRef(null);
  const username = useRef();
  const [desktopCoverPic, setDesktopCoverPic] = useState(null);
  const [mobileCoverPic, setMobileCoverPic] = useState(null);
  const { onSubmit: handleCreate, status, error } = useCreateEvent();
  const { onSubmit: handleUpdate, status: updateStatus, error: updateError } = useUpdateEvent();
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [croppingUrl, setCroppingUrl] = useState();
  const [isLoading, setisLoading] = useState(false);
  const [aspectRatio, setAspectRatio] = useState();
  const checkType = useRef();
  const { showErrorToast } = useToast();
  const validator = useValidation(eventValidation);
  const [eventData, setEventData] = useState();
  const [isDesktopBannerFalse, setIsDesktopBannerFalse] = useState(false);
  const [isMobileBannerFalse, setIsMobileBannerFalse] = useState(false);

  const handleFormSubmit = async data => {
    setisLoading(true);
    const now = new Date();
    const formattedDate = now.toISOString().split('T')[0];
    const formattedTime = now.toTimeString().split(' ')[0];
    const formData = new FormData();
    formData.append('event[title]', data.title);
    formData.append('event[description]', data.description);
    formData.append('event[event_date]', formattedDate);
    formData.append('event[duration]', '02:00:00');
    formData.append('event[event_time]', formattedTime);
    formData.append('event[web_cover_image]', data.web_cover_image);
    formData.append('event[mobile_cover_image]', data.mobile_cover_image);

    const updatedEventData = await postEventData(celebrityId, formData);

    const roomDetail = await startEvent(updatedEventData.event.id);

    setisLoading(false);

    goTo(
      routeWithProps(routesPaths.goLive, {
        role: 'Host',
        room: roomDetail?.liveStream.roomKey,
        username: username.current.toLowerCase(),
        celebrityId: celebrityId,
        eventId: updatedEventData?.event.id
      })
    );

    //  console.log(updatedEventData, result, 'data');
  };

  const { values, errors, handleValueChange, handleSubmit, handleBlur, setValues } = useForm(
    {
      onSubmit: handleFormSubmit,
      validateOnBlur: true,
      validateOnChange: true,
      initialValues: {}
      // validator
    },
    [handleFormSubmit]
  );

  const inputProps = useTextInputProps(handleValueChange, handleBlur, values);
  const handleDesktopCoverUpload = () => {
    desktopCoverRef.current.click();
  };
  const handleMobileCoverUpload = () => {
    mobileCoverRef.current.click();
  };

  const handleDesktopCoverChange = async e => {
    const file = e.target.files[0];
    const result = await checkImageDimensions(
      file,
      imageSizes.eventDesktopCover.width,
      imageSizes.eventDesktopCover.height
    );
    if (result.isValid) {
      setCropModalOpen(true);
      setAspectRatio(16 / 9);
      setCroppingUrl(URL.createObjectURL(file));
      checkType.current = 'desktop';
    } else {
      showErrorToast(result.error);
    }
  };

  const handleMobileCoverChange = async e => {
    const file = e.target.files[0];
    const result = await checkImageDimensions(
      file,
      imageSizes.eventMobileCover.width,
      imageSizes.eventMobileCover.height
    );
    if (result.isValid) {
      setCropModalOpen(true);
      setAspectRatio(16 / 9);
      setCroppingUrl(URL.createObjectURL(file));
      checkType.current = 'mobile';
    } else {
      showErrorToast(result.error);
    }
  };

  const handleCancel = () => {
    setCropModalOpen(false);
    setCroppingUrl();
    setAspectRatio();
    checkType.current = null;
  };

  const handleCrop = cropImg => {
    if (checkType.current === 'desktop') {
      setDesktopCoverPic(cropImg);
      handleValueChange(fields.web_cover_image, cropImg);
    } else {
      setMobileCoverPic(cropImg);
      handleValueChange(fields.mobile_cover_image, cropImg);
    }
  };

  const postEventData = async (celebrityId, event) => {
    try {
      const { data } = await EventService.addEvent(celebrityId, event);
      return data;
    } catch ({ response }) {
      throw parseError(response);
    }
  };

  const startEvent = async eventId => {
    try {
      const { data } = await EventService.startEventById(eventId);
      return data;
    } catch ({ response }) {
      throw parseError(response);
    }
  };

  const getProfile = async () => {
    try {
      const { data } = await UserService.getProfile();
      username.current = data.user.username;
    } catch ({ response }) {
      throw parseError(response);
    }
  };

  useEffect(() => {
    getProfile();
  }, []);

  const handleCancelBtn = () => {
    const route = routeWithProps(routesPaths.celebrityId, {
      username: username.current.toLowerCase()
    });
    goToWithSearch(route, `?tab=3`);
  };

  // const isLoading = status === LOADING || updateStatus === LOADING;
  const hasErrors = some(errors);
  const isFormIncomplete =
    !values[fields.title] || !values[fields.description] || !desktopCoverPic || !mobileCoverPic;

  const isDisabled = isFormIncomplete || isLoading;

  return (
    <div className="add-event-form">
      <div className="add-event-form-fields">
        <Input
          name="title"
          placeholder="Event Title"
          label="Title"
          errors={errors[fields.title]}
          {...inputProps(fields.title)}
        />
        <Input
          name="description"
          placeholder="Event Description"
          label="Description"
          errors={errors[fields.description]}
          {...inputProps(fields.description)}
        />
        <input
          type="file"
          ref={desktopCoverRef}
          style={{ display: 'none' }}
          accept="image/*"
          name="profile_image"
          onChange={handleDesktopCoverChange}
        />
        <input
          type="file"
          ref={mobileCoverRef}
          style={{ display: 'none' }}
          accept="image/*"
          onChange={handleMobileCoverChange}
          name="banner"
        />
        <div>
          <span className={desktopCoverPic?.name ? 'white-custom-label' : 'custom-label'}>
            Cover Image (Desktop)
          </span>
          {eventId && desktopCoverPic && !isDesktopBannerFalse && (
            <div className="display-loaded-pic">
              <img className="url-pic" src={eventData?.webCoverImageUrl} alt="desktop-banner" />
              <Button
                labelId="Change"
                type="secondary"
                onClick={() => {
                  setDesktopCoverPic(null);
                  setIsDesktopBannerFalse(true);
                  handleDesktopCoverUpload();
                }}
                className="change-button"
              />
            </div>
          )}
          {desktopCoverPic && (!eventId || isDesktopBannerFalse) && (
            <div className="white-label">
              <span className="white-name">{desktopCoverPic?.name}</span>
              <span
                className="white-name"
                style={{ cursor: 'pointer' }}
                onClick={() => setDesktopCoverPic(null)}
              >
                x
              </span>
            </div>
          )}
          {!desktopCoverPic && (
            <div className="button-margin">
              <Button
                title="UPLOAD COVER IMAGE"
                type="secondary"
                onClick={handleDesktopCoverUpload}
                className="profile-button"
              />
              <span className="label-margin">Recommended dimension: 800px X 450px (16:9)</span>
            </div>
          )}
        </div>
        <div>
          <span className={mobileCoverPic?.name ? 'white-custom-label' : 'custom-label'}>
            cover image (mobile)
          </span>
          {eventId && mobileCoverPic && !isMobileBannerFalse && (
            <div className="display-loaded-pic">
              <img className="url-pic" src={eventData?.mobileCoverImageUrl} alt="desktop-banner" />
              <Button
                labelId="Change"
                type="secondary"
                onClick={() => {
                  setMobileCoverPic(null);
                  setIsMobileBannerFalse(true);
                  handleMobileCoverUpload();
                }}
                className="change-button"
              />
            </div>
          )}
          {mobileCoverPic && (!eventId || isMobileBannerFalse) && (
            <div className="white-label">
              <span className="white-name">{mobileCoverPic?.name}</span>
              <span
                className="white-name"
                style={{ cursor: 'pointer' }}
                onClick={() => setMobileCoverPic(null)}
              >
                x
              </span>
            </div>
          )}
          {!mobileCoverPic && (
            <div className="button-margin">
              <Button
                title="UPLOAD COVER IMAGE"
                type="secondary"
                onClick={handleMobileCoverUpload}
                className="profile-button"
              />
              <span className="label-margin">Recommended dimension: 320px X 180px (16:9)</span>
            </div>
          )}
        </div>
      </div>
      <div className="creator-form-error p1">{error || updateError}</div>
      <div className="btn-container">
        <Button
          title="Cancel"
          className="profile-button"
          type="secondary"
          onClick={() => handleCancelBtn()}
        />
        <Button
          title="Go Live"
          className="submit-button"
          onClick={handleSubmit}
          type="submit"
          disabled={isDisabled}
        >
          {isLoading && (
            <div style={{ marginTop: '-15px' }}>
              <Loading type="ball-clip-rotate" />
            </div>
          )}
        </Button>
      </div>
      {cropModalOpen && (
        <ImageCropperModal
          visible={cropModalOpen}
          imageUrl={croppingUrl}
          onCancel={handleCancel}
          onCrop={handleCrop}
          aspectRatio={aspectRatio}
        />
      )}
    </div>
  );
};

export default memo(AddGoLiveForm);
