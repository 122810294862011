import { createAction, createThunk } from '@rootstrap/redux-tools';
import seriesService from 'services/seriesService';
import parseError from 'utils/parseError';

export const addEpisode = createAction('ADD_EPISODE');

export const clearEpisodes = createAction('CLEAR_EPISODES');

export const removeEpisode = createAction('REMOVE_EPISODE');

export const reassignEpisodes = createAction('REASSIGN_EPISODES');

// export const updateEpisode = createAction('UPDATE_EPISODE');
export const updateEpisode = createThunk('UPDATE_EPISODE', async props => {
  try {
    const { data } = await seriesService.updateEpisode(props);
    return data;
  } catch ({ response }) {
    throw parseError(response);
  }
});

export const getVideoUrlForJWPlayer = createThunk('GET_VIDEO_URL', async video => {
  try {
    const { data } = await seriesService.getVideoUrlForJWPlayer(video.title);
    return { ...data, ...video };
  } catch ({ response }) {
    throw parseError(response);
  }
});
