import React, { useEffect, useState } from 'react';
import { string } from 'prop-types';
import cn from 'classnames';

import { black40 } from 'styles/common/_constants.scss';
import { useNavigation } from 'hooks';

import { BackArrowIcon } from 'components/icons';

const Header = ({ label }) => {
  const [showBackground, setShowBackground] = useState(false);
  const { goBack } = useNavigation();

  const handleScroll = () => setShowBackground(window.pageYOffset > 0);

  useEffect(() => window?.addEventListener('scroll', handleScroll), []);

  return (
    <header className={cn('header', 'custom-header', { showBackground })}>
      <div className="back-button clickable" onClick={goBack}>
        <BackArrowIcon color={black40} />
      </div>
      <div className="category-semibold">{label}</div>
    </header>
  );
};

Header.propTypes = {
  label: string.isRequired
};

export default Header;
