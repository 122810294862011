import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useStatus, SUCCESS, ERROR } from '@rootstrap/redux-tools';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { requestCode, login, registerDevice } from 'state/actions/userActions';
import { routesPaths } from 'constants/routesPaths';
import { useToast, useAnalytics, useSession } from 'hooks';
import { MIXPANEL_EVENTS } from 'constants/constants';

export default (isSignUp = false, customAction) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  const { trackEvent, createAlias, identify, setPeople } = useAnalytics();
  const { info } = useSession();

  const { showToast, showErrorToast } = useToast();
  const { status, error } = useStatus(login);
  const { status: resendStatus, error: resendError } = useStatus(requestCode);

  const onSubmit = useCallback(confirmation => dispatch(login(confirmation)), [dispatch]);

  useEffect(() => {
    if (status === SUCCESS) {
      if (customAction) {
        customAction();
      } else {
        history.push(routesPaths.home);
      }
      dispatch(login.reset());
      dispatch(registerDevice());
      if (isSignUp) {
        showToast(intl.formatMessage({ id: 'user.createAccount.success' }));
        trackEvent(MIXPANEL_EVENTS.signUpCompleted);
        createAlias(info?.uid);
      } else {
        identify(info?.uid);
      }
      setPeople({
        $email: info?.uid
      });
    }
  }, [status, dispatch, history, intl, isSignUp, showToast, trackEvent, info]);

  const resendCodeRequest = useCallback(phone => dispatch(requestCode(phone)), [dispatch]);

  useEffect(() => {
    if (resendStatus === SUCCESS) {
      dispatch(requestCode.reset());
      showToast(intl.formatMessage({ id: 'verifyPhone.sendCodeSuccess' }));
    }
    if (resendStatus === ERROR) {
      dispatch(requestCode.reset());
      showErrorToast(resendError || intl.formatMessage({ id: 'verifyPhone.sendCodeError' }));
    }
  }, [resendStatus, resendError, dispatch, intl, showToast, showErrorToast]);

  return {
    onSubmit,
    status,
    error,
    resendCode: resendCodeRequest
  };
};
