import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { number } from 'prop-types';

import { useModal } from 'hooks';
import useNotifications from 'hooks/notifications/useNotifications';
import useAccount from 'hooks/account/useAccount';
import { routesPaths } from 'constants/routesPaths';
import { accountTabs } from 'constants/constants';

import Modal from 'components/common/Modal/Modal';
import Notifications from 'components/notifications/Notifications/Notifications';
import NavRow from 'components/account/AccountNav/NavRow';
import { CrownIcon, BellIcon, LogoutIcon, CreditCardIcon, SettingsIcon } from 'components/icons';

import { zIndexModalSecond } from 'styles/common/_constants.scss';
import useLogout from 'hooks/users/useLogout';

const { SUBSCRIPTIONS, PAYMENTS, SETTINGS, ADSUBSCRIPTION } = accountTabs;

const AccountNav = ({ initialTab }) => {
  const intl = useIntl();
  const history = useHistory();
  const { renderAccountContent, changeUrl } = useAccount();
  const { logout } = useLogout();

  const [selectedTab, setSelectedTab] = useState(initialTab);

  const { toggle, isShowing } = useModal(initialTab);
  const { toggle: toggleNotifications, isShowing: showingNotifications } = useModal();

  const showModal = tab => {
    setSelectedTab(tab);
    changeUrl(tab);
    toggle();
  };
  const hideModal = () => {
    changeUrl();
    toggle();
  };

  const { badge } = useNotifications();

  const logoutClick = () => {
    logout();
    history.push(routesPaths.home);
  };

  return (
    <div className="account-navigation">
      <NavRow
        label={intl.formatMessage({ id: 'notifications.title' })}
        icon={<BellIcon width={20} height={20} />}
        onClick={toggleNotifications}
        showBadge={badge}
      />
      <NavRow
        label={intl.formatMessage({ id: 'subscriptions.title' })}
        icon={<CrownIcon width={20} height={20} />}
        onClick={() => showModal(SUBSCRIPTIONS.id)}
      />
      <NavRow
        label={intl.formatMessage({ id: 'adsubscription.title' })}
        icon={<CrownIcon width={20} height={20} />}
        onClick={() => showModal(ADSUBSCRIPTION.id)}
      />
      <NavRow
        label={intl.formatMessage({ id: 'navbar.paymentMethod' })}
        icon={<CreditCardIcon width={20} height={20} />}
        onClick={() => showModal(PAYMENTS.id)}
      />
      <NavRow
        label={intl.formatMessage({ id: 'navbar.settings' })}
        icon={<SettingsIcon width={20} height={20} />}
        onClick={() => showModal(SETTINGS.id)}
      />
      <NavRow
        label={intl.formatMessage({ id: 'user.logout' })}
        icon={<LogoutIcon />}
        className="light"
        onClick={logoutClick}
        disableArrow
      />
      <Modal hide={toggleNotifications} isShowing={showingNotifications} zIndex={zIndexModalSecond}>
        <Notifications isModal />
      </Modal>
      <Modal hide={hideModal} isShowing={isShowing} zIndex={zIndexModalSecond}>
        {renderAccountContent(selectedTab, true)}
      </Modal>
    </div>
  );
};

AccountNav.propTypes = {
  initialTab: number
};

export default AccountNav;
