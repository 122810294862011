import React, { useEffect, useState } from 'react';
import { shape, bool, string, func } from 'prop-types';
import { SUCCESS } from '@rootstrap/redux-tools';

import Modal from 'components/common/Modal/Modal';
import Button from 'components/common/Button/Button';
import Crown from 'components/icons/membership/Crown';
import AuthModal from 'components/user/AuthModal/AuthModal';
import UnlockEpisode from 'components/unlock/UnlockEpisode';
import PurchaseModal from 'components/payments/PurchaseModal/PurchaseModal';

import { celebrityShape } from 'constants/propTypesShapes';

import { useIsMobile, useModal } from 'hooks';
import useProfile from 'hooks/users/useProfile';
import useSubscribeContext from 'hooks/account/useSubscribeContext';

import { zIndexModalSecond } from 'styles/common/_constants.scss';

import { checkSubscription } from 'utils/helpers';

const ICON_SIZE = 20;

const UnlockEpisodeButton = ({ celebrity, title, size, onSuccess, smallBoldFont }) => {
  const { closeMenu, paymentsVisible } = useSubscribeContext();
  const isMobile = useIsMobile();

  const { toggle: toggleAuth, isShowing: isShowingAuth } = useModal();
  const { toggle: toggleSubscribe, isShowing: isShowingSubscribe } = useModal();
  const { toggle: togglePayments, isShowing: isShowingPayments } = useModal(
    isMobile && paymentsVisible
  );
  const [showedAuth, setShowedAuth] = useState(false);

  const {
    profile: { memberships },
    requestProfile,
    status,
    reset
  } = useProfile();

  const isSubscribed = checkSubscription(memberships, celebrity.id);

  const showAuthModal = () => {
    setShowedAuth(true);
    toggleAuth();
  };

  const hidePaymentsModal = () => {
    closeMenu();
    togglePayments();
  };

  useEffect(() => {
    if (status === SUCCESS) {
      if (showedAuth) {
        isSubscribed && !!onSuccess && onSuccess();
        !isSubscribed && togglePayments();
      }
      reset();
      setShowedAuth(false);
    }
  }, [status]);

  return (
    <>
      <Button
        size={size}
        type={isSubscribed ? 'black' : 'primary'}
        title={title}
        icon={<Crown width={ICON_SIZE} height={ICON_SIZE} />}
        labelId={isSubscribed ? 'membership.subscribed' : 'membership.subscribe'}
        onClick={toggleSubscribe}
        smallBoldFont={smallBoldFont}
        className="subscribe-button"
      />
      <Modal hide={toggleAuth} isShowing={isShowingAuth} zIndex={zIndexModalSecond}>
        <AuthModal isModal onSuccess={requestProfile} />
      </Modal>
      <Modal hide={toggleSubscribe} isShowing={isShowingSubscribe}>
        <UnlockEpisode
          celebrity={celebrity}
          togglePayments={togglePayments}
          redirectToAuth={showAuthModal}
        />
      </Modal>
      <Modal hide={hidePaymentsModal} isShowing={isShowingPayments}>
        <PurchaseModal celebrity={celebrity} onSuccess={onSuccess} />
      </Modal>
    </>
  );
};

UnlockEpisodeButton.defaultProps = {
  size: 'xxsmall'
};

UnlockEpisodeButton.propTypes = {
  celebrity: shape(celebrityShape).isRequired,
  onSuccess: func,
  title: string.isRequired,
  size: string,
  smallBoldFont: bool
};

export default UnlockEpisodeButton;
