import httpClient from 'httpClient';

class VideoAdsService {
  static createvideoAds(celebrityId, videoad) {
    return httpClient.post(`/celebrities/${celebrityId}/video_ads`, videoad);
  }

  static getVideoAdsById(videoId, celebrityId) {
    return httpClient.get(`/celebrities/${celebrityId}/video_ads/${videoId}`);
  }

  static updatevideoAd(celebrityId, videoId, videoad) {
    return httpClient.put(`/celebrities/${celebrityId}/video_ads/${videoId}`, videoad);
  }

  static deletevideoAd(celebrityId, videoId) {
    return httpClient.delete(`/celebrities/${celebrityId}/video_ads/${videoId}`);
  }

  static deleteNarrativeIndivualvideoAd(celebrityId, narrativeId, videoId) {
    return httpClient.delete(
      `/celebrities/${celebrityId}/narratives/${narrativeId}/video_ads/${videoId}`
    );
  }

  static deleteSeriesIndivualvideoAd(celebrityId, episodeId, videoId) {
    return httpClient.delete(
      `/celebrities/${celebrityId}/episodes/${episodeId}/video_ads/${videoId}`
    );
  }

  static disableNarrativevideoAd(celebrityId, narrativeId, videoId) {
    return httpClient.put(
      `/celebrities/${celebrityId}/narratives/${narrativeId}/video_ads/${videoId}/disable`
    );
  }

  static disableSeriesvideoAd(celebrityId, episodeId, videoId) {
    return httpClient.put(
      `/celebrities/${celebrityId}/episodes/${episodeId}/video_ads/${videoId}/disable`
    );
  }
}

export default VideoAdsService;
