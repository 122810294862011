import React, { useEffect, useCallback, useMemo } from 'react';
import cn from 'classnames';
import { shape, func } from 'prop-types';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash/isEmpty';

import Loading from 'components/common/Loading';
import UnlockEpisodeHeader from 'components/unlock/UnlockEpisodeHeader';
import { usePrice } from 'components/payments/PurchaseModal/useModalInfo';
import MembershipBenefit from 'components/membership/MembershipBenefit/MembershipBenefit';
import { membershipBenefits } from 'components/membership/MembershipDetails/MembershipDetails';

import { celebrityShape } from 'constants/propTypesShapes';
import { MIXPANEL_EVENTS, PURCHASE_TYPES } from 'constants/constants';

import useEpisode from 'hooks/useEpisode';
import useProfile from 'hooks/users/useProfile';
import useMembership from 'hooks/celebrities/useMembership';
import { useClickOutside, useAnalytics, useSession } from 'hooks';

import { getFullName, checkSubscription } from 'utils/helpers';

import UnlockSubscribeBanner from '../UnlockSubscribeBanner';

const UnlockEpisode = ({ celebrity, hide, togglePayments, redirectToAuth }) => {
  const intl = useIntl();
  const ref = useClickOutside(hide);
  const { authenticated } = useSession();
  const { trackEvent } = useAnalytics();
  const {
    profile: { memberships }
  } = useProfile();
  const { loading, membershipPlans, getMembership } = useMembership();
  const { episode, flowConfig } = useEpisode();

  const isSubscribed = checkSubscription(memberships, celebrity.id);

  const EPISODE_PRICE = usePrice({ episode, memberships, celebrity });

  const { firstName, lastName } = celebrity;
  const fullName = useMemo(() => getFullName(firstName, lastName), [firstName, lastName]);
  const PURCHASE_MEMBERSHIP_AND_EPISODE =
    flowConfig.purchaseType === PURCHASE_TYPES.MEMBERSHIP_EPISODE;

  useEffect(() => {
    if (!authenticated) {
      redirectToAuth();
    }
  }, [authenticated]);

  useEffect(() => {
    getMembership(celebrity.id);
  }, [celebrity]);

  useEffect(() => {
    trackEvent(MIXPANEL_EVENTS.membershipView, {
      'Creator Name': fullName
    });
  }, []);

  const showPayments = useCallback(() => {
    if (authenticated) {
      togglePayments();
      hide();
    } else {
      redirectToAuth();
    }
  }, [authenticated, redirectToAuth, togglePayments, hide]);

  if (loading || isEmpty(membershipPlans))
    return (
      <div className="membership">
        <Loading />
      </div>
    );

  const planDetails = membershipPlans[0];

  return (
    <div
      ref={ref}
      className={cn('unlock-episode-container', {
        'h-100': flowConfig.purchaseType === PURCHASE_TYPES.MEMBERSHIP_EPISODE
      })}
    >
      <UnlockEpisodeHeader
        episode={episode.title}
        price={EPISODE_PRICE}
        membershipPrice={planDetails.price}
        celebrity={celebrity}
        hide={hide}
        showPayments={showPayments}
        isSubscribed={isSubscribed}
      />
      {PURCHASE_MEMBERSHIP_AND_EPISODE && (
        <div className="unlock-membership-content">
          {authenticated && (
            <>
              {!isSubscribed && (
                <div className="membership-content-description p2">
                  {intl.formatMessage({ id: 'membership.description' }, { name: fullName })}
                </div>
              )}
              <div className="membership-content-title">
                <h4 className="bold">{fullName}</h4>
                <h4 className="bold">
                  {intl.formatMessage({ id: 'membership.celebrityBenefits' })}
                </h4>
              </div>
            </>
          )}
          {membershipBenefits.map(({ icon, title, description }, index) => (
            <MembershipBenefit
              key={`benefit-${index}`}
              icon={icon}
              title={intl.formatMessage({ id: title })}
              description={intl.formatMessage({ id: description }, { firstName })}
            />
          ))}
          {!isSubscribed && (
            <UnlockSubscribeBanner
              episode={episode.title}
              onClick={showPayments}
              planDetails={planDetails}
              celebrityName={fullName}
            />
          )}
        </div>
      )}
    </div>
  );
};

UnlockEpisode.propTypes = {
  celebrity: shape(celebrityShape).isRequired,
  togglePayments: func.isRequired,
  hide: func,
  redirectToAuth: func.isRequired
};

export default UnlockEpisode;
