import httpClient from 'httpClient';
import { applyQueryParams } from 'utils/helpers';

class LiveEventsService {
  getScheduledLiveEvent(id) {
    return httpClient.get(`/scheduled_live_events/${id}`);
  }

  purchaseScheduledLiveEvent(cardId, liveId) {
    const url = applyQueryParams('/ppvs/scheduled_live_events', {
      credit_card_id: cardId,
      scheduled_live_event_id: liveId
    });
    return httpClient.post(url);
  }
}

export default new LiveEventsService();
