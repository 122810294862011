import { useIntl } from 'react-intl';

import { PURCHASE_TYPES } from 'constants/constants';
import useProfile from 'hooks/users/useProfile';
import { checkSubscription } from 'utils/helpers';
import { isEmpty } from 'lodash';

export function usePrice({ episode = {}, memberships, celebrity }) {
  if (isEmpty(episode)) return null;
  const { membersPrice, nonMembersPrice } = episode;
  const isSubscribed = checkSubscription(memberships, celebrity.id);
  return isSubscribed ? membersPrice : nonMembersPrice || membersPrice;
}

const useModalInfo = ({ purchaseType, modalInfo, episode, celebrity }) => {
  const intl = useIntl();
  const {
    profile: { memberships }
  } = useProfile();

  const price = usePrice({ episode, memberships, celebrity });

  if (!purchaseType) {
    return modalInfo;
  }

  if (purchaseType === PURCHASE_TYPES.EPISODE) {
    return {
      title: intl.formatMessage({ id: 'unlock.episode.title' }, { name: episode.title }),
      subtitle: intl.formatMessage({ id: 'unlock.episode.payment' }),
      price: `$${price}`,
      image: episode.thumbnailsUrls[0]?.src
    };
  }

  if (purchaseType === PURCHASE_TYPES.PODCAST) {
    return {
      title: intl.formatMessage({ id: 'unlock.podcast.title' }, { name: episode.title }),
      subtitle: intl.formatMessage({ id: 'unlock.episode.payment' }),
      price: `$${price}`,
      image: episode.video.thumbnailsUrls[0]?.src
    };
  }

  if (purchaseType === PURCHASE_TYPES.MEMBERSHIP_EPISODE) {
    return {
      title: modalInfo.title,
      subtitle: episode.title,
      price,
      image: episode.thumbnailsUrls[0]?.src
    };
  }
  return {};
};

export default useModalInfo;
