import React from 'react';
import { number, string, oneOf } from 'prop-types';

import { white } from 'styles/common/_constants.scss';

const UP = 'up';
const DOWN = 'down';
const LEFT = 'left';
const RIGHT = 'right';

const ArrowIcon = ({ width = 15, height = 8, color = white, type, className }) => {
  let rotation = 0;
  switch (type) {
    case UP:
      rotation = 180;
      break;
    case LEFT:
      rotation = 90;
      break;
    case RIGHT:
      rotation = 270;
      break;
    default:
      rotation = 0;
      break;
  }

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 15 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="logoTitle logoDesc"
      role="img"
      style={{ transform: `rotate(${rotation}deg)`, transformOrigin: '50% 50%' }}
    >
      <title id="logoTitle">Arrow</title>
      <desc id="logoDesc">Arrow icon</desc>
      <path
        d="M1.5 1L7.5 7L13.5 1"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

ArrowIcon.propTypes = {
  className: string,
  width: number,
  height: number,
  color: string,
  type: oneOf([DOWN, UP, LEFT, RIGHT])
};

ArrowIcon.defaultProps = {
  type: DOWN
};

export default ArrowIcon;
