import { useSelector } from 'react-redux';

export const useSeries = () =>
  useSelector(({ series: { seriesList, keepWatchingList } }) => ({
    seriesList,
    keepWatchingList
  }));

export const useKeepWatching = serieId =>
  useSelector(({ series: { keepWatchingList } }) => {
    const serieAux = keepWatchingList.find(element => {
      return element.id == serieId;
    });
    return serieAux?.watchedEpisode;
  });
