import { useIntl } from 'react-intl';
import { getFullName, getShortPeriod } from 'utils/helpers';
import { useState, useEffect, useMemo, useCallback } from 'react';
import { useStatus, SUCCESS, ERROR } from '@rootstrap/redux-tools';
import { useDispatch } from 'react-redux';

import usePayments from 'hooks/payments/usePayments';
import useMembership from 'hooks/celebrities/useMembership';
import { useAnalytics, useToast } from 'hooks';
import { createCard } from 'state/actions/paymentsActions';
import { MIXPANEL_EVENTS } from 'constants/constants';

export default (celebrity, liveEvent) => {
  const [showConfirmation, setShowConfirmation] = useState(false);

  const intl = useIntl();
  const { trackEvent } = useAnalytics();
  const { getCreditCards, creditCards, reset } = usePayments();
  const { showErrorToast } = useToast();
  const { membershipPlans } = useMembership();
  const dispatch = useDispatch();

  const { status, error } = useStatus(createCard);

  const membership = membershipPlans[0];

  useEffect(() => {
    dispatch(createCard.reset());
    getCreditCards();
    !!celebrity &&
      trackEvent(MIXPANEL_EVENTS.subscriptionStarted, {
        'Subscription Type': membership?.name,
        'Creator Name': getFullName(celebrity.firstName, celebrity.lastName)
      });
  }, []);

  useEffect(() => {
    switch (status) {
      case SUCCESS:
        reset();
        getCreditCards();
        trackEvent(MIXPANEL_EVENTS.creditCardAdded);
        setShowConfirmation(true);
        break;
      case ERROR:
        reset();
        showErrorToast(error);
        break;
      default:
    }
  }, [status]);

  const getEventInfo = useCallback(() => {
    const { title, logoImageUrl, price } = liveEvent;
    const subtitle = intl.formatMessage({ id: 'payPerView.purchaseSubtitle' });

    return { title, image: logoImageUrl, price: `$${price}`, subtitle };
  }, [liveEvent, intl]);

  const getCelebrityInfo = useCallback(() => {
    const { firstName, lastName, profileImageUrl } = celebrity;
    const { price, period } = membership;
    const title = `${intl.formatMessage({
      id: 'membership.complete.to'
    })} ${getFullName(firstName, lastName)}`;

    const subtitle = intl.formatMessage(
      { id: 'membership.subscribePrice' },
      { price, period: getShortPeriod(period) }
    );

    const priceLabel = intl.formatMessage(
      { id: 'membership.pricePerPeriod' },
      { price, period: getShortPeriod(period) }
    );

    return { title, subtitle, price: priceLabel, image: profileImageUrl };
  }, [celebrity, membership, intl]);

  const modalInfo = useMemo(() => (celebrity ? getCelebrityInfo() : getEventInfo()), [celebrity]);

  return {
    modalInfo,
    showConfirmation,
    setShowConfirmation,
    membership,
    creditCards
  };
};
