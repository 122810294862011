import React from 'react';

import { VideoIcon } from 'components/icons';

const LiveLabel = () => (
  <div className="live-label">
    <VideoIcon />
  </div>
);

export default LiveLabel;
