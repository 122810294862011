/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import './CustomModal.css';

const CustomModal = ({ visible, onCancel, children, onSave }) => {
  if (!visible) return null;

  return (
    <div className="custom-modal-overlay" onClick={onCancel}>
      <div className="custom-modal-content" onClick={e => e.stopPropagation()}>
        <div className="custom-modal-header">
          <p />
          <h2>Crop image</h2>
          <button className="custom-modal-close" onClick={onCancel}>
            ×
          </button>
        </div>
        <div className="custom-modal-body">{children}</div>
        <div className="custom-modal-footer">
          <button className="custom-modal-cancel" onClick={onCancel}>
            CANCEL
          </button>
          <button className="custom-modal-save" onClick={onSave}>
            CROP & SAVE
          </button>
        </div>
      </div>
    </div>
  );
};

CustomModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

export default CustomModal;
