/* eslint-disable camelcase */
/* eslint-disable no-useless-catch */
/* eslint-disable no-plusplus */
/* eslint-disable no-await-in-loop */
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useStatus, SUCCESS } from '@rootstrap/redux-tools';

import { useNavigation, useSession, useToast } from 'hooks';
import capitalize from 'lodash/capitalize';
import parseError from 'utils/parseError';
import UserService from 'services/userService';
import { updateWidgetAd } from 'state/actions/widgetAdAction';
import { routeWithProps } from 'utils/helpers';
import { routesPaths } from 'constants/routesPaths';

export default () => {
  const dispatch = useDispatch();
  const {
    user: { id: celebrityId }
  } = useSession();
  const username = useRef();
  const { showToast } = useToast();
  const { goTo } = useNavigation();
  const [loading, setLoading] = useState(false);
  const { status, error } = useStatus(updateWidgetAd);

  const onSubmit = useCallback(
    data => {
      setLoading(true);
      const formData = new FormData();
      Object.keys(data).forEach(key => {
        if (Array.isArray(data[key])) {
          // Handle arrays like episode_ids and narrative_ids as comma-separated values
          if (key === 'episode_ids' || key === 'narrative_ids') {
            if (Array.isArray(data[key])) {
              data[key].forEach(id => {
                formData.append(`widget_ad[${key}][]`, id);
              });
            } else if (typeof data[key] === 'string') {
              const ids = data[key].split(',').map(id => id.trim());
              ids.forEach(id => {
                formData.append(`widget_ad[${key}][]`, id);
              });
            }
          } else if (key === 'widget_ad_images') {
            // Handle nested arrays (like widget_ad_images)
            data[key].forEach((imgData, index) => {
              Object.keys(imgData).forEach(innerKey => {
                formData.append(`widget_ad[${key}][${index}][${innerKey}]`, imgData[innerKey]);
              });
            });
          }
        } else {
          // Handle non-array fields
          if (key !== 'id') {
            formData.append(`widget_ad[${key}]`, data[key]);
          }
        }
      });

      dispatch(updateWidgetAd(celebrityId, formData, data.id));
    },
    [dispatch, celebrityId]
  );

  const getProfile = async () => {
    try {
      const { data } = await UserService.getProfile();
      username.current = data.user.username;
    } catch ({ response }) {
      throw parseError(response);
    }
  };

  useEffect(() => {
    getProfile();
  }, []);

  useEffect(() => {
    if (status === SUCCESS) {
      showToast('Widget Ad Updated!');
      goTo(routeWithProps(routesPaths.adManager, { tab: 'widgetsAds' }));
      dispatch(updateWidgetAd.reset());
    }
  }, [status, dispatch, showToast]);

  return {
    onSubmit,
    status,
    loading,
    error: capitalize(error)
  };
};
