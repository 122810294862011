import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import ActiveInfoCard from './ActiveInfoCard';

const ActiveIn = ({ data }) => {
  const [activeInData, setActiveInData] = useState();

  const combineEpisodesAndNarratives = data => {
    const episodes = data.episodes.map(episode => ({
      ...episode,
      type: 'Movies & Series'
    }));

    const narratives = data.narratives.map(narrative => ({
      ...narrative,
      type: 'Shorts'
    }));

    setActiveInData([...episodes, ...narratives]);
  };
  useEffect(() => {
    combineEpisodesAndNarratives(data);
  }, [data]);

  return (
    <div className={cn('active-in')}>
      {' '}
      <div>
        <span className="title">Active in</span>
      </div>
      <div className="active-info-card-container">
        {activeInData?.map((item, index) => {
          return <ActiveInfoCard data={item} key={index} />;
        })}

        {/* <ActiveInfoCard />
        <ActiveInfoCard />
        <ActiveInfoCard />
        <ActiveInfoCard /> */}
      </div>
    </div>
  );
};

export default ActiveIn;
