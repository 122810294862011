import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useStatus, SUCCESS, ERROR } from '@rootstrap/redux-tools';

import useToast from 'hooks/useToast';
import { deleteSeries } from 'state/actions/seriesActions';

export default callback => {
  const dispatch = useDispatch();
  const { status, error } = useStatus(deleteSeries);
  const { showErrorToast, showToast } = useToast();

  useEffect(() => {
    if (status === SUCCESS) {
      showToast('Publication successfully deleted!');
      dispatch(deleteSeries.reset());
      callback();
    } else if (status === ERROR) {
      showErrorToast(error);
      dispatch(deleteSeries.reset());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return useCallback(seriesId => dispatch(deleteSeries(seriesId)), [dispatch]);
};
