import React from 'react';
import { shape, func } from 'prop-types';
import { useIntl } from 'react-intl';

import { getFullName, getShortPeriod } from 'utils/helpers';
import { celebrityShape, membershipPlan } from 'constants/propTypesShapes';
import { useIsMobile } from 'hooks';

import BackgroundOpacity from 'components/common/BackgroundOpacity/BackgroundOpacity';
import ProfilePicture from 'components/ids/ProfilePicture/ProfilePicture';
import Close from 'components/common/Close/Close';
import Button from 'components/common/Button/Button';
import Crown from 'components/icons/membership/Crown';

const BACKGROUND_OPACITY = 0.7;
const BUTTON_ICON_WIDTH = 20;
const PROFILE_IMAGE_SIZE = 92;

const MembershipHeader = ({ celebrity, hide, planDetails, showPayments, isSubscribed }) => {
  const intl = useIntl();
  const { bannerUrl, profileImageUrl, firstName, lastName } = celebrity;
  const { price, period } = planDetails;
  const isMobile = useIsMobile();

  return (
    <div className="membership-header">
      <div className="membership-header-celebrity">
        {!!bannerUrl && <BackgroundOpacity background={bannerUrl} opacity={BACKGROUND_OPACITY} />}
        <div className="membership-header-gradient" />
        <div className="membership-header-gradient-vertical desktop-only" />
        <div className="membership-header-celebrity-content">
          <ProfilePicture
            profileImage={profileImageUrl}
            width={PROFILE_IMAGE_SIZE}
            height={PROFILE_IMAGE_SIZE}
          />
          <div className="category-semibold celebrity-name">
            {isSubscribed ? (
              <span>
                {intl.formatMessage(
                  { id: 'membership.celebritySubscription' },
                  { name: getFullName(firstName, lastName) }
                )}
              </span>
            ) : (
              <>
                <span> {intl.formatMessage({ id: 'subscriptions.subscribeTo' })}</span>
                <span>{getFullName(firstName, lastName)}</span>
              </>
            )}
          </div>
          <div className="bottom-container">
            {!isMobile && (
              <div className="p2 description">
                {intl.formatMessage({ id: 'membership.price' }, { price: price?.toFixed(2) })}
              </div>
            )}
            {!isSubscribed && (
              <Button
                size="small"
                type="primary"
                title={intl.formatMessage(
                  {
                    id: 'membership.subscribeCelebrityPrice'
                  },
                  { price, period: getShortPeriod(period), name: firstName }
                )}
                icon={<Crown width={BUTTON_ICON_WIDTH} height={BUTTON_ICON_WIDTH} />}
                onClick={showPayments}
              />
            )}
          </div>
        </div>
        <Close onClick={hide} />
      </div>
    </div>
  );
};

MembershipHeader.propTypes = {
  celebrity: shape(celebrityShape).isRequired,
  planDetails: shape(membershipPlan).isRequired,
  hide: func.isRequired,
  showPayments: func.isRequired
};

export default MembershipHeader;
