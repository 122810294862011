import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useStatus, SUCCESS, ERROR } from '@rootstrap/redux-tools';

import useToast from 'hooks/useToast';
import { reportSeries } from 'state/actions/seriesActions';
import useAnalytics from 'hooks/useAnalytics';
import { MIXPANEL_EVENTS } from 'constants/constants';
import { replaceError } from 'utils/helpers';

export default () => {
  const dispatch = useDispatch();
  const { trackEvent } = useAnalytics();
  const { status, error } = useStatus(reportSeries);

  const { showErrorToast, showToast } = useToast();

  useEffect(() => {
    if (status === SUCCESS) {
      trackEvent(MIXPANEL_EVENTS.reportContent);
      showToast('The content was successfully reported!');
      dispatch(reportSeries.reset());
    }
    if (status === ERROR) {
      showErrorToast(replaceError(error, /^reason /));
      dispatch(reportSeries.reset());
    }
  }, [dispatch, showErrorToast, showToast, status, error]);

  return useCallback(({ seriesId, params }) => dispatch(reportSeries({ seriesId, params })), [
    dispatch
  ]);
};
