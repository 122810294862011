import { createReducer } from '@rootstrap/redux-tools';

import { getNotifications } from 'state/actions/notificationsActions';

const initialState = {
  notifications: [],
  badge: false
};

export default createReducer(initialState, {
  [getNotifications.success]: (state, { payload }) => {
    const unreadNotifs = payload.filter(({ read }) => !read);
    state.badge = unreadNotifs.length > 0;
    state.notifications = payload;
  }
});
