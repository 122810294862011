import React from 'react';
import { number } from 'prop-types';

const Crown = ({ width = 24, height = 24 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.9869 12.3999L22.4982 6.00907C22.4989 6.00019 22.4888 5.9946 22.4817 5.99991L17.9619 9.35327C17.9581 9.35607 17.9529 9.35598 17.9493 9.35305L11.9881 4.59879C11.9844 4.59577 11.979 4.59577 11.9752 4.59879L6.01412 9.35303C6.01043 9.35597 6.00522 9.35605 6.00144 9.35323L1.51798 5.99975C1.5109 5.99445 1.50086 5.99988 1.50141 6.00871L2.45227 21.0893C2.45261 21.0948 2.45714 21.0991 2.46262 21.0991H11.9817H21.5371C21.5427 21.0991 21.5473 21.0946 21.5475 21.0891L21.7222 16.2603"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M2.23242 16.524H21.7298"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);

Crown.propTypes = {
  width: number,
  height: number
};

export default Crown;
