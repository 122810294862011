import React from 'react';
import cn from 'classnames';
import DummyContentImg from 'assets/dummyContentImg.png';
// import ContentActions from 'components/contentActions/ContentActions';
import { useNavigation } from 'hooks';
import { routesPaths } from 'constants/routesPaths';
import { routeWithProps } from 'utils/helpers';

const ContentCard = ({ data }) => {
  const { goTo, goToWithSearch } = useNavigation();
  const handleRedirect = () => {
    if (data.contentType === 'Shorts') {
      goTo(
        routeWithProps(routesPaths.narratives, {
          celebrityId: data.celebrityId,
          id: data.id
        })
      );
    }
    if (data.contentType === 'Movie/Series') {
      const route = routeWithProps(routesPaths.celebrityId, {
        username: data.celebrityId
      });
      goToWithSearch(route, `?seriesId=${data?.id}`);
    }
    if (data.contentType === 'Episode') {
      goTo(
        routeWithProps(routesPaths.celebrityId, {
          username: data.celebrityId
        })
      );
    }
  };
  return (
    <div className={cn('content-card')} onClick={() => handleRedirect()}>
      <div className="content-img">
        <img
          src={data?.thumbnails?.[0]?.src || DummyContentImg}
          alt="dummy"
          className="content-img-box"
        />
      </div>
      <div className="title">{data?.title}</div>
      <div className="description">
        By <span style={{ color: '#2400FC' }}>{data?.celebrity}</span>
      </div>
      {/* <div>
        <ContentActions data={data} />
      </div> */}
    </div>
  );
};

export default ContentCard;
