import React from 'react';
import { number, string } from 'prop-types';

const AvatarBorderSmall = ({ width = 72, height = 72, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0)">
      <rect width="72" height="72" fill="url(#pattern0)" />
      <mask
        id="path-2-outside-1"
        maskUnits="userSpaceOnUse"
        x="-8"
        y="-8"
        width="88"
        height="88"
        fill="black"
      >
        <rect fill="white" x="-8" y="-8" width="88" height="88" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.3952 4.11429H67.8857V63.7714H72V4.11429V0H67.8857H25.3952V4.11429ZM72 67.8857H67.8857H4.11429V7.71427H0V67.8857V72H4.11429H67.8857H72V67.8857ZM0 4.11427V0H4.11429H17.0803V4.11429H6.17143L0 4.11427Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.3952 4.11429H67.8857V63.7714H72V4.11429V0H67.8857H25.3952V4.11429ZM72 67.8857H67.8857H4.11429V7.71427H0V67.8857V72H4.11429H67.8857H72V67.8857ZM0 4.11427V0H4.11429H17.0803V4.11429H6.17143L0 4.11427Z"
        fill="#2400FC"
      />
      <path
        d="M67.8857 4.11429H75.8857V-3.88571H67.8857V4.11429ZM25.3952 4.11429H17.3952V12.1143H25.3952V4.11429ZM67.8857 63.7714H59.8857V71.7714H67.8857V63.7714ZM72 63.7714V71.7714H80V63.7714H72ZM72 0H80V-8H72V0ZM25.3952 0V-8H17.3952V0H25.3952ZM72 67.8857H80V59.8857H72V67.8857ZM4.11429 67.8857H-3.88571V75.8857H4.11429V67.8857ZM4.11429 7.71427H12.1143V-0.285728H4.11429V7.71427ZM0 7.71427V-0.285728H-8V7.71427H0ZM0 72H-8V80H0V72ZM72 72V80H80V72H72ZM0 0V-8H-8V0H0ZM0 4.11427H-8V12.1143L-1.78019e-05 12.1143L0 4.11427ZM17.0803 0H25.0803V-8H17.0803V0ZM17.0803 4.11429V12.1143H25.0803V4.11429H17.0803ZM6.17143 4.11429L6.17142 12.1143H6.17143V4.11429ZM67.8857 -3.88571H25.3952V12.1143H67.8857V-3.88571ZM75.8857 63.7714V4.11429H59.8857V63.7714H75.8857ZM67.8857 71.7714H72V55.7714H67.8857V71.7714ZM80 63.7714V4.11429H64V63.7714H80ZM80 4.11429V0H64V4.11429H80ZM72 -8H67.8857V8H72V-8ZM67.8857 -8H25.3952V8H67.8857V-8ZM17.3952 0V4.11429H33.3952V0H17.3952ZM72 59.8857H67.8857V75.8857H72V59.8857ZM4.11429 75.8857H67.8857V59.8857H4.11429V75.8857ZM-3.88571 7.71427V67.8857H12.1143V7.71427H-3.88571ZM4.11429 -0.285728H0V15.7143H4.11429V-0.285728ZM-8 7.71427V67.8857H8V7.71427H-8ZM-8 67.8857V72H8V67.8857H-8ZM0 80H4.11429V64H0V80ZM4.11429 80H67.8857V64H4.11429V80ZM67.8857 80H72V64H67.8857V80ZM80 72V67.8857H64V72H80ZM-8 0V4.11427H8V0H-8ZM4.11429 -8H0V8H4.11429V-8ZM17.0803 -8H4.11429V8H17.0803V-8ZM25.0803 4.11429V0H9.08029V4.11429H25.0803ZM17.0803 -3.88571H6.17143V12.1143H17.0803V-3.88571ZM-1.78019e-05 12.1143L6.17142 12.1143L6.17145 -3.88571L1.78019e-05 -3.88573L-1.78019e-05 12.1143Z"
        fill="black"
        mask="url(#path-2-outside-1)"
      />
    </g>
  </svg>
);

AvatarBorderSmall.propTypes = {
  width: number,
  height: number,
  className: string
};

export default AvatarBorderSmall;
