import React from 'react';
import { number } from 'prop-types';

const Ad = ({ width = 24, height = 24 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m4 13.548 1.575 6.26c.044.177.066.265.092.342A1.998 1.998 0 0 0 7.4 21.493c.08.007.172.007.355.007.228 0 .343 0 .44-.01a1.994 1.994 0 0 0 1.797-1.786c.009-.096.009-.21.009-.437V5.1m8.5 7.951c1.933 0 3.5-1.557 3.5-3.478 0-1.922-1.567-3.48-3.5-3.48M10.25 5.1H6.5C4.015 5.1 2 7.102 2 9.573c0 2.47 2.015 4.472 4.5 4.472h3.75c1.766 0 3.927.941 5.594 1.845.973.527 1.46.79 1.778.751a.947.947 0 0 0 .691-.408c.187-.26.187-.778.187-1.816V4.728c0-1.038 0-1.557-.187-1.816a.947.947 0 0 0-.691-.408c-.319-.039-.805.224-1.778.751C14.177 4.16 12.016 5.1 10.25 5.1Z"
      stroke="white"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Ad.propTypes = {
  width: number,
  height: number
};

export default Ad;
