import React from 'react';
import { arrayOf, func, number, shape, string } from 'prop-types';
import { useIntl } from 'react-intl';
import cn from 'classnames';

import ScrollingArrows from 'components/common/ScrollingArrows';
import FeaturedCard from 'components/featured/FeaturedCard/FeaturedCard';
import { serieShape } from 'constants/propTypesShapes';

const FeaturedCarousel = ({ content, selectedContent, onClick, className, titleId }) => {
  const intl = useIntl();

  return (
    <div className={cn('featured-carousel', className)}>
      <h3 className="featured-carousel-title">{intl.formatMessage({ id: titleId })}</h3>
      <ScrollingArrows className="featured-carousel-content">
        {content.map((element, index) => (
          <FeaturedCard
            element={element}
            key={`${element.id}-${index}`}
            isSelected={element.id === selectedContent}
            onClick={onClick}
          />
        ))}
      </ScrollingArrows>
    </div>
  );
};

// TODO: Add live event to arrayOf
FeaturedCarousel.propTypes = {
  content: arrayOf(shape(serieShape)).isRequired,
  selectedContent: number,
  titleId: string.isRequired,
  onClick: func,
  className: string
};

export default FeaturedCarousel;
