import React from 'react';
import { string } from 'prop-types';
// import LiveIcon from 'assets/live-icon.png';
import { VIDEO_SHORT_DESCRIPTION_LENGTH } from 'constants/constants';
import MoreText from 'components/common/MoreText/MoreText';
import { convertDateFormatInStandard, getTimeFromISO } from 'utils/helpers';

const PodcastCardInfo = ({
  title,
  podcastTime,
  podcastDate,
  duration
  // membersPrice,
  // nonMembersPrice,
  // isAuthenticated
}) => (
  <div className="podcast-card-info">
    <div className="title-row">
      <div style={{ display: 'inline-flex', gap: '10px' }}>
        <h5 className="bold">{title}</h5>
        {/* <div className="live-stream-tag">
          <img height="20px" width="25px" src={LiveIcon} alt="live-tag" />
          <span className="live-span">Live</span>
        </div> */}
      </div>
      {/* <h5 className="bold">${isAuthenticated ? membersPrice : nonMembersPrice}</h5> */}
    </div>
    <div className="date-row">
      <div className="p1">
        <MoreText
          text={`${convertDateFormatInStandard(podcastDate)} ${getTimeFromISO(podcastTime)}`}
          limitLength={VIDEO_SHORT_DESCRIPTION_LENGTH}
        />
      </div>
      <div>{duration}</div>
    </div>
  </div>
);

PodcastCardInfo.propTypes = {
  title: string.isRequired,
  podcastTime: string.isRequired,
  podcastDate: string.isRequired,
  duration: string.isRequired
  // membersPrice: string.isRequired,
  // nonMembersPrice: string.isRequired,
  // isAuthenticated: bool
};

export default PodcastCardInfo;
