import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useStatus, SUCCESS, ERROR } from '@rootstrap/redux-tools';

import useToast from 'hooks/useToast';
import { updateNarrative } from 'state/actions/narrativeActions';
import useNavigation from 'hooks/useNavigation';
import { routeWithProps } from 'utils/helpers';
import { routesPaths } from 'constants/routesPaths';
import useSession from 'hooks/useSession';

export default callback => {
  const dispatch = useDispatch();
  const { status } = useStatus(updateNarrative);
  const { showErrorToast, showToast } = useToast();
  const { goToWithSearch } = useNavigation();
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useSession();

  useEffect(() => {
    if (status === SUCCESS) {
      const route = routeWithProps(routesPaths.celebrityId, {
        username: user.username.toLowerCase()
      });
      goToWithSearch(route, `?tab=2`);
      showToast('Shorts Successfully updated!');
      dispatch(updateNarrative.reset());
      setIsLoading(false);
    }
    if (status === ERROR) {
      showErrorToast('Something went wrong!');
      dispatch(updateNarrative.reset());
      setIsLoading(false);
    }
  }, [callback, dispatch, showErrorToast, showToast, status]);

  const updateNarrativeWithLoading = useCallback(
    narrative => {
      setIsLoading(true);
      dispatch(updateNarrative(narrative));
    },
    [dispatch]
  );

  return { updateNarrative: updateNarrativeWithLoading, isLoading };
};
