import React from 'react';
import { func, number } from 'prop-types';

import { useSession } from 'hooks';

import UserHeader from 'components/account/UserHeader/UserHeader';
import AccountNav from 'components/account/AccountNav/AccountNav';
import ModalHeader from 'components/common/ModalHeader/ModalHeader';

const AccountModal = ({ hide, initialTab }) => {
  const { user, authenticated } = useSession();
  return (
    <div className="account-modal">
      <ModalHeader hideModal={hide} titleId={authenticated && 'account.title'} />
      {authenticated && <UserHeader user={user} />}
      <AccountNav initialTab={initialTab} />
    </div>
  );
};

AccountModal.propTypes = {
  hide: func,
  initialTab: number
};

export default AccountModal;
