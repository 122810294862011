import httpClient from 'httpClient';

import { applyQueryParams } from 'utils/helpers';

class MembershipService {
  createMembership(celebrityId, membershipId, creditCardId) {
    const url = applyQueryParams(`celebrities/${celebrityId}/memberships`, {
      credit_card_id: creditCardId,
      membership_plan_id: membershipId
    });
    return httpClient.post(url);
  }

  cancelMembership(celebrityId, membershipId) {
    return httpClient.put(`/celebrities/${celebrityId}/memberships/${membershipId}/cancel`);
  }

  buyEpisode({ episodeId, creditCardId }) {
    return httpClient.post(`/ppvs/episodes`, {
      episode_id: episodeId,
      credit_card_id: creditCardId
    });
  }

  buyPodcast({ podcastId, creditCardId }) {
    return httpClient.post(`/ppvs/podcasts`, {
      podcast_id: podcastId,
      credit_card_id: creditCardId
    });
  }

  getAdmembership() {
    return httpClient.get(`/ad_membership_plans`);
  }

  subscribeadtier(celebrityId, creditCardId, membershipPlanId) {
    return httpClient.post(`celebrities/${celebrityId}/memberships/subscribe_ad_tier`, {
      credit_card_id: creditCardId,
      membership_plan_id: membershipPlanId
    });
  }

  registerEvent(creditCardId, eventId) {
    return httpClient.post(`ppvs/events`, {
      event_id: eventId,
      credit_card_id: creditCardId
    });
  }
}

export default new MembershipService();
