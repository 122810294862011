import React, { useState } from 'react';
import { oneOfType, arrayOf, node } from 'prop-types';

const EpisodeContext = React.createContext([{}, () => {}]);

const EpisodeContextProvider = ({ children }) => {
  const [episode, setEpisode] = useState(null);
  const [flowConfig, setFlowConfig] = useState({});

  return (
    <EpisodeContext.Provider value={{ episode, setEpisode, flowConfig, setFlowConfig }}>
      {children}
    </EpisodeContext.Provider>
  );
};

EpisodeContextProvider.propTypes = {
  children: oneOfType([arrayOf(node), node])
};

export { EpisodeContext, EpisodeContextProvider };
