/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useParams } from 'react-router-dom';

import { BackArrowIcon } from 'components/icons';
import EditSquareIcon from 'assets/editSquare.png';
import TrashIcon from 'assets/trash.png';
import { zIndexModalThird } from 'styles/common/_constants.scss';
import ActiveIn from 'components/AdDetails/ActiveIn';
import VideoAdDetails from 'components/AdDetails/VideoAdDetails';
import BannerAdDetails from 'components/AdDetails/BannerAdDetails';
import WidgetAdDetails from 'components/AdDetails/WidgetAdDetails';
import { adTypes } from 'constants/constants';
import parseError from 'utils/parseError';
import AdService from 'services/adService';
import VideoAdsService from 'services/videoAds';
import { useModal, useNavigation, useSession } from 'hooks';
import Loading from 'components/common/Loading';
import { routesPaths } from 'constants/routesPaths';
import { routeWithProps } from 'utils/helpers';
import DeleteAd from 'components/AdManager/DeleteAd/DeleteAd';
import Modal from 'components/common/Modal/Modal';

const AdDetails = () => {
  const { type, id } = useParams();
  const { toggle, isShowing } = useModal();
  const {
    user: { id: celebrityId }
  } = useSession();
  const { goTo } = useNavigation();
  const [adData, setAdData] = useState();
  const [loading, setLoading] = useState(true);

  const getVideoAdDetails = async () => {
    try {
      const { data } = await VideoAdsService.getVideoAdsById(id, celebrityId);
      setAdData(data.videoAd);
      setLoading(false);
    } catch ({ response }) {
      setLoading(false);
      throw parseError(response);
    } finally {
      setLoading(false);
    }
  };

  const getBannerAdDetails = async () => {
    try {
      const { data } = await AdService.getBannerById(id, celebrityId);
      setAdData(data.bannerAd);
    } catch ({ response }) {
      throw parseError(response);
    } finally {
      setLoading(false);
    }
  };

  const getWidgetAdDetails = async () => {
    try {
      const { data } = await AdService.getWidgetAdByID(id, celebrityId);
      setAdData(data.widgetAd);
    } catch ({ response }) {
      throw parseError(response);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (type === adTypes.VIDEO) {
      getVideoAdDetails();
    } else if (type === adTypes.BANNER) {
      getBannerAdDetails();
    } else {
      getWidgetAdDetails();
    }
  }, []);

  const displayDetailsUI = type => {
    if (type === adTypes.VIDEO) {
      return <VideoAdDetails data={adData} />;
    }
    if (type === adTypes.BANNER) {
      return <BannerAdDetails data={adData} />;
    }
    if (type === adTypes.WIDGET) {
      return <WidgetAdDetails data={adData} />;
    }
  };

  const handleRedirect = () => {
    return goTo(
      routeWithProps(routesPaths.adManager, {
        tab: `${type.toLowerCase()}Ads`
      })
    );
  };

  const redirectEdit = () => {
    if (type === adTypes.VIDEO) {
      return goTo(
        routeWithProps(routesPaths.editvideoAds, {
          videoId: id,
          celebrityId
        })
      );
    }
    if (type === adTypes.BANNER) {
      return goTo(
        routeWithProps(routesPaths.editBannerAd, {
          bannerId: id
        })
      );
    }
    if (type === adTypes.WIDGET) {
      return goTo(
        routeWithProps(routesPaths.editWidgetAds, {
          widgetId: id,
          celebrityId
        })
      );
    }
  };

  if (loading) {
    return (
      <div className="home-loading">
        <Loading />
      </div>
    );
  }

  return (
    <div className={cn('ad-details')}>
      <div className="ad-details-gradient" />

      <div className="layer content-container">
        <div className="ad-details-content">
          <div className="back-button" onClick={() => window.history.back()}>
            <BackArrowIcon />
          </div>
          <div className="page-header">
            <div>
              <span className="title">Ad Details</span>
            </div>
            <div className="button-container">
              <div className="common-button" onClick={toggle}>
                <img height="20px" width="20px" src={TrashIcon} alt="trash" />
                <div className="button-text">
                  <span>Delete</span>
                </div>
              </div>
              <div className="common-button" onClick={() => redirectEdit()}>
                <img height="20px" width="20px" src={EditSquareIcon} alt="edit" />
                <div className="button-text">
                  <span>Edit</span>
                </div>
              </div>
            </div>
          </div>
          <div className="main-container">{displayDetailsUI(type)}</div>
          <div>
            <ActiveIn data={adData} />
          </div>
        </div>
      </div>
      <Modal hide={toggle} isShowing={isShowing} topModal zIndex={zIndexModalThird}>
        <DeleteAd
          page="details"
          celebrityId={celebrityId}
          currentId={id}
          type={type}
          handleRedirect={handleRedirect}
        />
      </Modal>
    </div>
  );
};

AdDetails.loadData = async () => {};

export default AdDetails;
