import React from 'react';
import { useToggle } from 'hooks';

import Modal from 'components/common/Modal/Modal';
import MenuModal from 'components/header/MenuModal/MenuModal';
import { HamburgerMenuIcon } from 'components/icons';

const Header = () => {
  const [showMenuModal, toggleMenuModal] = useToggle(false);

  return (
    <>
      <HamburgerMenuIcon className="hamburger-icon mobile-only" onClick={toggleMenuModal} />
      <Modal hide={toggleMenuModal} isShowing={showMenuModal} leftAnimation>
        <MenuModal />
      </Modal>
    </>
  );
};

export default Header;
