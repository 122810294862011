import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { routesPaths } from 'constants/routesPaths';
import { MIXPANEL_EVENTS } from 'constants/constants';
import { useAnalytics } from 'hooks';

import Button from 'components/common/Button/Button';
import BackgroundOpacity from 'components/common/BackgroundOpacity/BackgroundOpacity';

import notFoundBackground from 'assets/not-found-bkg.png';

const NotFoundPage = () => {
  const intl = useIntl();
  const history = useHistory();
  const { pathname } = useLocation();
  const { trackEvent } = useAnalytics();

  useEffect(() => {
    trackEvent(MIXPANEL_EVENTS.notFound, { 'Current Url': pathname });
  }, []);

  return (
    <div className="not-found">
      <BackgroundOpacity background={notFoundBackground} opacity={0.5} />
      <div className="not-found-gradient" />
      <div className="not-found-content">
        <div className="section-title">{intl.formatMessage({ id: 'notfound.title' })}</div>
        <h1>{intl.formatMessage({ id: 'notfound.subtitle' })}</h1>
        <h4 className="semibold">{intl.formatMessage({ id: 'notfound.message1' })}</h4>
        <h4 className="semibold"> {intl.formatMessage({ id: 'notfound.message2' })}</h4>
        <Button
          labelId="notfound.goHome"
          type="primary"
          onClick={() => history.push(routesPaths.home)}
        />
      </div>
    </div>
  );
};

export default NotFoundPage;
