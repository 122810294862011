import httpClient from 'httpClient';

class NotificationsService {
  getNotifications() {
    return httpClient.get('/notifications');
  }

  readNotifications(data) {
    return httpClient.put('/notifications/read', data);
  }
}

export default new NotificationsService();
