import { createThunk, createAction } from '@rootstrap/redux-tools';

import userService from 'services/userService';
import parseError from 'utils/parseError';
import { getToken } from 'utils/firebase';
// import CryptoJS from 'crypto-js';

export const requestCode = createThunk('REQUEST_CODE', async phone => {
  try {
    await userService.requestCode({ user: { phone } });
  } catch ({ response }) {
    throw parseError(response);
  }
});

export const login = createThunk('LOGIN', async user => {
  try {
    const response = await userService.login({ user });
    // const encryptedAccessId = CryptoJS.AES.encrypt(
    //   response.headers.accessid,
    //   process.env.PUBLIC_KEY
    // ).toString();
    // const encryptedSecretKey = CryptoJS.AES.encrypt(
    //   response.headers.secretkey,
    //   process.env.PUBLIC_KEY
    // ).toString();

    // localStorage.setItem('encryptedAccessId', encryptedAccessId);
    // localStorage.setItem('encryptedSecretKey', encryptedSecretKey);
    return response.data.user;
  } catch ({ response }) {
    throw parseError(response);
  }
});

export const logout = createThunk('LOGOUT', async () => {
  try {
    await userService.logout();
  } catch ({ response }) {
    throw parseError(response);
  }
});

export const signUp = createThunk('SIGNUP', async user => {
  try {
    const { data } = await userService.signUp({ user });
    return data;
  } catch ({ response }) {
    throw parseError(response);
  }
});

// eslint-disable-next-line camelcase
export const upgradeToCreator = createThunk('UPGRADE_TO_CREATOR', async user => {
  try {
    const { data } = await userService.upgradeToCreator(user);
    localStorage.setItem('profileImageUrl', data.user.profileImageUrl);
    localStorage.setItem('username', data.user.username);
    return data.user;
  } catch ({ response }) {
    throw parseError(response);
  }
});

export const registerDevice = createThunk('REGISTER_DEVICE', async () => {
  try {
    const token = await getToken();
    if (token) {
      await userService.registerDevice({ device: { token } });
      return token;
    }
  } catch ({ response }) {
    throw parseError(response);
  }
});

export const unregisterDevice = createThunk('UNREGISTER_DEVICE', async token => {
  try {
    await userService.unregisterDevice(token);
  } catch ({ response }) {
    throw parseError(response);
  }
});

export const getProfile = createThunk('GET_PROFILE', async () => {
  try {
    const { data } = await userService.getProfile();
    return data.user;
  } catch ({ response }) {
    throw parseError(response);
  }
});

export const deleteUserAccount = createThunk('DELETE_USER_ACCOUNT', async () => {
  try {
    await userService.deleteUserAccount();
  } catch ({ response }) {
    throw parseError(response);
  }
});

export const changeCreatorProfileImage = createThunk(
  'CHANGE_CREATOR_PROFILE_IMAGE',
  async (celebrityId, image) => {
    try {
      const { data } = await userService.changeCreatorProfileImage(celebrityId, image);
      return data.celebrity;
    } catch ({ response }) {
      throw parseError(response);
    }
  }
);

export const setUserToConfirm = createThunk('SET_USER_TO_CONFIRM', user => user);

export const updateSession = createAction('UPDATE_SESSION');
