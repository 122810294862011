import React, { useEffect, useState } from 'react';
import Dropdown from 'components/header/Dropdown/Dropdown';
import DropdownRow from 'components/header/Dropdown/DropdownRow';
import parseError from 'utils/parseError';
import categoriesService from 'services/categoriesService';
import SearchIcon from 'assets/search.png';
import Loading from 'components/common/Loading';

const CategoryDropdown = ({ handleCategoryCloseDropDown, handleCategoryFilterCall }) => {
  const [categories, setCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const getCategories = async () => {
    try {
      setIsLoading(true);
      const { data } = await categoriesService.getCategories();
      const transformedData = data.categories.map(item => ({
        value: item.id,
        label: item.name
      }));
      setCategories(transformedData);
      setFilteredCategories(transformedData);
    } catch ({ response }) {
      throw parseError(response);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  const handleSearch = value => {
    setSearchTerm(value);
    const filtered = categories.filter(category =>
      category.label.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredCategories(filtered);
  };

  const stopPropagation = e => {
    e.stopPropagation();
  };

  return (
    <>
      <Dropdown className="navbar-dropdown category-dropdown">
        <div className="search-input-wrapper" onClick={stopPropagation}>
          <span className="search-icon">
            <img src={SearchIcon} height="15px" width="15px" alt="search" />
          </span>
          <input
            type="text"
            value={searchTerm}
            onChange={e => {
              e.preventDefault();
              handleSearch(e.target.value);
            }}
            placeholder="Search Categories"
            className="search-input"
          />
        </div>
        <div>
          {!isLoading &&
            filteredCategories.map(({ label, value }) => (
              <DropdownRow
                label={label}
                key={value}
                onClick={() => {
                  handleCategoryCloseDropDown();
                  handleCategoryFilterCall(value);
                }}
              />
            ))}
          {isLoading && (
            <div className="home-loading">
              <Loading />
            </div>
          )}
          {!isLoading && filteredCategories.length === 0 && (
            <div className="no-results" style={{ margin: '10px', color: 'rgb(178, 178, 178)' }}>
              No categories found
            </div>
          )}
        </div>
      </Dropdown>
    </>
  );
};

export default CategoryDropdown;
