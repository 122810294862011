import React from 'react';
import { shape, func, bool, oneOfType, arrayOf, string } from 'prop-types';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import useLiveAnalytics from 'hooks/liveEvents/useLiveAnalytics';
import { celebrityInfoShape } from 'constants/propTypesShapes';

import PlayerNavigationBar from 'components/Players/Controls/PlayerNavigationBar';

const LiveControls = ({ player, celebrities, sponsorUrl, isScheduled, title, controlsVisible }) => {
  const intl = useIntl();
  const { trackTimeViewed } = useLiveAnalytics({
    player,
    celebrity: celebrities,
    isScheduled,
    title
  });

  return (
    <>
      <div
        className={cn('controls live-controls custom-controls')}
        style={{ visibility: controlsVisible ? 'visible' : 'hidden' }}
      >
        <PlayerNavigationBar
          celebrities={celebrities}
          disableSharing
          onExitPlayer={trackTimeViewed}
        />
      </div>
      <div className="live-tag">
        <div className="live-circle" />
        <h6 className="bold">{intl.formatMessage({ id: 'live.isLive' })}</h6>
      </div>
      {!!sponsorUrl && (
        <img
          src={sponsorUrl}
          alt="Sponsor logo"
          className="sponsor-logo"
          style={{ visibility: controlsVisible ? 'visible' : 'hidden' }}
        />
      )}
    </>
  );
};

LiveControls.propTypes = {
  player: shape({
    on: func.isRequired,
    getState: func.isRequired
  }).isRequired,
  sponsorUrl: string,
  celebrities: oneOfType([shape(celebrityInfoShape), arrayOf(shape(celebrityInfoShape))]),
  isScheduled: bool,
  controlsVisible: bool,
  title: string
};

export default LiveControls;
