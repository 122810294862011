/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import Button from 'components/common/Button/Button';
import DateFilterIcon from 'assets/date-filter.png';
import FilterIcon from 'assets/filter.png';
import { useToggle } from 'hooks';
import categoriesService from 'services/categoriesService';
import parseError from 'utils/parseError';
import DateDropdown from './DateDropDown';
import CategoryDropdown from './CategoryDropDown';

const SearchHeader = ({ value, handleFilterCall, handleCategoryFilterCall }) => {
  const [showDateDropdown, toggleDateDropdown] = useToggle(false);
  const [showCategoryDropdown, toggleShowCategoryDropdown] = useToggle(false);
  const searchParams = new URLSearchParams(location.search);
  const [selectedSort, setSelectedSort] = useState(searchParams.get('sort') || '');
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categories, setCategories] = useState([]);
  const handleCloseDateDropDown = () => {
    toggleDateDropdown();
  };

  const handleCategoryCloseDropDown = () => {
    toggleShowCategoryDropdown();
  };

  const sortLabels = {
    popularity: 'Popularity',
    trending: 'Trending',
    date: 'Date'
  };

  const getCategories = async () => {
    try {
      const { data } = await categoriesService.getCategories();
      const transformedData = data.categories.map(item => ({
        value: item.id,
        label: item.name
      }));
      setCategories(transformedData);

      const categoryId = searchParams.get('category_id');
      if (categoryId) {
        const selectedCat = transformedData.find(cat => cat.value.toString() === categoryId);
        if (selectedCat) {
          setSelectedCategory(selectedCat);
        }
      }
    } catch ({ response }) {
      throw parseError(response);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  const handleCategorySelect = categoryId => {
    const selected = categories.find(cat => cat.value === categoryId);
    setSelectedCategory(selected);
    handleCategoryFilterCall(categoryId);
  };

  return (
    <div className="search-header">
      <div className="search-header-title">
        Search result for “<span style={{ color: '#2400FC' }}>{value}</span>”
      </div>
      <div className="button-container">
        <Button className="date-button" onClick={toggleDateDropdown}>
          <img height="20px" width="20px" src={DateFilterIcon} alt="date-filter" />
          {selectedSort ? sortLabels[selectedSort] : 'Sort By'}
          {showDateDropdown && (
            <DateDropdown
              handleFilterCall={key => {
                setSelectedSort(key);
                handleFilterCall(key);
              }}
              handleCloseDropDown={handleCloseDateDropDown}
            />
          )}
        </Button>
        <Button className="category-button" onClick={toggleShowCategoryDropdown}>
          <img height="20px" width="20px" src={FilterIcon} alt="filter" />
          {selectedCategory ? selectedCategory.label : 'All Category'}
          {showCategoryDropdown && (
            <CategoryDropdown
              handleCategoryFilterCall={handleCategorySelect}
              handleCategoryCloseDropDown={handleCategoryCloseDropDown}
            />
          )}
        </Button>
      </div>
    </div>
  );
};

export default SearchHeader;
