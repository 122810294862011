import React from 'react';
import Switch from 'react-switch';

const CommonSwitch = ({ checked, onChange, onColor = '#86d3ff', offColor = '#ccc', ...props }) => {
  return (
    <Switch
      checked={checked}
      onChange={onChange}
      onColor={onColor}
      offColor={offColor}
      {...props}
    />
  );
};

export default CommonSwitch;
