import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useStatus, SUCCESS, ERROR } from '@rootstrap/redux-tools';

import useToast from 'hooks/useToast';
import { deletePodcast } from 'state/actions/podcastActions';

export default callback => {
  const dispatch = useDispatch();
  const { status } = useStatus(deletePodcast);
  const { showToast, showErrorToast } = useToast();

  useEffect(() => {
    if (status === SUCCESS) {
      showToast('Podcast successfully deleted!');
      dispatch(deletePodcast.reset());
      callback();
    }
    if (status === ERROR) {
      showErrorToast('There was an error trying to delete your podcast.');
      dispatch(deletePodcast.reset());
    }
  });

  return useCallback((celebrityId, podcastId) => dispatch(deletePodcast(celebrityId, podcastId)), [
    dispatch
  ]);
};
