import React from 'react';
import { number, string } from 'prop-types';

import { secondaryColor } from 'styles/common/_constants.scss';

const InstagramIcon = ({ width = 20, height = 20, color = secondaryColor }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="logoTitle logoDesc"
    role="img"
  >
    <title id="logoTitle">Instagram</title>
    <desc id="logoDesc">Instagram logo</desc>

    <path
      d="M16 1H6C3.23858 1 1 3.23858 1 6V16C1 18.7614 3.23858 21 6 21H16C18.7614 21 21 18.7614 21 16V6C21 3.23858 18.7614 1 16 1Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.0002 10.3701C15.1236 11.2023 14.9815 12.0523 14.594 12.7991C14.2065 13.5459 13.5933 14.1515 12.8418 14.5297C12.0903 14.908 11.2386 15.0397 10.408 14.906C9.57732 14.7723 8.80996 14.3801 8.21503 13.7852C7.62011 13.1903 7.22793 12.4229 7.09426 11.5923C6.9606 10.7616 7.09226 9.90995 7.47052 9.15843C7.84878 8.40691 8.45438 7.7938 9.20118 7.4063C9.94798 7.0188 10.7979 6.87665 11.6302 7.00006C12.4791 7.12594 13.265 7.52152 13.8719 8.12836C14.4787 8.73521 14.8743 9.52113 15.0002 10.3701Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.5 5.5H16.51"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

InstagramIcon.propTypes = {
  width: number,
  height: number,
  color: string
};

export default InstagramIcon;
