import React from 'react';
import { number, string, func } from 'prop-types';

import { white } from 'styles/common/_constants.scss';

const HamburgerMenuIcon = ({ width = 24, height = 25, color = white, className, onClick }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="logoTitle logoDesc"
    role="img"
    className={className}
    onClick={onClick}
  >
    <title id="logoTitle">Menu</title>
    <desc id="logoDesc">Menu icon</desc>
    <path
      d="M3 12H21"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M3 6H21" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M3 18H21"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

HamburgerMenuIcon.propTypes = {
  width: number,
  height: number,
  color: string,
  className: string,
  onClick: func.isRequired
};

export default HamburgerMenuIcon;
