import React from 'react';
import { shape } from 'prop-types';
import cn from 'classnames';

import { celebrityInfoShape } from 'constants/propTypesShapes';
import { routesPaths } from 'constants/routesPaths';
import { routeWithProps } from 'utils/helpers';
import { useNavigation } from 'hooks';

const FeaturedCelebrity = ({ celebrity: { firstName, lastName, profileImageUrl, username } }) => {
  const { goTo } = useNavigation();

  const goToCelebrity = () => {
    goTo(routeWithProps(routesPaths.celebrityId, { username: username.toLowerCase() }));
  };

  return (
    <div className="featured-celebrity reduced" onClick={goToCelebrity}>
      <img
        src={profileImageUrl}
        alt="Celebrity Avatar"
        className={cn('featured-celebrity-avatar')}
      />
      <h5 className="medium">{firstName}</h5>
      <div className="p2">{lastName}</div>
    </div>
  );
};

FeaturedCelebrity.propTypes = {
  celebrity: shape(celebrityInfoShape)
};

export default FeaturedCelebrity;
