import React from 'react';
import cn from 'classnames';

import { defineMessages, useIntl } from 'react-intl';
import CreateSeriesForm from 'components/Originals/CreateSeriesForm';
import { BackArrowIcon } from 'components/icons';
import { useModal } from 'hooks';
import Modal from 'components/common/Modal/Modal';
import CancelModal from 'components/CancelModal/CancelModal';
import { useParams } from 'react-router-dom';

const messages = defineMessages({
  title: { id: 'title.uploadOriginal' }
});

const AddOriginals = () => {
  const intl = useIntl();
  const { seriesId, celebrityId, episodeId } = useParams();
  const { toggle, isShowing } = useModal();
  return (
    <div className={cn('create-series')}>
      <div className="become-creator-gradient" />
      <div className="layer content-container">
        <div className="become-creator-content">
          <div className="container">
            <div className="back-button" onClick={toggle}>
              <BackArrowIcon />
            </div>
            <h1 className="title">
              {seriesId ? 'Edit Movies & Series details' : intl.formatMessage(messages.title)}
            </h1>
            <CreateSeriesForm episodeId={episodeId} id={seriesId} celId={celebrityId} />
          </div>
        </div>
        <Modal hide={toggle} isShowing={isShowing} className="original-modal">
          <CancelModal id={seriesId} />
        </Modal>
      </div>
    </div>
  );
};

AddOriginals.loadData = async () => {};

export default AddOriginals;
