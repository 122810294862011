import httpClient from 'httpClient';
import { applyQueryParams } from 'utils/helpers';
import { CONTENT_TYPE, MULTIPART_FORM_DATA } from './userService';

class SeriesService {
  getOneSeries(id) {
    return httpClient.get(`/series/${id}`);
  }

  addSeries(series) {
    return httpClient.post('/series', series, {
      headers: { [CONTENT_TYPE]: MULTIPART_FORM_DATA },
      transformRequest: () => series
    });
  }

  updateSeries(series, id) {
    return httpClient.put(`/series/${id}`, series, {
      headers: { [CONTENT_TYPE]: MULTIPART_FORM_DATA },
      transformRequest: () => series
    });
  }

  getVideoUrlForJWPlayer(title) {
    return httpClient.get(applyQueryParams('/videos/upload_url', { title }));
  }

  deleteSeries(seriesId) {
    return httpClient.delete(`/series/${seriesId}`);
  }

  reportSeries({ seriesId, params }) {
    return httpClient.post(`/series/${seriesId}/reported_contents`, params);
  }

  reportEpisode({ episodeId, params }) {
    return httpClient.post(`/episodes/${episodeId}/reported_contents`, params);
  }

  updateEpisode(props) {
    const { seriesId, position, ...bodyParam } = props;
    const newPosition = position + 1;
    const body = {
      title: bodyParam.title,
      description: bodyParam.description,
      lead_cast: bodyParam.leadCast,
      credits: bodyParam.credits,
      viewer_category: bodyParam.viewerCategory,
      credits_start_time: bodyParam.creditsStartTime,
      position: newPosition,
      release_date_time: bodyParam.releaseDateTime,
      early_release_date_time: bodyParam.exclusive === true ? null : bodyParam.earlyReleaseDateTime,
      exclusive: bodyParam.exclusive,
      non_members_price: bodyParam.nonMembersPrice,
      members_price: bodyParam.membersPrice
    };
    return httpClient.put(
      `series/${seriesId}/episodes/${newPosition}`,
      JSON.stringify({
        episode: body
      })
    );
  }
}

export default new SeriesService();
