import React, {  useEffect } from 'react';
import { shape, string, func, bool } from 'prop-types';

import Modal from 'components/common/Modal/Modal';
import Button from 'components/common/Button/Button';
import PurchaseModal from 'components/payments/PurchaseModal/PurchaseModal';
import Crown from 'components/icons/membership/Crown';
import { celebrityShape } from 'constants/propTypesShapes';
import { useModal, useSession } from 'hooks';
import AuthModal from 'components/user/AuthModal/AuthModal';
import useProfile from 'hooks/users/useProfile';
import { zIndexModalSecond } from 'styles/common/_constants.scss';

const ICON_SIZE = 20;

const UnlockPodcastButton = ({ celebrity, title, size, onSuccess, smallBoldFont }) => {
    // console.log("UnlockPodcastButton Component Rendered");
  const { toggle: togglePayments, isShowing: isShowingPayments } = useModal();
  const { toggle: toggleAuth, isShowing: isShowingAuth } = useModal();
  const { authenticated } = useSession();

  const showPaymentsModal = () => {
    // console.log("Opening Payments Modal");
    if (!authenticated) {
        showAuthModal();
    }else{
    // setShowedAuth(true);
    togglePayments();
    }
  };

  const {
    profile,
    requestProfile,
  } = useProfile();

  const showAuthModal = () => {
    // console.log("Opening Auth Modal");
    // setShowedAuth(true);
    toggleAuth();
  };

//   useEffect(() => {
//     console.log("Authenticated status:", authenticated);
//     if (!authenticated) {
//         showAuthModal();
//     }
//   }, [authenticated]);

  useEffect(() => {
    console.log("Celebrity Data:", celebrity);
  }, [celebrity]);

  return (
    <>
      <Button
        size={size}
        type="primary"
        title={title}
        icon={<Crown width={ICON_SIZE} height={ICON_SIZE} />}
        onClick={showPaymentsModal}
        smallBoldFont={smallBoldFont}
        className="subscribe-button"
      />
      <Modal hide={togglePayments} isShowing={isShowingPayments}>
        <PurchaseModal celebrity={celebrity} onSuccess={onSuccess} type='podcast' />
      </Modal>
      <Modal hide={toggleAuth} isShowing={isShowingAuth} zIndex={zIndexModalSecond}>
        <AuthModal isModal onSuccess={requestProfile} />
      </Modal>
    </>
  );
};

UnlockPodcastButton.defaultProps = {
  size: 'xxsmall',
};

UnlockPodcastButton.propTypes = {
  celebrity: shape(celebrityShape).isRequired,
  onSuccess: func,
  title: string.isRequired,
  size: string,
  smallBoldFont: bool,
};

export default UnlockPodcastButton;
