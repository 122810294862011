import React, { useMemo, useEffect, useState } from 'react';
import { black20 } from 'styles/common/_constants.scss';
import { useClickOutside, useSession, useAnalytics, useToast, useNavigation } from 'hooks';
import { useIntl } from 'react-intl';
import { useStatus, SUCCESS, ERROR } from '@rootstrap/redux-tools';
import { func } from 'prop-types';
import { CrossIcon } from 'components/icons';
import Button from 'components/common/Button/Button';
import usePayments from 'hooks/payments/usePayments';
import isEmpty from 'lodash/isEmpty';
import { MIXPANEL_EVENTS } from 'constants/constants';
import { useDispatch } from 'react-redux';
import CreditCard from 'components/payments/CreditCard/CreditCard';
import EmptyMessage from 'components/common/EmptyMessage/EmptyMessage';
import useMembership from 'hooks/celebrities/useMembership';
import NewCardForm from 'components/payments/NewCardForm/NewCardForm';
import { createCard } from 'state/actions/paymentsActions';
import { routeWithProps } from 'utils/helpers';
import { routesPaths } from 'constants/routesPaths';

const AdPayment = ({ hide, isModal = false, plans }) => {
  const { user } = useSession();
  const ref = useClickOutside(hide);
  const intl = useIntl();
  const dispatch = useDispatch();
  const { goTo } = useNavigation();
  const { trackEvent } = useAnalytics();
  const { showToast, showErrorToast } = useToast();
  const [isPaymentDisabled, setIsPaymentDisabled] = useState(false);
  const [opencard, setopencard] = useState(true);
  const { getCreditCards, creditCards, getCardsLoading: loading } = usePayments();
  const defaultCard = creditCards.find(({ isDefault }) => isDefault);
  const otherCards = creditCards.filter(({ isDefault }) => !isDefault);
  const showEmptyMessage = useMemo(() => !loading && isEmpty(creditCards), [loading, creditCards]);
  const {
    subscribeAdTier,
    AdMembershipStatus,
    AdMembershipStatusError,
    resetMembership
  } = useMembership();
  const SubscribePlans = async () => {
    try {
      setIsPaymentDisabled(true);
      const payload = {
        creditCardId: defaultCard?.id,
        membershipPlanId: plans?.id,
        celebrityId: user?.id
      };
      subscribeAdTier(payload);
      // showToast(intl.formatMessage({ id: 'membership.ad.success' }));
      // hide();
    } catch (error) {
      console.error('Error subscribing mebership', error);
    }
  };
  useEffect(() => {
    resetMembership();
    getCreditCards();
  }, []);
  const { status, error } = useStatus(createCard);

  useEffect(() => {
    if (AdMembershipStatus === SUCCESS) {
      showToast(intl.formatMessage({ id: 'membership.ad.success' }));
      resetMembership();
      setIsPaymentDisabled(false);
      goTo(
        routeWithProps(routesPaths.adManager, {
          tab: 'videoAds'
        })
      );
    }
    if (AdMembershipStatus === ERROR) {
      showErrorToast(AdMembershipStatusError);
      setIsPaymentDisabled(false);
      resetMembership();
    }
  }, [AdMembershipStatus]);

  useEffect(() => {
    if (status === SUCCESS) {
      dispatch(createCard.reset());
      getCreditCards();
      trackEvent(MIXPANEL_EVENTS.creditCardAdded);
      showToast(intl.formatMessage({ id: 'creditCard.success' }));
      setopencard(!opencard);
      // hide();
    }
    if (status === ERROR) {
      dispatch(createCard.reset());
      trackEvent(MIXPANEL_EVENTS.creditCardRejected);
      showErrorToast(error);
    }
  }, [status]);
  return (
    <div className="add-payment-method" ref={ref}>
      <div className="flex-spaced">
        <h2>{intl.formatMessage({ id: 'paymentMethod.title' })}</h2>
        <div onClick={hide} style={{ cursor: 'pointer' }}>
          <CrossIcon width={24} height={24} color={black20} />
        </div>
      </div>
      {opencard ? (
        <>
          <div className="paymentcard">
            {showEmptyMessage &&
              (isModal ? (
                <EmptyMessage titleId="creditCard.empty" />
              ) : (
                <h5 className="empty-title">{intl.formatMessage({ id: 'creditCard.empty' })}</h5>
              ))}
            <Button
              className="Addcard"
              labelId="creditCard.addCard"
              type="secondary"
              size="xxsmall"
              lightFont
              onClick={() => setopencard(!opencard)}
            />
          </div>
          {defaultCard && (
            <div className="cards active-card">
              <p className="p1 cardstitle">
                {intl.formatMessage({ id: 'creditCard.default.label' })}
              </p>
              <CreditCard card={defaultCard} isDefault reducedMask={isModal} />
            </div>
          )}
          {!isEmpty(otherCards) && (
            <div className="cards other-cards">
              <div className="p1 cardstitle">{intl.formatMessage({ id: 'creditCard.others' })}</div>
              {otherCards.map(card => (
                <CreditCard card={card} key={card.id} reducedMask={isModal} />
              ))}
            </div>
          )}
          <div className="adpaymentsubmit submit-container">
            <Button
              labelId="membership.makePayment"
              type="primary"
              size="small"
              onClick={SubscribePlans}
              disabled={isPaymentDisabled || !defaultCard?.id}
            />
          </div>
        </>
      ) : (
        <>
          <div className="paymentcard">
            {showEmptyMessage &&
              (isModal ? (
                <EmptyMessage titleId="creditCard.empty" />
              ) : (
                <h5 className="empty-title">{intl.formatMessage({ id: 'creditCard.empty' })}</h5>
              ))}
            <Button
              className="Addcard"
              labelId="creditCard.cards"
              type="secondary"
              size="xxsmall"
              lightFont
              onClick={() => setopencard(!opencard)}
            />
          </div>
          <NewCardForm />
        </>
      )}
    </div>
  );
};

AdPayment.propTypes = {
  hide: func
};

export default AdPayment;
