import React from 'react';
import { useIntl } from 'react-intl';
import { shape } from 'prop-types';
import { isEmpty } from 'lodash';

import { serieShape } from 'constants/propTypesShapes';
import { routesPaths } from 'constants/routesPaths';
import { routeWithProps } from 'utils/helpers';
import { useNavigation } from 'hooks';

import FeaturedCelebrities from 'components/celebrity/FeaturedCelebrities/FeaturedCelebrities';

const About = ({ serie }) => {
  const { description, celebrity, categories } = serie;
  const intl = useIntl();
  const { goTo } = useNavigation();

  const getCategoryLink = category => {
    const slug = isEmpty(category.parentCategory) ? category.slug : category.parentCategory.slug;
    return routeWithProps(routesPaths.category, {
      slug
    });
  };

  return (
    <div className="about scrollable-container">
      <div className="p1 title">{description}</div>
      <div className="about-separator" />
      <FeaturedCelebrities
        celebrities={[celebrity]}
        showArrows={false}
        titleId="serie.creator"
        size="small"
      />
      {!!categories.length && (
        <>
          <div className="about-separator" />
          <div className="categories">
            <h3 className="small">{intl.formatMessage({ id: 'serie.categories' })}</h3>
            <div className="flex">
              {categories.map(category => (
                <div
                  className="category tag-bold"
                  onClick={() => goTo(getCategoryLink(category))}
                  key={`category-${category.id}`}
                >
                  {category.name}
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

About.propTypes = {
  serie: shape(serieShape)
};

export default About;
