/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/button-has-type */
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import DataTable from 'react-data-table-component';
import parseError from 'utils/parseError';
import AdService from 'services/adService';
import TrashIcon from 'assets/trash.png';
import DetailPageArrowIcon from 'assets/detailPageArrow.png';
import EditSquareIcon from 'assets/editSquare.png';
import { useModal, useNavigation, useSession } from 'hooks';
import Loading from 'components/common/Loading';
import DummyImg from 'assets/IDBackForAds.png';
import Modal from 'components/common/Modal/Modal';
import { zIndexModalThird } from 'styles/common/_constants.scss';
import { adTypes } from 'constants/constants';
import { routesPaths } from 'constants/routesPaths';
import { routeWithProps } from 'utils/helpers';
import { customStyles } from './tableStyle';
import DeleteAd from './DeleteAd/DeleteAd';

const BannerAdsList = () => {
  const [bannerAds, setBannerAds] = useState([]);
  const [loading, setLoading] = useState(true);
  const { goTo } = useNavigation();
  const [currentId, setCurrentId] = useState();
  const { toggle, isShowing } = useModal();
  const {
    user: { id: celebrityId }
  } = useSession();

  const getBannerAdsList = async () => {
    try {
      setLoading(true);
      const { data } = await AdService.getBannerAdList(celebrityId);
      setBannerAds(data.bannerAds);
    } catch ({ response }) {
      throw parseError(response);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      name: 'Thumbnail',
      selector: row => row.title,
      cell: row => (
        <div className={cn('thumbnail-container')}>
          <img
            src={row.webBannerUrl || DummyImg}
            alt={row.title}
            className={cn('thumbnail-image')}
          />
        </div>
      )
    },
    {
      name: 'Title',
      selector: row => row.title,
      cell: row => (
        <div className={cn('duration-container')}>
          <div className={cn('title-container')}>
            <div className={cn('ad-title')}>{row.title}</div>
          </div>
        </div>
      )
    },
    {
      name: 'Active in',
      cell: row => (
        <div className={cn('title-container')}>
          <div className={cn('ad-active-in')}>
            {row.narrativeCounts > 0 &&
              `${row.narrativeCounts} Shorts${`${row.episodeCounts > 0 ? ', ' : ''}`}`}
            {row.episodeCounts > 0 && `${row.episodeCounts} Movies & Series`}
            {row.episodeCounts === 0 && row.narrativeCounts === 0 && '-'}
          </div>
        </div>
      )
    },
    {
      name: 'End date',
      selector: row => row.endDate,
      format: row => (row.endDate === null ? '-' : new Date(row.endDate).toLocaleDateString('en-US', { timeZone: 'UTC' }))
    },
    {
      name: 'Impressions',
      selector: row => row.impressions,
      cell: row => <div>{row.impressions || '-'}</div>
    },
    {
      name: 'Clicks',
      selector: row => row.clicks,
      cell: row => <div>{row.clicks || '-'}</div>
    },
    {
      name: 'Actions',
      cell: row => (
        <div className={cn('table-action-flex')}>
          <button
            style={{ cursor: 'pointer' }}
            onClick={() => {
              goTo(
                routeWithProps(routesPaths.editBannerAd, {
                  bannerId: row.id
                })
              );
            }}
          >
            <img height="20px" width="20px" src={EditSquareIcon} alt="edit" />
          </button>
          <button
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setCurrentId(row.id);
              toggle();
            }}
          >
            <img height="20px" width="20px" src={TrashIcon} alt="trash" />
          </button>
          <button
            style={{ cursor: 'pointer' }}
            onClick={() => {
              goTo(routeWithProps(routesPaths.adDetails, { type: adTypes.BANNER, id: row.id }));
            }}
          >
            <img height="20px" width="20px" src={DetailPageArrowIcon} alt="detailPage" />
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true
    }
  ];

  const handleApiCall = () => {
    getBannerAdsList();
  };

  useEffect(() => {
    getBannerAdsList();
  }, []);

  return (
    <div className={cn('bannerAdsList')}>
      <div className={cn('table-container')} style={{ backgroundColor: 'black' }}>
        <DataTable
          customStyles={customStyles}
          columns={columns}
          data={bannerAds}
          pagination={false}
          progressPending={loading}
          progressComponent={
            <div className="content-loading">
              <Loading />
            </div>
          }
          responsive
        />
      </div>
      <Modal hide={toggle} isShowing={isShowing} topModal zIndex={zIndexModalThird}>
        <DeleteAd
          handleApiCall={handleApiCall}
          celebrityId={celebrityId}
          currentId={currentId}
          type={adTypes.BANNER}
        />
      </Modal>
    </div>
  );
};

export default BannerAdsList;
