import React from 'react';
import { number, string } from 'prop-types';

import { primaryColor } from 'styles/common/_constants.scss';

const AvatarHeaderSquare = ({ width, height, className, color = primaryColor }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 140 140"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M45.3796 8V12H49.3796H128V124V128H132H140H144V124V8V0V-4H140H132H49.3796H45.3796V0V8ZM144 132V128H140H132H12V15L12 12H33.2117H37.2117V8V0V-4H33.2117H8H0H-4V0V7.99997V11V12V15V132V140V144H0H8H132H140H144V140V132Z"
      fill={color}
      stroke="black"
      strokeWidth="8"
    />
  </svg>
);

AvatarHeaderSquare.propTypes = {
  width: number,
  height: number,
  className: string,
  color: string
};

export default AvatarHeaderSquare;
