import React from 'react';
import { useIntl } from 'react-intl';

import { useNavigation } from 'hooks';
import { legalTabs } from 'constants/constants';

import Dropdown from 'components/header/Dropdown/Dropdown';
import DropdownRow from 'components/header/Dropdown/DropdownRow';
import { routesPaths } from 'constants/routesPaths';
import { routeWithProps } from 'utils/helpers';

const NavbarDropdown = () => {
  const intl = useIntl();
  const { goTo } = useNavigation();

  const routes = [
    // {
    //   label: { id: 'navbar.linkOfInterest' },
    //   route: routeWithProps(routesPaths.legal, { tab: legalTabs.LINK_OF_INTEREST.tab })
    // },
    {
      label: { id: 'navbar.privacyTerms' },
      route: routeWithProps(routesPaths.legal, { tab: legalTabs.PRIVACY.tab }),
      isNew: false
    },
    {
      label: { id: 'navbar.termsOfService' },
      route: routeWithProps(routesPaths.legal, { tab: legalTabs.TERMS.tab }),
      isNew: false
    },
    {
      label: { id: 'navbar.contentMarketPlace' },
      isNew: true
    }
  ];

  const handleNewOpen = () => {
    window.open(process.env.CONTENT_PDF_LINK, '_blank');
  };

  return (
    <Dropdown className="navbar-dropdown" triangleLeft>
      {routes.map(({ label, route, isNew }) => (
        <DropdownRow
          label={intl.formatMessage(label)}
          onClick={() => {
            isNew ? handleNewOpen() : goTo(route);
          }}
          key={route}
        />
      ))}
    </Dropdown>
  );
};

export default NavbarDropdown;
