/* eslint-disable no-plusplus */
import React from 'react';
import { string, number, element, arrayOf } from 'prop-types';
import { maxMobileWidth } from 'styles/common/_constants.scss';

const MasonryGrid = ({ defaultColumns, mobileColumns, children, className, columnClassName }) => {
  const columns = [];
  const isMobile = window?.innerWidth <= maxMobileWidth;

  const columnAmount = isMobile ? mobileColumns : defaultColumns;

  for (let i = 0; i < columnAmount; i++) {
    columns.push(
      <div key={`col${i}`} className={columnClassName}>
        {children.filter((element, index) => i == index % columnAmount)}
      </div>
    );
  }

  return <div className={className}>{columns}</div>;
};

MasonryGrid.propTypes = {
  defaultColumns: number,
  children: arrayOf(element),
  className: string,
  columnClassName: string,
  mobileColumns: number
};

MasonryGrid.defaultProps = {
  defaultColumns: 3,
  mobileColumns: 1
};

export default MasonryGrid;
