import React from 'react';
import { string, number, bool } from 'prop-types';
import cn from 'classnames';

import { CrownIcon } from 'components/icons';

import { white } from 'styles/common/_constants.scss';

const PriceCrownIcon = ({ className, width, height, membership, price }) => (
  <div className={cn('price-crown-icon-container', className)}>
    {membership && <CrownIcon fill color={white} width={width} height={height} />}
    {price && (
      <>
        {membership && <h5 className="bold price-crown-add-icon">+</h5>}
        <h5 className="bold">{`$${price?.toFixed(2)}`}</h5>
      </>
    )}
  </div>
);

PriceCrownIcon.propTypes = {
  className: string,
  width: number,
  height: number,
  membership: bool,
  price: string
};

PriceCrownIcon.defaultProps = {
  width: 18,
  height: 18
};

export default PriceCrownIcon;
