import React, { useMemo } from 'react';
import { shape, oneOf, func, bool } from 'prop-types';
import cn from 'classnames';

import { celebrityInfoShape } from 'constants/propTypesShapes';
import { routesPaths } from 'constants/routesPaths';
import { routeWithProps, avatarName, getFullName } from 'utils/helpers';
import { useIsMobile, useNavigation } from 'hooks';

const FeaturedCelebrity = ({ celebrity, size, onClick, hideTitle }) => {
  const { goTo } = useNavigation();
  const isMobile = useIsMobile();

  const { firstName, lastName, profileImageUrl, username, title } = celebrity;

  const celebrityName = useMemo(
    () => (isMobile ? avatarName(firstName, lastName) : getFullName(firstName, lastName)),
    [firstName, lastName, isMobile]
  );

  const goToCelebrity = () => {
    !!onClick && onClick(celebrity);
    goTo(routeWithProps(routesPaths.celebrityId, { username: username.toLowerCase() }));
  };

  return (
    <div className="featured-celebrity" onClick={goToCelebrity}>
      <img
        src={profileImageUrl}
        alt="Celebrity Avatar"
        className={cn('featured-celebrity-avatar', size)}
      />
      <div className="featured-celebrity-info">
        <h3 className="small">{celebrityName}</h3>
        {!hideTitle && <div className="p2">{title}</div>}
      </div>
    </div>
  );
};

FeaturedCelebrity.propTypes = {
  celebrity: shape(celebrityInfoShape),
  size: oneOf(['large', 'medium', 'small']),
  onClick: func,
  hideTitle: bool
};

FeaturedCelebrity.defaultProps = {
  size: 'large'
};

export default FeaturedCelebrity;
