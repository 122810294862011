import React, { useState } from 'react';
import { useIntl, defineMessages } from 'react-intl';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';

import { billing as billingValidations } from 'utils/constraints';
import { useForm, useValidation, useTextInputProps } from 'hooks';
import usePayments from 'hooks/payments/usePayments';

import Field from 'components/common/StripeField/StripeField';
import Input from 'components/common/Input/Input';
import withStripe from 'components/hocs/withStripe';
import Button from 'components/common/Button/Button';
import CountryDropdown from 'components/common/CountryDropdown/CountryDropdown';

const messages = defineMessages({
  creditCard: { id: 'form.creditCard' },
  creditCardPlaceholder: { id: 'form.creditCard.placeholder' },
  expiryDate: { id: 'form.expiryDate' },
  expiryDatePlaceholder: { id: 'form.expiryDate.placeholder' },
  cvv: { id: 'form.cvv' },
  cvvPlaceholder: { id: 'form.cvv.placeholder' },
  name: { id: 'form.name' },
  namePlaceholder: { id: 'form.name.placeholder' },
  lastName: { id: 'form.lastName' },
  lastNamePlaceholder: { id: 'form.lastName.placeholder' },
  country: { id: 'form.country' },
  countryPlaceholder: { id: 'form.country.placeholder' },
  zipcode: { id: 'form.zipcode' },
  zipcodePlaceholder: { id: 'form.zipcode.placeholder' }
});

const fields = {
  firstName: 'firstName',
  lastName: 'lastName',
  zipcode: 'zipcode',
  country: 'country'
};

const NewCardForm = () => {
  const [cardState, setCardState] = useState({});
  const [expiryState, setExpiryState] = useState({});
  const [CVCState, setCVCState] = useState({});
  const intl = useIntl();

  const validator = useValidation(billingValidations);
  const stripe = useStripe();
  const elements = useElements();
  const { createCreditCard: onSubmit, attemptCreateToken, loading } = usePayments(stripe, elements);

  const { values, errors, handleValueChange, handleSubmit, handleBlur } = useForm(
    {
      onSubmit,
      validator,
      validateOnBlur: true,
      validateOnChange: true,
      onInvalidSubmit: attemptCreateToken
    },
    [onSubmit]
  );

  const inputProps = useTextInputProps(handleValueChange, handleBlur, values);

  return (
    <div className="new-card-form">
      <div className="new-card-form-section">
        <h4 className="new-card-form-section-title h4-medium">
          {intl.formatMessage({ id: 'membership.form.creditCardInfo' })}
        </h4>
        <div className="new-card-form-row">
          <div className="half-row">
            <Field
              onChange={setCardState}
              label={intl.formatMessage(messages.creditCard)}
              StripeComponent={CardNumberElement}
              error={cardState.error}
            />
          </div>
          <div className="half-row">
            <Field
              onChange={setExpiryState}
              label={intl.formatMessage(messages.expiryDate)}
              StripeComponent={CardExpiryElement}
              error={expiryState.error}
            />
            <Field
              onChange={setCVCState}
              label={intl.formatMessage(messages.cvv)}
              StripeComponent={CardCvcElement}
              error={CVCState.error}
              width={13}
            />
          </div>
        </div>
      </div>
      <div className="new-card-form-section">
        <h4 className="new-card-form-section-title h4-medium">
          {intl.formatMessage({ id: 'membership.form.billingInfo' })}
        </h4>
        <div className="new-card-form-row">
          <div className="half-row">
            <Input
              name="firstName"
              placeholder={intl.formatMessage(messages.namePlaceholder)}
              label={intl.formatMessage(messages.name)}
              errors={errors[fields.firstName]}
              {...inputProps(fields.firstName)}
            />
          </div>
          <div className="half-row">
            <Input
              name="lastName"
              placeholder={intl.formatMessage(messages.lastNamePlaceholder)}
              label={intl.formatMessage(messages.lastName)}
              errors={errors[fields.lastName]}
              {...inputProps(fields.lastName)}
            />
          </div>
        </div>
        <div className="new-card-form-row">
          <div className="half-row">
            <CountryDropdown
              name="country"
              label={intl.formatMessage(messages.country)}
              {...inputProps(fields.country)}
              className="input-container"
            />
          </div>
          <div className="half-row">
            <Input
              name="zipcode"
              placeholder={intl.formatMessage(messages.zipcodePlaceholder)}
              label={intl.formatMessage(messages.zipcode)}
              errors={errors[fields.zipcode]}
              {...inputProps(fields.zipcode)}
            />
          </div>
        </div>
      </div>
      <div className="submit-container">
        <Button
          disabled={!stripe || loading}
          onClick={handleSubmit}
          labelId="membership.createPaymentMethod"
          type="primary"
          size="small"
        />
      </div>
    </div>
  );
};

export default withStripe(NewCardForm);
