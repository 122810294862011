import React from 'react';
import { string, bool } from 'prop-types';
import cn from 'classnames';

const Tag = ({ label, className, centeredContent, isTransparent }) => (
  <div
    className={cn(
      'tag tag-bold',
      { centered: centeredContent },
      { transparent: isTransparent },
      className
    )}
  >
    {label}
  </div>
);

Tag.propTypes = {
  label: string.isRequired,
  className: string,
  centeredContent: bool,
  isTransparent: bool
};

Tag.defaultProps = {
  className: ''
};

export default Tag;
