import React, { useMemo, useCallback } from 'react';
import { shape } from 'prop-types';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import cn from 'classnames';

import { routesPaths } from 'constants/routesPaths';
import { contentShape } from 'constants/propTypesShapes';
import { routeWithProps, avatarName } from 'utils/helpers';
import { useIsMobile, useNavigation, useModal, useAnalytics } from 'hooks';
import { getWrittenDate } from 'utils/date';
import { LIVE_STATUSES, MIXPANEL_EVENTS } from 'constants/constants';

import Button from 'components/common/Button/Button';
import LiveLabel from 'components/common/Labels/LiveLabel';
import CelebrityAvatar from 'components/home/CelebrityAvatar/CelebrityAvatar';
import { PlayIcon } from 'components/icons';
import TrailerModal from 'components/featured/TrailerModal/TrailerModal';
import LiveIndicatorArrows from 'components/common/LiveIndicatorArrows/LiveIndicatorArrows';

import Modal from 'components/common/Modal/Modal';
import PayPerViewButton from 'components/payPerView/PayPerViewButton/PayPerViewButton';

const ScheduledEventHero = ({ event }) => {
  const { getLinkTo, goTo } = useNavigation();
  const intl = useIntl();
  const isMobile = useIsMobile();
  const { toggle, isShowing } = useModal(false);
  const { trackEvent } = useAnalytics();

  const { logoImageUrl, description, celebrities, liveStart, bannerUrl, state, title, ppv } =
    event || {};

  const isLive = useMemo(() => state === LIVE_STATUSES.STARTED, [state]);
  const liveDate = useMemo(() => getWrittenDate(liveStart), [liveStart]);

  const watchLive = useCallback(() => {
    trackEvent(MIXPANEL_EVENTS.playScheduledLive, { title });
    goTo(routeWithProps(routesPaths.scheduledLive, { id: event.id }));
  }, [trackEvent, event, goTo, title]);

  const playTrailer = useCallback(() => {
    trackEvent(MIXPANEL_EVENTS.playTrailer, { title });
    toggle();
  }, [toggle, title, trackEvent]);

  return (
    <>
      <div className="scheduled-event-container">
        <div className="featured-content-info scheduled-event-hero">
          {event && (
            <>
              <div className="event-info">
                <div className="event-label">
                  <LiveLabel />
                  <span className="tag-bold">
                    {intl.formatMessage({
                      id: isLive ? 'scheduledLive.liveNow' : 'scheduledLive.tag'
                    })}
                  </span>
                  {isLive ? (
                    <div className="live-circle mobile-only" />
                  ) : (
                    <span className="p3-regular mobile-only">{liveDate}</span>
                  )}
                </div>
                <img src={logoImageUrl} alt="movies&series" className="event-logo" />
                <h4 className="semibold event-description">{description}</h4>
              </div>
              <div className="scheduled-event-hero-buttons">
                {!isLive && (
                  <>
                    {!!bannerUrl && (
                      <Button
                        labelId="scheduledLive.trailer"
                        type="primary"
                        icon={<PlayIcon />}
                        onClick={playTrailer}
                      />
                    )}
                    {ppv && (
                      <PayPerViewButton
                        event={event}
                        className={cn('pay-per-view-button', { 'no-margin': !bannerUrl })}
                      />
                    )}
                  </>
                )}
                {isLive &&
                  isMobile &&
                  (ppv ? (
                    <PayPerViewButton
                      event={event}
                      className={cn('pay-per-view-button no-margin')}
                    />
                  ) : (
                    <Button
                      labelId="scheduledLive.watch"
                      type="red"
                      icon={<PlayIcon />}
                      onClick={watchLive}
                    />
                  ))}
              </div>
            </>
          )}
          <div
            className={cn('scheduled-event-hero-creators', { 'scrollable-container': isMobile })}
          >
            {celebrities &&
              celebrities.map(({ profileImageUrl, firstName, lastName, id, username }, index) => (
                <Link
                  to={getLinkTo(
                    routeWithProps(routesPaths.celebrityId, { username: username.toLowerCase() })
                  )}
                  key={`celebrity-${id}-${index}`}
                >
                  <CelebrityAvatar
                    backgroundImage={profileImageUrl}
                    title={avatarName(firstName, lastName)}
                    small={isMobile}
                    isLive={isLive && !isMobile}
                  />
                </Link>
              ))}
          </div>
        </div>
        {isLive && !isMobile && (
          <div className="live-tags">
            <LiveIndicatorArrows />
            <div className="watch-live-button">
              <div className="live-circle" />
              {ppv ? (
                <PayPerViewButton
                  event={event}
                  className={cn('pay-per-view-button', { 'no-margin': !bannerUrl })}
                />
              ) : (
                <Button
                  labelId="scheduledLive.watch"
                  type="red"
                  icon={<PlayIcon />}
                  onClick={watchLive}
                />
              )}
            </div>
          </div>
        )}
        {!isLive && (
          <div className="scheduled-event-hero-tag desktop-only">
            <div className="category-semibold">
              {intl.formatMessage({ id: 'scheduledLive.liveEvent' })}
            </div>
            <h4 className="semibold">{liveDate}</h4>
          </div>
        )}
      </div>
      <Modal hide={toggle} isShowing={isShowing} disablePadding>
        <TrailerModal
          celebrities={celebrities}
          title={title}
          description={description}
          videoUrl={bannerUrl}
        />
      </Modal>
    </>
  );
};

ScheduledEventHero.propTypes = {
  event: shape(contentShape)
};

export default ScheduledEventHero;
