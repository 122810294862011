import { createThunk } from '@rootstrap/redux-tools';

import paymentsService from 'services/paymentsService';
import parseError from 'utils/parseError';

export const createCard = createThunk('CREATE_CARD', async card => {
  try {
    const { data } = await paymentsService.createCard({ creditCard: card });
    return data.creditCard;
  } catch ({ response }) {
    throw parseError(response);
  }
});

export const getCards = createThunk('GET_CARDS', async () => {
  try {
    const { data } = await paymentsService.getCards();
    return data.creditCards;
  } catch ({ response }) {
    throw parseError(response);
  }
});

export const setDefaultCreditCard = createThunk('SET_DEFAULT_CREDIT_CARD', async id => {
  try {
    const { data } = await paymentsService.setDefaultCreditCard(id);
    return data;
  } catch ({ response }) {
    throw parseError(response);
  }
});
