import React from 'react';
import cn from 'classnames';

import { defineMessages, useIntl } from 'react-intl';
import AddNarrativeForm from 'components/Narratives/AddNarrativeForm';
import { BackArrowIcon } from 'components/icons';
import { useParams } from 'react-router-dom';

const messages = defineMessages({
  title: { id: 'title.uploadNarrative' }
});

const AddNarratives = () => {
  const intl = useIntl();
  const { narrativeId, celebrityId } = useParams();
  return (
    <div className={cn('become-creator')}>
      <div className="become-creator-gradient" />
      <div className="layer content-container">
        <div className="become-creator-content">
          <div className="container" style={{ overflow: 'hidden' }}>
            <div className="back-button" onClick={() => window.history.back()}>
              <BackArrowIcon />
            </div>
            <h1 className="title">
              {narrativeId ? 'Update Shorts' : intl.formatMessage(messages.title)}
            </h1>
            <AddNarrativeForm id={narrativeId} celId={celebrityId} />
          </div>
        </div>
      </div>
    </div>
  );
};

AddNarratives.loadData = async () => {};

export default AddNarratives;
