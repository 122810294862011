import React from 'react';
import { useIntl } from 'react-intl';
import { arrayOf, shape } from 'prop-types';

import { useAnalytics, useNavigation } from 'hooks';
import { routeWithProps } from 'utils/helpers';

import { routesPaths } from 'constants/routesPaths';
import { serieShape } from 'constants/propTypesShapes';
import { MIXPANEL_EVENTS } from 'constants/constants';

import SimpleThumbnail from 'components/categoryList/SimpleThumbnail/SimpleThumbnail';

const KeepWatchingSection = ({ series }) => {
  const { goToWithSearch } = useNavigation();
  const intl = useIntl();
  const { trackEvent } = useAnalytics();

  const onThumbnailClick = serie => {
    const {
      id,
      title,
      watchedEpisode: { id: episodeId, time, title: episodeTitle }
    } = serie || {};
    const route = routeWithProps(routesPaths.series, { id, episode: episodeId });
    goToWithSearch(route, `t?${time}`);
    trackEvent(MIXPANEL_EVENTS.keepWatching, {
      seriesName: title,
      episode: episodeTitle
    });
  };

  return (
    <div className="keep-watching-section">
      <div className="keep-watching-section-title">
        <h3>{intl.formatMessage({ id: 'general.keepWatching' })}</h3>
      </div>
      <div className="keep-watching-section-items">
        {series.map(serie => (
          <SimpleThumbnail
            serie={serie}
            className="keep-watching-content"
            key={`keep-watching-${serie.id}`}
            onClick={() => onThumbnailClick(serie)}
            customLabel={intl.formatMessage(
              { id: 'episodes.resume' },
              { num: serie.watchedEpisode.position + 1 }
            )}
          />
        ))}
      </div>
    </div>
  );
};

KeepWatchingSection.propTypes = {
  series: arrayOf(shape(serieShape))
};

export default KeepWatchingSection;
