import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LOADING, SUCCESS } from '@rootstrap/redux-tools';

import { useScheduleLive, useAnalytics, useSession } from 'hooks';
import useWatchLiveSocket from 'hooks/liveEvents/useWatchLiveSocket';
import { LIVE_STATUSES, MIXPANEL_EVENTS } from 'constants/constants';

import Loading from 'components/common/Loading';
import LivePlayer from 'components/Players/VideoJs/LivePlayer';
import UpcomingLanding from 'components/payPerView/UpcomingLanding/UpcomingLanding';

const ScheduledLivePlayerPage = () => {
  const { id: eventId } = useParams();
  const { trackEvent } = useAnalytics();
  const { user } = useSession();

  const { getScheduled, scheduledLive, getScheduledStatus, getScheduledReset } = useScheduleLive();

  const { isLive, offLine } = useWatchLiveSocket({
    status: getScheduledStatus,
    eventId,
    isScheduled: true
  });

  const [showUpcoming, setShowUpcoming] = useState(false);

  const { state, celebrities, videoUrl, sponsorUrl, paidByUser, ppv, title } = scheduledLive || {};

  const isHost = useMemo(() => celebrities?.some(({ id }) => id === user?.id), [user, celebrities]);
  const liveNow = useMemo(() => state === LIVE_STATUSES.STARTED || isLive, [state, isLive]);

  useEffect(() => {
    getScheduled(eventId, liveNow);
  }, []);

  useEffect(() => {
    !!title &&
      (!ppv || paidByUser) &&
      trackEvent(MIXPANEL_EVENTS.scheduledLiveEventView, { 'Event Name': title });
  }, [title, paidByUser]);

  useEffect(() => {
    if (getScheduledStatus === SUCCESS || isLive) {
      getScheduledReset();
      if (showUpcoming && liveNow && (!ppv || paidByUser)) {
        setShowUpcoming(false);
      } else if (!liveNow || (ppv && !paidByUser && !isHost)) {
        setShowUpcoming(true);
      }
    }
  }, [getScheduledStatus, isLive]);

  if (showUpcoming) {
    return <UpcomingLanding event={scheduledLive} />;
  }

  if (getScheduledStatus === LOADING) {
    return (
      <div className="live-loading">
        <Loading />
      </div>
    );
  }

  return (
    <div className="video-frame">
      {scheduledLive?.id && (
        <LivePlayer
          celebrities={celebrities}
          liveUrl={videoUrl}
          isScheduled
          sponsorUrl={sponsorUrl}
          title={title}
          offline={offLine}
        />
      )}
    </div>
  );
};

export default ScheduledLivePlayerPage;
