import { createReducer } from '@rootstrap/redux-tools';

import { createCard, getCards } from 'state/actions/paymentsActions';

const initialState = {
  creditCard: {},
  creditCards: []
};

export default createReducer(initialState, {
  [createCard.success]: (state, { payload }) => {
    state.creditCard = payload;
  },
  [getCards.success]: (state, { payload }) => {
    state.creditCards = payload;
  }
});
