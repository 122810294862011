/* eslint-disable react/self-closing-comp */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import parseError from 'utils/parseError';
import { func, object, string } from 'prop-types';
import celebritiesService from 'services/celebritiesService';
import { useSession } from 'hooks';
import { videoTypes } from 'constants/constants';
import Loading from 'components/common/Loading';
import DummyImg from 'assets/IDBack.png';

const Content = ({ hide, type, onSaveContent, content }) => {
  const { user } = useSession();
  const [loading, setLoading] = useState(false);
  const [contentData, setContentData] = useState({
    narratives: [],
    originals: []
  });
  const [selectedItems, setSelectedItems] = useState({});
  const getVideoContent = async () => {
    try {
      setLoading(true);
      const { data } = await celebritiesService.getCelebrity(user.username);
      const processedData = {
        narratives: data.celebrity.narratives || [],
        originals: (data.celebrity.series || []).flatMap(series =>
          series.episodes.map(episode => ({
            ...episode,
            seriesTitle: series.title,
            seriesId: series.id
          }))
        )
      };
      setLoading(false);
      setContentData(processedData);
    } catch ({ response }) {
      setLoading(false);
      throw parseError(response);
    }
  };

  useEffect(() => {
    if (content && contentData) {
      const initialSelected = {};

      const matchAndSetSelected = (contentIds, dataArray) => {
        contentIds.forEach(id => {
          const matchedItem = dataArray.find(
            item =>
              item.id.toString() === id.toString() || item.episodeId?.toString() === id.toString()
          );
          if (matchedItem) {
            initialSelected[matchedItem.id] = true;
          }
        });
      };

      matchAndSetSelected(content.narratives, contentData.narratives);
      matchAndSetSelected(content.series, contentData.originals);
      setSelectedItems(initialSelected);
    }
  }, [content, contentData]);

  useEffect(() => {
    const fetchAndInitialize = async () => {
      try {
        await getVideoContent();
      } catch (error) {
        console.error(error);
      }
    };

    fetchAndInitialize();
  }, []);

  const isAnythingSelected = () => {
    return Object.values(selectedItems).some(value => value === true);
  };

  const handleCheckboxChange = itemId => {
    setSelectedItems(prev => ({
      ...prev,
      [itemId]: !prev[itemId]
    }));
  };
  const renderThumbnail = item => {
    const thumbnailUrl = item.thumbnailsUrls?.[0]?.src || '';
    return (
      <div key={item.id} className={cn('ad-thumbnail-container')}>
        <div className={cn('ad-thumbnail')}>
          <img src={thumbnailUrl || DummyImg} alt={item.title} />
        </div>
        <label className={cn('ad-checkbox-label')}>
          <input
            type="checkbox"
            className={cn('ad-custom-checkbox')}
            checked={selectedItems[item.id] || false}
            onChange={() => handleCheckboxChange(item.id)}
          />
          <span className={cn('ad-checkmark')}></span>
          <span className={cn('ad-video-title')}>
            {type === videoTypes.ORIGINALS ? `${item.seriesTitle} - ${item.title}` : item.title}
          </span>
        </label>
      </div>
    );
  };

  const renderContent = () => {
    const items = type === videoTypes.ORIGINALS ? contentData.originals : contentData.narratives;
    return (
      <>
        {items.length === 0 ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              height: '300px',
              alignItems: 'center'
            }}
          >
            <p>No Content Available</p>
          </div>
        ) : (
          <div className={cn('ad-thumbnail-grid')}>
            {items.map((item, index) => renderThumbnail(item, index))}
          </div>
        )}
      </>
    );
  };

  const handleSaveContent = () => {
    const selectedIds = Object.keys(selectedItems).filter(id => selectedItems[id]);
    const selectedContent = {
      narratives: [],
      series: []
    };

    selectedIds.forEach(id => {
      const item = [...contentData.originals, ...contentData.narratives].find(
        item => item.id.toString() === id
      );

      if (item.seriesTitle) {
        selectedContent.series.push(item.episodeId);
      } else {
        selectedContent.narratives.push(item.id);
      }
    });

    onSaveContent(selectedContent);
    hide();
  };

  if (loading) {
    return (
      <div className={cn('content-loading')}>
        <Loading />
      </div>
    );
  }
  return (
    <div className={cn('main-content-div')}>
      <div className={cn('contentPage')}>
        <div className={cn('scrollable-content')}>{renderContent()}</div>
      </div>
      <div className={cn('fixed-bottom')}>
        <button
          className={cn('save-button', { 'disabled-button': !isAnythingSelected() })}
          onClick={handleSaveContent}
          disabled={!isAnythingSelected()}
        >
          SAVE CONTENT
        </button>
      </div>
    </div>
  );
};

Content.propTypes = {
  hide: func,
  type: string,
  onSaveContent: func,
  content: object
};

export default Content;
