import React from 'react';
import { shape } from 'prop-types';
import { useIntl } from 'react-intl';

import { getDayAndMonth, getDiffDay } from 'utils/date';
import { MEMBERSHIP_STATUSES } from 'constants/constants';
import { membershipShape } from 'constants/propTypesShapes';
import Crown from 'components/icons/membership/Crown';
import UserIcon from 'assets/user.png';

const SubscriberMembershipCard = ({ membership }) => {
  const intl = useIntl();

  const {
    currentPeriodEndsAt,
    status,
    user: { fullName },
    membershipPlan: { period }
  } = membership;

  const { ACTIVE, ACTIVE_CANCELED } = MEMBERSHIP_STATUSES;

  return (
    <div className="membership-card">
      <div className="membership-card-banner">
        <div className="gradient" />
      </div>
      <div className="membership-card-content">
        <div className="flex content-row">
          <img className="membership-card-avatar" src={UserIcon} alt="avatar" />
          <div className="category-semibold">{fullName}</div>
        </div>
        <div className="flex-end content-row">
          <div className="flex-1">
            {status === ACTIVE && (
              <div className="p1">
                {`${intl.formatMessage(
                  { id: 'membershipCard.periodLabel' },
                  { period }
                )} ${getDayAndMonth(currentPeriodEndsAt)} `}
                {intl.formatMessage(
                  { id: 'membershipCard.timeToNextPayment' },
                  {
                    expiry: `${getDiffDay(currentPeriodEndsAt)} ${intl.formatMessage(
                      { id: 'date.days' },
                      { amount: getDiffDay(currentPeriodEndsAt) }
                    )}`
                  }
                )}
              </div>
            )}
            {status === ACTIVE_CANCELED && (
              <div className="p1">
                <span className="red">
                  {`${intl.formatMessage({ id: 'membershipCard.canceled' })}. `}
                </span>
                {`${intl.formatMessage({ id: 'membershipCard.activeUntil' })} ${getDayAndMonth(
                  currentPeriodEndsAt
                )} `}
              </div>
            )}
          </div>
          {status === ACTIVE && (
            <div className="subscription-label">
              <Crown />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

SubscriberMembershipCard.propTypes = {
  membership: shape(membershipShape).isRequired
};

export default SubscriberMembershipCard;
