import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLoading, SUCCESS, useStatus } from '@rootstrap/redux-tools';

import { getNotifications, readNotifications } from 'state/actions/notificationsActions';

export default () => {
  const dispatch = useDispatch();

  const { status: readStatus } = useStatus(readNotifications);

  const notifications = useSelector(({ notifications }) => notifications.notifications);

  const requestNotifications = useCallback(() => {
    return dispatch(getNotifications());
  }, [dispatch]);

  const readAllNotifications = useCallback(() => {
    const ids = notifications.filter(({ read }) => !read).map(({ id }) => id);
    if (ids?.length) {
      return dispatch(readNotifications(ids));
    }
  }, [notifications, dispatch]);

  useEffect(() => {
    if (readStatus === SUCCESS) {
      dispatch(readNotifications.reset());
    }
  }, [readStatus, dispatch]);

  return {
    notifications,
    loading: useLoading(getNotifications),
    badge: useSelector(({ notifications: { badge } }) => badge),
    requestNotifications,
    readAllNotifications
  };
};
