import React, { useState } from 'react';
import { shape } from 'prop-types';
import cn from 'classnames';

import { subcategoryShape } from 'constants/propTypesShapes';
import { routesPaths } from 'constants/routesPaths';
import { routeWithProps } from 'utils/helpers';
import { useToggle, useModal, useNavigation } from 'hooks';

import SimpleThumbnail from 'components/categoryList/SimpleThumbnail/SimpleThumbnail';
import { ArrowIcon } from 'components/icons';
import FeaturedCelebrities from 'components/celebrity/FeaturedCelebrities/FeaturedCelebrities';
import SerieLanding from 'components/serieLanding/SerieLanding/SerieLanding';
import Modal from 'components/common/Modal/Modal';

import { black40 } from 'styles/common/_constants.scss';

const SubcategorySection = ({ subcategory: { name, content, celebrities } }) => {
  const { goTo } = useNavigation();
  const [expanded, toggleExpanded] = useToggle();
  const [shownSerie, setShownSerie] = useState();
  const { toggle, isShowing } = useModal();

  const hasCelebrities = celebrities?.length > 0;

  const handleItemClick = (narrative, serie) => {
    if (serie) {
      setShownSerie(serie);
      toggle();
    } else {
      const { id, celebrity: { id: celebrityId } = {} } = narrative || {};
      narrative && goTo(routeWithProps(routesPaths.narratives, { celebrityId, id }));
    }
  };

  return (
    <div className="subcategory-section">
      <div
        className={cn('subcategory-section-title', { expanded }, { clickable: hasCelebrities })}
        onClick={() => hasCelebrities && toggleExpanded()}
      >
        <h3>{name}</h3>
        {hasCelebrities && (
          <ArrowIcon width={13} height={7} color={black40} type={expanded ? 'up' : 'down'} />
        )}
      </div>
      <div className={cn('container', { expanded })}>
        <FeaturedCelebrities
          celebrities={celebrities}
          className={cn('subcategory-celebrities', { collapsed: !expanded })}
          size="medium"
          mobileReduced
        />
        <div className="subcategory-section-items">
          {content.map(({ narrative, series }) => (
            <SimpleThumbnail
              narrative={narrative}
              serie={series}
              className="subcategory-content"
              key={narrative?.id || series?.id}
              onClick={() => handleItemClick(narrative, series)}
            />
          ))}
        </div>
      </div>
      <Modal hide={toggle} isShowing={isShowing}>
        <SerieLanding serie={shownSerie} />
      </Modal>
    </div>
  );
};

SubcategorySection.propTypes = {
  subcategory: shape(subcategoryShape)
};

export default SubcategorySection;
