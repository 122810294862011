import { messaging } from 'utils/firebase';
import { useDispatch } from 'react-redux';

import { registerDevice } from 'state/actions/userActions';
import { useToast } from 'hooks';
import useSession from 'hooks/useSession';
import useNotifications from './useNotifications';

export default () => {
  const { showNotificationToast } = useToast();
  const { authenticated, token } = useSession();
  const dispatch = useDispatch();
  const { requestNotifications } = useNotifications();

  const showNotification = payload => {
    const {
      notification: { title }
    } = payload;
    requestNotifications();
    showNotificationToast(title);
  };

  const initializePushNotifications = () => {
    if (!messaging) return;
    if (authenticated && !token) {
      dispatch(registerDevice());
    }
    messaging.onMessage(payload => showNotification(payload));
  };

  return { showNotification, initializePushNotifications };
};
