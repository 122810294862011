import { createThunk } from '@rootstrap/redux-tools';
import reactionService from 'services/reactionService';
import parseError from 'utils/parseError';

export const getReaction = createThunk('GET_REACTION', async param => {
  try {
    const { data } = await reactionService.getReaction(param);
    return data;
  } catch ({ response }) {
    throw parseError(response);
  }
});

export const reaction = createThunk('REACTION', async param => {
  try {
    const { data } = await reactionService.reaction(param);
    return data;
  } catch ({ response }) {
    throw parseError(response);
  }
});

export const deleteReaction = createThunk('DELETE_REACTION', async param => {
  try {
    const { data } = await reactionService.deleteReaction(param);
    return data;
  } catch ({ response }) {
    throw parseError(response);
  }
});
