import React, { useMemo } from 'react';
import { shape } from 'prop-types';
import { useIntl, defineMessages } from 'react-intl';
import cn from 'classnames';

import { useNavigation, useIsMobile } from 'hooks';
import useCountdown from 'hooks/useCountdown';
import { contentShape } from 'constants/propTypesShapes';
import { getWrittenDate } from 'utils/date';

import BackgroundOpacity from 'components/common/BackgroundOpacity/BackgroundOpacity';
import PlayerNavigationBar from 'components/Players/Controls/PlayerNavigationBar';
import PayPerViewButton from 'components/payPerView/PayPerViewButton/PayPerViewButton';
import { LIVE_STATUSES } from 'constants/constants';

const labels = defineMessages({
  offline: { id: 'live.offline' },
  live: { id: 'live.isLive' },
  button: { id: 'payPerView.buyWithPrice' },
  title: { id: 'payPerView.upcomingLiveMessage' },
  liveTitle: { id: 'scheduledLive.started' }
});

const UpcomingLanding = ({ event }) => {
  const intl = useIntl();
  const {
    title,
    sponsorUrl,
    desktopImageUrl,
    mobileImageUrl,
    celebrities,
    bannerUrl,
    liveStart,
    state,
    ppv
  } = event;
  const { goBack } = useNavigation();
  const isMobile = useIsMobile();

  const timeLeft = useCountdown(liveStart);
  const isLive = useMemo(() => state === LIVE_STATUSES.STARTED, [state]);

  const backgroundImage = useMemo(() => (isMobile ? mobileImageUrl : bannerUrl), [
    isMobile,
    bannerUrl,
    mobileImageUrl
  ]);

  return (
    <div className="video-container upcoming-landing">
      <BackgroundOpacity
        background={backgroundImage || desktopImageUrl}
        opacity={0}
        isVideo={!isMobile && !!bannerUrl}
      />
      <div className="controls live-controls">
        <PlayerNavigationBar celebrities={celebrities} disableSharing customGoBack={goBack} />
        <div className={cn('live-tag', { offline: !isLive })}>
          <div className="live-circle" />
          <h6 className="bold">{intl.formatMessage(isLive ? labels.live : labels.offline)}</h6>
        </div>
        <div className="upcoming-landing-message flex-center flex-column">
          {isLive && <h1>{intl.formatMessage(labels.liveTitle)}</h1>}
          {!isLive &&
            (timeLeft ? (
              <>
                <h1>{intl.formatMessage(labels.title)}</h1>
                <h1>{timeLeft}</h1>
              </>
            ) : (
              <h1>{intl.formatMessage({ id: 'countdown.hasStarted' })}</h1>
            ))}
          <div className="p3-regular">{getWrittenDate(liveStart)}</div>
          {ppv && <PayPerViewButton event={event} type="primary" isLanding />}
        </div>
        <div className="info" style={{ display: 'initial' }}>
          {!!sponsorUrl && <img src={sponsorUrl} alt="Sponsor logo" className="sponsor-logo" />}
          <h4>{title}</h4>
        </div>
      </div>
    </div>
  );
};

UpcomingLanding.propTypes = {
  event: shape(contentShape)
};

export default UpcomingLanding;
