import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { adManagementTabs } from 'constants/constants';

import BannerAdsList from 'components/ManageAds/ManageBannerAds';
import WidgetAdList from 'components/ManageAds/ManageWidgetAds';
import VideoAdsList from 'components/ManageAds/ManageVideoAds';
import { routeWithProps } from 'utils/helpers';
import { routesPaths } from 'constants/routesPaths';
import { getTabNameFromId } from 'utils/dataHelpers';
import { useSession } from 'hooks';

export default () => {
  const { replace } = useHistory();
  const { narrativeId, episodeId, seriesId } = useParams();
  const {
    user: { id: celebrityId }
  } = useSession();
  const renderAdManagementContent = (tab, data, isModal = false) => {
    const { VIDEO_ADS, BANNER_ADS, WIDGET_ADS } = adManagementTabs;
    switch (tab) {
      case VIDEO_ADS.id:
        return <VideoAdsList isModal={isModal} VideoAds={data?.data?.videoAds} />;
      case BANNER_ADS.id:
        return <BannerAdsList isModal={isModal} BannerAds={data?.data?.bannerAds} />;
      case WIDGET_ADS.id:
        return <WidgetAdList isModal={isModal} WidgetAds={data?.data?.widgetAds} />;
      default:
    }
  };

  const changeUrl = id => {
    const tab = id ? getTabNameFromId(adManagementTabs, id) : '';
    if (narrativeId) {
      replace(
        routeWithProps(routesPaths.managevideoAdsNarrative, {
          tab,
          narrativeId,
          celebrityId
        })
      );
    } else {
      replace(
        routeWithProps(routesPaths.managevideoAdsSeries, { tab, celebrityId, episodeId, seriesId })
      );
    }
  };

  return {
    renderAdManagementContent,
    changeUrl
  };
};
