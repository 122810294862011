import React from 'react';
import { number, string } from 'prop-types';

import { white } from 'styles/common/_constants.scss';

const ShareIcon = ({ width = 20, height = 20, color = white, strokeWidth = 1.5 }) => (
  // <svg
  //   width={width}
  //   height={height}
  //   viewBox="0 0 20 20"
  //   fill="none"
  //   xmlns="http://www.w3.org/2000/svg"
  //   aria-labelledby="logoTitle logoDesc"
  //   role="img"
  // >
  //   <title id="logoTitle">Share</title>
  //   <desc id="logoDesc">Share icon</desc>
  //   <path
  //     d="M3.33337 10V16.6667C3.33337 17.1087 3.50897 17.5326 3.82153 17.8452C4.13409 18.1577 4.55801 18.3333 5.00004 18.3333H15C15.4421 18.3333 15.866 18.1577 16.1786 17.8452C16.4911 17.5326 16.6667 17.1087 16.6667 16.6667V10"
  //     stroke={color}
  //     strokeWidth={strokeWidth}
  //     strokeLinecap="round"
  //     strokeLinejoin="round"
  //   />
  //   <path
  //     d="M13.3334 4.99999L10.0001 1.66666L6.66675 4.99999"
  //     stroke={color}
  //     strokeWidth={strokeWidth}
  //     strokeLinecap="round"
  //     strokeLinejoin="round"
  //   />
  //   <path
  //     d="M10 1.66666V12.5"
  //     stroke={color}
  //     strokeWidth={strokeWidth}
  //     strokeLinecap="round"
  //     strokeLinejoin="round"
  //   />
  // </svg>
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M20.791 12.607c.244-.209.366-.313.411-.438a.5.5 0 0 0 0-.338c-.045-.124-.167-.23-.41-.438L12.32 4.132c-.42-.36-.63-.54-.809-.545a.5.5 0 0 0-.4.184C11 3.91 11 4.186 11 4.74v4.296a9.666 9.666 0 0 0-8 9.516v.612a11.401 11.401 0 0 1 8-4.093v4.19c0 .554 0 .83.112.969a.5.5 0 0 0 .4.184c.178-.005.388-.185.809-.545l8.47-7.26Z"
    />
  </svg>
);

ShareIcon.propTypes = {
  width: number,
  height: number,
  color: string,
  strokeWidth: number
};

export default ShareIcon;
