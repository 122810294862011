import React from 'react';
import { string, object } from 'prop-types';
import cn from 'classnames';

import InfoTag from '../InfoTag';

const Info = ({ event, className }) => {
  const { mobileCoverImageUrl, title } = event;

  return (
    <div className={cn('info', className)}>
      <InfoTag title={title} />
      <img src={mobileCoverImageUrl} alt="mobile-cover" className="serie-logo" />
    </div>
  );
};

Info.propTypes = {
  event: object,
  className: string
};

export default Info;
