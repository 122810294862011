import React, { useEffect } from 'react';
import cn from 'classnames';

import { LiveHeroArrows } from 'components/icons';

import { useToggle } from 'hooks';

const ANIMATION_DURATION = 1500;

const LiveIndicatorArrows = () => {
  const [animationVisible, toggleAnimation] = useToggle(true);

  useEffect(() => {
    const interval = setInterval(() => {
      toggleAnimation();
    }, ANIMATION_DURATION);
    return () => {
      clearInterval(interval);
    };
  }, [animationVisible]);

  return (
    <>
      <LiveHeroArrows className={cn('live-arrow bottom-left', { hidden: !animationVisible })} />
      <LiveHeroArrows
        className={cn('live-arrow top-right', { hidden: !animationVisible })}
        rotation={180}
      />
    </>
  );
};

export default LiveIndicatorArrows;
