import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useStatus, SUCCESS, ERROR } from '@rootstrap/redux-tools';

import useToast from 'hooks/useToast';

import { deleteNarrative } from 'state/actions/narrativeActions';

export default callback => {
  const dispatch = useDispatch();
  const { status } = useStatus(deleteNarrative);
  const { showToast, showErrorToast } = useToast();

  useEffect(() => {
    if (status === SUCCESS) {
      showToast('Publication successfully deleted!');
      dispatch(deleteNarrative.reset());
      callback();
    }
    if (status === ERROR) {
      showErrorToast('There was an error trying to delete your publication.');
      dispatch(deleteNarrative.reset());
    }
  });

  return useCallback(
    (celebrityId, narrativeId) => dispatch(deleteNarrative(celebrityId, narrativeId)),
    [dispatch]
  );
};
