export const customStyles = {
  headRow: {
    style: {
      backgroundColor: 'rgba(0, 0, 0, 1)',
      color: 'white',
      fontSize: '14px',
      paddingLeft: '8px', // Added left padding
      paddingRight: '8px', // Added right padding
      fontWeight: 700,
      textAlign: 'left',
      justifyContent: 'left !important',
      WebkitJustifyContent: 'flex-start'
    }
  },
  headCells: {
    style: {
      justifyContent: 'flex-start',
      WebkitJustifyContent: 'flex-start', // Added to left-align header content
      paddingLeft: '0', // Reset left padding for header cells
      paddingRight: '0', // Reset right padding for header cells
      '& .eAbDNw': {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: '100%',
        width: '100%',
        outline: 'none',
        userSelect: 'none',
        overflow: 'hidden',
        textAlign: 'left'
      }
    }
  },
  rows: {
    style: {
      backgroundColor: 'rgba(0, 0, 0, 1)',
      color: 'white',
      fontSize: '16px',
      fontWeight: 400,
      padding: '20px 0px',
      '&:not(:last-of-type)': {
        borderBottomStyle: 'none'
      }
    }
  },
  progress: {
    style: {
      backgroundColor: 'rgba(0, 0, 0, 1)',
      color: 'white'
    }
  },
  cells: {
    style: {
      paddingTop: '8px',
      paddingBottom: '8px',
      paddingLeft: '0px',
      paddingRight: '0px',
      margin: '8px',
      display: 'flex',
      alignItems: 'flex-start',
      WebkitJustifyContent: 'flex-start'
    }
  },
  noData: {
    style: {
      backgroundColor: 'black',
      color: 'white'
    }
  },
  table: {
    styles: {
      backgroundColor: 'none'
    }
  }
};
