import React from 'react';
import { number, string } from 'prop-types';

import { white } from 'styles/common/_constants.scss';

const OptionsIcon = ({ width = 16, height = 4, color = white }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="logoTitle logoDesc"
    role="img"
  >
    <title id="logoTitle">Options</title>
    <desc id="logoDesc">Options icon</desc>
    <path
      d="M8.00033 2.83332C8.46056 2.83332 8.83366 2.46023 8.83366 1.99999C8.83366 1.53975 8.46056 1.16666 8.00033 1.16666C7.54009 1.16666 7.16699 1.53975 7.16699 1.99999C7.16699 2.46023 7.54009 2.83332 8.00033 2.83332Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.8333 2.83332C14.2936 2.83332 14.6667 2.46023 14.6667 1.99999C14.6667 1.53975 14.2936 1.16666 13.8333 1.16666C13.3731 1.16666 13 1.53975 13 1.99999C13 2.46023 13.3731 2.83332 13.8333 2.83332Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.16683 2.83332C2.62707 2.83332 3.00016 2.46023 3.00016 1.99999C3.00016 1.53975 2.62707 1.16666 2.16683 1.16666C1.70659 1.16666 1.3335 1.53975 1.3335 1.99999C1.3335 2.46023 1.70659 2.83332 2.16683 2.83332Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

OptionsIcon.propTypes = {
  width: number,
  height: number,
  color: string
};

export default OptionsIcon;
