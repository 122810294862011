/* eslint-disable no-cond-assign */
import React, { Fragment, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { useAnalytics } from 'hooks';
import { MIXPANEL_EVENTS } from 'constants/constants';
import Hyperlink from 'components/common/Hyperlink/Hyperlink';
import { TERMS_OF_SERVICES } from 'constants/legal';

const Terms = () => {
  const intl = useIntl();

  /* eslint-disable */
  const parseBodyWithLinks = body => {
    try {
      // Check the input type and length for validation
      if (typeof body !== 'string' || body.length > 10000) {
        throw new Error('Input is not valid or too long.');
      }

      // Enhanced regex pattern to capture [link:title](url) with both http(s) and mailto: links
      const linkRegex = /\[link:([^\]]+?)\]\((https?:\/\/[^\s]+|mailto:[^\s]+)\)/g;

      const parts = [];
      let lastIndex = 0;
      let match;

      // Iterate over all matches
      while ((match = linkRegex.exec(body)) !== null) {
        const textBeforeLink = body.slice(lastIndex, match.index);

        // Destructure the link title and URL
        const [, linkText, linkUrl] = match;

        // Add text before the current link (if any)
        if (textBeforeLink) {
          parts.push({ type: 'text', content: textBeforeLink });
        }

        // Add the actual link object
        parts.push({ type: 'link', text: linkText, link: linkUrl });

        // Update lastIndex to the end of the current match
        lastIndex = linkRegex.lastIndex;
      }

      // Add any remaining text after the last link
      if (lastIndex < body.length) {
        parts.push({ type: 'text', content: body.slice(lastIndex) });
      }

      return parts;
    } catch (error) {
      console.error('Error parsing body:', error);
      return body; // Return original body if an error occurs
    }
  };

  const { trackEvent } = useAnalytics();

  useEffect(() => {
    trackEvent(MIXPANEL_EVENTS.terms);
  }, []);

  return (
    <div className="terms">
      <h1 className="title desktop-only">{intl.formatMessage({ id: 'terms.title' })}</h1>
      {/* <div className="content p2">
        <p>Last Updated: September 8, 2020</p>
        <p>
          These Terms of Service (&ldquo;Terms&rdquo;) are a contract between you and Identifi, Inc.
          (&ldquo;Identifi,&rdquo; &ldquo;we,&rdquo; or &ldquo;us&rdquo;). These Terms govern your
          use of our &ldquo;Services,&ldquo; which refers to the services provided by Identifi for
          discovering, watching, and interacting with our content, including our mobile
          applications, TV applications, and similar players (&ldquo;Apps&rdquo;), our websites, and
          our marketing campaigns. By accepting these Terms or otherwise using the Services, you
          agree to these Terms. If you don&rsquo;t agree to any of these Terms, you are not
          permitted to use the Services.
        </p>
        <p>
          TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, YOU AGREE THAT ALL DISPUTES EXCEPT FOR
          INJUNCTIVE RELIEF AND DISPUTES ELIGIBLE FOR SMALL CLAIMS COURT WILL BE RESOLVED BY BINDING
          ARBITRATION AND NOT COURTS, JUDGES, OR JURIES, AND YOUR CLAIMS CANNOT BE BROUGHT AS A
          CLASS ACTION AGAINST US. Please review Section 18 (&ldquo;Disputes&rdquo;) for full
          details.
        </p>
        <p>
          We can change these Terms at any time. If we believe a change is material or that we are
          legally required to provide notice, we&rsquo;ll use commercially reasonable efforts to let
          you know before the change takes effect, such as through a notice by email or through the
          Services. Changes to these Terms that we believe are immaterial are effective immediately
          upon posting of the new Terms on the Services. By using the Services on or after that
          effective date, you agree to the new Terms. If you don&rsquo;t agree with any changes to
          these Terms, you must immediately stop using the Services, including cancelling any
          subscriptions and deleting any account(s), as any use of the Services after the effective
          date of the new Terms will be subject to the new Terms.
        </p>
        <p>
          <strong>Who Can Use Identifi.</strong>&nbsp;We want our Service to be as open and
          inclusive as possible, but we also want it to be safe, secure, and in accordance with the
          law. So, we need you to commit to a few restrictions in order to be part of the Identifi
          community.&nbsp; By using the Services, you affirm that&nbsp;
        </p>
        <ol type="a">
          <li>You must be at least 13 years old.</li>
          <li>
            You must not be prohibited from receiving any aspect of our Service under applicable
            laws or engaging in payments related Services if you are on an applicable denied party
            listing.
          </li>
          <li>
            We must not have previously disabled your account for violation of law or any of our
            policies.
          </li>
          <li>
            you are located in one of the geographic territories to which Identifi directs the
            Services.
          </li>
        </ol>
        <p>
          <strong>How You Can&apos;t Use Identifi.</strong>&nbsp;Providing a safe Service for a
          broad community requires that we all do our part.&nbsp;
        </p>
        <ol type="a">
          <li>
            <strong>You can&apos;t impersonate others or provide inaccurate information.</strong>
            <br />
            You don&apos;t have to disclose your identity on Identifi, but you must provide us with
            accurate and up to date information (including registration information). Also, you may
            not impersonate someone you aren&apos;t, and you can&apos;t create an account for
            someone else unless you have their express permission.
          </li>
          <li>
            <strong>
              You can&apos;t do anything unlawful, misleading, or fraudulent or for an illegal or
              unauthorized purpose.
            </strong>
          </li>
          <li>
            <strong>
              You can&apos;t violate (or help or encourage others to violate) these Terms or our
              policies
            </strong>
            .
          </li>
          <li>
            <strong>
              You can&apos;t do anything to interfere with or impair the intended operation of the
              Service.
            </strong>
          </li>
          <li>
            <strong>
              You can&apos;t attempt to create accounts or access or collect information in
              unauthorized ways.
            </strong>
            <br />
            This includes creating accounts or collecting information in an automated way without
            our express permission.
          </li>
          <li>
            <strong>
              You can&apos;t attempt to buy, sell, or transfer any aspect of your account (including
              your username) or solicit, collect, or use login credentials or badges of other users.
            </strong>
          </li>
          <li>
            <strong>
              You can&apos;t post private or confidential information or do anything that violates
              someone else&apos;s rights, including intellectual property.
            </strong>
          </li>
          <li>
            <strong>
              You can&apos;t use a domain name or URL in your username without our prior written
              consent.
            </strong>
          </li>
        </ol>
        <p>
          <strong>Permissions You Give to Us as A &ldquo;Creator&rdquo; on the Service.</strong>
          &nbsp;As part of our agreement, you as a &ldquo;Creator&rdquo; on the Service also give us
          permissions that we need to provide the Service.&nbsp;
        </p>
        <ol type="a">
          <li>
            <strong>
              We do not claim ownership of your content, but you grant us a license to use it.
            </strong>
            <br />
            Nothing is changing about your rights in your content. We do not claim ownership of your
            content that you post on or through the Service. Instead, when you share, post, or
            upload content that is covered by intellectual property rights (like photos or videos)
            on or in connection with our Service, you hereby grant to us a non-exclusive,
            royalty-free, transferable, sub-licensable, worldwide license to host, use, distribute,
            modify, run, copy, publicly perform or display, translate, and create derivative works
            of your content (consistent with your privacy and application settings). You can end
            this license anytime by deleting your content or account. However, content will continue
            to appear if you shared it with others and they have not deleted it.&nbsp;
          </li>
          <li>
            <strong>
              Permission to use your username, profile picture, and information about your
              relationships and actions with accounts, ads, and sponsored content.
            </strong>
            <br />
            You give us permission to show your username, profile picture, and information about
            your actions (such as likes) or relationships (such as follows) next to or in connection
            with accounts, ads, offers, and other sponsored content that you follow or engage,
            without any compensation to you.&nbsp;
          </li>
          <li>
            <strong>
              You agree that we can download and install updates to the Service on your device.
            </strong>
          </li>
        </ol>
        <ol>
          <li>
            <strong> Account</strong>
          </li>
        </ol>
        <p>
          You may need to register for an account to use all or part of the Services, such as the
          Apps. When you register for an account or sign up for our mailing list, you may be
          required to provide us with some information about yourself, including personal
          information such as your email address, password, and/or year of birth. You represent and
          warrant that the information you provide to us is accurate. We may require that you change
          your password or any other information that you provide to us in registering for an
          account. Your login is for your personal use only and may not be shared by you for others
          to access your account. You agree to use a strong, unique password for Services that is
          not used on other services and acknowledge that reusing passwords may lead to others being
          able to access your account. You, and not Identifi, are responsible for any use or misuse
          of your account or password, and you must promptly notify us of any confidentiality breach
          or unauthorized use of your login information or your Services account. Accounts may only
          be created on certain devices and may then be used across websites and devices made
          available in your region.
        </p>
        <ol start="2">
          <li>
            <strong> Subscriptions</strong>
          </li>
        </ol>
        <p>
          We offer some Services to you for free while other Services require you to first enable a
          subscription or pay-per-view (PPV) purchases that may require payment (collectively,
          &ldquo;Subscription&rdquo;). Subscriptions may have differing conditions and limitations,
          which will be disclosed at your sign-up or in other communications with you. Subscription
          offerings may vary by jurisdiction, including in availability, pricing, and enabled
          features and content.&nbsp; Any recurring or monthly Subscription will continue and
          automatically renew until terminated or as otherwise stated during sign-up or other
          communications with you. You can cancel your Subscription before it renews as set out
          below in the &ldquo;Cancellation&rdquo; section. You can find specific details regarding
          your Subscription in the Settings of the Apps.&nbsp;
        </p>
        <p>
          By signing up for a Subscription , you agree that Identifi or its payment processor may
          charge the fees shown to you during sign up on a periodic basis to the payment method you
          specify at the time of your initial purchase. All fees for the Subscription are
          non-refundable to the fullest extent permitted under applicable law. You acknowledge and
          agree that any fees for the Subscription may increase at any time, subject to any prior
          notice that we may provide. Additional fees may apply for new features, content, or
          additions to the Apps that may be made available from time to time, in which case Identifi
          will provide you with notice in advance of charging the additional fees. In the event
          Identifi charges additional fees in connection with the Subscription, you will have an
          opportunity to review and accept the additional fees that you will be charged, prior to
          being charged. If you do not accept any such additional fees, Identifi may discontinue
          your access to the Subscription. You acknowledge and agree that Identifi may use Apple
          Pay, Google Pay, or other third-party payment processors to process fees for the
          Subscription on our behalf, and that Identifi will not be liable for any errors caused by
          such third-party payment processors.
        </p>
        <ol start="3">
          <li>
            <strong> Trials</strong>
          </li>
        </ol>
        <p>
          We may sometimes offer free or discounted trials to our Subscriptions . If we offer you a
          trial, the duration, pricing (including whether it&rsquo;s free), and other details of
          your trial will be stated either in the material describing the particular trial or during
          your sign-up for the trial. The terms of such trial offers may change and such offers may
          expire at any time. Trial offers may vary based on region and the specific user. If you
          cancel or upgrade your subscription during a trial or while using a promotional code or
          other credits, your trial may end and the offer may no longer be available.
        </p>
        <p>
          Some trials will auto-enroll users into normal Subscription fees after the trial period
          expires. For such plans, you will receive a prompt to accept those terms and the payment
          processor may charge a small amount to verify that your payment method is valid. Under
          these plans, once your trial period ends, we will automatically begin billing your payment
          method for your periodic Subscription fees (plus any applicable taxes), unless you cancel
          prior to the end of your trial. For that reason, unless otherwise indicated in the trial
          description, you will be asked to set up a valid payment method when redeeming a trial
          offer. For existing subscribers who accept a trial to the Apps, at the end of the trial,
          you may be charged a prorated amount to cover the period between the date the trial ends
          and the date your next billing period begins. If you don&rsquo;t want to be charged after
          your trial period under an auto-enroll trial plan, please cancel your trial Subscription
          no later than the day before the last day of your free trial period.
        </p>
        <ol start="4">
          <li>
            <strong> Cancellation</strong>
          </li>
        </ol>
        <p>
          Your Subscription will continue on a recurring basis according to its terms unless and
          until you cancel your Subscription or are otherwise suspended or discontinued pursuant to
          these terms. You must cancel your Subscription before your next renewal date in order to
          avoid billing for each renewal period. We or our payment processor will bill the periodic
          Subscription fee plus any applicable taxes to the active payment method at the time. If
          you modify your Subscription to switch from one Subscription plan to another during your
          billing period, you may not have continued access to your original Subscription plan. If
          you cancel your Subscription, cancellation will be effective at the end of the current
          billing period -- this means that you will have continued access to your Subscription for
          the remainder of that period, but you will not receive a refund. You will also forfeit any
          service, referral, or redeemed gift card credits upon cancellation, including if you
          switch your billing method from Identifi&rsquo;s direct billing to a third-party payment
          processor like Apple Pay or Google Pay, or vice versa. You can cancel any Subscription by
          logging into your account online at
          <a href="https://www.weidentifi.com">www.weidentifi.com</a> and following the instructions
          in the settings of your account. If you pay for your Subscription through your account
          with a third party (e.g. through the Google Play store) and want to cancel your
          Subscription or manage your billing, you may need to do so through your account with such
          third party.
        </p>
        <ol start="5">
          <li>
            <strong> Ownership of Content and Services</strong>
          </li>
        </ol>
        <p>
          You acknowledge and agree that Identifi owns all rights, titles, and interest in and to
          the Services, including: (a) all videos, audio, images, graphics, text, interfaces,
          information, data, software, and all other elements of the Services, and the design,
          selection, look, feel, and arrangement thereof; and (b) all intellectual property and
          other legal rights (including, but not limited to, any and all copyrights, patents, patent
          applications, trade secrets, trademarks and other intangible rights) therein and thereto.
          You may not publish, reproduce, distribute, display, perform, edit, adapt, modify, or
          otherwise exploit any part of the Services or any content or information made available
          through the Services without Identifi&rsquo;s written consent or the use of
          Identifi&rsquo;s sharing features. You will not acquire or earn any ownership rights in
          any copyrights, patents, trade secrets, trademarks or other intellectual property rights
          on account of these Terms or any access to or use of the Services. You agree that all
          content provided to you through the Services is owned or licensed by Identifi, its service
          providers, and/or other third parties, and that you will have no ownership rights in such
          content.
        </p>
        <ol start="6">
          <li>
            <strong> Comments</strong>
          </li>
        </ol>
        <p>
          Some content on Identifi may allow interactive commenting or messaging. By commenting in a
          Identifi show or live stream, you agree that Identifi, its affiliates, and their
          respective officers, directors, employees, contractors, and agents (collectively, the
          &quot;Administrators&quot;) will not be liable for any technical, administrative, or other
          problems that may cause commenting to be disrupted or corrupted, or for errors in any
          promotional or marketing materials related to commenting. If for any reason commenting is
          not executed as planned or the Administrators have concerns about the fairness, integrity,
          or other aspects of the commenting, the Administrators may, in their sole discretion, take
          any action they deem appropriate, including (a) terminating or suspending commenting at
          any point, or (b) disqualifying, blocking or removing any comments for any reason. By
          commenting, you agree to release, indemnify and hold the Administrators harmless against
          any and all claims and liabilities of any kind arising directly or indirectly out of your
          participation in the commenting.
        </p>
        <ol start="7">
          <li>
            <strong> License Grant and Restrictions</strong>
          </li>
        </ol>
        <p>
          Subject to your compliance with these Terms and payment of all applicable fees, Identifi
          hereby grants you a limited, personal, nonexclusive, non-transferable, non-sublicensable,
          and revocable license to access and use the Services solely for your personal,
          noncommercial use, as provided herein. The following uses of Services are prohibited:
        </p>
        <ol>
          <li>
            transferring or otherwise permitting any other person or entity to access the Services
            using your account, which you acknowledge and agree is personal to you and is
            non-transferable;
          </li>
          <li>
            publishing, reproducing, distributing, displaying, performing, editing, adapting,
            modifying, creating derivative works of, reselling, or otherwise exploiting the Services
            or any content distributed thereon except through the use of Identifi&apos;s sharing
            features;
          </li>
          <li>
            circumventing or disabling any content protection system or digital rights management
            technology used with the Services;
          </li>
          <li>
            decompiling, reverse engineering, disassembling, or otherwise reducing the Services or
            any software or technology provided to you in connection with the Services, to a
            human-readable form, except to the extent expressly permitted by applicable law
            notwithstanding this restriction;
          </li>
          <li>
            removing identification, copyright, trademark, or other proprietary notices from content
            or materials provided on the Services;
          </li>
          <li>accessing or using the Services in an unlawful or unauthorized manner;</li>
          <li>
            accessing or tampering with non-public areas of the Services, our computer systems, or
            the systems of our service providers or partners;
          </li>
          <li>
            accessing or searching the Services by any means other than the generally available,
            published interfaces (e.g., APIs) that we provide;
          </li>
          <li>
            forging any TCP/IP packet header or any part of the header information in any email or
            posting, or in any way use the Services to send altered, deceptive, or false
            information;
          </li>
          <li>
            impersonating or otherwise posing as someone you aren&rsquo;t in communicating with us,
            creating an account, or otherwise using the Services;
          </li>
          <li>
            interfering with, or disrupting, the access of any user, host, or network, including
            sending a virus, overloading, flooding, spamming, mail-bombing the Services, or by
            scripting the creation of content or accounts in such a manner as to interfere with or
            create an undue burden on the Services;
          </li>
          <li>
            conducting facial recognition on any content or other visual material available on the
            Services; and
          </li>
          <li>attempting to do, or assisting any third party in doing, any of the foregoing.</li>
        </ol>
        <p>
          You may access our Services only via our Apps, supported devices for casting or mirroring,
          or standard browsers such as Chrome, Safari, Firefox, Internet Explorer, and not with
          scripts, bots, or other automation code that access our Services to extract content or
          information (&quot;Crawling&quot;). In addition to the rules about Crawling above, you
          must not conduct or support Crawling of our Services except with prior written notice to
          us and in full compliance with the provisions of our robots.txt file. We reserve the right
          to withdraw permission granted for Crawling for any and all reasons, including, without
          limitation, our assessment that your Crawling places an excessive load or burden on the
          Services (as determined by Identifi).
        </p>
        <p>
          Our Services (including any software, content, or other copyrighted materials) are
          licensed, not sold to you. You must not copy, adapt, distribute, publicly display or
          publicly perform any copyrighted materials we make available as part of our Services,
          except in full compliance with these Terms, which constitute license conditions and
          limitations, and contractual covenants. You acknowledge that fees, such as those from your
          mobile carrier or Internet service provider, may apply to the use and operation of your
          device in connection with your use of the Services, and that you are solely responsible
          for any such third-party terms and fees.
        </p>
        <p>
          We will not take legal action against security researchers for violations of relevant
          restrictions on the use of Service to the extent that they are in compliance with the
          rules and policies in our responsible vulnerability disclosure program.&nbsp;
        </p>
        <ol start="8">
          <li>
            <strong> Access to Services</strong>
          </li>
        </ol>
        <p>You acknowledge the following:</p>
        <ol>
          <li>
            Identifi requires high-speed Internet access and a Identifi-compatible device. The
            quality of the content may differ based on your device, settings, and network quality.
            Not all devices are supported for casting and mirroring.
          </li>
          <li>
            Certain features, content, Subscription plans, or Services may not be available or may
            be different based on the time, geographic location, device, account settings, or
            behavior on the Apps;
          </li>
          <li>
            Access to the Services may be interrupted for reasons within or beyond the control of
            Identifi, and that Identifi cannot and does not guarantee you will be able to access or
            use the Services whenever you wish to do so;&nbsp;
          </li>
          <li>
            Identifi offers its Services in locations, times, and on platforms in its sole
            discretion, and availability may be unavailable, prohibited, revoked, or discontinued at
            any time; and
          </li>
          <li>Features, content, or Services offered may be changed or removed at any time.</li>
        </ol>
        <p>
          To the fullest extent permitted under applicable law, we will not be liable for any change
          to or any discontinuation of the Services. You agree that no refunds, discounts, or other
          consideration shall be provided to you by Identifi or its partners for being unable to
          access the Services or part of the Services unless legally required.
        </p>
        <ol start="9">
          <li>
            <strong> Third Party Services and Links</strong>
          </li>
        </ol>
        <p>
          The Services may contain links to third-party content and integrations with third parties,
          such as advertisers and social media sites. Identifi does not control, endorse, sponsor,
          recommend, or otherwise accept responsibility for any loss or damage that may arise from
          your use of such third-party content and integrations. These links and integrations are
          provided only as a convenience, and Identifi does not make any representations or
          warranties with respect to third-party links and integrations. Use of any linked
          third-party content and integrations is at your own risk and subject to the terms of use
          for such third-party content.
        </p>
        <ol start="10">
          <li>
            <strong> Security</strong>
          </li>
        </ol>
        <p>
          If you find a security vulnerability in the Services, you must report the issue to us at{' '}
          <Hyperlink text={SUPPORT_EMAIL} link={`mailto:${SUPPORT_EMAIL}`} /> and keep it
          confidential until we have fixed the vulnerability.&nbsp;
        </p>
        <ol start="11">
          <li>
            <strong> Identifi&rsquo;s Trademarks</strong>
          </li>
        </ol>
        <p>
          The word &ldquo;Identifi,&rdquo; the Identifi logo, the phrase &ldquo;We Identifi&rdquo;
          and other Identifi marks, graphics, and logos are trademarks of Identifi. None of the
          Identifi trademarks may be used, copied, downloaded, or otherwise exploited without
          Identifi&rsquo;s prior written consent in each instance. You may not use our trade names,
          trademarks, service marks or logos in connection with any product or service that is not
          ours, or in any manner that is likely to cause confusion. Nothing contained on the
          Services should be construed as granting any right to use any trade names, trademarks,
          service marks or logos without the express prior written consent of Identifi or the owner
          of the marks, as applicable.
        </p>
        <ol start="12">
          <li>
            <strong> Promotions</strong>
          </li>
        </ol>
        <p>
          Any sweepstakes, contests, raffles, surveys, games, or similar promotions (collectively,
          &ldquo;Promotions&rdquo;) made available through the Services will be governed by rules
          that are separate from these Terms. If you participate in any Promotions, please carefully
          review any promotion-specific rules as well as our Privacy Policy. If and to the extent
          those rules conflict with these Terms or the Privacy Policy, the Promotion rules will
          govern.
        </p>
        <ol start="13">
          <li>
            <strong> Feedback</strong>
          </li>
        </ol>
        <p>
          Identifi may use any comments, information, ideas, concepts, suggestions, reviews,
          techniques, or any other material contained in any communication you send to us
          (&ldquo;Feedback&rdquo;), including customer support inquiries or responses to surveys,
          worldwide and in perpetuity without compensation, acknowledgment or payment to you for any
          purpose whatsoever, including, but not limited to, developing, manufacturing, and
          marketing products and creating, modifying, or improving the Identifi Services. In
          addition, you agree to waive and not to enforce any &ldquo;moral rights&rdquo; in and to
          the Feedback, to the extent permitted by applicable law.
        </p>
        <ol start="14">
          <li>
            <strong> Communications with You</strong>
          </li>
        </ol>
        <p>
          As permitted by applicable law, Identifi, Identifi&rsquo;s service providers, and/or
          Identifi partners may send you communications that (i) solicit Feedback via email,
          surveys, bug reports, or other methods Identifi may determine; (ii) collect additional
          information regarding issues you report in your Feedback; (iii) notify you of changes to
          the Services or these Terms; and (iv) tell you about future Identifi programs, products or
          services.
        </p>
        <ol start="15">
          <li>
            <strong> Unsolicited Materials</strong>
          </li>
        </ol>
        <p>
          Identifi does not accept unsolicited materials or ideas for content. Should you send any
          unsolicited materials or ideas, you do so with the understanding that (a) there is no
          implied agreement of any sort between you and Identifi regarding such materials or ideas,
          (b) no consideration of any sort will be provided to you, (c) neither Identifi nor any of
          its affiliates is responsible for the alleged or actual similarity of any content or
          programming in any media to such unsolicited materials, and (d) you are hereby waiving and
          releasing any and all moral rights or other claims against Identifi and its affiliates
          related to such materials and ideas, even if any content or programming developed,
          produced or exploited by Identifi or its affiliates in any manner is substantially similar
          to the material or idea you submitted to us. Identifi&rsquo;s standard policy is to return
          unsolicited materials submitted in writing (and delete any materials submitted
          electronically) without reviewing them or keeping a copy.
        </p>
        <ol start="16">
          <li>
            <strong> Customer Support</strong>
          </li>
        </ol>
        <p>
          Identifi cares about your issues, thoughts, and concerns. To help us find the best person
          to address your issue, please email the appropriate contact below:
        </p>
        <ul>
          <li>
            Report a bug, security vulnerability, privacy issue or feedback:{' '}
            <Hyperlink text={SUPPORT_EMAIL} link={`mailto:${SUPPORT_EMAIL}`} />
          </li>
          <li>
            Media, advertising or sponsorship inquiries or issues:{' '}
            <Hyperlink text={MEDIA_INQUIRIES_EMAIL} link={`mailto:${MEDIA_INQUIRIES_EMAIL}`} />
          </li>
          <li>
            Submissions for content: n/a (we do not accept unsolicited submissions, see above)
          </li>
        </ul>
        <ol start="17">
          <li>
            <strong> Complaints</strong>
          </li>
        </ol>
        <p>
          If you have a question or complaint regarding our Services, please send an e-mail to{' '}
          <Hyperlink text={SUPPORT_EMAIL} link={`mailto:${SUPPORT_EMAIL}`} /> . Please note that
          e-mail communications will not necessarily be secure; accordingly you should not include
          credit card information or other sensitive information in your e-mail correspondence with
          us. California residents may reach the Complaint Assistance Unit of the Division of
          Consumer Services of the California Department of Consumer Affairs by mail at 1625 North
          Market Blvd., Sacramento, CA 95834, or by telephone at (916) 445-1254 or (800) 952-5210.
        </p>
        <ol start="18">
          <li>
            <strong> Disputes</strong>
          </li>
        </ol>
        <p>
          If you have an issue or concern with the Services, we&rsquo;d like to try to resolve it
          informally first. Before filing a legal complaint against Identifi, you agree to try to
          resolve the dispute informally by contacting{' '}
          <Hyperlink text={SUPPORT_EMAIL} link={`mailto:${SUPPORT_EMAIL}`} /> . We&rsquo;ll try to
          resolve the dispute informally by responding to you by email. If the dispute is not
          resolved within 15 business days of submission, you or Identifi may bring a formal
          proceeding.
        </p>
        <p>
          Venue. You and Identifi agree that any judicial proceeding to resolve claims relating to
          these terms or the Service will be brought in the federal or state courts of Los Angeles
          County, California, subject to the mandatory arbitration provisions below. Both you and
          Identifi consent to venue and personal jurisdiction in such courts for any matters not
          resolved by arbitration as provided below. If you reside in a country (e.g., in the EU or
          Canada) with laws that give consumers the right to bring disputes in their local courts,
          this paragraph doesn&rsquo;t affect those rights.
        </p>
        <p>
          Arbitration. Unless you are a consumer located in a jurisdiction that prohibits the
          exclusive use of arbitration for dispute resolution or class action waivers, you also
          agree to the following mandatory arbitration provisions and class action waivers to the
          extent they are not prohibited under local, applicable law:
        </p>
        <ol type="a">
          <li>
            &nbsp;We both agree to arbitrate.&nbsp;You and Identifi each agree to resolve any claims
            relating to these Terms or the Services (&ldquo;Disputes&rdquo;) through final and
            binding arbitration by a single arbitrator, except as set forth under Exceptions to
            Agreement to Arbitrate below. This includes disputes arising out of or relating to
            interpretation or application of this &ldquo;Mandatory Arbitration Provisions&rdquo;
            section, including its enforceability, revocability, or validity. YOU ARE GIVING UP THE
            RIGHT TO LITIGATE (OR PARTICIPATE IN AS A PARTY OR CLASS MEMBER) ALL DISPUTES IN COURT
            BEFORE A JUDGE OR JURY. INSTEAD, ALL DISPUTES WILL BE RESOLVED BEFORE A NEUTRAL
            ARBITRATOR, WHOSE DECISION WILL BE FINAL EXCEPT FOR A LIMITED RIGHT OF APPEAL UNDER THE
            FEDERAL ARBITRATION ACT OR SIMILAR LEGISLATION IN THE JURISDICTION WHERE THE ARBITRATION
            IS SEATED.
          </li>
          <li>
            30-day right to opt-out of agreement to arbitrate. You can decline this agreement to
            arbitrate by emailing us at{' '}
            <Hyperlink text={SUPPORT_EMAIL} link={`mailto:${SUPPORT_EMAIL}`} /> from the email
            address associated with your account (if you have one), including your name and a
            statement of your intention to opt out in the body of the email. Your opt-out notice
            must be sent to us no later than thirty (30) days after your first becoming subject to
            this arbitration agreement; otherwise, you will be bound to arbitrate all Disputes in
            accordance with this Section 18. If you opt out of this agreement to resolve all
            Disputes by arbitration, Identifi also will not be bound by this agreement to arbitrate
            Disputes. Your election to opt out of this arbitration provision will apply only to
            Disputes that arise after the date you opt out. Any Disputes arising on or before the
            date you opt out of arbitration will continue to be governed by this arbitration
            agreement. If you have previously opted out of this arbitration agreement under a prior
            version of these Terms, you do not need to opt out again.
          </li>
          <li>
            Arbitration procedures. Any dispute, claim or controversy arising out of or relating to
            these Terms or the breach, termination, enforcement, interpretation or validity thereof,
            including the determination of the scope or applicability of this agreement to
            arbitrate, shall be determined exclusively by arbitration in Los Angeles, California in
            the United States of America before an arbitrator. The arbitration shall be administered
            by JAMS pursuant to its JAMS&rsquo; Streamlined Arbitration Rules and Procedures. The
            language to be used in the arbitral proceedings will be English. Judgment on the Award
            may be entered in any court having jurisdiction. This clause shall not preclude parties
            from seeking provisional remedies in aid of arbitration from a court of appropriate
            jurisdiction.
          </li>
          <li>
            Exceptions to the agreement to arbitrate. Either you or Identifi may assert Disputes, if
            they qualify, in small claims court in Los Angeles, CA, or any United States county
            where you live or work. Identifi may bring a lawsuit solely for injunctive relief to
            stop unauthorized use or abuse of the Services, or intellectual property infringement
            (for example, trademark, trade secret, copyright, or patent rights) without first
            engaging in arbitration or the informal dispute-resolution process described above. If
            the agreement to arbitrate is found not to apply to you or your claim, you agree to the
            exclusive jurisdiction of the state and federal courts in Los Angeles County, California
            to resolve your claim to the fullest extent permitted by applicable law.
          </li>
          <li>
            NO CLASS ACTIONS. TO THE FULLEST EXTENT OF THE LAW, YOU AND IDENTIFI AGREE THAT ANY
            PROCEEDINGS TO RESOLVE OR LITIGATE ANY DISPUTE IN ANY FORUM WILL BE CONDUCTED SOLELY ON
            AN INDIVIDUAL BASIS, AND NEITHER YOU NOR IDENTIFI WILL SEEK TO HAVE ANY DISPUTE HEARD AS
            A CLASS ACTION OR IN ANY OTHER PROCEEDING IN WHICH EITHER PARTY ACTS OR PROPOSES TO ACT
            IN A REPRESENTATIVE CAPACITY, EXCEPT THE ARBITRATOR MAY AWARD PUBLIC INJUNCTIVE RELIEF
            IN THE ARBITRATION IF APPLICABLE. No arbitration or proceeding will be combined with
            another without the prior written consent of all parties to all affected arbitrations or
            proceedings.
          </li>
          <li>
            Filing period. TO THE FULLEST EXTENT PERMITTED BY LAW, ANY DISPUTE UNDER THESE TERMS
            MUST BE FILED WITHIN ONE (1) YEAR, EITHER IN AN ARBITRATION PROCEEDING. The one-year
            period begins when the events giving rise to the Dispute first occur. If a claim is not
            submitted within one year, it is permanently barred. This period can only be extended by
            the written consent of both parties. No statutes or provisions of law that would toll or
            otherwise affect the time in which a party may bring a claim shall operate to extend the
            period limited in this Section, and any such statutes and provisions are hereby waived,
            to the fullest extent permitted by law.
          </li>
          <li>
            Modifications. You have the right to reject any changes to this arbitration provision,
            except for a change to Identifi&rsquo;s contact information. You may reject a change by
            sending us written notice within 30 days. This will result in your account on the
            Services being immediately terminated. Note that this arbitration provision, as it was
            prior to the rejected changes, will remain in effect.
          </li>
        </ol>
        <ol start="19">
          <li>
            <strong> Disclaimer of Warranties</strong>
          </li>
        </ol>
        <p>
          YOU EXPRESSLY AGREE THAT YOUR USE OF THE SERVICES IS AT YOUR OWN RISK. THE SERVICES ARE
          PROVIDED ON AN &ldquo;AS IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo; BASIS, TO THE FULLEST
          EXTENT PERMITTED BY LAW, AND ARE PROVIDED WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND,
          WHETHER EXPRESS, IMPLIED, OR STATUTORY. WITHOUT LIMITING THE FOREGOING, IDENTIFI, THEIR
          RESPECTIVE AFFILIATES, SUCCESSORS AND ASSIGNS, OR ANY OF THEIR RESPECTIVE INVESTORS,
          DIRECTORS, OFFICERS, EMPLOYEES, PROVIDERS (INCLUDING THOSE WHO HELP ADMINISTER AND OPERATE
          THOSE SERVICES), AGENTS, AND SUPPLIERS (COLLECTIVELY, THE &ldquo;IDENTIFI PARTIES&rdquo;)
          DO NOT MAKE ANY WARRANTIES OF FITNESS FOR A PARTICULAR PURPOSE, TITLE, MERCHANTABILITY,
          COMPLETENESS, AVAILABILITY, COMPATIBILITY, OR NON-INFRINGEMENT; OR THAT THE SERVICES WILL
          BE UNINTERRUPTED, FREE OF VIRUSES AND OTHER HARMFUL COMPONENTS, ACCURATE, ERROR FREE, OR
          RELIABLE; OR AS TO THE ACCURACY, CURRENCY, OR COMPLETENESS OF ANY INFORMATION MADE
          AVAILABLE THROUGH THE SERVICES, WHETHER PROVIDED IN VIDEO, AUDIO, TEXTUAL, GRAPHICAL, OR
          OTHER FORM (THE &ldquo;SERVICE CONTENT&rdquo;). IDENTIFI PARTIES NO NOT MAKE ANY
          REPRESENTATIONS OR WARRANTIES WITH RESPECT TO ANY PROVIDERS. IDENTIFI PARTIES ARE NOT
          RESPONSIBLE OR LIABLE FOR ANY DECISIONS YOU MAY MAKE IN RELIANCE ON THE SERVICES OR
          SERVICE CONTENT, OR FOR ANY DEFECTS OR ERRORS IN THE SERVICE, FOR YOUR USE OF THE APPS
          WITH HARDWARE THAT DOES NOT MEET THE MINIMUM REQUIREMENTS SPECIFIED BY IDENTIFI, OR FOR
          YOUR USE OF ANY VERSION OF THE APPS OTHER THAN THE MOST RECENT GENERALLY AVAILABLE RELEASE
          OF SUCH APPS. YOU EXPRESSLY AGREE THAT YOU ARE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR
          PROPERTY, TO THE FULLEST EXTENT PERMITTED BY LAW, INCLUDING YOUR COMPUTER SYSTEM OR MOBILE
          DEVICE, OR THE LOSS OF DATA, THAT RESULTS FROM USE OF THE SERVICES.
        </p>
        <ol start="20">
          <li>
            <strong> Limitations of Liability</strong>
          </li>
        </ol>
        <p>
          This paragraph is inapplicable in Quebec: TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO
          EVENT SHALL IDENTIFI BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL,
          CONSEQUENTIAL, OR OTHER DAMAGES, INCLUDING LOSS OF PROFITS, LOSS OF SAVINGS OR REVENUE,
          LOSS OF USE, LOSS OF LIFE OR HEALTH, THE CLAIMS OF THIRD PARTIES, AND ANY COST OF ANY
          SUBSTITUTE SERVICES, ARISING OUT OF OR IN ANY WAY RELATED TO THE SERVICES, WHETHER BASED
          IN CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, OR ANY OTHER THEORY, EVEN IF
          THE IDENTIFI PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. TO THE FULLEST
          EXTENT PERMITTED BY LAW, IN NO EVENT SHALL THE IDENTIFI PARTIES&rsquo; TOTAL LIABILITY TO
          YOU FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION, WHETHER IN CONTRACT, TORT (INCLUDING
          NEGLIGENCE) OR OTHERWISE EXCEED THE GREATER OF THE ACTUAL AMOUNT YOU PAID FOR THE SERVICES
          (IF ANY) AND USD $100. THE PARTIES AGREE THAT THE FOREGOING LIMITATIONS ARE ESSENTIAL TO
          THE BASIS OF THE BARGAIN CONTEMPLATED BY THESE TERMS.
        </p>
        <p>
          THE FOREGOING LIMITATIONS SHALL APPLY ONLY TO THE EXTENT PERMISSIBLE UNDER APPLICABLE LAW
          (SOME JURISDICTIONS DO NOT PERMIT DISCLAIMERS OF IMPLIED WARRANTIES OR THE EXCLUSION OR
          LIMITATION OF CERTAIN DAMAGES, SO THESE TERMS MAY NOT APPLY TO YOU).
        </p>
        <ol start="21">
          <li>
            <strong> Indemnity</strong>
          </li>
        </ol>
        <p>
          To the fullest extent permitted under applicable law, you agree to defend, indemnify, and
          hold harmless the Identifi Parties (as defined above) and their respective successors and
          assigns, from and against all claims, liabilities, damages, judgments, awards, losses,
          costs, expenses and fees (including attorneys&rsquo; fees) arising out of or relating to
          (a) your use of, or activities in connection with the Services (including all Feedback);
          (b) any violation or alleged violation of these Terms by you, and (c) your fraud,
          intentional misconduct, negligence, or other tortious or criminal acts or omissions.
          Identifi reserves the right to employ separate counsel and assume the exclusive defense
          and control of any matter otherwise subject to indemnification by you. In such event, you
          shall provide Identifi with such information and assistance as Identifi reasonably
          requests.
        </p>
        <ol start="22">
          <li>
            <strong> Termination</strong>
          </li>
        </ol>
        <p>
          Identifi may terminate or suspend your use of the Services at any time and without prior
          notice, for any or no reason, including if Identifi believes that you have violated or
          acted inconsistently with the letter or spirit of these Terms. Upon any such termination
          or suspension, your right to use the Apps will immediately cease, and Identifi may,
          without liability to you or any third party to the fullest extent permitted by applicable
          law, immediately deactivate or delete your account, and all associated data and materials,
          without any obligation to provide any further access to such data or materials. Identifi
          reserves the right to pursue all rights and remedies available at law or equity. These
          terms shall survive the termination of your account, deletion of data, and opt out by the
          user.
        </p>
        <ol start="23">
          <li>
            <strong> Governing Law</strong>
          </li>
        </ol>
        <p>
          These Terms are governed by the laws of the United States (including federal arbitration
          law) and the State of California, U.S.A., without regard to its principles of conflicts of
          law, and regardless of your location.
        </p>
        <ol start="24">
          <li>
            <strong> Miscellaneous</strong>
          </li>
        </ol>
        <ol>
          <li>
            Identifi&rsquo;s Relationship with You. These Terms do not, and shall not be construed
            to, create any partnership, joint venture, employer-employee, agency or
            franchisor-franchisee relationship between you and Identifi.
          </li>
          <li>
            No waiver. Identifi&rsquo;s failure to enforce any provisions of these Terms or respond
            to a violation of these by any person does not waive Identifi&rsquo;s right to
            subsequently enforce any terms or conditions of the Terms or respond to any such
            violations.
          </li>
          <li>
            Severability. If any provision of these terms is found invalid by a court of competent
            jurisdiction, you agree that such provision shall be deemed severable from these Terms,
            that the court should try to give effect to the parties&rsquo; intentions as reflected
            in the provision, and that all other provisions of the Terms will remain in full effect.
          </li>
          <li>
            Assignment, Transfer, and Sublicensing. You may not assign, transfer or sublicense any
            or all of your rights or obligations under these Terms without our express prior written
            consent. We may assign, transfer or sublicense any or all of our rights or obligations
            under these Terms without restriction.
          </li>
          <li>
            Headings. Any heading, caption or section title contained herein is for convenience
            only, and in no way defines or explains or affects the interpretation of any section or
            provision or the rights of any party.
          </li>
          <li>
            Interpretation. All terms defined in the singular shall have the same meanings when used
            in the plural, where appropriate and unless otherwise specified. Any use of the term
            &ldquo;including&rdquo; or variations thereof in these Terms shall be construed as if
            followed by the phrase &ldquo;without limitation.&rdquo;
          </li>
          <li>
            Force Majeure. Identifi will not be responsible for any failure to fulfill any
            obligation due to any cause beyond its reasonable control.
          </li>
          <li>
            Entire agreement. These Terms (including any document incorporated by reference into
            them) are the whole agreement between Identifi and you concerning the Services.
          </li>
          <li>
            Notices. Notices to you (including notices of changes to these Terms) may be made via
            posting to the Services or by e-mail (including in each case via links), or by regular
            mail.
          </li>
          <li>
            Admissibility. Without limitation, a printed version of these Terms and of any notice
            given in electronic form shall be admissible in judicial or administrative proceedings
            based upon or relating to these Terms to the same extent and subject to the same
            conditions as other business documents and records originally generated and maintained
            in printed form.
          </li>
          <li>
            Export Control. You hereby represent and warrant that (i) you are not located in a
            country that is subject to a U.S. Government embargo, or that has been designated by the
            U.S. Government as a &ldquo;terrorist supporting&rdquo; country; and (ii) you are not
            listed on any U.S. Government list of prohibited or restricted parties.
          </li>
          <li>
            Language. The parties have requested and agreed that this contract and all related
            documents shall be drafted in English only.
          </li>
        </ol>
        <ol start="25">
          <li>
            <strong> Apple-Specific Terms</strong>
          </li>
        </ol>
        <p>
          In addition to your agreement with the foregoing terms and conditions, and notwithstanding
          anything to the contrary herein, the following provisions apply with respect to your use
          of any version of the Apps compatible with the iOS operating system of Apple Inc.
          (&ldquo;Apple&rdquo;). Apple is not a party to these Terms and does not own and is not
          responsible for the Apps. Apple is not providing any warranty for the Apps except, if
          applicable, to refund the purchase price for it. Apple is not responsible for maintenance
          or other support services for the Apps and shall not be responsible for any other claims,
          losses, liabilities, damages, costs or expenses with respect to the Apps, including any
          third-party product liability claims, claims that the Apps fail to conform to any
          applicable legal or regulatory requirement, claims arising under consumer protection or
          similar legislation, and claims with respect to intellectual property infringement. Any
          inquiries or complaints relating to the use of the Apps, including those pertaining to
          intellectual property rights, must be directed to Identifi in accordance with the
          &ldquo;Customer Support&rdquo; section above. The license you have been granted herein to
          our iOS-compatible Apps is limited to a non-transferable license to use the Apps on an
          Apple-branded product that runs Apple&rsquo;s iOS operating system and is owned or
          controlled by you, or as otherwise permitted by the Usage Rules set forth in Apple&rsquo;s
          App Store Terms of Service, except that the Apps may also be accessed and used by other
          accounts associated with you via Apple&rsquo;s Family Sharing or volume purchasing
          programs. In addition, you must comply with the terms of any third-party agreement
          applicable to you when using the Apps, such as your wireless data service agreement. Apple
          and Apple&rsquo;s subsidiaries are third-party beneficiaries of these Terms and, upon your
          acceptance of the terms and conditions of these Terms, will have the right (and will be
          deemed to have accepted the right) to enforce these Terms against you as a third-party
          beneficiary thereof; notwithstanding the foregoing, Company&rsquo;s right to enter into,
          rescind or terminate any variation, waiver or settlement under these Terms are not subject
          to the consent of any third party.
        </p>
        <p>
          &copy; 2020 Identifi, Inc. unless otherwise noted. All rights reserved. Identifi and its
          Logo are trademarks of Identifi, Inc. All other product names, company names, marks,
          logos, and symbols are trademarks of their respective owners.
        </p>
        <p>Identifi</p>
      </div> */}
      <div className="content p2">
        {TERMS_OF_SERVICES.map(({ title, body }, index) => (
          <Fragment key={index}>
            <h3 className="legal-title">{title}</h3>
            <div className="body p2">
              {parseBodyWithLinks(body).map((part, idx) => {
                if (part.type === 'text') {
                  return part.content;
                }
                if (part.type === 'link') {
                  return <Hyperlink key={idx} text={part.text} link={part.link} />;
                }
              })}
            </div>
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default Terms;
