import React from 'react';
import { bool } from 'prop-types';
import cn from 'classnames';

import { ReducedLogoIcon } from 'components/icons';

const ReducedLogo = ({ isTransparent }) => (
  <div className={cn('original-logo flex-center', { transparent: isTransparent })}>
    <ReducedLogoIcon width={18} height={18} opacity={1} />
  </div>
);

ReducedLogo.propTypes = {
  isTransparent: bool
};

export default ReducedLogo;
