import React from 'react';
import { number, string } from 'prop-types';

import { white } from 'styles/common/_constants.scss';

const CreditCardIcon = ({ width = 20, height = 20, color = white }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="logoTitle logoDesc"
    role="img"
  >
    <title id="logoTitle">Credit card</title>
    <desc id="logoDesc">Credit card icon</desc>
    <path
      d="M17.5 3.33331H2.50004C1.57957 3.33331 0.833374 4.0795 0.833374 4.99998V15C0.833374 15.9205 1.57957 16.6666 2.50004 16.6666H17.5C18.4205 16.6666 19.1667 15.9205 19.1667 15V4.99998C19.1667 4.0795 18.4205 3.33331 17.5 3.33331Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.833374 8.33331H19.1667"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

CreditCardIcon.propTypes = {
  width: number,
  height: number,
  color: string
};

export default CreditCardIcon;
