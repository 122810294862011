import React from 'react';
import { string, func, node, bool } from 'prop-types';
import cn from 'classnames';

const IconWithBackground = ({ className, icon, onClick, isSmall }) => (
  <div
    className={cn('icon-with-background', 'flex-center', className, { small: isSmall })}
    onClick={onClick}
  >
    {icon}
  </div>
);

IconWithBackground.propTypes = {
  icon: node.isRequired,
  className: string,
  onClick: func,
  isSmall: bool
};

IconWithBackground.defaultProps = {
  onClick: () => {}
};

export default IconWithBackground;
