import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStatus, SUCCESS, ERROR } from '@rootstrap/redux-tools';
import { useAnalytics, useNavigation, useToast } from 'hooks';
import capitalize from 'lodash/capitalize';
import { editSeries, getTrailerUrlForJWPlayer } from 'state/actions/seriesActions';
import { parseHashtags, routeWithProps, isValidURL } from 'utils/helpers';
import { clearEpisodes } from 'state/actions/episodesActions';
import { routesPaths } from 'constants/routesPaths';
import parseError from 'utils/parseError';
import UserService from 'services/userService';
import { MIXPANEL_EVENTS, MIXPANEL_PARAMS } from 'constants/constants';
import axios from 'axios';

export default () => {
  const { trackEvent } = useAnalytics();
  const dispatch = useDispatch();
  const { goTo } = useNavigation();
  const username = useRef();
  const [loading, setLoading] = useState(false);
  const { showErrorToast, showToast } = useToast();
  const [series, setSeries] = useState({});
  const { status, error } = useStatus(editSeries);
  const { trailerData } = useSelector(({ seriesUpload: { trailerData } }) => ({ trailerData }));
  const { episodes } = useSelector(({ episodes: { episodes } }) => ({ episodes }));
  const [completedVideos, setCompletedVideos] = useState(0);
  const [uploadedVideos, setUploadedVideos] = useState(0);
  const [uploadProgress, setUploadProgress] = useState(0);
  const totalVideoRef = useRef();
  const videoDoneRef = useRef();
  const totalVideos = episodes.length + (!isValidURL(series.trailer) ? 1 : 0);
  const { status: getTrailerUrlStatus, error: getTrailerUrlError } = useStatus(
    getTrailerUrlForJWPlayer
  );

  const editNewSeries = async seriesData => {
    const { categoryIds, description, hashtags, title, trailer, id } = seriesData;
    const data = {
      id,
      description,
      title,
      banner_media_id: trailerData ? trailerData?.mediaId : '',
      mime_type: trailerData ? trailer?.type : seriesData.mimeType
    };
    const newEpisodes = [...episodes, ...seriesData.episodes];
    const episodesList = newEpisodes.map(
      (
        {
          creditsStartTime,
          description,
          earlyReleaseDateTime,
          exclusive,
          mediaId,
          membersPrice,
          nonMembersPrice,
          releaseDateTime,
          title,
          seriesVideo,
          content_viewer_category,
          lead_cast,
          credits,
          episodeId
        },
        index
      ) => ({
        credits_start_time: creditsStartTime,
        early_release_date_time: earlyReleaseDateTime,
        media_id: mediaId,
        members_price: membersPrice === 0 ? null : Number(membersPrice).toFixed(2),
        non_members_price: nonMembersPrice === 0 ? null : Number(nonMembersPrice).toFixed(2),
        release_date_time: releaseDateTime,
        position: index,
        episode_id: episodeId || null,
        mime_type: seriesVideo?.type || 'video/mp4',
        credits,
        content_viewer_category,
        description,
        exclusive,
        title,
        lead_cast
      })      
    );
    const formData = new FormData();
    Object.keys(data).forEach(key => {
      formData.append(`series[${key}]`, data[key]);
    });

    formData.append('series[logo_image]', seriesData.logo_image);
    formData.append('series[desktop_image]', seriesData.desktop_image);
    formData.append('series[mobile_image]', seriesData.mobile_image);

    formData.append('series[category_ids]', JSON.stringify(categoryIds));
    formData.append('series[hashtags]', JSON.stringify(hashtags));
    formData.append('series[episodes]', JSON.stringify(episodesList));

    formData.append('series[lead_cast]', seriesData.lead_cast);
    formData.append('series[content_viewer_category]', seriesData.content_viewer_category);
    formData.append('series[notify_subscribers]', seriesData.notify_subscribers);
    formData.append('series[credits]', seriesData.credits);
    // formData.append('series[season]', series.season);

    await dispatch(editSeries(formData, seriesData.id));
  };

  useEffect(() => {
    if (getTrailerUrlStatus === SUCCESS) {
      editNewSeries(series);
      return;
    }

    if (getTrailerUrlStatus === ERROR) {
      showErrorToast(getTrailerUrlError);
      dispatch(getTrailerUrlForJWPlayer.reset());
      setLoading(false);
    }
  }, [getTrailerUrlStatus]);

  const onSubmit = useCallback(
    data => {
      const categoryIds = data.categoryIds.map(({ value }) => value);
      data.hashtags = parseHashtags(data.description);
      data.categoryIds = categoryIds;
      setSeries(data);
      if (isValidURL(data.trailer)) {
        // If the trailer is a URL, we'll assume it is already uploaded and can be used directly
        editNewSeries(data);
      } else {
        // If the trailer is not a URL, we dispatch the action to upload it
        dispatch(getTrailerUrlForJWPlayer(data.trailer));
      }
    },
    [dispatch]
  );

  const resetDispatchData = () => {
    dispatch(clearEpisodes());
    dispatch(editSeries.reset());
    dispatch(getTrailerUrlForJWPlayer.reset());
  };

  const onCompleteVideoUpload = () => {
    resetDispatchData();
    showToast('Publication successfully updated!');
    setLoading(false);
    goTo(
      routeWithProps(routesPaths.celebrityId, {
        username: username.current.toLowerCase()
      })
    );
  };

  const getProfile = async () => {
    try {
      const { data } = await UserService.getProfile();
      username.current = data.user.username;
    } catch ({ response }) {
      throw parseError(response);
    }
  };

  useEffect(() => {
    getProfile();
  }, []);

  useEffect(() => {
    if (completedVideos === totalVideos) {
      onCompleteVideoUpload();
      videoDoneRef.current = 2;
    }
  }, [completedVideos]);

  const uploadFile = async (url, file) => {
    try {
      const totalSize = file.size;

      await axios.put(url, file, {
        headers: {
          'Content-Type': file.type
        },
        onUploadProgress: progressEvent => {
          if (progressEvent.lengthComputable) {
            const uploadedSize = progressEvent.loaded;
            const progress = Math.round((uploadedSize / totalSize) * 100);
            setUploadProgress(progress);
          }
        }
      });
    } catch (error) {
      throw error;
    }
  };

  const uploadVideosInJWPlayer = async videos => {
    try {
      videoDoneRef.current = 1;
      setUploadProgress(0);
      setUploadedVideos(0);
      totalVideoRef.current = videos.length;

      for (let index = 0; index < videos.length; index++) {
        const video = videos[index];

        await uploadFile(video.uploadUrl, video.video);

        setUploadedVideos(prevCount => prevCount + 1);
        setCompletedVideos(prev => prev + 1);
        setUploadProgress(0);
      }
    } catch (error) {
      showErrorToast('Something went wrong, try again please!');
    }
  };

  useEffect(() => {
    if (status === SUCCESS) {
      setLoading(true);
      trackEvent(MIXPANEL_EVENTS.uploadSeries, {
        [MIXPANEL_PARAMS.seriesName]: series.title
      });

      const videosToUpload = [];
      if (!isValidURL(series.trailer)) {
        videosToUpload.push({
          video: series.trailer,
          mediaId: trailerData?.mediaId,
          uploadUrl: trailerData?.uploadUrl
        });
      }
      episodes.forEach(({ mediaId, uploadUrl, seriesVideo }) => {
        videosToUpload.push({
          video: seriesVideo,
          mediaId,
          uploadUrl
        });
      });
      if (videosToUpload.length === 0) {
        onCompleteVideoUpload();
      } else {
        uploadVideosInJWPlayer(videosToUpload);
      }
      // uploadVideosInJWPlayer(videosToUpload);
    }
  }, [status, dispatch]);

  return {
    onSubmit,
    status,
    loading,
    uploadProgress,
    uploadedVideos,
    error: capitalize(error),
    totalVideos: totalVideoRef.current,
    uploadingStatus: videoDoneRef.current
  };
};
