import React, { memo } from 'react';
import { func, string, bool, arrayOf } from 'prop-types';
import { useIntl } from 'react-intl';

import Close from 'components/common/Close/Close';
import ProfilePicture from 'components/ids/ProfilePicture/ProfilePicture';

import { purchaseItemShape } from 'constants/propTypesShapes';

import PurchaseItem from '../PurchaseItem';

const PROFILE_IMAGE_SIZE = 56;

const EventModalHeader = ({ image, title, price, showProfile, hide, purchaseItems = [] }) => {
  const intl = useIntl();

  return (
    <>
      <div className="purchase-modal-header">
        <Close onClick={hide} />
        <h2 className="purchase-modal-header-title">
          {intl.formatMessage({ id: 'payments.completePurchase' })}
        </h2>
        <div className="purchase-modal-header-celebrity">
          <div className="flex-center">
            {showProfile ? (
              <ProfilePicture
                profileImage={image}
                width={PROFILE_IMAGE_SIZE}
                height={PROFILE_IMAGE_SIZE}
              />
            ) : (
              <img src={image} alt="Event" className="logo" />
            )}
            <div className="modal-info">
              {!purchaseItems.length ? (
                <>
                  <h5 className="bold">{title}</h5>
                </>
              ) : (
                <>
                  <h5 className="bold">{title}</h5>
                  <div className="modal-info-title-container">
                    <h5 className="bold plus">+</h5>
                    {/* <div className="p1 description with-episode desktop-only">{subtitle}</div> */}
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="flex second-row">
            {/* <div className="p1 description mobile-only">{subtitle}</div> */}
            {!purchaseItems.length && (
              <h4 className="price bold">{`${price}.${String(0).padEnd(2, '0')}`}</h4>
            )}
          </div>
        </div>
      </div>
      {purchaseItems?.map((purchaseItem, index) => <PurchaseItem key={index} {...purchaseItem} />)}
    </>
  );
};

EventModalHeader.propTypes = {
  image: string,
  title: string,
  price: string,
  showProfile: bool,
  hide: func.isRequired,
  purchaseItems: arrayOf(purchaseItemShape)
};

export default memo(EventModalHeader);
