import React from 'react';
import { number, string } from 'prop-types';

import { secondaryColor } from 'styles/common/_constants.scss';

const YoutubeIcon = ({ width = 22, height = 16, color = secondaryColor }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="logoTitle logoDesc"
    role="img"
  >
    <title id="logoTitle">Youtube</title>
    <desc id="logoDesc">Youtube logo</desc>
    <path
      d="M22.5401 3.42C22.4213 2.94541 22.1794 2.51057 21.8387 2.15941C21.4981 1.80824 21.0708 1.55318 20.6001 1.42C18.8801 1 12.0001 1 12.0001 1C12.0001 1 5.12008 1 3.40008 1.46C2.92933 1.59318 2.50206 1.84824 2.16143 2.19941C1.8208 2.55057 1.57887 2.98541 1.46008 3.46C1.1453 5.20556 0.991319 6.97631 1.00008 8.75C0.988863 10.537 1.14285 12.3213 1.46008 14.08C1.59104 14.5398 1.83839 14.9581 2.17823 15.2945C2.51806 15.6308 2.9389 15.8738 3.40008 16C5.12008 16.46 12.0001 16.46 12.0001 16.46C12.0001 16.46 18.8801 16.46 20.6001 16C21.0708 15.8668 21.4981 15.6118 21.8387 15.2606C22.1794 14.9094 22.4213 14.4746 22.5401 14C22.8524 12.2676 23.0064 10.5103 23.0001 8.75C23.0113 6.96295 22.8573 5.1787 22.5401 3.42V3.42Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.75 12.02L15.5 8.74998L9.75 5.47998V12.02Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

YoutubeIcon.propTypes = {
  width: number,
  height: number,
  color: string
};

export default YoutubeIcon;
