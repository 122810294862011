import React, { useState, useCallback, useEffect } from 'react';
import { arrayOf, shape, bool, number } from 'prop-types';

import { narrativeShape, celebrityInfoShape } from 'constants/propTypesShapes';
import { routeWithProps, showNarrativeAd } from 'utils/helpers';
import { useNavigation, useIsMobile, useAnalytics } from 'hooks';
import { ImageSlider } from 'components/common/slider/imageSlider';
import NarrativesPlayer from 'components/Players/VideoJs/NarrativesPlayer';

import { routesPaths } from 'constants/routesPaths';
import { MIXPANEL_EVENTS, MIXPANEL_PARAMS } from 'constants/constants';
import { useChangingUrl } from '../hooks';

const NarrativesHorizontalSlider = ({
  narratives,
  native,
  initialIndex,
  celebrity,
  authenticated
}) => {
  const { trackEvent } = useAnalytics();
  const [selectedNarrative, setSelectedNarrative] = useState(initialIndex);
  // const firstNarrativeSelected = selectedNarrative === 0;
  const [isTransitioning, setIsTransitioning] = useState(false);
  // const lastNarrativeSelected = selectedNarrative === narratives.length - 1;
  const [index, setIndex] = useState(0);
  const { goToWithSearch } = useNavigation();
  const isMobile = useIsMobile();
  useChangingUrl({ narratives, visiblePlayer: selectedNarrative, celebrityId: celebrity.id });
  const findValidNarrative = (currentIndex, direction) => {
    let nextIndex = currentIndex;
    while (nextIndex >= 0 && nextIndex < narratives.length) {
      nextIndex += direction;
      if (
        narratives[nextIndex] &&
        narratives[nextIndex].thumbnailsUrls &&
        narratives[nextIndex].thumbnailsUrls.length > 0
      ) {
        return nextIndex;
      }
    }
    return -1;
  };

  const firstNarrativeSelected = findValidNarrative(selectedNarrative, -1) === -1;
  const lastNarrativeSelected = findValidNarrative(selectedNarrative, 1) === -1;

  const next = useCallback(() => {
    const nextValidIndex = findValidNarrative(selectedNarrative, 1);
    setIndex(index + 1);
    if (nextValidIndex !== -1) {
      setSelectedNarrative(nextValidIndex);
    } else {
      const route = routeWithProps(routesPaths.celebrityId, {
        username: celebrity.username
      });
      goToWithSearch(route, `?tab=2`);
    }
  }, [selectedNarrative, index, goToWithSearch, narratives]);

  const previous = useCallback(() => {
    const prevValidIndex = findValidNarrative(selectedNarrative, -1);
    setIndex(index - 1);
    if (prevValidIndex !== -1) {
      setSelectedNarrative(prevValidIndex);
    } else {
      const route = routeWithProps(routesPaths.celebrityId, {
        username: celebrity.username
      });
      goToWithSearch(route, `?tab=2`);
    }
  }, [selectedNarrative, index, narratives]);

  const nextSlider = useCallback(() => {
    if (isTransitioning) return; // Prevent multiple triggers during transition

    setIsTransitioning(true); // Lock the transition

    const nextValidIndex = findValidNarrative(selectedNarrative, 1);
    const isLastIndex = nextValidIndex === -1; // Check if it's the last narrative

    setIndex(prevIndex => {
      // If it's the last narrative, reset index to 0, otherwise increment
      return isLastIndex ? 0 : prevIndex + 1;
    });

    if (!isLastIndex) {
      // If it's not the last narrative, proceed normally
      setSelectedNarrative(nextValidIndex);
    } else {
      // If it's the last narrative, start from the beginning (index 0)
      setSelectedNarrative(0);
    }

    // Unlock after a small delay
    setTimeout(() => {
      setIsTransitioning(false); // Unlock for next interaction
    }, 100); // Reduced timeout for smoother transitions
  }, [selectedNarrative, isTransitioning, goToWithSearch, narratives]);

  const previousSlider = useCallback(() => {
    if (isTransitioning) return; // Prevent multiple triggers during transition

    setIsTransitioning(true); // Lock the transition

    const prevValidIndex = findValidNarrative(selectedNarrative, -1);
    const isFirstIndex = prevValidIndex === -1; // Check if it's the first narrative

    setIndex(prevIndex => {
      // If it's the first narrative, set index to the last one, otherwise decrement
      return isFirstIndex ? narratives.length - 1 : prevIndex - 1;
    });

    if (!isFirstIndex) {
      // If it's not the first narrative, proceed normally
      setSelectedNarrative(prevValidIndex);
    } else {
      // If it's the first narrative, set the narrative to the last one
      setSelectedNarrative(narratives.length - 1);
    }

    // Unlock after a small delay
    setTimeout(() => {
      setIsTransitioning(false); // Unlock for next interaction
    }, 100); // Reduced timeout for smoother transitions
  }, [selectedNarrative, isTransitioning, goToWithSearch, narratives]);

  const currentNarrative = narratives[selectedNarrative];
  const sortedBanners = [...currentNarrative?.bannerAds]
    .filter(banner => !banner.disable)
    .sort((a, b) => b.id - a.id);
  const latestBanner = sortedBanners[0];
  const allWidgetAdImages = currentNarrative?.widgetAds
    ?.filter(widget => !widget.disable)
    .flatMap(widget =>
      widget.widgetAdImages.map(image => ({
        ...image,
        widgetId: widget.id
      }))
    );
  const hasAds = Boolean(latestBanner?.mobileBannerUrl || allWidgetAdImages.length);

  const CurrentIndex = narratives[selectedNarrative].id;
  const handleBannerClick = useCallback(() => {
    if (latestBanner?.actionButtonUrl) {
      trackEvent(MIXPANEL_EVENTS.bannerAdClicked, {
        id: latestBanner.id,
        typeName: MIXPANEL_PARAMS.bannerAd
      });
      window.open(latestBanner.actionButtonUrl, '_blank');
    }
  }, [latestBanner]);

  useEffect(() => {
    if (isMobile) {
      let deltaYTotal = 0;
      const swipeThreshold = 50; // Minimum scroll distance for a swipe to be considered

      const handleWheel = e => {
        deltaYTotal += e.deltaY; // Accumulate the vertical scroll distance

        // If the total scroll distance surpasses the threshold, trigger the action
        if (Math.abs(deltaYTotal) > swipeThreshold) {
          if (deltaYTotal > 0) {
            // Scroll down -> next narrative
            nextSlider();
          } else {
            // Scroll up -> previous narrative
            previousSlider();
          }
          deltaYTotal = 0; // Reset the accumulated scroll distance
        }
      };

      window.addEventListener('wheel', handleWheel);

      // Cleanup event listener on unmount
      return () => {
        window.removeEventListener('wheel', handleWheel);
      };
    }
  }, [next, previous]);

  useEffect(() => {
    if (latestBanner) {
      trackEvent(MIXPANEL_EVENTS.bannerAdViewed, {
        id: latestBanner.id,
        typeName: MIXPANEL_PARAMS.bannerAd
      });
    }
    if (allWidgetAdImages.length) {
      trackEvent(MIXPANEL_EVENTS.widgetAdViewed, {
        id: allWidgetAdImages[0].id,
        typeName: MIXPANEL_PARAMS.widgetAd
      });
    }
  }, []);
  return (
    <div
      className="slider-container"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: hasAds ? 'flex-start' : 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        overflow: 'hidden'
      }}
    >
      {((isMobile && latestBanner?.mobileBannerUrl) ||
        (!isMobile && latestBanner?.webBannerUrl)) && (
        <div
          className="banner-container"
          style={{
            width: '80vw',
            padding: isMobile ? '0.5rem 0' : '0.5rem',
            background: 'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%)',
            zIndex: 10
          }}
        >
          <div
            className="banner-ad"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: latestBanner?.actionButtonUrl ? 'pointer' : 'default'
            }}
            onClick={() => (latestBanner?.actionButtonUrl ? handleBannerClick() : undefined)}
          >
            <img
              src={isMobile ? latestBanner?.mobileBannerUrl : latestBanner?.webBannerUrl}
              alt="banner-ad"
              style={{
                maxHeight: isMobile ? '120px' : '6vh',
                width: '80%'
                // objectFit: 'contain'
              }}
            />
          </div>
        </div>
      )}
      <div
        className="slider-content"
        style={{
          position: 'relative',
          width: '100%',
          height: hasAds ? 'calc(100% - 60px)' : '100vh',
          maxWidth: isMobile ? '100vw' : '80vw',
          maxHeight: hasAds ? '80vh' : '100vh',
          boxShadow: hasAds
            ? '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)'
            : 'none',
          borderRadius: hasAds ? '0.5rem' : '0',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <div
          style={{
            width: '100%',
            height: hasAds ? undefined : '100vh',
            flexGrow: hasAds ? 1 : undefined
          }}
        >
          <NarrativesPlayer
            CurrentIndex={CurrentIndex}
            key={currentNarrative.id}
            native={native}
            goToNext={next}
            goToPrevious={previous}
            className="slide"
            indexId={`jwplayer-${currentNarrative.id}`}
            narrative={currentNarrative}
            play
            celebrity={celebrity}
            showAds={showNarrativeAd(index)}
            showNextArrow={!lastNarrativeSelected}
            showPreviousArrow={!firstNarrativeSelected}
            index={showNarrativeAd(index)}
            authenticated={authenticated}
            width="100%"
            height="100%"
          />
        </div>
        {allWidgetAdImages.length ? (
          isMobile && allWidgetAdImages.length > 5 ? (
            <>
              <ImageSlider
                items={allWidgetAdImages.slice(0, Math.ceil(allWidgetAdImages.length / 2))}
                mobileView={isMobile}
                sliderId="slider1"
              />
              <ImageSlider
                items={allWidgetAdImages.slice(Math.ceil(allWidgetAdImages.length / 2))}
                mobileView={isMobile}
                sliderId="slider2"
              />
            </>
          ) : (
            <ImageSlider items={allWidgetAdImages} mobileView={isMobile} sliderId="slider1" />
          )
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

NarrativesHorizontalSlider.propTypes = {
  narratives: arrayOf(shape(narrativeShape)).isRequired,
  native: bool,
  initialIndex: number,
  celebrity: shape(celebrityInfoShape)
};

export default NarrativesHorizontalSlider;
