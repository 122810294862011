import React from 'react';
import { Route } from 'react-router-dom';
import { func, bool } from 'prop-types';

import withLayout from 'components/common/Layout/Layout';

const RouteComponent = ({ component: Component, layout, customHeader, disableFooter, ...route }) =>
  layout ? (
    <Route render={withLayout(Component, customHeader, disableFooter)} {...route} />
  ) : (
    <Route {...route}>{<Component />}</Route>
  );

RouteComponent.propTypes = {
  component: func.isRequired,
  layout: bool,
  customHeader: bool,
  disableFooter: bool
};

RouteComponent.defaultProps = {
  layout: true,
  customHeader: false,
  disableFooter: false
};

export default RouteComponent;
