/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */
import React, { useEffect, useState, useCallback } from 'react';
import Select from 'react-select';
import categoriesService from 'services/categoriesService';
import parseError from 'utils/parseError';
import PropTypes from 'prop-types';

const CustomCategory = ({ handleCategoryChange, value }) => {
  const [categories, setCategories] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);

  useEffect(() => {
    if (value && Array.isArray(value)) {
      setSelectedCategories(value);
    } else if (value && typeof value === 'object') {
      setSelectedCategories([value]);
    }
  }, [value]);

  const getCategories = useCallback(async () => {
    try {
      const { data } = await categoriesService.getCategories();
      const transformedData = data.categories.map(item => ({
        value: item.id,
        label: item.name
      }));
      setCategories(transformedData);
    } catch ({ response }) {
      console.error('Error fetching categories:', parseError(response));
    }
  }, []);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  const handleInputChange = newValue => {
    setInputValue(newValue);
  };

  const handleAddCategory = async () => {
    if (!inputValue.trim()) return;

    setIsLoading(true);
    try {
      const { data } = await categoriesService.createCategory({
        category: { name: inputValue.trim() }
      });

      const newCategory = { value: data.category.id, label: data.category.name };
      setCategories(prevCategories => [...prevCategories, newCategory]);

      setSelectedCategories(prevSelected => {
        const updatedSelected = [...prevSelected, newCategory];
        handleCategoryChange(updatedSelected);
        return updatedSelected;
      });

      setInputValue('');
    } catch (error) {
      console.error('Error adding new category:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const NoOptionsMessage = props => {
    return (
      <div {...props.innerProps} style={{ padding: '8px 12px', textAlign: 'center' }}>
        <button
          onClick={handleAddCategory}
          disabled={isLoading || !inputValue.trim()}
          style={{
            padding: '1rem 2.5rem',
            backgroundColor: '#2400FC',
            color: 'white',
            border: 'none',
            cursor: 'pointer',
            fontSize: '16px',
            fontWeight: 'bold',
            fontFamily: 'Raleway'
          }}
        >
          {isLoading ? 'Adding...' : 'Add Category'}
        </button>
      </div>
    );
  };

  const handleSelectChange = newValue => {
    setSelectedCategories(newValue || []);
    handleCategoryChange(newValue || []);
  };

  return (
    <div className="categories">
      <span className="custom-label">CATEGORIES</span>
      <div className="custom-select">
        <Select
          closeMenuOnSelect={false}
          isMulti
          options={categories}
          value={selectedCategories}
          onChange={handleSelectChange}
          onInputChange={handleInputChange}
          inputValue={inputValue}
          isLoading={isLoading}
          noOptionsMessage={NoOptionsMessage}
          placeholder="Select..."
        />
      </div>
    </div>
  );
};

CustomCategory.propTypes = {
  handleCategoryChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        label: PropTypes.string.isRequired
      })
    ),
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired
    })
  ])
};

export default CustomCategory;
