import React, { useEffect } from 'react';
import { object } from 'prop-types';

import { getCelebrity, getCelebrityWithSeriesId } from 'state/actions/celebritiesActions';
import { useAnalytics, useCelebrity } from 'hooks';
import { MIXPANEL_EVENTS } from 'constants/constants';
import { useDispatch } from 'react-redux';

import CelebrityBanner from 'components/ids/CelebrityBanner/CelebrityBanner';
import CelebrityContent from 'components/ids/CelebrityContent/CelebrityContent';
import Loading from 'components/common/Loading';
import NotFoundPage from 'pages/NotFoundPage';
import useMembership from 'hooks/celebrities/useMembership';
import useCelebrityBySeries from 'hooks/useCelebrityBySeries';

const CelebrityIdPage = ({ match }) => {
  const {
    params: { username }
  } = match;
  const urlParams = new URLSearchParams(window.location.search);
  const seriesId = urlParams.get('seriesId');
  const tab = urlParams.get('tab');
  const { trackEvent } = useAnalytics();
  const dispatch = useDispatch();
  const { celebrity, error, reset, loading } = useCelebrity();
  const {
    celebrity: celebritySeries,
    error: errorSeries,
    loading: celebrityLoading
  } = useCelebrityBySeries();
  const { getMembership } = useMembership();
  useEffect(() => {
    if (seriesId) {
      dispatch(getCelebrityWithSeriesId(username, seriesId));
    } else {
      dispatch(getCelebrity(username));
    }
    window && window.scrollTo(0, 0);
    return () => {
      reset();
    };
  }, [username, seriesId]);

  useEffect(() => {
    const currentCelebrity = seriesId ? celebritySeries : celebrity;
    if (currentCelebrity.id) {
      getMembership(currentCelebrity.id);
      trackEvent(MIXPANEL_EVENTS.creatorID, {
        name: `${currentCelebrity.firstName} ${currentCelebrity.lastName}`
      });
    }
  }, [celebrity, celebritySeries]);

  if (error || errorSeries) return <NotFoundPage />;

  if (loading || !celebrity.id || celebrityLoading || !celebritySeries.id)
    return (
      <div className="home-loading">
        <Loading />
      </div>
    );

  const currentCelebrity = seriesId ? celebritySeries : celebrity;
  return (
    <>
      <CelebrityBanner celebrity={currentCelebrity} />
      {currentCelebrity.id && <CelebrityContent celebrity={currentCelebrity} tab={tab} />}
    </>
  );
};

CelebrityIdPage.loadData = async (store, match) => {
  const {
    params: { username }
  } = match;
  await store.dispatch(getCelebrity(username));
};

CelebrityIdPage.propTypes = {
  match: object.isRequired
};

export default CelebrityIdPage;
