import React from 'react';
import { string, node, func, bool } from 'prop-types';
import cn from 'classnames';

import Badge from 'components/common/Badge/Badge';
import { ArrowIcon } from 'components/icons';

import { black60 } from 'styles/common/_constants.scss';

const NavRow = ({ label, icon, onClick, showBadge, className, disableArrow }) => (
  <div onClick={onClick} className={cn('account-navigation-item', className)}>
    <div className="flex">
      {icon}
      <h6>{label}</h6>
      {showBadge && <Badge />}
    </div>
    {!disableArrow && (
      <div className="flex-center">
        <ArrowIcon color={black60} type="right" />
      </div>
    )}
  </div>
);

NavRow.propTypes = {
  label: string.isRequired,
  onClick: func.isRequired,
  icon: node,
  showBadge: bool,
  disableArrow: bool,
  className: string
};

export default NavRow;
