import React from 'react';
import { number, string } from 'prop-types';

import { white } from 'styles/common/_constants.scss';

const CloseIcon = ({ width = 30, height = 30, color = white }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd" stroke={color} strokeLinecap="round" strokeLinejoin="round">
        <path d="m7.5 7.5 6 6" />

        <path d="m13.5 7.5-6 6" />
      </g>
    </svg>
  );
};

CloseIcon.propTypes = {
  width: number,
  height: number,
  color: string
};
export default CloseIcon;
