import React from 'react';
import { func } from 'prop-types';
import { useIntl } from 'react-intl';

import { useToggle, useClickOutside } from 'hooks';

import { SettingsIcon } from 'components/icons';
import Dropdown from 'components/header/Dropdown/Dropdown';

const Settings = ({ onSelectCancel }) => {
  const intl = useIntl();
  const [showDropdown, toggleDropdown] = useToggle(false);

  const ref = useClickOutside(toggleDropdown, showDropdown);

  const handleClick = () => {
    onSelectCancel();
    toggleDropdown();
  };

  return (
    <div className="settings" ref={ref}>
      <div className="settings-icon clickable" onClick={toggleDropdown}>
        <SettingsIcon />
      </div>
      {showDropdown && (
        <Dropdown>
          <div className="clickable" onClick={handleClick}>
            <h6>{intl.formatMessage({ id: 'subscriptions.cancel' })}</h6>
          </div>
        </Dropdown>
      )}
    </div>
  );
};

Settings.propTypes = {
  onSelectCancel: func
};

export default Settings;
