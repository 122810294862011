import React from 'react';
import { bool, shape } from 'prop-types';
import { useIntl } from 'react-intl';
import cn from 'classnames';

import { getMaskedCreditCard, getCardIcon } from 'utils/helpers';
import { useToggle, useClickOutside } from 'hooks';
import usePayments from 'hooks/payments/usePayments';
import { creditCardShape } from 'constants/propTypesShapes';

import Button from 'components/common/Button/Button';

const CreditCard = ({ card: { brand, last4, id }, isDefault, reducedMask }) => {
  const intl = useIntl();
  const cardIcon = getCardIcon(brand);
  const [defaultBlockVisible, toggleDefaultBlock] = useToggle(false);

  const { setAsDefaultCard, setDefaultLoading } = usePayments();

  const ref = useClickOutside(toggleDefaultBlock, defaultBlockVisible);

  return (
    <div ref={ref}>
      <div className={cn('credit-card p1', { default: isDefault })} onClick={toggleDefaultBlock}>
        <div className="radio-button">{isDefault && <div className="inner-circle" />}</div>
        {!!cardIcon && <img src={cardIcon} alt="card" className={cn('card-icon')} />}
        {getMaskedCreditCard(last4, reducedMask)}
      </div>
      {!isDefault && (
        <div className={cn('default-block', { visible: defaultBlockVisible })}>
          <div className="p1">{intl.formatMessage({ id: 'creditCard.default.confirmation' })}</div>
          <div className="flex-justify-end">
            <Button
              labelId="general.cancel"
              type="secondary"
              size="xsmall"
              onClick={toggleDefaultBlock}
            />
            <Button
              labelId="creditCard.setAsDefault"
              type="primary"
              size="xsmall"
              disabled={setDefaultLoading}
              onClick={() => setAsDefaultCard(id)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

CreditCard.propTypes = {
  card: shape(creditCardShape),
  isDefault: bool,
  reducedMask: bool
};

export default CreditCard;
