import React, { useMemo } from 'react';
import { shape, bool, func } from 'prop-types';
import cn from 'classnames';

import { renderImage } from 'utils/helpers';
import { contentShape } from 'constants/propTypesShapes';
import { LIVE_STATUSES } from 'constants/constants';

import BackgroundOpacity from 'components/common/BackgroundOpacity/BackgroundOpacity';
import ReducedLogo from 'components/common/Brand/ReducedLogo';
import LiveLabel from 'components/common/Labels/LiveLabel';

const FeaturedCard = ({ element, isSelected, onClick }) => {
  const { desktopImageUrl, logoImageUrl, mobileImageUrl, liveEvent, state } = element;
  const backgroundImage = renderImage(desktopImageUrl, mobileImageUrl);

  const isLive = useMemo(() => liveEvent && state === LIVE_STATUSES.STARTED, [liveEvent, state]);

  return (
    <div className={cn('featured-card', { selected: isSelected })} onClick={() => onClick(element)}>
      <BackgroundOpacity background={backgroundImage} opacity={isSelected ? 0 : 0.4} />
      {isSelected && <div className="featured-card-gradient" />}
      {isLive && <div className="live-circle" />}
      <div className="layer featured-card-info">
        <img src={logoImageUrl} alt="series logo" className="featured-card-logo" />
        {liveEvent ? <LiveLabel /> : <ReducedLogo />}
      </div>
    </div>
  );
};

FeaturedCard.propTypes = {
  element: shape(contentShape),
  isSelected: bool,
  onClick: func.isRequired
};

export default FeaturedCard;
