import { createReducer } from '@rootstrap/redux-tools';

import { getCategoriesFeatured, getCategory } from 'state/actions/categoriesActions';
import { filterSeries, filterSubCategoriesSeries } from 'utils/dataHelpers';

const initialState = {
  featured: [],
  category: {
    promotedOriginals: [],
    featuredCelebrities: [],
    subCategories: []
  }
};

export default createReducer(initialState, {
  [getCategoriesFeatured.success]: (state, { payload }) => {
    state.featured = payload;
  },
  [getCategory.success]: (state, { payload }) => {
    state.category = {
      ...payload,
      subCategories: filterSubCategoriesSeries(payload.subCategories),
      promotedOriginals: filterSeries(payload.promotedOriginals)
    };
  }
});
