/* eslint-disable no-restricted-globals */
/* eslint-disable no-new */
/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import queryString from 'query-string';
import isEmpty from 'lodash/isEmpty';
import { take, keys } from 'lodash';
import WarningImg from 'assets/warning.svg';
// import CryptoJS from 'crypto-js';

import { useIntl } from 'react-intl';

import { maxMobileWidth, warning, primaryColor } from 'styles/common/_constants.scss';
import {
  AVATAR_NAME_LENGTH,
  JWPLAYER_URL,
  SHARING_PLATFORMS,
  PERIODS,
  CREDIT_CARDS,
  NARRATIVE_STATES,
  MEMBERSHIP_STATUSES,
  NARRATIVE_AD_COUNT,
  REGEX_HASHTAGS,
  videoUploadsType
} from 'constants/constants';
import { toast } from 'react-toastify';
import { routesPaths } from 'constants/routesPaths';

export const parseInputErrors = error => {
  if (!error) {
    return;
  }
  if (Array.isArray(error)) {
    return error[0];
  }
  return error;
};

export const applyQueryParams = (url, params = {}) => {
  if (isEmpty(params)) {
    return url;
  }
  const queryParams = queryString.stringify(params);
  return `${url}?${queryParams}`;
};

export const getAsQueryParam = (params = {}) => {
  const queryParams = queryString.stringify(params);
  return `?${queryParams}`;
};

export const routeWithProps = (route, props) => {
  let newRoute = route;
  Object.keys(props).forEach(prop => {
    newRoute = newRoute.replace(`:${prop}?`, props[prop]);
    newRoute = newRoute.replace(`:${prop}`, props[prop]);
  });
  return newRoute;
};

export const renderImage = (desktopImage, mobileImage) => {
  if (!desktopImage) return mobileImage;
  if (!mobileImage) return desktopImage;
  const isMobile = window && window.innerWidth <= maxMobileWidth;
  return isMobile ? mobileImage : desktopImage;
};

export const trimString = (string, maxLength) => {
  if (!string) return { shortWord: '', hasMore: false };
  let shortWord = string.slice(0, maxLength);
  const hasMore = shortWord.length < string.length;
  if (hasMore) shortWord = `${shortWord.trim()}...`;
  return { shortWord, hasMore };
};

export const getFullName = (firstName = '', lastName = '') => {
  return `${firstName} ${lastName}`;
};

export const celebrityNameFontSize = (firstName, lastName) => {
  const fullName = getFullName(firstName, lastName).replace(/\s/g, '');

  const LONG_NAME_LIMIT = 30;
  const MEDIUM_NAME_LIMIT = 16;

  if (fullName.length >= LONG_NAME_LIMIT) {
    return 'small';
  }
  if (fullName.length >= MEDIUM_NAME_LIMIT) {
    return 'medium';
  }
  return '';
};

export const avatarName = (firstName = '', lastName = '') => {
  const fullWord = `${firstName} ${lastName?.slice(0, 1)}.`;
  const { shortWord } = trimString(fullWord, AVATAR_NAME_LENGTH);
  return shortWord;
};

export const getPlayerUrl = id => {
  return JWPLAYER_URL.replace(`<id>`, id);
};

export const copyToClipboard = text => {
  const dummy = document.createElement('input');
  document.body.appendChild(dummy);
  dummy.setAttribute('value', text);
  dummy.select();
  document.execCommand('copy');
  document.body.removeChild(dummy);
  toast.success('Copied to clipboard!');
};

export const getSharingUrl = (socialPlatform, url, text) => {
  const { FACEBOOK, TWITTER } = SHARING_PLATFORMS;
  switch (socialPlatform) {
    case FACEBOOK:
      return applyQueryParams(FACEBOOK.sharingUrl, { u: url, quote: text });
    case TWITTER:
      return applyQueryParams(TWITTER.sharingUrl, { url, text });
    default:
  }
};

export const getOriginalLabel = episodeCount => {
  const intl = useIntl();

  return (
    <>
      {`${intl.formatMessage({ id: 'content.original' })} - `}
      <span className="bold">
        {`(${episodeCount} ${intl.formatMessage({ id: 'episodes.abbreviation' })}.)`}
      </span>
    </>
  );
};

export const includeAllFields = (object, fields, touched) => {
  const expected = keys(fields);
  const current = keys(object);
  const allTouched = keys(touched);
  return current.length === expected.length && allTouched.length === expected.length;
};

const getEpisodeInfo = (episode, member) => {
  const {
    id,
    thumbnailsUrls,
    title,
    celebrity: { firstName, lastName },
    series: { id: serieId },
    exclusive,
    earlyReleaseDateTime
  } = episode;

  let label;
  if (exclusive) label = 'notifications.exclusiveTag';
  else if (earlyReleaseDateTime && member) label = 'notifications.earlyTag';
  else label = 'notifications.episodeTag';

  return {
    title,
    subtitle: getFullName(firstName, lastName),
    image: thumbnailsUrls?.[0]?.src,
    redirect: routeWithProps(routesPaths.series, { id: serieId, episode: id }),
    label,
    labelColor: primaryColor,
    member
  };
};

const getNarrativeInfo = narrative => {
  const {
    id,
    thumbnailsUrls,
    title,
    celebrity: { id: celebrityId, firstName, lastName }
  } = narrative;

  return {
    title,
    subtitle: getFullName(firstName, lastName),
    image: thumbnailsUrls?.[0]?.src,
    redirect: routeWithProps(routesPaths.narratives, { celebrityId, id }),
    label: 'notifications.narrativesTag',
    labelColor: primaryColor
  };
};

const getSeriesInfo = serie => {
  const {
    id,
    title,
    bannerUrl,
    celebrity: { username, firstName, lastName }
  } = serie;

  return {
    title,
    subtitle: getFullName(firstName, lastName),
    image: bannerUrl,
    redirect: routeWithProps(routesPaths.celebrityId, { username }),
    search: `series=${id}`,
    label: 'notifications.seriesTag',
    labelColor: primaryColor
  };
};

const getEventContent = event => {
  const {
    webCoverImageUrl,
    title,
    celebrity: { firstName, lastName }
  } = event;

  return {
    title,
    subtitle: getFullName(firstName, lastName),
    image: webCoverImageUrl,
    label: 'notifications.eventTag',
    labelColor: primaryColor
  };
};

const getMembershipContent = (membership, message) => {
  const {
    membershipPlan: { name },
    celebrity: { profileImageUrl }
  } = membership;

  return {
    name,
    subtitle: name,
    image: profileImageUrl,
    label: 'notifications.adTag',
    labelColor: primaryColor,
    message
  };
};

const getDMInfo = dm => {
  const intl = useIntl();
  const {
    message,
    celebrity: { firstName, lastName, profileImageUrl }
  } = dm;

  return {
    title: intl.formatMessage(
      { id: 'notifications.dm.title' },
      { name: getFullName(firstName, lastName) }
    ),
    message,
    image: profileImageUrl,
    label: 'notifications.dm'
  };
};

const getReportedContent = content => {
  const { content: contentData, reason, contentType } = content || {};
  const {
    type,
    firstName,
    lastName,
    title,
    profileImageUrl,
    thumbnailsUrls,
    desktopImageUrl,
    webCoverImageUrl,
    video
  } = contentData || {};
  const contentImg = () => {
    switch (contentType) {
      case 'List':
        return desktopImageUrl;
      case 'Event':
        return webCoverImageUrl;
      case 'Podcast':
        return video?.thumbnailsUrls?.[0]?.src;
      case 'Narrative':
        return thumbnailsUrls?.[0]?.src;
      default:
        return null;
    }
  };

  const image = type ? profileImageUrl || WarningImg : contentImg();

  return {
    title: type ? getFullName(firstName, lastName) : title,
    subtitle: reason,
    label: 'notifications.report',
    image
  };
};

const getLiveInfo = live => {
  const {
    title,
    celebrity: { id, profileImageUrl }
  } = live;

  return {
    title,
    image: profileImageUrl,
    label: 'notifications.liveTag',
    labelColor: warning,
    redirect: routeWithProps(routesPaths.live, { celebrityId: id })
  };
};

const getScheduleLiveInfo = scheduledLive => {
  const { id, title, desktopImageUrl, celebrities } = scheduledLive;

  const VIEWABLE_CELEBRITIES = 3;
  const SEPARATOR = ', ';
  const subtitle = take(celebrities, VIEWABLE_CELEBRITIES).map(({ firstName, lastName }) =>
    getFullName(firstName, lastName)
  );

  return {
    isScheduledLive: true,
    subtitle: subtitle.join(SEPARATOR),
    title,
    image: desktopImageUrl,
    label: 'notifications.liveTag',
    secondLabel: 'notifications.scheduleLiveTag',
    labelColor: warning,
    redirect: routeWithProps(routesPaths.scheduledLive, { id })
  };
};

export const getNotificationInfo = notification => {
  const {
    narrative,
    series,
    episode,
    live,
    dm,
    member,
    scheduledLive,
    reportedContent,
    event,
    membership,
    message
  } = notification;
  switch (true) {
    case !!narrative:
      return getNarrativeInfo(narrative);
    case !!series:
      return getSeriesInfo(series);
    case !!episode:
      return getEpisodeInfo(episode, member);
    case !!live:
      return getLiveInfo(live);
    case !!scheduledLive:
      return getScheduleLiveInfo(scheduledLive);
    case !!dm:
      return getDMInfo(dm);
    case !!reportedContent:
      return getReportedContent(reportedContent);
    case !!event:
      return getEventContent(event);
    case !!membership:
      return getMembershipContent(membership, message);
    default:
      return true;
  }
};

export const getShortPeriod = period => {
  switch (period) {
    case PERIODS.MONTH:
      return 'mo';
    default:
  }
};

export const checkSubscription = (memberships, celebrityId) => {
  const subscription = memberships?.find(({ celebrity }) => celebrity.id === celebrityId);
  const isActive = !isEmpty(subscription) && subscription.status !== MEMBERSHIP_STATUSES.INACTIVE;
  return isActive;
};

export const getCorrectPlayer = (disableAds = false, unsubscribedPlayer) => {
  return getPlayerUrl(disableAds ? process.env.PLAYER_WITHOUT_ADS_ID : unsubscribedPlayer);
};

export const getMaskedCreditCard = (last4, reducedMask = false) => {
  return reducedMask ? `**** ${last4}` : `**** **** **** ${last4}`;
};

export const getCardIcon = type => {
  const { icon } = CREDIT_CARDS.find(({ name }) => name == type) || {};
  return icon;
};

export const getPublishedNarratives = narratives => {
  const publishedNarratives = narratives.filter(
    ({ stage }) => stage === NARRATIVE_STATES.published
  );
  return publishedNarratives;
};

export const showNarrativeAd = index => index % NARRATIVE_AD_COUNT === 0 && index != 0;

export const fixedPrice = num => num?.toFixed(2);

export const parseHashtags = text => text.match(REGEX_HASHTAGS);

export const replaceError = (error, replace) => error?.replace(replace, '');
// export const getDecryptedValue = key => {
//   const encryptedValue = localStorage.getItem(key);
//   if (encryptedValue) {
//     const bytes = CryptoJS.AES.decrypt(encryptedValue, process.env.PUBLIC_KEY);
//     return bytes.toString(CryptoJS.enc.Utf8);
//   }
//   return null;
// };

export const validateImage = file => {
  const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
  const maxSizeInBytes = 10 * 1024 * 1024;

  if (!allowedTypes.includes(file?.type)) {
    return { valid: false, error: 'Invalid file type. Please upload a JPEG, PNG, or GIF image.' };
  }

  if (file.size > maxSizeInBytes) {
    return {
      valid: false,
      error: 'The file size exceeds 10MB limit. Please upload a smaller image.'
    };
  }

  return { valid: true };
};

export const handleDateKeyDown = e => {
  if (e.key !== 'Tab' && e.key !== 'Enter' && e.key !== 'Escape') {
    e.preventDefault();
  }
};

export const isValidURL = url => {
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};

export const isValidAspectRatio = video => {
  if (!video || !video.width || !video.height) {
    return false;
  }

  const { width, height, type } = video;
  const aspectRatio = width / height;

  let allowedRatios;
  if (type === videoUploadsType.Shorts) {
    allowedRatios = [9 / 16];
  } else if (type === videoUploadsType.Movies) {
    allowedRatios = [16 / 9];
  } else if (type === videoUploadsType.Common) {
    allowedRatios = [1 / 1, 9 / 16, 16 / 9, 4 / 5];
  }
  // Allowed aspect ratios
  // [1, 3 / 2, 4 / 3, 9 / 16, 16 / 9];

  return allowedRatios.some(ratio => Math.abs(aspectRatio - ratio) < 0.01);
};

export const isValidResolution = video => {
  if (!video || video.height === undefined || video.width === undefined) {
    return false;
  }

  return video.height >= 720 || video.width >= 720;
};

export const validateVideo = (file, type) => {
  return new Promise(resolve => {
    const video = document.createElement('video');
    video.preload = 'metadata';

    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);
      const videoInfo = {
        width: video.videoWidth,
        height: video.videoHeight,
        duration: video.duration,
        type
      };

      const isResolutionValid = isValidResolution(videoInfo);
      const isAspectRatioValid = isValidAspectRatio(videoInfo);

      resolve({
        isValid: isResolutionValid && isAspectRatioValid,
        error: null,
        isResolutionValid,
        isAspectRatioValid,
        videoInfo
      });
    };

    video.onerror = () => {
      resolve({
        isValid: false,
        isResolutionValid: false,
        isAspectRatioValid: false,
        videoInfo: null,
        error: 'Error loading video.'
      });
    };

    video.src = URL.createObjectURL(file);
  });
};

export const checkImageDimensions = (file, minWidth, minHeight) => {
  return new Promise(resolve => {
    const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
    const maxSizeInBytes = 10 * 1024 * 1024;

    if (!allowedTypes.includes(file?.type)) {
      resolve({
        isValid: false,
        error: 'Invalid file type. Please upload a JPEG, PNG, or GIF image.'
      });
      return;
    }

    if (file.size > maxSizeInBytes) {
      resolve({
        isValid: false,
        error: 'The file size exceeds 10MB limit. Please upload a smaller image.'
      });
      return;
    }
    const img = new Image();
    img.onload = () => {
      const isValidDimensions = img.width >= minWidth && img.height >= minHeight;
      if (!isValidDimensions) {
        resolve({
          isValid: false,
          error: `The Image resolution is too low. Please ensure it is at least ${minWidth}X${minHeight} pixels.`,
          width: img.width,
          height: img.height
        });
      } else {
        resolve({
          isValid: true,
          width: img.width,
          height: img.height
        });
      }
    };
    img.onerror = () => {
      resolve({ isValid: false, error: 'Failed to load image' });
    };
    img.src = URL.createObjectURL(file);
  });
};

export const convertPathName = value => {
  if (value === 'shorts') {
    return 'narrative';
  }
  if (value === 'episodes') {
    return 'episode';
  }
  return 'series';
};

export const parseParams = (params = '') => {
  const urlParams = new URLSearchParams(params);
  const value = Object.fromEntries(urlParams.entries());

  Object.keys(value).forEach(key => {
    if (value[key].includes(',')) {
      value[key] = value[key].split(',');
    }
  });
  return value;
};

export const appendParams = value => {
  const params = parseParams(location.search);
  const data = { ...params, ...value };
  Object.keys(data).filter(e => (data[e] === '' || !data[e].toString().length) && delete data[e]);

  const queryString = new URLSearchParams(data).toString();
  const newUrl = queryString ? `${location.pathname}?${queryString}` : location.pathname;

  window.history.pushState({}, null, newUrl);
};

export const convertUtcToLocal = utcDate => {
  const localDate = new Date(utcDate);
  return localDate.toLocaleString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  });
};

export const convertUtcToLocalTime = utcDate => {
  const localDate = new Date(utcDate);
  return localDate.toLocaleString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  });
};

export const getTimeFromISO = isoString => {
  if (!isoString) return null;

  // Create a new Date object from the ISO string
  const date = new Date(isoString);

  // Get hours, minutes, and seconds from the date
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const seconds = String(date.getUTCSeconds()).padStart(2, '0');

  // Return time in HH:MM:SS format
  return `${hours}:${minutes}:${seconds}`;
};

export const convertUtcToLocalFormat = utcDate => {
  const localDate = new Date(utcDate);

  const dateOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  };

  const timeOptions = {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  };

  const datePart = localDate.toLocaleDateString('en-GB', dateOptions);
  const timePart = localDate.toLocaleTimeString('en-US', timeOptions);

  return `${datePart}, ${timePart}`;
};

export const convertDateFormatInStandard = inputDate => {
  const [year, month, day] = inputDate.split('-');

  const formattedDay = day.padStart(2, '0');
  const formattedMonth = month.padStart(2, '0');

  return `${formattedDay}/${formattedMonth}/${year}`;
};

export const toMinutesAndSeconds = time => {
  if (Number.isNaN(Number(time)) || time < 0) time = 0;
  const minutes = Math.floor(time / 60);
  const seconds = time - minutes * 60;
  if (minutes === 0) return [seconds, 's'];
  if (seconds === 0) return [`${minutes}:00`, 'm'];
  if (seconds <= 9) return [`${minutes}:0${seconds}`, 'm'];
  return [`${minutes}:${seconds}`, 'm'];
};
