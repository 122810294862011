import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useStatus, ERROR, SUCCESS } from '@rootstrap/redux-tools';

import { useToast, useAnalytics } from 'hooks';
import { follow, unFollow } from 'state/actions/celebritiesActions';
import { MIXPANEL_EVENTS } from 'constants/constants';

import { getFullName } from 'utils/helpers';

export default ({ id, firstName, lastName }) => {
  const dispatch = useDispatch();
  const { showErrorToast } = useToast();
  const { trackEvent } = useAnalytics();

  const { status: followStatus, error: followError } = useStatus(follow);
  const { status: unFollowStatus, error: unFollowError } = useStatus(unFollow);

  const resetStatus = status => status === ERROR || status === SUCCESS;

  useEffect(() => {
    if (followStatus === ERROR) {
      showErrorToast(followError);
    }
    resetStatus(followStatus) && dispatch(follow.reset());
  }, [dispatch, followError, showErrorToast, followStatus]);

  useEffect(() => {
    if (unFollowStatus === ERROR) {
      showErrorToast(unFollowError);
    }
    resetStatus(unFollowStatus) && dispatch(unFollow.reset());
  }, [dispatch, unFollowError, showErrorToast, unFollowStatus]);

  return {
    follow: useCallback(() => {
      dispatch(follow(id));
      trackEvent(MIXPANEL_EVENTS.followClicked, {
        'Creator Name': getFullName(firstName, lastName)
      });
    }, [dispatch, id, firstName, lastName, trackEvent]),

    unFollow: useCallback(() => {
      dispatch(unFollow(id));
    }, [id, dispatch])
  };
};
