import React from 'react';
import { func, string } from 'prop-types';

import { getSharingUrl, copyToClipboard } from 'utils/helpers';
import { SHARING_PLATFORMS, MIXPANEL_EVENTS } from 'constants/constants';
import { useAnalytics } from 'hooks';

import { FacebookIcon, TwitterIcon, CopyIcon, CrossIcon } from 'components/icons';
import IconWithBackground from 'components/common/IconWithBackground/IconWithBackground';

import { white } from 'styles/common/_constants.scss';

const SharingBlock = ({ onClose, shareUrl, shareText, videoTitle }) => {
  const { TWITTER, FACEBOOK } = SHARING_PLATFORMS;
  const { trackEvent } = useAnalytics();

  const share = socialPlatform => {
    trackEvent(MIXPANEL_EVENTS.shareContent, {
      platformName: socialPlatform.name,
      videoName: videoTitle
    });
    window && window.open(getSharingUrl(socialPlatform, shareUrl, shareText), '_blank');
  };

  return (
    <div className="sharing-icons" onClick={e => e.stopPropagation()}>
      <IconWithBackground
        icon={<CopyIcon />}
        onClick={() => {
          copyToClipboard(shareUrl);
        }}
        isSmall
      />
      <div onClick={() => share(TWITTER)}>
        <IconWithBackground icon={<TwitterIcon color={white} />} isSmall />
      </div>
      <div onClick={() => share(FACEBOOK)}>
        <IconWithBackground icon={<FacebookIcon color={white} />} isSmall />
      </div>
      <IconWithBackground icon={<CrossIcon />} onClick={onClose} isSmall />
    </div>
  );
};

SharingBlock.defaultProps = {
  shareText: ''
};

SharingBlock.propTypes = {
  onClose: func.isRequired,
  shareUrl: string.isRequired,
  videoTitle: string.isRequired,
  shareText: string
};

export default SharingBlock;
