/* eslint-disable react/button-has-type */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Close from 'components/common/Close/Close';
import { white, black40 } from 'styles/common/_constants.scss';
import { useClickOutside, useIsMobile } from 'hooks';
import useReportNarrative from 'hooks/narrative/useReportNarrative';
import useReportSeries from 'hooks/series/useReportSeries';
import useReportCelebrities from 'hooks/celebrities/useReportCelebrities';
import Button from 'components/common/Button/Button';
import Input from 'components/common/Input/Input';
import useReportPodcast from 'hooks/podcast/useReportPodcast';
import useReportEvent from 'hooks/event/useReportEvent';
import useReportEpisode from 'hooks/series/useReportEpisode';

const ReportModal = ({ hide, id, title, type }) => {
  const isMobile = useIsMobile();
  const ref = useClickOutside(hide, id);
  const [otherReasonData, setOtherReasonData] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const reportOptions = [
    'Scam or fraud',
    'Hate speech or symbol',
    'Intellectual property violation',
    'Other'
  ];
  const reportContent = useReportNarrative();
  const reportSeries = useReportSeries();
  const reportCelebrity = useReportCelebrities();
  const reportPodcast = useReportPodcast();
  const reportEvent = useReportEvent();
  const reportEpisode = useReportEpisode();
  const onReport = async data => {
    if (type === 'isNarrative') {
      await reportContent({
        narrativeId: id,
        params: {
          reported_content: {
            reason: data
          }
        }
      });
    }
    if (type === 'isUser') {
      await reportCelebrity({
        celebrityId: id,
        params: {
          reported_content: {
            reason: data
          }
        }
      });
    }
    if (type === 'isPodcast') {
      await reportPodcast({
        podcastId: id,
        params: {
          reported_content: {
            reason: data
          }
        }
      });
    }
    if (type === 'isEvent') {
      await reportEvent({
        eventId: id,
        params: {
          reported_content: {
            reason: data
          }
        }
      });
    }
    if (type === 'isSeries') {
      await reportSeries({
        seriesId: id,
        params: {
          reported_content: {
            reason: data
          }
        }
      });
    }
    if (type === 'isEpisode') {
      await reportEpisode({
        episodeId: id,
        params: {
          reported_content: {
            reason: data
          }
        }
      });
    }
    setIsLoading(false);
    hide();
  };

  const handleOptionClick = option => {
    setSelectedOption(option);
    if (option !== 'Other') {
      onReport(option);
    }
  };

  const handleOtherSubmit = () => {
    if (otherReasonData.trim()) {
      setIsLoading(true);
      onReport(otherReasonData);
    }
  };

  return (
    <div className="serie-landing" ref={ref}>
      <div className="serie-landing-header">
        {title} <Close onClick={hide} color={isMobile ? white : black40} />
      </div>
      <div className="layer serie-landing-header-content">
        {selectedOption !== 'Other' && (
          <ul>
            {reportOptions.map((option, index) => (
              <li
                key={index}
                onClick={() => handleOptionClick(option)}
                style={{ cursor: 'pointer', paddingLeft: '20px' }}
              >
                <div className="d-flex">
                  <div>{option}</div>
                </div>
              </li>
            ))}
          </ul>
        )}
        {selectedOption === 'Other' && (
          <div style={{ padding: '20px' }}>
            <Input
              placeholder="Write a reason to report"
              value={otherReasonData}
              onChange={e => setOtherReasonData(e.target.value)}
              style={{ marginBottom: '10px' }}
            />
            <Button
              labelId="Submit"
              type="primary"
              className="profile-button"
              style={{ padding: '5px 10px' }}
              disabled={isLoading || !otherReasonData.trim()}
              onClick={handleOtherSubmit}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ReportModal;
