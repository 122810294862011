import React from 'react';
import { string, node, bool } from 'prop-types';
import cn from 'classnames';

const Dropdown = ({ children, className, triangleLeft }) => (
  <div className={cn('dropdown', className)}>
    <span className={cn('triangle', { 'align-left': triangleLeft })} />
    {children}
  </div>
);

Dropdown.propTypes = {
  children: node,
  className: string,
  triangleLeft: bool
};

export default Dropdown;
