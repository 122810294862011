import React from 'react';
import { object, bool, string, func, oneOfType } from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import { useSession } from 'hooks';

import RouteComponent from 'components/routes/RouteComponent';
import { routesPaths } from 'constants/routesPaths';

const PrivateRoute = ({ ...route }) => {
  const { authenticated } = useSession();
  return authenticated ? (
    <RouteComponent {...route} />
  ) : (
    <Route
      render={props => (
        <Redirect
          to={{
            pathname: routesPaths.login,
            state: { from: props.location }
          }}
        />
      )}
    />
  );
};

PrivateRoute.propTypes = {
  component: oneOfType([func, object]).isRequired,
  path: string.isRequired,
  exact: bool,
  location: object,
  userType: string,
  withoutNavbar: bool
};

export default PrivateRoute;
