import React from 'react';
import { ReducedLogoIcon } from 'components/icons';

export const getUserPicture = (user, size = 16) => {
  return user ? (
    <img src={user} alt="profile" />
  ) : (
    <ReducedLogoIcon height={size} width={size} opacity={1} />
  );
};

export const getIdFromTab = (tabs, selectedTab) => {
  const tab = Object.values(tabs).find(obj => obj.tab === selectedTab);
  return tab?.id;
};

export const getTabNameFromId = (tabs, selectedId) => {
  const selectedTab = Object.values(tabs).find(obj => obj.id === selectedId);
  return selectedTab?.tab;
};

export const getLocaleFromId = (tabs, selectedId) => {
  const selectedTab = Object.values(tabs).find(obj => obj.id === selectedId);
  return selectedTab?.localeId;
};

export const getEpisodeIndex = (serie, episodeId) => {
  const { episodes, earlyReleaseEpisodes } = serie;
  const episodeIndex = episodes?.findIndex(({ id }) => id == episodeId);

  if (episodeIndex >= 0) {
    const { exclusive, link } = episodes[episodeIndex];
    const needsSubscription = exclusive && !link;
    return { episodeIndex, needsSubscription };
  }

  const earlyReleaseIndex = earlyReleaseEpisodes?.findIndex(({ id }) => id == episodeId);
  return { isEarlyRelease: earlyReleaseIndex >= 0, earlyReleaseIndex };
};

export const filterEpisodes = (episodes, filterEarlyRelease = false) =>
  episodes?.filter(({ link, exclusive }) => (filterEarlyRelease ? !link : link || exclusive));

export const filterAccessibleEpisodes = series => ({
  ...series,
  episodes: series.episodes
});

export const filterSeries = content =>
  content
    ?.filter(
      ({ episodes, firstEpisode, liveEvent }) =>
        filterEpisodes(episodes)?.length || firstEpisode?.link || liveEvent
    )
    .map(element =>
      element.liveEvent
        ? element
        : { ...element, episodes: element.episodes && filterEpisodes(element.episodes) }
    );

export const filterSubCategoriesSeries = subCategories =>
  subCategories
    .filter(({ content }) => content.length)
    .map(subCategory => ({
      ...subCategory,
      content: subCategory.content.filter(
        ({ series, narrative }) => series?.firstEpisode || narrative
      )
    }));
