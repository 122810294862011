import { useSelector } from 'react-redux';
import { useLoading } from '@rootstrap/redux-tools';
import { createPodcast } from 'state/actions/podcastActions';

export default () => {
  return {
    uploadingPodcast: useSelector(({ podcastReducer }) => podcastReducer?.uploadingPodcast || null),
    loading: useLoading(createPodcast)
  };
};
