import { useRef, useEffect, useCallback } from 'react';
import ActionCable from 'actioncable';
import { applyQueryParams } from 'utils/helpers';
import useSession from 'hooks/useSession';

export default () => {
  const cable = useRef();
  const { info } = useSession();

  useEffect(() => {
    cable.current = ActionCable.createConsumer(applyQueryParams(process.env.CABLE_URL, info));
  }, [info]);

  const connect = useCallback(({ channel, onConnect, onReceived, onDisconnect = () => {} }) => {
    return cable.current.subscriptions.create(channel, {
      connected: () => console.log('%c Socket connected', 'color: green') || onConnect,
      received: message =>
        console.log('%c Message recieved', 'color: orange', message) || onReceived(message),
      disconnected: e => console.log('%c Socket disonnected', 'color: red') || onDisconnect(e)
    });
  }, []);

  const disconnect = useCallback(() => cable.current?.disconnect(), []);

  return { connect, disconnect };
};
