import React, { useEffect } from 'react';
import { func } from 'prop-types';
import { useStatus, SUCCESS, ERROR } from '@rootstrap/redux-tools';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import { useClickOutside, useAnalytics, useToast } from 'hooks';
import usePayments from 'hooks/payments/usePayments';
import { createCard } from 'state/actions/paymentsActions';
import { MIXPANEL_EVENTS } from 'constants/constants';

import NewCardForm from 'components/payments/NewCardForm/NewCardForm';
import { CrossIcon } from 'components/icons';

import { black20 } from 'styles/common/_constants.scss';

const AddPaymentMethod = ({ hide }) => {
  const intl = useIntl();
  const { trackEvent } = useAnalytics();
  const ref = useClickOutside(hide);
  const { showToast, showErrorToast } = useToast();
  const dispatch = useDispatch();

  const { status, error } = useStatus(createCard);

  const { getCreditCards } = usePayments();

  useEffect(() => {
    if (status === SUCCESS) {
      dispatch(createCard.reset());
      getCreditCards();
      trackEvent(MIXPANEL_EVENTS.creditCardAdded);
      showToast(intl.formatMessage({ id: 'creditCard.success' }));
      hide();
    }
    if (status === ERROR) {
      dispatch(createCard.reset());
      trackEvent(MIXPANEL_EVENTS.creditCardRejected);
      showErrorToast(error);
    }
  }, [status]);

  return (
    <div className="add-payment-method" ref={ref}>
      <div className="flex-spaced">
        <h2>{intl.formatMessage({ id: 'paymentMethod.new' })}</h2>
        <div onClick={hide}>
          <CrossIcon width={24} height={24} color={black20} />
        </div>
      </div>
      <NewCardForm />
    </div>
  );
};

AddPaymentMethod.propTypes = {
  hide: func
};

export default AddPaymentMethod;
