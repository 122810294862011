import React, { memo } from 'react';
import { bool, func, object, string } from 'prop-types';
import cn from 'classnames';

const IconButton = ({ onClick, icon, className, disabled }) => (
  <div
    className={cn('button secondary icon-button', className)}
    onClick={disabled ? undefined : onClick}
  >
    {icon}
  </div>
);

IconButton.propTypes = {
  onClick: func.isRequired,
  icon: object.isRequired,
  className: string,
  disabled: bool
};

export default memo(IconButton);
