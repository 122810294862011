import React from 'react';
import ReactCodeInput from 'react-code-input';
import { func, number, bool } from 'prop-types';
import cn from 'classnames';

const CodeInput = ({ onChange, fields, error }) => (
  <ReactCodeInput
    type="number"
    fields={fields}
    className={cn('code-input', { error })}
    onChange={onChange}
  />
);

CodeInput.propTypes = {
  onChange: func.isRequired,
  fields: number.isRequired,
  error: bool
};

export default CodeInput;
