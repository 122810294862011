import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { shape, bool } from 'prop-types';

import { celebrityShape } from 'constants/propTypesShapes';
import { useNavigation } from 'hooks';
import { routeWithProps } from 'utils/helpers';

import { ReducedLogoIcon, BackArrowIcon } from 'components/icons';
import Button from 'components/common/Button/Button';
import { routesPaths } from 'constants/routesPaths';

const OfflineScreen = ({ celebrity: { username }, isScheduled }) => {
  const intl = useIntl();
  const { goTo, goBack } = useNavigation();

  const messages = defineMessages({
    title: { id: isScheduled ? 'live.ended.title' : 'live.offline.title' },
    subtitle: { id: 'live.offline.subtitle' }
  });

  const handleRedirect = () => {
    if (isScheduled) {
      goBack();
    } else {
      goTo(routeWithProps(routesPaths.celebrityId, { username: username.toLowerCase() }), true);
    }
  };

  return (
    <>
      <div className="offline-screen">
        <ReducedLogoIcon width={153} height={154} opacity={0.1} />
        <h1 className="title">{intl.formatMessage(messages.title)}</h1>
        {!isScheduled && <div className="p2 subtitle">{intl.formatMessage(messages.subtitle)}</div>}
        <Button
          type="primary"
          labelId={isScheduled ? 'live.ended.button' : 'live.offline.button'}
          size="large"
          icon={isScheduled && <BackArrowIcon />}
          onClick={handleRedirect}
        />
      </div>
      <div className="live-tag offline">
        <div className="live-circle" />
        <h6 className="bold">{intl.formatMessage({ id: 'live.offline' })}</h6>
      </div>
    </>
  );
};

OfflineScreen.propTypes = {
  celebrity: shape(celebrityShape),
  isScheduled: bool
};

export default OfflineScreen;
