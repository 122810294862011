import { number, string, arrayOf, object, oneOfType, shape, bool, oneOf } from 'prop-types';

import { PURCHASE_TYPES } from './constants';

export const serieShape = {
  id: number.isRequired,
  desktopImageUrl: string,
  mobileImageUrl: string,
  description: string.isRequired,
  logoImageUrl: string.isRequired
};

export const podcastShape = {
  id: number.isRequired,
  bannerImageUrl: string,
  title: string.isRequired
};

export const celebrityShape = {
  id: number.isRequired,
  firstName: string.isRequired,
  lastName: string.isRequired,
  bio: string,
  profileImageUrl: string.isRequired,
  bannerUrl: string.isRequired,
  activeLiveEvent: bool
};

export const celebrityInfoShape = {
  id: oneOfType([number, string]).isRequired,
  username: string.isRequired,
  profileImageUrl: string.isRequired
};

export const episodeShape = {
  id: number.isRequired,
  title: string.isRequired,
  description: string,
  thumbnailsUrls: arrayOf(object),
  link: string
};

export const narrativeShape = {
  id: number.isRequired,
  title: string.isRequired,
  description: string,
  thumbnailsUrls: arrayOf(object).isRequired,
  link: string.isRequired
};

export const categoryFeaturedShape = {
  id: number.isRequired,
  name: string.isRequired,
  slug: string.isRequired,
  description: string,
  seriesOriginal: shape(serieShape),
  narratives: arrayOf(shape(narrativeShape))
};

export const subcategoryShape = {
  id: number.isRequired,
  name: string.isRequired,
  description: string.isRequired
};

export const dmShape = {
  id: number.isRequired,
  price: number.isRequired,
  period: string.isRequired,
  name: string
};

export const notificationShape = {
  id: number.isRequired,
  read: bool.isRequired,
  narrative: shape(narrativeShape),
  episode: shape(episodeShape),
  dm: shape({
    celebrity: object,
    message: string
  }),
  live: shape({
    celebrity: object,
    title: string
  })
};

export const membershipPlan = {
  id: number.isRequired,
  price: number.isRequired,
  period: string.isRequired,
  name: string
};

export const membershipShape = {
  id: number.isRequired,
  status: string.isRequired,
  currentPeriodEndsAt: string,
  celebrity: shape(celebrityShape),
  membershipPlan: shape(membershipPlan)
};

export const creditCardShape = {
  id: number.isRequired,
  brand: string.isRequired,
  last4: string.isRequired,
  zipCode: string,
  country: string,
  cardHolderFirstName: string,
  cardHolderLastName: string,
  expMonth: oneOfType([number, string]),
  expYear: oneOfType([number, string])
};

export const scheduledLiveEventShape = {
  id: number.isRequired,
  desktopImageUrl: string.isRequired,
  mobileImageUrl: string.isRequired,
  description: string.isRequired,
  logoImageUrl: string.isRequired,
  state: string.isRequired,
  liveEvent: bool.isRequired,
  liveStart: string.isRequired,
  bannerUrl: string,
  price: number.isRequired,
  ppv: bool.isRequired,
  sponsorUrl: string
};

export const contentShape = {
  id: number.isRequired,
  desktopImageUrl: string.isRequired,
  mobileImageUrl: string.isRequired,
  description: string.isRequired,
  logoImageUrl: string.isRequired,
  state: string,
  liveEvent: bool,
  liveStart: string
};

export const unlockContentConfigShape = {
  title: string.isRequired,
  buttonLabel: string.isRequired,
  purchaseType: oneOf([PURCHASE_TYPES.EPISODE, PURCHASE_TYPES.MEMBERSHIP_EPISODE, PURCHASE_TYPES.PODCAST]).isRequired
};

export const purchaseItemShape = {
  title: string.isRequired,
  description: string.isRequired,
  episodeName: string,
  price: string.isRequired
};
