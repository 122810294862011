import React from 'react';
import { shape } from 'prop-types';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { routesPaths } from 'constants/routesPaths';
import { serieShape } from 'constants/propTypesShapes';
import { routeWithProps, avatarName } from 'utils/helpers';
import { useIsMobile, useModal, useNavigation } from 'hooks';

import Button from 'components/common/Button/Button';
import CelebrityAvatar from 'components/home/CelebrityAvatar/CelebrityAvatar';
import SerieInfo from 'components/series/SerieInfo/SerieInfo';
import { InfoIcon, PlayIcon } from 'components/icons';
import SerieLanding from 'components/serieLanding/SerieLanding/SerieLanding';
import Modal from 'components/common/Modal/Modal';

const SeriesHero = ({ serie }) => {
  const { toggle, isShowing } = useModal();
  const { getLinkTo, goTo } = useNavigation();

  const { id: celebrityId, firstName, lastName, profileImageUrl, username } =
    serie?.celebrities?.[0] || {};
  const isMobile = useIsMobile();

  const watchFirstEpisode = () => {
    goTo(
      routeWithProps(routesPaths.series, {
        id: serie.id,
        episode: serie.firstEpisode.id
      })
    );
  };

  return (
    <>
      <div className="featured-content-info">
        {serie && (
          <>
            <SerieInfo serie={serie} />
            <div className="featured-content-buttons">
              <Button
                labelId="common.play"
                type="primary"
                icon={<PlayIcon />}
                onClick={watchFirstEpisode}
              />
              <Button
                labelId="general.info"
                onClick={toggle}
                type="secondary"
                icon={<InfoIcon />}
                className={cn('info-button', { 'button-xsmall': isMobile })}
              />
            </div>
          </>
        )}
        {!!celebrityId && (
          <Link
            to={getLinkTo(
              routeWithProps(routesPaths.celebrityId, { username: username.toLowerCase() })
            )}
          >
            <CelebrityAvatar
              backgroundImage={profileImageUrl}
              title={avatarName(firstName, lastName)}
            />
          </Link>
        )}
      </div>
      <Modal hide={toggle} isShowing={isShowing}>
        <SerieLanding serie={serie} />
      </Modal>
    </>
  );
};

SeriesHero.propTypes = {
  serie: shape(serieShape)
};

export default SeriesHero;
