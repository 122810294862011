/* eslint-disable no-restricted-globals */
import React from 'react';

import { searchPills } from 'constants/constants';
import { getTabNameFromId } from 'utils/dataHelpers';
import { routeWithProps } from 'utils/helpers';
import CreatorPage from 'components/search/CreatorPage';
import ContentPage from 'components/search/ContentPage';
import AllContentPage from 'components/search/AllContentPage';
import { routesPaths } from 'constants/routesPaths';
import { useHistory } from 'react-router-dom';

export default () => {
  const history = useHistory();
  const renderSearchContent = (tab, dateFilter, categoryFilter) => {
    const { ALL, CREATOR, SHORTS, MOVIES, EPISODES } = searchPills;
    switch (tab) {
      case ALL.id:
        return <AllContentPage dateFilter={dateFilter} categoryFilter={categoryFilter} />;
      case CREATOR.id:
        return <CreatorPage dateFilter={dateFilter} categoryFilter={categoryFilter} />;
      case SHORTS.id:
        return <ContentPage dateFilter={dateFilter} categoryFilter={categoryFilter} />;
      case MOVIES.id:
        return <ContentPage dateFilter={dateFilter} categoryFilter={categoryFilter} />;
      case EPISODES.id:
        return <ContentPage dateFilter={dateFilter} categoryFilter={categoryFilter} />;
      default:
    }
  };

  const changeUrl = (id, searchTerm, dateFilter, categoryFilter) => {
    const tab = id ? getTabNameFromId(searchPills, id) : 'all';
    const url = routeWithProps(routesPaths.searchPage, { tab });
    const params = new URLSearchParams(location.search);
    if (searchTerm !== undefined) {
      if (searchTerm) {
        const decodedSearch = decodeURIComponent(searchTerm);
        params.set('search', decodedSearch);
      } else {
        params.delete('search');
      }
    }

    if (dateFilter !== undefined) {
      if (dateFilter) {
        params.set('sort', dateFilter);
      } else {
        params.delete('sort');
      }
    }

    if (categoryFilter !== undefined) {
      if (categoryFilter) {
        params.set('category_id', categoryFilter);
      } else {
        params.delete('category_id');
      }
    }

    const finalUrl = `${url}?${params.toString()}`;
    history.replace(finalUrl);
  };

  return {
    renderSearchContent,
    changeUrl
  };
};
