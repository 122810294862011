import React, { useRef, useState } from 'react';
import { useToast } from 'hooks';
import ChangeProfileIcon from 'assets/image-user.png';
import ChangeCoverIcon from 'assets/icons/ChangeProfile/ChangeProfile.png';
import Loading from 'components/common/Loading';
import useChangeProfileImage from 'hooks/users/useChangeProfileImage';
import ImageCropperModal from 'components/common/ImageCropper/ImageCropperModal';
import { imageRatios, imageSizes } from 'constants/constants';
import { checkImageDimensions } from 'utils/helpers';

const ChangeProfile = () => {
  const fileInputRef = useRef(null);
  const fileBannerInputRef = useRef(null);
  const { showToast, showErrorToast } = useToast();
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [croppingUrl, setCroppingUrl] = useState();
  const [aspectRatio, setAspectRatio] = useState(null);
  const { coverLoading, loading, onChangeProfileImage } = useChangeProfileImage();
  const uploadImgType = useRef();

  const handleFileChange = async event => {
    const file = event.target.files[0];
    const result = await checkImageDimensions(
      file,
      imageSizes.profile.width,
      imageSizes.profile.height
    );
    if (result.isValid) {
      uploadImgType.current = 'profile_image';
      setAspectRatio(imageRatios.profile);
      setCropModalOpen(true);
      setCroppingUrl(URL.createObjectURL(file));
    } else {
      showErrorToast(result.error);
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleCoverFileChange = async event => {
    const file = event.target.files[0];
    const result = await checkImageDimensions(
      file,
      imageSizes.coverWeb.width,
      imageSizes.coverWeb.height
    );
    if (result.isValid) {
      uploadImgType.current = 'banner';
      setAspectRatio(imageRatios.covertArtWeb);
      setCropModalOpen(true);
      setCroppingUrl(URL.createObjectURL(file));
    } else {
      showErrorToast(result.error);
    }
    if (fileBannerInputRef.current) {
      fileBannerInputRef.current.value = '';
    }
  };
  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleBannerClick = () => {
    fileBannerInputRef.current.click();
  };
  const handleCancel = () => {
    setCropModalOpen(false);
    setCroppingUrl();
    setAspectRatio(null);
  };

  const handleCrop = async croppedFile => {
    await onChangeProfileImage(croppedFile, uploadImgType.current);
    showToast(
      `${
        uploadImgType.current !== 'banner' ? 'Profile Picture' : 'Cover Picture'
      } Changed Successfully!`
    );
  };
  return (
    <div className="change-profile">
      <div className="img-container">
        <div
          className="profile-row"
          style={{ cursor: `${loading ? 'not-allowed' : 'pointer'}` }}
          onClick={loading ? undefined : handleClick}
        >
          <img src={ChangeProfileIcon} className="profile-icon" alt="profile" />
          Change Profile Image {loading && <Loading type="ball-clip-rotate" />}
        </div>
        <div
          className="profile-row"
          style={{ cursor: `${coverLoading ? 'not-allowed' : 'pointer'}` }}
          onClick={coverLoading ? undefined : handleBannerClick}
        >
          <img src={ChangeCoverIcon} className="cover-icon" alt="profile" />
          Change Cover Image {coverLoading && <Loading type="ball-clip-rotate" />}
        </div>
        <input
          id="profile-upload"
          type="file"
          accept="image/*"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
        <input
          id="banner-upload"
          type="file"
          accept="image/*"
          ref={fileBannerInputRef}
          style={{ display: 'none' }}
          onChange={handleCoverFileChange}
        />
        {cropModalOpen && (
          <ImageCropperModal
            visible={cropModalOpen}
            imageUrl={croppingUrl}
            onCancel={handleCancel}
            onCrop={handleCrop}
            aspectRatio={aspectRatio}
          />
        )}
      </div>
    </div>
  );
};

export default ChangeProfile;
