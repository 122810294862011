import React from 'react';
import { bool, string } from 'prop-types';
import LiveIcon from 'assets/live-icon.png';
import { VIDEO_SHORT_DESCRIPTION_LENGTH } from 'constants/constants';
import MoreText from 'components/common/MoreText/MoreText';
import { convertDateFormatInStandard } from 'utils/helpers';

const EventCardInfo = ({
  title,
  eventStartTimeInUtc,
  duration,
  eventDate,
  membersPrice,
  nonMembersPrice,
  isAuthenticated,
  status
}) => (
  <div className="event-card-info">
    <div className="title-row">
      <div style={{ display: 'inline-flex', gap: '10px', alignItems: 'center' }}>
        <h5 className="bold">{title}</h5>
        {status === 'ongoing' && (
          <div className="live-stream-tag">
            <img height="20px" width="25px" src={LiveIcon} alt="live-tag" />
            <span className="live-span">Live</span>
          </div>
        )}
      </div>
      <h5 className="bold">${isAuthenticated ? membersPrice : nonMembersPrice}</h5>
    </div>
    <div className="date-row">
      <div className="p1">
        <MoreText
          text={convertDateFormatInStandard(eventDate)}
          limitLength={VIDEO_SHORT_DESCRIPTION_LENGTH}
        />
      </div>
      <div>{duration}</div>
    </div>
  </div>
);

EventCardInfo.propTypes = {
  title: string.isRequired,
  eventStartTimeInUtc: string.isRequired,
  duration: string.isRequired,
  membersPrice: string.isRequired,
  nonMembersPrice: string.isRequired,
  isAuthenticated: bool,
  status: string
};

export default EventCardInfo;
