import { createReducer } from '@rootstrap/redux-tools';
import { createVideoAds } from 'state/actions/videoadsAction';

const initialState = {
  uploadingvideoads: {}
};

export default createReducer(initialState, {
  [createVideoAds.success]: (state, { payload }) => {
    state.uploadingvideoads = payload;
  }
});
