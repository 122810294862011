import React from 'react';
import { func, oneOfType, shape, arrayOf, bool } from 'prop-types';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { routeWithProps } from 'utils/helpers';
import { routesPaths } from 'constants/routesPaths';
import { celebrityInfoShape } from 'constants/propTypesShapes';

import CelebrityAvatar from 'components/home/CelebrityAvatar/CelebrityAvatar';
import MultipleCelebrityAvatars from 'components/common/MultipleCelebrityAvatars/MultipleCelebrityAvatars';

const CelebritiesButton = ({ celebrities, onExitPlayer, topRow }) =>
  Array.isArray(celebrities) ? (
    <MultipleCelebrityAvatars
      celebrities={celebrities}
      onAvatarClick={onExitPlayer}
      className={cn({ 'top-row-avatars': topRow })}
    />
  ) : (
    <Link
      className="avatar-button"
      to={routeWithProps(routesPaths.celebrityId, {
        username: celebrities.username.toLowerCase()
      })}
      onClick={onExitPlayer}
    >
      <CelebrityAvatar backgroundImage={celebrities.profileImageUrl} size="small" />
    </Link>
  );

CelebritiesButton.propTypes = {
  onExitPlayer: func,
  topRow: bool,
  celebrities: oneOfType([shape(celebrityInfoShape), arrayOf(shape(celebrityInfoShape))])
};

CelebritiesButton.defaultProps = {
  onExitPlayer: () => {}
};

export default CelebritiesButton;
