import { useMemo, useCallback } from 'react';
import { useIntl, defineMessages } from 'react-intl';

import { useIsMobile, useScheduleLive, useSession } from 'hooks';
import { LIVE_STATUSES } from 'constants/constants';
import useProfile from 'hooks/users/useProfile';

import { fixedPrice } from 'utils/helpers';

const labels = defineMessages({
  purchased: { id: 'payPerView.purchased' },
  purchasedStarted: { id: 'scheduledLive.watch' },
  default: { id: 'payPerView.buyWithPrice' },
  buyReduced: { id: 'payPerView.buy' },
  watchStarted: { id: 'payPerView.watchLivePrice' },
  watch: { id: 'payPerView.watch' }
});

export default (event, isLanding) => {
  const intl = useIntl();
  const { id, price: eventPrice, state, celebrities } = event;
  const { checkPurchasedPPV } = useProfile();
  const isMobile = useIsMobile();
  const { user } = useSession();
  const price = useMemo(() => fixedPrice(eventPrice), [eventPrice]);

  const {
    scheduledLive: { paidByUser }
  } = useScheduleLive();

  const isLive = useMemo(() => state === LIVE_STATUSES.STARTED, [state]);
  const purchased = useMemo(() => checkPurchasedPPV(id) || paidByUser, [
    id,
    checkPurchasedPPV,
    paidByUser
  ]);

  const isHost = useMemo(() => user?.id && celebrities.some(({ id }) => id === user.id), [
    user,
    celebrities
  ]);

  const getPurchasedLabel = useCallback(() => {
    if (isLive) {
      return labels.purchasedStarted;
    }
    if (isLanding) {
      return labels.purchased;
    }
    return labels.watch;
  }, [isLive, isLanding]);

  const getTitle = useMemo(() => {
    switch (true) {
      case purchased || isHost:
        return intl.formatMessage(getPurchasedLabel());
      case isLive && !isLanding:
        return intl.formatMessage(labels.watchStarted, { price });
      case isMobile:
        return intl.formatMessage(labels.buyReduced);
      default:
        return intl.formatMessage(labels.default, { price });
    }
  }, [purchased, isMobile, isLive, price, isLanding, intl, getPurchasedLabel]);

  return { getTitle, purchased, isLive, isHost };
};
