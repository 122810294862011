import React, { memo } from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { LOADING } from '@rootstrap/redux-tools';
import some from 'lodash/some';
import { func } from 'prop-types';

import { useForm, useValidation, usePhoneInputProps } from 'hooks';
import useLogin from 'hooks/users/useLogin';
import { login as loginValidations } from 'utils/constraints';
import { includeAllFields } from 'utils/helpers';

import Loading from 'components/common/Loading';
import CountryPhoneInput from 'components/common/CountryPhoneInput/CountryPhoneInput';
import Button from 'components/common/Button/Button';

const messages = defineMessages({
  phone: { id: 'form.phoneNumber' },
  phonePlaceholder: { id: 'form.phoneNumber.placeholder' }
});

const fields = {
  phone: 'phone'
};

export const LoginForm = ({ redirectVerifyPhone }) => {
  const intl = useIntl();
  const { onSubmit, status, error } = useLogin(redirectVerifyPhone);

  const validator = useValidation(loginValidations);
  const { values, errors, handleValueChange, handleSubmit, handleBlur, touched } = useForm(
    {
      onSubmit,
      validator,
      validateOnBlur: true
    },
    [onSubmit]
  );

  const phoneInputProps = usePhoneInputProps(handleValueChange, handleBlur, values);

  return (
    <div className="login-form">
      <CountryPhoneInput
        name="phone"
        placeholder={intl.formatMessage(messages.phonePlaceholder)}
        label={intl.formatMessage(messages.phone)}
        errors={errors[fields.phone]}
        {...phoneInputProps(fields.phone)}
      />
      <div className="login-form-error p1">{error}</div>
      <Button
        labelId={status === LOADING ? 'general.loading' : 'form.signin.submit'}
        className="submit-button"
        onClick={handleSubmit}
        disabled={status === LOADING || some(errors) || !includeAllFields(values, fields, touched)}
      />
      {status === LOADING && <Loading type="ball-clip-rotate" />}
    </div>
  );
};

LoginForm.propTypes = {
  redirectVerifyPhone: func
};

export default memo(LoginForm);
