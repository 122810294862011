import { useDispatch } from 'react-redux';
import { useStatus, LOADING } from '@rootstrap/redux-tools';

import { subscribeAdTier, registerEvent } from 'state/actions/membershipActions';

export default () => {
  const dispatch = useDispatch();
  const { status, error } = useStatus(subscribeAdTier);

  const eventAd = async membership => {
    const { creditCardId, eventId } = membership;
    dispatch(registerEvent(creditCardId, eventId));
  };

  return {
    loading: status === LOADING,
    error,
    eventAd,
    status
  };
};
