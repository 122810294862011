import React, { useState, useMemo } from 'react';
import { func, arrayOf, object, bool } from 'prop-types';

import { SearchIcon } from 'components/icons';

const CountriesDropdown = ({ options, onClick, withSearch }) => {
  const [search, setSearch] = useState('');

  const filteredOptions = useMemo(
    () => options.filter(({ name }) => name.toLowerCase().includes(search.toLowerCase())),
    [options, search]
  );

  const onValueChange = event => {
    setSearch(event.target.value);
  };

  return (
    <div className="countries-dropdown" onClick={e => e.stopPropagation()}>
      {withSearch && (
        <div className="countries-dropdown-filter">
          <input value={search} onChange={onValueChange} />
          <SearchIcon className="countries-dropdown-filter-icon" />
        </div>
      )}
      <div className="countries-dropdown-options">
        {filteredOptions.map(country => (
          <div
            className="countries-dropdown-option"
            onClick={() => onClick(country)}
            key={country.code}
          >
            <h6>{country.name}</h6>
          </div>
        ))}
      </div>
    </div>
  );
};

CountriesDropdown.propTypes = {
  options: arrayOf(object),
  onClick: func.isRequired,
  withSearch: bool
};

export default CountriesDropdown;
