/* eslint-disable no-restricted-globals */
import dayjs from 'dayjs';

export const getDayAndMonth = date => {
  return dayjs(date).format('DD MMM');
};

export const getDiffDay = date => {
  const days = Math.abs(dayjs().diff(new Date(date), 'day'));
  return days;
};

export const getReleaseDate = date => {
  return dayjs(date).format('DD:MM:YYYY');
};

export const timeToSeconds = time => {
  const [hours, minutes, seconds] = time.split(':');
  return Number(hours) * 360 + Number(minutes) * 60 + Number(seconds);
};

export const getWrittenDate = date => {
  return dayjs(date).format('MMM D h:mm A');
};

export const calculateTimeLeft = date => {
  const futureDate = dayjs(date);
  const now = dayjs();

  const days = futureDate.diff(now, 'days');
  const hours = futureDate.diff(now, 'hours');
  const minutes = futureDate.diff(now, 'minutes');
  const seconds = futureDate.diff(now, 'seconds');

  const data = {
    days,
    hours: hours - days * 24,
    minutes: minutes - hours * 60,
    seconds: seconds - minutes * 60
  };

  return data;
};

export function formatDate(dateString) {
  const date = new Date(dateString);

  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];

  const dayOfWeek = daysOfWeek[date.getDay()];
  const dayOfMonth = date
    .getDate()
    .toString()
    .padStart(2, '0');
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${dayOfWeek}, ${dayOfMonth} ${month} ${year}`;
}

export function formatDateForPicker(isoDateString) {
  const date = new Date(isoDateString);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    throw new Error('Invalid date string provided');
  }

  // Format the date as DD/MM/YYYY
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const year = date.getUTCFullYear();

  return `${day}/${month}/${year}`;
}

export const getTodayDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

function padZero(num) {
  return num.toString().padStart(2, '0');
}

export function convertDateFormat(inputDate) {
  // Parse the input date string
  const [datePart, timePart] = inputDate.split(' ');
  const [year, month, day] = datePart.split('-');
  const [hours, minutes] = timePart.split(':');

  // Create a new Date object
  const date = new Date(year, month - 1, day, hours, minutes);

  // Subtract one day
  date.setDate(date.getDate() - 1);

  // Format the date to the desired output
  const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(
    date.getDate()
  )}T${padZero(date.getHours())}:${padZero(date.getMinutes())}:${padZero(date.getSeconds())}`;

  return formattedDate;
}

export function convertMinutesToHHMMSS(minutes) {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = Math.floor(minutes % 60);
  const seconds = Math.floor((minutes % 1) * 60);
  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(remainingMinutes).padStart(2, '0');
  const formattedSeconds = String(seconds).padStart(2, '0');
  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}
