import { useSelector } from 'react-redux';

export const USER_TYPES = {
  user: 'User',
  celebrity: 'Celebrity'
};

export default () =>
  useSelector(({ session: { authenticated, user, info, userToConfirm, token } }) => ({
    authenticated,
    info,
    userToConfirm,
    token,
    user: user || {},
    creatorLogged: authenticated && user?.type === USER_TYPES.celebrity,
    userLogged: authenticated && user?.type === USER_TYPES.user
  }));
