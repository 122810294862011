import React from 'react';
import cn from 'classnames';
import { bool, string } from 'prop-types';

import ReducedLogo from 'components/common/Brand/ReducedLogo';
import Tag from 'components/common/Tag/Tag';

const InfoTag = ({ withBackground, className, title }) => {
  return (
    <div className={cn('original', { full: withBackground }, className)}>
      <ReducedLogo isTransparent={withBackground} />
      <Tag label={title} className="original-tag" centeredContent isTransparent />
    </div>
  );
};

InfoTag.propTypes = {
  withBackground: bool,
  className: string,
  title: string
};

export default InfoTag;
