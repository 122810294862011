/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import { number, object, bool, func, string } from 'prop-types';
import { useIntl } from 'react-intl';

import { routesPaths } from 'constants/routesPaths';
import { routeWithProps } from 'utils/helpers';
import { useClickOutside, useModal, useNavigation, useSession } from 'hooks';

import VideoThumbnail from 'components/common/VideoThumbnail/VideoThumbnail';
import { EditIcon, InfoIcon, OptionsIcon, ShareIcon, TrashIcon } from 'components/icons';
import IconWithBackground from 'components/common/IconWithBackground/IconWithBackground';
import DeletePublication from 'components/DeletePublication/DeletePublication';
import Modal from 'components/common/Modal/Modal';
import { zIndexModalThird } from 'styles/common/_constants.scss';
import SharingBlock from 'components/sharing/SharingBlock/SharingBlock';
import ReportModal from 'components/ReportModal/ReportModal';
import EventCardInfo from './EventCardInfo';
import EventInfo from '../EventInfo/EventInfo';

const CLOSED = 0;
const OPENED = 1;
const ICONS_DISPLAYED = 2;

const EventCard = ({
  celebrityId,
  events,
  isLoggedUser,
  refresh,
  username,
  celebrityName,
  isHome
}) => {
  const { toggle, isShowing } = useModal();
  const { toggle: eventToggle, isShowing: isEventShowing } = useModal();
  const intl = useIntl();
  const { authenticated } = useSession();
  // const { user } = useSession();
  const { toggle: reportToggle, isShowing: reportShowing } = useModal();
  const [optionsStatus, setOptionsStatus] = useState(CLOSED);
  const {
    membersPrice,
    title,
    webCoverImageUrl,
    id,
    eventDate,
    duration,
    eventStartTimeInUtc,
    nonMembersPrice,
    status
  } = events;
  const { goTo } = useNavigation();

  const ref = useClickOutside(() => setOptionsStatus(CLOSED), optionsStatus);

  const getUrl = () => {
    const route = routeWithProps(routesPaths.celebrityId, {
      username: username.toLowerCase()
    });
    return `${process.env.FRONT_URL}${route}?tab=3`;
  };

  const handleEditEvent = () => {
    goTo(
      routeWithProps(routesPaths.editEvent, {
        eventId: id,
        celebrityId
      })
    );
  };

  const handleCardClick = e => {
    if (!ref.current || !ref.current.contains(e.target)) {
      eventToggle();
    }
  };

  const handleOptionsClick = e => {
    e.stopPropagation();
    setOptionsStatus(optionsStatus === OPENED ? CLOSED : OPENED);
  };

  return (
    <div
      className="event-card event-card-horizontal"
      style={{
        flex: '0 0 auto',
        minWidth: isHome ? '280px' : '100%',
        maxWidth: isHome ? '320px' : '100%',
        width: isHome ? 'calc((100% - 6rem) / 4)' : '100%'
      }}
      onClick={handleCardClick}
    >
      <VideoThumbnail thumbnail={webCoverImageUrl} className="event-card-horizontal-thumbnail">
        {status === 'upcoming' && <div className="event-status-purple">Upcoming</div>}
        {status === 'completed' && <div className="event-status-success">Completed</div>}
      </VideoThumbnail>

      <div className="event-card-horizontal-gradient" />
      <div className="event-card-horizontal-info">
        <EventCardInfo
          title={title}
          eventStartTimeInUtc={eventStartTimeInUtc}
          eventDate={eventDate}
          membersPrice={membersPrice}
          duration={duration}
          nonMembersPrice={nonMembersPrice}
          isAuthenticated={authenticated}
          status={status}
        />
      </div>
      {!isHome && (
        <div className="sharing-options" ref={ref} onClick={e => e.stopPropagation()}>
          {optionsStatus === OPENED && (
            <>
              {!isLoggedUser && (
                <button className="report-button" onClick={reportToggle}>
                  Report
                </button>
              )}
              {isLoggedUser && (
                <IconWithBackground
                  icon={<TrashIcon />}
                  onClick={e => {
                    e.stopPropagation();
                    toggle();
                  }}
                  isSmall
                />
              )}
              <IconWithBackground
                icon={<ShareIcon />}
                onClick={e => {
                  e.stopPropagation();
                  setOptionsStatus(ICONS_DISPLAYED);
                }}
                isSmall
              />
              {isLoggedUser && status !== 'completed' && (
                <IconWithBackground
                  icon={<EditIcon />}
                  onClick={e => {
                    e.stopPropagation();
                    handleEditEvent();
                  }}
                  isSmall
                />
              )}
              <IconWithBackground
                icon={<InfoIcon />}
                onClick={e => {
                  e.stopPropagation();
                  eventToggle();
                }}
                isSmall
              />
            </>
          )}
          {optionsStatus === ICONS_DISPLAYED && (
            <SharingBlock
              onClose={e => {
                e.stopPropagation();
                setOptionsStatus(CLOSED);
              }}
              shareUrl={getUrl()}
              videoTitle={title}
              shareText={intl.formatMessage({ id: 'sharing.event' }, { creator: celebrityName })}
            />
          )}
          {(optionsStatus === CLOSED || optionsStatus === OPENED) && (
            <IconWithBackground icon={<OptionsIcon />} onClick={handleOptionsClick} isSmall />
          )}
          {isShowing && (
            <Modal hide={toggle} isShowing={isShowing} topModal zIndex={zIndexModalThird}>
              <DeletePublication
                type="event"
                refresh={refresh}
                celebrityId={celebrityId}
                id={id}
                username={username}
              />
            </Modal>
          )}
          {reportShowing && (
            <Modal
              hide={reportToggle}
              isShowing={reportShowing}
              topModal
              zIndex={zIndexModalThird}
              className="original-modal"
            >
              <ReportModal title="Report Event" type="isEvent" id={id} />
            </Modal>
          )}
        </div>
      )}
      <Modal hide={eventToggle} isShowing={isEventShowing}>
        <EventInfo isAuthenticated={authenticated} event={events} celebrityId={celebrityId} />
      </Modal>
    </div>
  );
};

EventCard.propTypes = {
  celebrityId: number.isRequired,
  celebrityName: string.isRequired,
  isLoggedUser: bool,
  events: object,
  refresh: func,
  username: string,
  isHome: bool
};

export default EventCard;
