import { useRef, useCallback, useEffect, useState } from 'react';
import { throttle } from 'lodash';

export default () => {
  const scrollRef = useRef();
  const [arrowVisibility, setArrowVisibility] = useState({ left: false, right: true });

  useEffect(() => {
    scrollRef.current.onscroll = throttle(() => {
      setArrowVisibility({
        left: scrollRef.current.scrollLeft > 0,
        right:
          scrollRef.current.scrollLeft + scrollRef.current.offsetWidth <
          scrollRef.current.scrollWidth
      });
    }, 200);

    return () => {
      scrollRef.current.onscroll = undefined;
    };
  }, []);

  const scrollRight = useCallback(() => {
    scrollRef.current.scrollBy({
      left: scrollRef.current.offsetWidth * 0.8,
      behavior: 'smooth'
    });
  }, []);

  const scrollLeft = useCallback(() => {
    scrollRef.current.scrollBy({
      left: -scrollRef.current.offsetWidth * 0.8,
      behavior: 'smooth'
    });
  }, []);

  return {
    scrollRight,
    scrollLeft,
    scrollRef,
    showRightArrow: arrowVisibility.right,
    showLeftArrow: arrowVisibility.left
  };
};
