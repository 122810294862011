import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { arrayOf, shape, number } from 'prop-types';
import VisibilitySensor from 'react-visibility-sensor';
import smoothscroll from 'smoothscroll-polyfill';
import { ImageSlider } from 'components/common/slider/imageSlider';
import { narrativeShape, celebrityShape } from 'constants/propTypesShapes';
import { showNarrativeAd } from 'utils/helpers';
import { useNavigation, useIsMobile, useAnalytics } from 'hooks';
import NarrativesPlayer from 'components/Players/VideoJs/NarrativesPlayer';

import { MIXPANEL_EVENTS, MIXPANEL_PARAMS } from 'constants/constants';
import { useChangingUrl, scrollIntoView } from '../hooks';

const NarrativesVertical = ({ narratives, initialIndex, celebrity }) => {
  const { trackEvent } = useAnalytics();
  const [visiblePlayer, setVisiblePlayer] = useState();
  const [selectedNarrative, setSelectedNarrative] = useState(initialIndex);
  const { id: celebrityId } = celebrity;
  const { goBack } = useNavigation();
  const isMobile = useIsMobile();
  const firstEpisodeIndex = useMemo(() => initialIndex, []);
  const filteredNarratives = useMemo(
    () =>
      narratives.filter(
        narrative => narrative.thumbnailsUrls && narrative.thumbnailsUrls.length > 0
      ),
    [narratives]
  );
  const lastNarrativeSelected = visiblePlayer === filteredNarratives.length - 1;
  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  useEffect(() => {
    if (initialIndex !== -1) {
      scrollIntoView(initialIndex, 'auto');
      setVisiblePlayer(initialIndex);
      setSelectedNarrative(initialIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialIndex]);

  useChangingUrl({ narratives: filteredNarratives, visiblePlayer, celebrityId });

  const next = useCallback(() => {
    lastNarrativeSelected ? goBack() : scrollIntoView(visiblePlayer + 1, 'smooth');
  }, [visiblePlayer, lastNarrativeSelected, goBack]);
  const currentNarrative = narratives[selectedNarrative];
  const sortedBanners = [...currentNarrative?.bannerAds]
    .filter(banner => !banner.disable)
    .sort((a, b) => b.id - a.id);
  const latestBanner = sortedBanners[0];
  const allWidgetAdImages = currentNarrative?.widgetAds
    ?.filter(widget => !widget.disable)
    .flatMap(widget =>
      widget.widgetAdImages.map(image => ({
        ...image,
        widgetId: widget.id
      }))
    );
  const hasAds = Boolean(latestBanner?.mobileBannerUrl || allWidgetAdImages.length);
  const handleBannerClick = useCallback(() => {
    if (latestBanner?.actionButtonUrl) {
      trackEvent(MIXPANEL_EVENTS.bannerAdClicked, {
        id: latestBanner.id,
        typeName: MIXPANEL_PARAMS.bannerAd
      });
      window.open(latestBanner.actionButtonUrl, '_blank');
    }
  }, [latestBanner]);

  useEffect(() => {
    if (latestBanner) {
      trackEvent(MIXPANEL_EVENTS.bannerAdViewed, {
        id: latestBanner.id,
        typeName: MIXPANEL_PARAMS.bannerAd
      });
    }
    if (allWidgetAdImages.length) {
      trackEvent(MIXPANEL_EVENTS.widgetAdViewed, {
        id: allWidgetAdImages[0].id,
        typeName: MIXPANEL_PARAMS.widgetAd
      });
    }
  }, []);
  return (
    <div
      className="slider-container"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        overflow: 'hidden'
      }}
    >
      {latestBanner?.mobileBannerUrl && (
        <div
          className="banner-container"
          style={{
            width: '100%',
            padding: '0.5rem 0',
            background: 'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%)',
            zIndex: 10
          }}
        >
          <div
            className="banner-ad"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: latestBanner?.actionButtonUrl ? 'pointer' : 'default'
            }}
            onClick={() => (latestBanner?.actionButtonUrl ? handleBannerClick() : undefined)}
          >
            <img
              src={latestBanner?.mobileBannerUrl}
              alt="banner-ad"
              style={{ maxHeight: '120px', width: '100%', objectFit: 'contain' }}
            />
          </div>
        </div>
      )}
      <div
        className="slider-content"
        style={{
          position: 'relative',
          width: '100%',
          maxWidth: '120rem',
          height: hasAds ? '82vh' : '100vh',
          boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
          borderRadius: '0.5rem',
          overflow: 'hidden'
        }}
      >
        {filteredNarratives.map((narrative, index) => (
          <VisibilitySensor key={index} onChange={visible => visible && setVisiblePlayer(index)}>
            <NarrativesPlayer
              goToNext={next}
              className="slide"
              indexId={`jwplayer-${currentNarrative?.id}`}
              narrative={currentNarrative}
              play={index === visiblePlayer}
              celebrity={celebrity}
              showAds={showNarrativeAd(index - firstEpisodeIndex)}
            />
          </VisibilitySensor>
        ))}
      </div>
      {allWidgetAdImages.length && isMobile ? (
        allWidgetAdImages.length > 5 ? (
          <>
            <ImageSlider
              items={allWidgetAdImages.slice(0, Math.ceil(allWidgetAdImages.length / 2))}
              mobileView
              sliderId="slider1"
            />
            <ImageSlider
              items={allWidgetAdImages.slice(Math.ceil(allWidgetAdImages.length / 2))}
              mobileView
              sliderId="slider2"
            />
          </>
        ) : (
          <ImageSlider items={allWidgetAdImages} mobileView sliderId="slider1" />
        )
      ) : (
        <></>
      )}
    </div>
  );
};

NarrativesVertical.propTypes = {
  narratives: arrayOf(shape(narrativeShape)).isRequired,
  initialIndex: number,
  celebrity: shape(celebrityShape)
};

export default NarrativesVertical;
