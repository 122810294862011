const extractFilename = url => {
  const decodedUrl = decodeURIComponent(url); // Decode URL in case it has encoded characters
  const matches = decodedUrl.match(/\/([^\/?#]+)(?=\?|$)/i);
  return matches && matches[1] ? matches[1] : null;
};

const extractMimeType = url => {
  const extension = url
    .split('.')
    .pop()
    .split(/\#|\?/)[0];
  switch (extension) {
    case 'jpg':
    case 'jpeg':
      return 'image/jpeg';
    case 'png':
      return 'image/png';
    case 'gif':
      return 'image/gif';
    default:
      return null; // Fallback to a default if the extension is unknown
  }
};

const loadImage = url => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous'; // Necessary for cross-origin images
    img.src = url;
    img.onload = () => resolve(img);
    img.onerror = () => reject(new Error('Image load failed'));
  });
};

export const urlToFile = async url => {
  const filename = extractFilename(url) || 'downloaded-image.jpg';
  const mimeType = extractMimeType(url) || 'image/jpeg';

  const image = await loadImage(url);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  // Set canvas dimensions to match the image
  canvas.width = image.width;
  canvas.height = image.height;

  // Draw the image onto the canvas
  ctx.drawImage(image, 0, 0);

  // Convert canvas to Blob
  const blob = await new Promise(resolve => canvas.toBlob(resolve, mimeType));

  // Convert Blob to File
  return new File([blob], filename, { type: mimeType });
};

export const urlToFileVideo = async url => {
  const filename = extractFilename(url) || 'downloaded-video.mp4';
  const mimeType = extractMimeTypeVideo(url) || 'video/mp4';

  // Fetch the video data as a Blob
  const response = await fetch(url);
  const blob = await response.blob();

  // Convert Blob to File
  return new File([blob], filename, { type: mimeType });
};

const extractMimeTypeVideo = url => {
  const extension = url
    .split('.')
    .pop()
    .split(/\#|\?/)[0];
  switch (extension) {
    case 'mp4':
      return 'video/mp4';
    case 'webm':
      return 'video/webm';
    case 'ogg':
      return 'video/ogg';
    // Add more cases as needed
    default:
      return '';
  }
};
