import React from 'react';
import { string, node, func, bool } from 'prop-types';
import cn from 'classnames';
import Badge from 'components/common/Badge/Badge';

const DropdownRow = ({ label, icon, className, onClick, showBadge }) => (
  <div onClick={onClick} className={cn('dropdown-option', className)}>
    {!!icon && <div className="icon">{icon}</div>}
    <h6>{label}</h6>
    {showBadge && <Badge />}
  </div>
);

DropdownRow.propTypes = {
  label: string.isRequired,
  onClick: func.isRequired,
  icon: node,
  className: string,
  showBadge: bool
};

export default DropdownRow;
