/* eslint-disable no-use-before-define */
/* eslint-disable func-names */
import React, { useState, useEffect, useRef } from 'react';
import { useStatus, ERROR } from '@rootstrap/redux-tools';
import { useIntl, defineMessages } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useForm, useValidation, useIsMobile, useToast, useModal, useNavigation } from 'hooks';
import Input from 'components/common/Input/Input';
import Button from 'components/common/Button/Button';
import Select from 'react-select';
import UserService from 'services/userService';
import { createVideoAds } from 'state/actions/videoadsAction';
import VidoeAdService from 'services/videoAds';
import { videoadsValidations } from 'utils/constraints';
import Modal from 'components/common/Modal/Modal';
import parseError from 'utils/parseError';
import Loading from 'components/common/Loading';
import SelectContentModal from 'components/adManagement/SelectContentModal';
import useUploadingVideoAds from 'hooks/videoAds/useUploadingVideoAds';
import { routesPaths } from 'constants/routesPaths';
import axios from 'axios';
import { getTodayDate } from 'utils/date';
import { Line } from 'rc-progress';
import { routeWithProps, handleDateKeyDown, validateVideo } from 'utils/helpers';
import { videoUploadsType } from 'constants/constants';
import { ValidationErrors } from 'utils/validationErrors';
import { customDropDownStyles } from 'styles/customDropDownStyles';
import UpgradeModal from 'components/upgradeModal/UpgradeModal';

const messages = defineMessages({
  title: { id: 'form.title' },
  videoLength: { id: 'form.videoLength' },
  adPlacement: { id: 'form.adPlacement' },
  externalLink: { id: 'form.externalLink' },
  buttonText: { id: 'form.buttonText' },
  adEndDate: { id: 'form.adEndDate' },
  upload: { id: 'btn.upload' },
  selectContent: { id: 'btn.selectContent' },
  publishAds: { id: 'btn.publishAds' },
  saveToAdsList: { id: 'btn.saveToAdsList' },
  edit: { id: 'title.edit' },
  SingleShorts: { id: 'content.shorts' },
  SingleOrignals: { id: 'content.originals' }
});

const FIELDS = {
  title: 'title',
  videoLength: 'videoLength',
  adPlacement: 'adPlacement',
  externalLink: 'externalLink',
  buttonText: 'buttonText',
  adEndDate: 'adEndDate'
};
const requiredFields = [FIELDS.title, FIELDS.videoLength, FIELDS.adPlacement];
const VIDEO_LENGTH_OPTIONS = [
  { value: '15', label: '15 sec' },
  { value: '30', label: '30 sec' },
  { value: '45', label: '45 sec' },
  { value: '60', label: '60 sec' }
];
const AD_PLACEMENT_OPTIONS = [
  { value: 0, label: 'Before Video' },
  { value: 1, label: 'After Video' },
  { value: 2, label: 'At Specific Interval' },
  { value: 3, label: 'At Custom Time' }
];

const getVideoLength = val => {
  switch (val) {
    case 15:
      return { value: '15', label: '15 sec' };
    case 30:
      return { value: '30', label: '30 sec' };
    case 45:
      return { value: '45', label: '45 sec' };
    case 60:
      return { value: '60', label: '60 sec' };
    default:
      return { value: '15', label: '15 sec' };
  }
};

const getAdPlacement = val => {
  switch (val) {
    case 'before_video':
      return { value: 0, label: 'Before Video' };
    case 'after_video':
      return { value: 1, label: 'After Video' };
    case 'custom_intervals':
      return { value: 2, label: 'At Specific Interval' };
    case 'specific_interval':
      return { value: 3, label: 'At Custom Time' };
    default:
      return { value: 0, label: 'Before Video' };
  }
};
const AddVideoAds = ({ id, celId, handlePlacement, contentType, contentid }) => {
  const { goTo } = useNavigation();
  const celebrityId = useRef();
  const { toggle, isShowing } = useModal();
  const { toggle: upgradeToggle, isShowing: upgradeShowing } = useModal();
  const intl = useIntl();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { showErrorToast, showToast } = useToast();
  const { loading, uploadingvideoads } = useUploadingVideoAds();
  const [intervals, setIntervals] = useState([]);
  const [customDuration, setCustomDuration] = useState('');
  const [videoFile, setVideoFile] = useState(null);
  const [selectedContent, setSelectedContent] = useState();
  const [videoLength, setvideoLength] = useState(null);
  const [adPlacement, setadPlacement] = useState(null);
  const [labelSelectedContent, setlabelSelectedContent] = useState('');
  const [processLoading, setProcessLoading] = useState(false);
  const [VideoAdData, setVideoAdData] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [contenteDisable, setcontenteDisable] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  // const [isOverLimit, setIsOverLimit] = useState(false);

  const { status, error } = useStatus(createVideoAds);
  const getProfile = async () => {
    try {
      const { data } = await UserService.getProfile();
      celebrityId.current = data.user.id;
    } catch ({ response }) {
      throw parseError(response);
    }
  };
  const getVideoAdsData = async () => {
    try {
      const { data } = await VidoeAdService.getVideoAdsById(id, celId);
      setVideoAdData(data.videoAd);
    } catch ({ response }) {
      throw parseError(response);
    }
  };
  useEffect(() => {
    getProfile();
    if (handlePlacement) {
      handleCustomAdPlacement();
    }
  }, [handlePlacement, contentType, contentid]);
  useEffect(() => {
    if (id) {
      getVideoAdsData();
    }
  }, [id]);
  const extra = {};
  const validator = useValidation({
    ...videoadsValidations,
    ...extra
  });
  const handleSaveContent = content => {
    setSelectedContent(content);
    toggle(); // Close the modal after saving
    setlabelSelectedContent(
      `${content.series.length} MOVIES & SERIES, ${content.narratives.length} SHORTS SELECTED`
    );
  };
  const handleCustomDurationChange = e => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    setCustomDuration(value);
  };
  const onSubmit = async values => {
    if (values.adPlacement.value === 2 && !customDuration) {
      showErrorToast('Please provide a Specific Interval.');
      return;
    }
    if (values.adPlacement.value === 3) {
      if (intervals.length === 0) {
        showErrorToast('Please provide at least one custom duration interval.');
        return;
      }
      if (intervals.some(interval => !interval)) {
        showErrorToast('All custom duration intervals must be filled out.');
        return;
      }
      const HHMMSSPattern = /^(?:[0-1]?\d|2[0-3]):[0-5]\d:[0-5]\d$/;
      if (intervals.some(interval => !interval || !HHMMSSPattern.test(interval))) {
        showErrorToast('All custom time must be in of format HH:MM:SS.');
        return;
      }
      if (selectedContent === null) {
        showErrorToast('Please select the content');
        return;
      }
    }
    const urlpattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[a-zA-Z0-9#?&%=._-]*)*\/?$/;
    if (values.buttonText && !urlpattern.test(values.externalLink)) {
      showErrorToast('Please specify valid external link');
      return;
    }

    const payload = {
      video_ad: {
        title: values.title,
        button_text: values.buttonText,
        action_button_url: values.externalLink,
        // end_date: values.adEndDate,
        video_length: Number(values.videoLength.value),
        placement_option: values.adPlacement.value
      }
    };
    if (values.adEndDate) {
      Object.assign(payload.video_ad, { end_date: values.adEndDate });
    }
    if (values.adPlacement.value === 3) {
      Object.assign(payload.video_ad, { custom_intervals: intervals });
    }
    if (values.adPlacement.value === 2) {
      Object.assign(payload.video_ad, { specific_interval: customDuration });
    }
    if (selectedContent?.narratives.length > 0) {
      Object.assign(payload.video_ad, { narrative_ids: selectedContent.narratives.map(e => e) });
    }
    if (selectedContent?.series.length > 0) {
      Object.assign(payload.video_ad, { episode_ids: selectedContent.series.map(e => e) });
    }
    setIsSubmitting(true);
    if (id) {
      try {
        // setUploadProgress(50);
        setProcessLoading(true);
        await VidoeAdService.updatevideoAd(celId, id, payload);
        showToast('Video Ad Successfully updated!');
        // setUploadProgress(100);
        setProcessLoading(false);
        goTo(routeWithProps(routesPaths.adManager, { tab: 'videoAds' }));
        setIsSubmitting(false);
      } catch (error) {
        setIsSubmitting(false);
        showErrorToast('Something went wrong!');
      }
    } else {
      try {
        await dispatch(createVideoAds(celebrityId.current, payload));
        setUploadProgress(1);
      } catch (error) {
        showErrorToast('Something went wrong!');
      }
    }
  };

  useEffect(() => {
    if (status === ERROR) {
      if (error === 'You have already uploaded maximum number of ads.') {
        setUploadProgress(0);
        upgradeToggle();
        setIsSubmitting(false);
      }
      dispatch(createVideoAds.reset());
    }
  }, [status, dispatch, error]);

  const { values, errors, handleValueChange, handleSubmit, handleBlur, setValues } = useForm(
    {
      onSubmit,
      validator,
      validateOnBlur: true,
      validateOnChange: true
    },
    [onSubmit]
  );
  const uploadFile = async url => {
    try {
      const totalSize = videoFile.size;
      await axios.put(url, videoFile, {
        headers: {
          'Content-Type': videoFile.type
        },
        onUploadProgress: progressEvent => {
          if (progressEvent.lengthComputable) {
            const uploadedSize = progressEvent.loaded;
            const progress = Math.round((uploadedSize / totalSize) * 100);
            setUploadProgress(progress);
          }
        }
      });
      setProcessLoading(false);
      setUploadProgress(100);
      showToast('Video Ad Added Successfully');
      setIsSubmitting(false);
      if (contentType) {
        window.history.back();
      } else {
        goTo(routeWithProps(routesPaths.adManager, { tab: 'videoAds' }));
      }
    } catch (error) {
      setUploadProgress(0);
      setProcessLoading(false);
      setIsSubmitting(false);
      // showErrorToast('Something went wrong, try again please!');
    }
  };
  useEffect(() => {
    if (VideoAdData && id) {
      setadPlacement(getAdPlacement(VideoAdData?.placementOption));
      setvideoLength(getVideoLength(VideoAdData?.videoLength));
      setlabelSelectedContent(
        `${VideoAdData.episodes.length} MOVIES & SERIES, ${VideoAdData.narratives.length} SHORTS SELECTED`
      );
      const episodeIds = VideoAdData.episodes.map(item => item.episodeId);
      const narrativeIds = VideoAdData.narratives.map(item => item.id);
      setSelectedContent({
        narratives: narrativeIds,
        series: episodeIds
      });
      setValues({
        ...VideoAdData,
        [FIELDS.externalLink]: VideoAdData.actionButtonUrl,
        [FIELDS.adEndDate]: VideoAdData.endDate
          ? new Date(VideoAdData.endDate).toISOString().split('T')[0]
          : null,
        [FIELDS.adPlacement]: getAdPlacement(VideoAdData.placementOption),
        [FIELDS.videoLength]: getVideoLength(VideoAdData.videoLength)
      });
      if (VideoAdData.placementOption === 'custom_intervals') {
        setCustomDuration(VideoAdData.specificInterval);
      }
      if (VideoAdData.placementOption === 'specific_interval') {
        setIntervals(VideoAdData.customIntervals);
      }
    }
  }, [VideoAdData]);
  useEffect(() => {
    if (uploadingvideoads.uploadingvideoads?.uploadUrl) {
      dispatch(createVideoAds.reset());
      setProcessLoading(true);
      uploadFile(uploadingvideoads.uploadingvideoads?.uploadUrl);
    }
  }, [uploadingvideoads.uploadingvideoads?.uploadUrl, dispatch]);

  const handlevideolengthChange = selectedOptions => {
    setvideoLength(selectedOptions);
    handleValueChange(FIELDS.videoLength, selectedOptions);
  };

  const handleadPlacementChange = selectedOptions => {
    setadPlacement(selectedOptions);
    handleValueChange(FIELDS.adPlacement, selectedOptions);
    if (selectedOptions.value !== 'specific_interval') {
      setCustomDuration(''); // custom_duration
    }
    if (selectedOptions.value === 'custom_duration') {
      setIntervals(['']);
    } else {
      setIntervals([]);
    }
  };
  const handleCustomAdPlacement = () => {
    handleadPlacementChange(getAdPlacement(handlePlacement));
    // setlabelSelectedContent(
    //   `${VideoAdData.episodes.length} ORIGINALS, ${VideoAdData.narratives.length} NARRATIVE SELECTED`
    // );
    // const episodeIds = VideoAdData.episodes.map(item => item.id);
    // const narrativeIds = VideoAdData.narratives.map(item => item.id);
    if (contentType === 'narrative') {
      setSelectedContent({
        narratives: [contentid],
        series: []
      });
      setlabelSelectedContent(messages.SingleShorts.id);
      setcontenteDisable(true);
    } else if (contentType === 'seriesid') {
      setSelectedContent({
        narratives: [],
        series: [contentid]
      });
      setlabelSelectedContent(messages.SingleOrignals.id);
      setcontenteDisable(true);
    }
  };
  function allUnique(array) {
    return array.every(value => array.indexOf(value) === array.lastIndexOf(value));
  }
  const addInterval = () => {
    if (allUnique(intervals)) {
      setIntervals([...intervals, '']);
    } else {
      showErrorToast('Only unique intervals are allowed.');
    }
  };

  const removeInterval = index => {
    const newIntervals = intervals.filter((_, i) => i !== index);
    setIntervals(newIntervals);
  };

  const handleIntervalChange = (index, value) => {
    let time = value.replace(/[^0-9]/g, '');
    if (time.length > 2) {
      time = `${time.slice(0, 2)}:${time.slice(2)}`;
    }
    if (time.length > 5) {
      time = `${time.slice(0, 5)}:${time.slice(5, 7)}`;
    }
    const newIntervals = [...intervals];
    newIntervals[index] = time;
    setIntervals(newIntervals);
  };
  const handleVideoUpload = async event => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    if (file && file.type.startsWith('video/')) {
      const {
        isValid,
        isResolutionValid,
        isAspectRatioValid,
        videoInfo,
        error
      } = await validateVideo(file, videoUploadsType.Common);

      if (error) {
        showErrorToast(ValidationErrors.loadingVideo);
        setVideoFile(null);
      } else if (!isResolutionValid) {
        showErrorToast(ValidationErrors.resolutionValidation);
        setVideoFile(null);
      } else if (!isAspectRatioValid) {
        showErrorToast(ValidationErrors.aspectRatioValidationForCommon);
        setVideoFile(null);
      } else if (videoInfo.duration > 60) {
        showErrorToast(ValidationErrors.durationValidation);
        setVideoFile(null);
      } else if (isValid) {
        setVideoFile(file);
      }
    } else {
      showErrorToast('Please upload a valid video file.');
    }
  };

  const isFormValid = () => {
    const baseFieldsValid = requiredFields.every(field => values[field]);
    const buttonTextValid =
      !values[FIELDS.externalLink] ||
      (values[FIELDS.externalLink] &&
        values[FIELDS.buttonText] &&
        values[FIELDS.buttonText].trim() !== '');
    return baseFieldsValid && buttonTextValid;
  };

  // const hasValidContent =
  // selectedContent &&
  // (selectedContent.narratives.length > 0 || selectedContent.series.length > 0);

  // const hasMissingFields = !isFormValid() || !videoFile;
  // const hasInvalidContent = !hasValidContent;
  
  // const isDisabled = id ? hasInvalidContent : hasMissingFields || hasInvalidContent;

  const isDisabled = id
  ? !isFormValid() ||
    !(selectedContent.narratives.length > 0 || selectedContent.series.length > 0)
  : !isFormValid() ||
    !videoFile ||
    !selectedContent ||
    (selectedContent &&
      !(selectedContent.narratives.length > 0 || selectedContent.series.length > 0));
  

  const labelId = id
  ? messages.edit.id
  : isMobile
  ? messages.saveToAdsList.id
  : messages.publishAds.id;

  const renderUploadSection = () => {
    if (videoFile) {
      return (
        <div className="white-label">
          <span className="uploaded-name">{videoFile?.name}</span>
          <span
            className="white-name"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (!isSubmitting) {
                setVideoFile(null);
              }
            }}
          >
            x
          </span>
        </div>
      );
    }
  
    return (
      <div className="button-margin">
        <Button
          labelId={messages.upload.id}
          type="secondary"
          size="large"
          className="profile-button"
          onClick={() => document.getElementById('video-upload-input').click()}
          disabled={isSubmitting}
        />
        <span className="label-margin">
          Recommended dimension: 1280px X 720px (1:1, 4:5, 9:16, 16:9)
        </span>
      </div>
    );
  };

  return (
    <div className="creator-form">
      <div className="creator-form-fields">
        <Input
          name="title"
          label={intl.formatMessage(messages.title)}
          placeholder={intl.formatMessage(messages.title)}
          value={values[FIELDS.title]}
          onChange={e => handleValueChange(FIELDS.title, e.target.value)}
          onBlur={() => handleBlur(FIELDS.title)}
          errors={errors[FIELDS.title]}
          disabled={isSubmitting}
        />
        <div className="categories">
          <span className="custom-label">{intl.formatMessage(messages.videoLength)}</span>
          <div className="custom-select">
            <Select
              className="basic-single"
              styles={customDropDownStyles}
              classNamePrefix="select"
              closeMenuOnSelect
              value={videoLength}
              options={VIDEO_LENGTH_OPTIONS}
              onChange={handlevideolengthChange}
              isSearchable={false}
              placeholder="Select"
              isDisabled={isSubmitting}
            />
          </div>
        </div>
        <div className="categories">
          <span className="custom-label">{intl.formatMessage(messages.adPlacement)}</span>
          <div className="custom-select">
            <Select
              className="basic-single"
              styles={customDropDownStyles}
              classNamePrefix="select"
              closeMenuOnSelect
              value={adPlacement}
              options={AD_PLACEMENT_OPTIONS}
              onChange={handleadPlacementChange}
              isSearchable={false}
              placeholder="Select"
              isDisabled={contentType ? contenteDisable : isSubmitting}
            />
          </div>
        </div>
        {adPlacement && adPlacement.value === 2 && (
          <Input
            name="customDuration"
            label="Specific Interval"
            placeholder="Specific Interval"
            value={customDuration}
            onChange={handleCustomDurationChange}
            disabled={isSubmitting}
          />
        )}
        {adPlacement && adPlacement.value === 3 && (
          <div className="custom-durations">
            {intervals.map((interval, index) => (
              <div key={index} className="duration-input-wrapper">
                <Input
                  name={`interval-${index}`}
                  label="Time"
                  placeholder="HH:MM:SS"
                  value={interval}
                  onChange={e => handleIntervalChange(index, e.target.value)}
                  disabled={isSubmitting}
                />
                <button
                  type="button"
                  onClick={() => removeInterval(index)}
                  className="remove-interval-btn"
                  disabled={isSubmitting}
                >
                  ✕
                </button>
              </div>
            ))}

            <Button
              type="secondary"
              size="small"
              onClick={addInterval}
              className="add-interval-btn"
              labelId="+ Add"
              disabled={isSubmitting}
            />
          </div>
        )}
        <Input
          name="externalLink"
          label={intl.formatMessage(messages.externalLink)}
          placeholder="http://"
          value={values[FIELDS.externalLink]}
          onChange={e => {
            handleValueChange(FIELDS.externalLink, e.target.value);
            if (e.target.value) {
              Object.assign(extra, {
                buttonText: {
                  presence: {
                    allowEmpty: true,
                    message: '^Button text is required when an external link is provided',
                    if(value, attributes) {
                      return attributes.externalLink && attributes.externalLink.length > 0;
                    }
                  }
                }
              });
              handleBlur(FIELDS.buttonText);
            }
          }}
          onBlur={() => handleBlur(FIELDS.externalLink)}
          errors={errors[FIELDS.externalLink]}
          disabled={isSubmitting}
        />
        {values[FIELDS.externalLink] && (
          <Input
            name="buttonText"
            label={intl.formatMessage(messages.buttonText)}
            placeholder="Enter a button text"
            value={values[FIELDS.buttonText]}
            onChange={e => {
              handleValueChange(FIELDS.buttonText, e.target.value);
            }}
            onBlur={() => handleBlur(FIELDS.buttonText)}
            errors={errors[FIELDS.buttonText]}
            disabled={isSubmitting}
          />
        )}
        <div className="date-card">
          <Input
            name="adEndDate"
            label={intl.formatMessage(messages.adEndDate)}
            type="date"
            placeholder="DD/MM/YY"
            value={values[FIELDS.adEndDate]}
            onChange={e => handleValueChange(FIELDS.adEndDate, e.target.value)}
            onKeyDown={handleDateKeyDown}
            min={getTodayDate()}
            disabled={isSubmitting}
          />
        </div>
        {id ? (
          <></>
        ) : (
          <div className="video-upload">
            <span className="custom-label">VIDEO AD</span>
            <input
              type="file"
              accept="video/*"
              onChange={handleVideoUpload}
              style={{ display: 'none' }}
              id="video-upload-input"
            />
            {renderUploadSection()}
          </div>
        )}
        <div>
          <Button
            type="secondary"
            size="large"
            className="video-ad-button"
            labelId={labelSelectedContent || messages.selectContent.id}
            onClick={toggle}
            disabled={contentType ? contenteDisable : isSubmitting}
          />
        </div>
        <Button
          type="primary"
          size="large"
          className="profile-button"
          labelId={
            // eslint-disable-next-line no-nested-ternary
            labelId
          }
          onClick={handleSubmit}
          disabled={isDisabled || isSubmitting}
        >
          {(loading || processLoading) && (
            <div style={{ marginTop: '-15px' }}>
              <Loading type="ball-clip-rotate" />
            </div>
          )}
        </Button>
        <Modal hide={toggle} isShowing={isShowing} className="series-form-modal">
          <SelectContentModal
            content={selectedContent}
            handleClose={toggle}
            onSaveContent={handleSaveContent}
          />
        </Modal>
        <Modal hide={upgradeToggle} isShowing={upgradeShowing} className="series-form-modal">
          <UpgradeModal hide={upgradeToggle} />
        </Modal>
      </div>
      <div>
        {uploadProgress > 0 && (
          <div style={{ marginTop: '10px' }}>
            <div style={{ textAlign: 'center', marginTop: '5px', color: 'white' }}>
              Finishing Upload
            </div>
            <Line percent={uploadProgress} strokeWidth={1} strokeColor="#2400FC" />
            <div style={{ textAlign: 'center', marginTop: '15px', color: 'white' }}>
              {uploadProgress}% Complete
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddVideoAds;
