import React from 'react';
import { number, string } from 'prop-types';

import { white } from 'styles/common/_constants.scss';

const CopyIcon = ({ width = 20, height = 20, color = white }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="logoTitle logoDesc"
    role="img"
  >
    <title id="logoTitle">Copy</title>
    <desc id="logoDesc">Copy icon</desc>
    <path
      d="M16.6667 7.5H9.16667C8.24619 7.5 7.5 8.24619 7.5 9.16667V16.6667C7.5 17.5871 8.24619 18.3333 9.16667 18.3333H16.6667C17.5871 18.3333 18.3333 17.5871 18.3333 16.6667V9.16667C18.3333 8.24619 17.5871 7.5 16.6667 7.5Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.1665 12.5H3.33317C2.89114 12.5 2.46722 12.3244 2.15466 12.0118C1.8421 11.6993 1.6665 11.2754 1.6665 10.8333V3.33332C1.6665 2.8913 1.8421 2.46737 2.15466 2.15481C2.46722 1.84225 2.89114 1.66666 3.33317 1.66666H10.8332C11.2752 1.66666 11.6991 1.84225 12.0117 2.15481C12.3242 2.46737 12.4998 2.8913 12.4998 3.33332V4.16666"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

CopyIcon.propTypes = {
  width: number,
  height: number,
  color: string
};

export default CopyIcon;
