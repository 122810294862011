import React from 'react';
import Dropdown from 'components/header/Dropdown/Dropdown';
import DropdownRow from 'components/header/Dropdown/DropdownRow';

const DateDropdown = ({ handleCloseDropDown, handleFilterCall }) => {
  const routes = [
    {
      label: 'Popularity',
      key: 'popularity'
    },
    {
      label: 'Trending',
      key: 'trending'
    },
    {
      label: 'Date',
      key: 'date'
    }
  ];

  return (
    <>
      <Dropdown className="navbar-dropdown date-dropdown">
        {routes.map(({ label, key }) => (
          <DropdownRow
            label={label}
            key={key}
            onClick={() => {
              handleCloseDropDown();
              handleFilterCall(key);
            }}
          />
        ))}
      </Dropdown>
    </>
  );
};

export default DateDropdown;
