import React from 'react';
import { number, string } from 'prop-types';

import { white } from 'styles/common/_constants.scss';

const LineDoubleIcon = ({ width = 20, height = 20, color = white }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 17"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path d="M17 6v1h-17v-1h17zM0 10h17v-1h-17v1z" fill={color} />
    </svg>
  );
};
LineDoubleIcon.propTypes = {
  width: number,
  height: number,
  color: string
};
export default LineDoubleIcon;
