import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useStatus, SUCCESS } from '@rootstrap/redux-tools';
import { useIntl } from 'react-intl';

import { useAnalytics, useToast } from 'hooks';
import { upgradeToCreator } from 'state/actions/userActions';
import capitalize from 'lodash/capitalize';
import { routesPaths } from 'constants/routesPaths';
import { MIXPANEL_EVENTS, MIXPANEL_PARAMS } from 'constants/constants';

export default () => {
  const dispatch = useDispatch();
  const { trackEvent } = useAnalytics();
  const history = useHistory();
  const intl = useIntl();
  const { status, error } = useStatus(upgradeToCreator);
  const { showToast } = useToast();
  const [username, setUsername] = useState('');

  const onSubmit = useCallback(
    user => {
      const formData = new FormData();
      formData.append('user[profile_image]', user.profile_image);
      formData.append('user[banner]', user.banner);
      formData.append('user[username]', user.username);
      formData.append('user[bio]', user.bio ? user.bio : '');
      formData.append('user[price]', Number(user.membershipPrice).toFixed(2));
      setUsername(user.username);
      dispatch(upgradeToCreator(formData));
    },
    [dispatch]
  );

  useEffect(() => {
    if (status === SUCCESS) {
      trackEvent(MIXPANEL_EVENTS.becomeCreator, {
        [MIXPANEL_PARAMS.creatorName]: username
      });
      showToast(intl.formatMessage({ id: 'form.creator.success' }));
      dispatch(upgradeToCreator.reset());
      history.push(routesPaths.home);
    }
  }, [status, dispatch, history, intl, showToast]);

  return {
    onSubmit,
    status,
    error: capitalize(error)
  };
};
