import React, { useMemo } from 'react';
import { arrayOf, shape, func, string } from 'prop-types';
import { Link } from 'react-router-dom';
import take from 'lodash/take';
import cn from 'classnames';
import { CSSTransitionGroup } from 'react-transition-group';

import { useIsMobile, useClickOutside, useToggle } from 'hooks';
import { routeWithProps } from 'utils/helpers';
import { routesPaths } from 'constants/routesPaths';
import { celebrityInfoShape } from 'constants/propTypesShapes';

import CelebrityAvatar from 'components/home/CelebrityAvatar/CelebrityAvatar';
import FeaturedCelebrities from 'components/celebrity/FeaturedCelebrities/FeaturedCelebrities';
import { CrossIcon } from 'components/icons';

import { maxMobileLandscape, white } from 'styles/common/_constants.scss';

const VIEWABLE_CELEBRITIES = 2;

const MultipleCelebrityAvatar = ({ celebrities, onAvatarClick, className }) => {
  const [celebritiesList, toggleCelebritiesList] = useToggle(false);
  const ref = useClickOutside(toggleCelebritiesList, celebritiesList);

  const isMobile = useIsMobile(maxMobileLandscape);
  const smallAvatars = useMemo(() => take(celebrities, VIEWABLE_CELEBRITIES), [celebrities]);

  return (
    <>
      <div className={cn('avatars-container collapsed', className)}>
        {smallAvatars.map(({ id, profileImageUrl, username }, index) => (
          <Link
            key={id}
            className={cn('multiple-avatar-button', { collapsed: index > 0 })}
            to={routeWithProps(routesPaths.celebrityId, { username: username.toLowerCase() })}
            onClick={onAvatarClick}
          >
            <CelebrityAvatar
              backgroundImage={profileImageUrl}
              size={isMobile ? 'small' : 'medium'}
            />
          </Link>
        ))}
        {celebrities.length > VIEWABLE_CELEBRITIES && (
          <div onClick={toggleCelebritiesList}>
            <h4 className={cn('bold more-celebrities collapsed', { small: isMobile })}>
              +{celebrities.length - VIEWABLE_CELEBRITIES}
            </h4>
          </div>
        )}
      </div>
      <CSSTransitionGroup
        transitionName="celebrities-transition"
        transitionEnterTimeout={500}
        transitionLeaveTimeout={500}
      >
        {celebritiesList && (
          <div ref={ref} className="celebrities-list">
            <div className="cross" onClick={toggleCelebritiesList}>
              <CrossIcon width={20} height={20} color={white} />
            </div>
            <FeaturedCelebrities
              celebrities={celebrities}
              titleId="general.creators"
              size="medium"
              smallTitle
              showArrows={false}
            />
          </div>
        )}
      </CSSTransitionGroup>
    </>
  );
};

MultipleCelebrityAvatar.propTypes = {
  celebrities: arrayOf(shape(celebrityInfoShape)).isRequired,
  onAvatarClick: func,
  className: string
};

export default MultipleCelebrityAvatar;
