import React, { memo } from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { LOADING } from '@rootstrap/redux-tools';
import some from 'lodash/some';
import { func } from 'prop-types';

import { useForm, useValidation, usePhoneInputProps, useTextInputProps } from 'hooks';
import useSignUp from 'hooks/users/useSignUp';
import { signup as signUpValidations } from 'utils/constraints';
import { includeAllFields, routeWithProps } from 'utils/helpers';

import Loading from 'components/common/Loading';
import CountryPhoneInput from 'components/common/CountryPhoneInput/CountryPhoneInput';
import Button from 'components/common/Button/Button';
import Input from 'components/common/Input/Input';
import { routesPaths } from 'constants/routesPaths';
import { legalTabs } from 'constants/constants';
import Hyperlink from 'components/common/Hyperlink/Hyperlink';

const messages = defineMessages({
  phone: { id: 'form.phoneNumber' },
  phonePlaceholder: { id: 'form.phoneNumber.placeholder' },
  name: { id: 'form.name' },
  namePlaceholder: { id: 'form.name.placeholder' },
  lastName: { id: 'form.lastName' },
  lastNamePlaceholder: { id: 'form.lastName.placeholder' },
  email: { id: 'form.email' },
  emailPlaceholder: { id: 'form.email.placeholder' }
});

const fields = {
  firstName: 'firstName',
  lastName: 'lastName',
  email: 'email',
  phone: 'phone'
};

export const SignUpForm = ({ redirectVerifyPhone }) => {
  const intl = useIntl();
  const { onSubmit, status, error } = useSignUp(redirectVerifyPhone);

  const validator = useValidation(signUpValidations);

  const { values, errors, handleValueChange, handleSubmit, handleBlur, touched } = useForm(
    {
      onSubmit,
      validator,
      validateOnBlur: true,
      validateOnChange: true
    },
    [onSubmit]
  );

  const inputProps = useTextInputProps(handleValueChange, handleBlur, values);
  const phoneInputProps = usePhoneInputProps(handleValueChange, handleBlur, values);

  return (
    <div className="sign-up-form">
      <div className="sign-up-form-fields">
        <Input
          name="firstName"
          placeholder={intl.formatMessage(messages.namePlaceholder)}
          label={intl.formatMessage(messages.name)}
          errors={errors[fields.firstName]}
          {...inputProps(fields.firstName)}
        />
        <Input
          name="lastName"
          placeholder={intl.formatMessage(messages.lastNamePlaceholder)}
          label={intl.formatMessage(messages.lastName)}
          errors={errors[fields.lastName]}
          {...inputProps(fields.lastName)}
        />
        <Input
          name="email"
          placeholder={intl.formatMessage(messages.emailPlaceholder)}
          label={intl.formatMessage(messages.email)}
          errors={errors[fields.email]}
          {...inputProps(fields.email)}
        />
        <CountryPhoneInput
          name="phone"
          placeholder={intl.formatMessage(messages.phonePlaceholder)}
          label={intl.formatMessage(messages.phone)}
          errors={errors[fields.phone]}
          {...phoneInputProps(fields.phone)}
        />
      </div>
      <div className="sign-up-form-error p1">{error}</div>
      <div className="p2 legal-text">
        {intl.formatMessage({ id: 'legalMessages.signUp' })}
        <Hyperlink
          text={intl.formatMessage({ id: 'terms.title' })}
          link={routeWithProps(routesPaths.legal, { tab: legalTabs.TERMS.tab })}
        />
        {intl.formatMessage({ id: 'legalMessages.signUp2' })}
        <Hyperlink
          text={intl.formatMessage({ id: 'privacyPolicies.name' })}
          link={routeWithProps(routesPaths.legal, { tab: legalTabs.PRIVACY.tab })}
        />
      </div>
      <Button
        labelId="form.signup.submit"
        className="submit-button"
        onClick={handleSubmit}
        disabled={status === LOADING || some(errors) || !includeAllFields(values, fields, touched)}
      />
      {status === LOADING && <Loading type="ball-clip-rotate" />}
    </div>
  );
};

SignUpForm.propTypes = {
  redirectVerifyPhone: func
};

export default memo(SignUpForm);
