import React from 'react';
import { number, string } from 'prop-types';

import { black40 } from 'styles/common/_constants.scss';

const CrossIcon = ({ width = 20, height = 20, color = black40 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="logoTitle logoDesc"
    role="img"
  >
    <title id="logoTitle">Cross</title>
    <desc id="logoDesc">Cross icon</desc>
    <path
      d="M15 5L5 15"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 5L15 15"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

CrossIcon.propTypes = {
  width: number,
  height: number,
  color: string
};

export default CrossIcon;
