import { useDispatch, useSelector } from 'react-redux';
import { useStatus } from '@rootstrap/redux-tools';

import { getScheduledLiveEvent, purchaseScheduledLiveEvent } from 'state/actions/liveEventsActions';

export default () => {
  const dispatch = useDispatch();
  const { status } = useStatus(getScheduledLiveEvent);

  const getScheduled = (id, isLive) => {
    dispatch(getScheduledLiveEvent(id, isLive));
  };

  const getScheduledReset = () => {
    dispatch(getScheduledLiveEvent.reset());
  };

  const purchaseScheduledLive = (cardId, liveId) => {
    dispatch(purchaseScheduledLiveEvent(cardId, liveId));
  };

  return {
    getScheduled,
    getScheduledStatus: status,
    scheduledLive: useSelector(({ liveEvents: { scheduledLive } }) => scheduledLive),
    purchaseScheduledLive,
    getScheduledReset
  };
};
