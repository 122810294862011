import httpClient from 'httpClient';

class SearchService {
  autoComplete(search) {
    return httpClient.get(`/autocomplete?search=${search}`);
  }

  search(search, contentType, dateFilter, categoryId) {
    return httpClient.get(
      `search?search=${search}&content_type=${contentType}&sort=${dateFilter}&category_id=${categoryId}`
    );
  }
}

export default new SearchService();
