/* eslint-disable no-restricted-globals */
import React, { useMemo, useState } from 'react';
import cn from 'classnames';
import { useParams } from 'react-router-dom';
import SearchHeader from 'components/search/SearchHeader';
import Pill from 'components/common/Pills/Pill';
import { searchPills } from 'constants/constants';
import { getIdFromTab } from 'utils/dataHelpers';
import useSearch from 'hooks/search/useSearch';

const { ALL } = searchPills;

const SearchPage = () => {
  const { tab } = useParams();
  const searchParams = new URLSearchParams(location.search);
  const searchTerm = searchParams.get('search') || '';
  const [dateFilter, setDateFilter] = useState(searchParams.get('sort') || '');
  const [categoryFilter, setCategoryFilter] = useState(searchParams.get('category_id') || '');
  const selectedTabId = useMemo(() => tab && getIdFromTab(searchPills, tab), [tab]);
  const [selectedTab, setSelectedTab] = useState(selectedTabId || ALL.id);

  const { renderSearchContent, changeUrl } = useSearch();
  const onTabChange = id => {
    changeUrl(id, undefined, undefined, undefined);
    setSelectedTab(id);
  };

  const handleFilterCall = value => {
    changeUrl(null, undefined, value, undefined);
    setDateFilter(value);
  };

  const handleCategoryFilterCall = value => {
    changeUrl(null, undefined, undefined, value);
    setCategoryFilter(value);
  };
  return (
    <div className={cn('search-page')}>
      <div className="search-page-content">
        <SearchHeader
          value={searchTerm}
          handleFilterCall={handleFilterCall}
          handleCategoryFilterCall={handleCategoryFilterCall}
        />
        <div className="custom-search-pills">
          <Pill
            tabs={Object.values(searchPills)}
            selectedTab={selectedTab}
            onTabSelect={onTabChange}
            className="search-pills"
          />
        </div>
        <div>{renderSearchContent(selectedTab, dateFilter, categoryFilter)}</div>
      </div>
    </div>
  );
};

export default SearchPage;
