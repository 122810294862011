import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { shape, func } from 'prop-types';

import { useClickOutside } from 'hooks';

import Close from 'components/common/Close/Close';
import { contentShape } from 'constants/propTypesShapes';
import BackgroundOpacity from 'components/common/BackgroundOpacity/BackgroundOpacity';
import Button from 'components/common/Button/Button';
import { PayPerViewIcon } from 'components/icons';
import { renderImage } from 'utils/helpers';

const labels = defineMessages({
  subtitle: { id: 'payPerView.purchaseSubtitle' },
  button: { id: 'payPerView.buyNow' },
  message: { id: 'payPerView.message' },
  secondMessage: { id: 'payPerView.secondMessage' }
});

const PayPerViewDetails = ({ event, togglePayments, hide }) => {
  const intl = useIntl();
  const ref = useClickOutside(hide);

  const { desktopImageUrl, mobileImageUrl, logoImageUrl, title, price } = event;

  return (
    <div className="pay-per-view-details" ref={ref}>
      <div className="pay-per-view-details-header">
        <BackgroundOpacity
          background={renderImage(desktopImageUrl, mobileImageUrl)}
          opacity={0.5}
        />
        <div className="opacity" />
        <Close onClick={hide} />
        <img src={logoImageUrl} alt={title} className="logo" />
      </div>
      <div className="pay-per-view-details-info">
        <div className="row">
          <div>
            <h4 className="semibold">{title}</h4>
            <div className="p1">{intl.formatMessage(labels.subtitle)}</div>
          </div>
          <Button
            title={intl.formatMessage(labels.button, { price })}
            type="primary"
            icon={<PayPerViewIcon />}
            onClick={togglePayments}
          />
        </div>
        <div className="message p2">
          <div>{intl.formatMessage(labels.message)}</div>
          <div>{intl.formatMessage(labels.secondMessage)}</div>
        </div>
      </div>
    </div>
  );
};

PayPerViewDetails.propTypes = {
  event: shape(contentShape).isRequired,
  togglePayments: func.isRequired,
  hide: func.isRequired
};

export default PayPerViewDetails;
