import React, { useEffect, useState } from 'react';
import TrashIcon from 'assets/trash.png';
import cn from 'classnames';
import { useNavigation, useToast, useModal } from 'hooks';
import { routeWithProps } from 'utils/helpers';
import { routesPaths } from 'constants/routesPaths';
import AdService from 'services/adService';
import { useParams } from 'react-router-dom';
import { zIndexModalThird } from 'styles/common/_constants.scss';
import Modal from 'components/common/Modal/Modal';
import UploadIcon from '../../assets/plus.png';
import sample from '../../assets/samplevideo.png';
import CommonSwitch from '../common/Switch/Switch';
import DeleteAd from './DeleteAd/DeleteAd';

const ManageWidgetAds = ({ WidgetAds }) => {
  const { narrativeId, celebrityId, episodeId } = useParams();
  const { toggle, isShowing } = useModal();
  const { goTo } = useNavigation();
  useEffect(() => {}, [WidgetAds]);
  const [adStates, setAdStates] = useState({
    before_widget: false
  });
  const [WidgetData, setWidgetData] = useState(null);
  useEffect(() => {
    if (WidgetAds) {
      setAdStates(prevState => ({
        ...prevState,
        before_widget: !WidgetAds[0]?.disable
      }));
      setWidgetData(WidgetAds[0]);
    }
  }, [WidgetAds]);
  const [currentId, setCurrentId] = useState();
  const [disSwitch, setdisSwitch] = useState(false);
  const { showErrorToast, showToast } = useToast();
  const handleApiCall = () => {
    window.location.reload();
  };
  const handleSwitchChange = async (nextChecked, adType, adData) => {
    setdisSwitch(true);
    setAdStates(prevState => ({
      ...prevState,
      [adType]: nextChecked
    }));
    if (narrativeId) {
      try {
        const { data } = await AdService.disableNarrativewidgetAd(
          celebrityId,
          narrativeId,
          adData.id
        );
        setdisSwitch(false);
        showToast(data.message);
        window.location.reload();
      } catch ({ response }) {
        setdisSwitch(false);
        showErrorToast('Something went wrong!');
      }
    } else {
      try {
        const { data } = await AdService.disableSerieswidgetAd(celebrityId, episodeId, adData.id);
        showToast(data.message);
        setdisSwitch(false);
        window.location.reload();
      } catch ({ response }) {
        showErrorToast('Something went wrong!');
        setdisSwitch(false);
      }
    }
  };
  const handleClick = () => {
    goTo(
      routeWithProps(routesPaths.individualaddwidgetAds, {
        type: narrativeId ? 'narrative' : 'seriesid',
        id: narrativeId || episodeId
      })
    );
  };
  return (
    <div>
      {WidgetData != null ? (
        <>
          <div className="banner-title-container">
            <div className="details-manages" style={{ marginTop: 'auto', marginBottom: 'auto' }}>
              <span className="custom-title ">{WidgetData.title}</span>
            </div>
            <div className="control-manages">
              <button
                type="button"
                style={{ cursor: 'pointer', marginRight: '10px' }}
                className="action-button"
                onClick={() => {
                  setCurrentId(WidgetData.id);
                  toggle();
                }}
              >
                <img height="20px" width="20px" src={TrashIcon} alt="trash" />
              </button>
              <CommonSwitch
                checked={adStates.before_widget}
                onColor="#2400FC"
                checkedIcon={false}
                uncheckedIcon={false}
                disabled={disSwitch}
                onChange={nextChecked =>
                  handleSwitchChange(nextChecked, 'before_widget', WidgetData)
                }
              />
            </div>
          </div>
          <div className="loadVideoAds">
            <div className="video-info">
              <div>
                {WidgetData?.widgetAdImages?.map(image => {
                  return (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <img
                        className="Videothumbnail"
                        style={{ marginTop: '5px', marginBottom: '5px' }}
                        src={image.widgetAdImageUrl || sample}
                        alt="thumbnail"
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div>
            <div className={cn('button-container')}>
              <button
                type="button"
                className={cn('upload-button')}
                onClick={() => {
                  handleClick();
                }}
              >
                <img
                  height="20x"
                  width="20px"
                  className={cn('upload-icon')}
                  src={UploadIcon}
                  alt="upload"
                />{' '}
                UPLOAD WIDGET
              </button>
            </div>
          </div>
        </>
      )}
      <Modal hide={toggle} isShowing={isShowing} topModal zIndex={zIndexModalThird}>
        <DeleteAd
          handleApiCall={handleApiCall}
          celebrityId={celebrityId}
          currentId={currentId}
          id={narrativeId || episodeId}
          type={narrativeId ? 'Shorts' : 'Movies & Series'}
          adtype="widget"
        />
      </Modal>
    </div>
  );
};

export default ManageWidgetAds;
