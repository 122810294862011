import React from 'react';

import { contentTabs, videoTypes } from 'constants/constants';
import Content from 'components/adManagement/Content';

export default () => {
  const renderVideoSelectContent = (tab, isModal = false, onSaveContent, hide, content) => {
    const { ORIGINALS, NARRATIVES } = contentTabs;
    switch (tab) {
      case ORIGINALS.id:
        return (
          <Content
            isModal={isModal}
            onSaveContent={onSaveContent}
            hide={hide}
            type={videoTypes.ORIGINALS}
            content={content}
          />
        );
      case NARRATIVES.id:
        return (
          <Content
            isModal={isModal}
            onSaveContent={onSaveContent}
            hide={hide}
            type={videoTypes.NARRATIVES}
            content={content}
          />
        );
      default:
    }
  };

  return {
    renderVideoSelectContent
  };
};
