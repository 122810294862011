/* eslint-disable camelcase */
/* eslint-disable no-useless-catch */
/* eslint-disable no-plusplus */
/* eslint-disable no-await-in-loop */
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useStatus, SUCCESS } from '@rootstrap/redux-tools';

import { useNavigation, useSession, useToast } from 'hooks';
import capitalize from 'lodash/capitalize';
import { updateBannerAd } from 'state/actions/bannerAdActions';
import { routeWithProps } from 'utils/helpers';
import { routesPaths } from 'constants/routesPaths';

export default () => {
  const dispatch = useDispatch();
  const {
    user: { id: celebrityId }
  } = useSession();
  const { goTo } = useNavigation();
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const { status, error } = useStatus(updateBannerAd);

  const onSubmit = useCallback(
    data => {
      setLoading(true);
      const formData = new FormData();
      Object.keys(data).forEach(key => {
        if (key === 'episode_ids' || key === 'narrative_ids') {
          if (Array.isArray(data[key])) {
            data[key].forEach(id => {
              formData.append(`banner_ad[${key}][]`, id);
            });
          } else if (typeof data[key] === 'string') {
            const ids = data[key].split(',').map(id => id.trim());
            ids.forEach(id => {
              formData.append(`banner_ad[${key}][]`, id);
            });
          }
        } else if (data[key] instanceof File) {
          formData.append(`banner_ad[${key}]`, data[key], data[key].name);
        } else {
          formData.append(`banner_ad[${key}]`, data[key]);
        }
      });
      dispatch(updateBannerAd(celebrityId, formData, data.id));
    },
    [dispatch]
  );

  useEffect(() => {
    if (status === SUCCESS) {
      showToast('Banner Ad Updated Successfully!');
      goTo(routeWithProps(routesPaths.adManager, { tab: 'bannerAds' }));
      dispatch(updateBannerAd.reset());
    }
  }, [status, dispatch, showToast]);

  return {
    onSubmit,
    status,
    loading,
    error: capitalize(error)
  };
};
