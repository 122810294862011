import { createReducer } from '@rootstrap/redux-tools';
import {
  login,
  signUp,
  logout,
  updateSession,
  getProfile,
  setUserToConfirm,
  registerDevice,
  unregisterDevice,
  upgradeToCreator,
  changeCreatorProfileImage
} from 'state/actions/userActions';

const initialState = {
  authenticated: false,
  user: null,
  userToConfirm: null,
  info: {},
  profile: {}
};

const actionHandlers = {
  [login.success]: (state, { payload }) => {
    state.user = payload;
  },
  [signUp.success]: (state, { payload }) => {
    state.user = payload;
  },
  [updateSession]: (state, { payload }) => {
    state.info = payload;
    state.authenticated = true;
  },
  [getProfile.success]: (state, { payload }) => {
    state.profile = payload;
  },
  [setUserToConfirm.success]: (state, { payload }) => {
    state.userToConfirm = payload;
  },
  [logout.success]: () => initialState,
  [logout.error]: state => ({ ...initialState, userToConfirm: state.userToConfirm }),
  [registerDevice.success]: (state, { payload }) => {
    state.token = payload;
  },
  [unregisterDevice.success]: state => {
    state.token = null;
  },
  [upgradeToCreator.success]: (state, { payload }) => {
    state.user = {
      ...payload,
      type: 'Celebrity'
    };
  },
  [changeCreatorProfileImage.success]: (state, { payload: { profileImageUrl } }) => {
    state.user = {
      ...state.user,
      profileImageUrl
    };
  }
};

export default createReducer(initialState, actionHandlers);
