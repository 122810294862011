import * as React from 'react';

const ReportIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeWidth={2}
      d="M9 19H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v13a2 2 0 0 1-2 2h-5M15 19l-3 3-3-3"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M13 6.043V6a1 1 0 1 0-2 .042L11.166 14h1.665L13 6.043Z"
      clipRule="evenodd"
    />
    <circle cx={12} cy={17} r={1} fill="#fff" />
  </svg>
);
export default ReportIcon;
