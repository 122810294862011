import { useState, useEffect } from 'react';
import {
  maxMobileWidth,
  minTabletWidth as defaultMinTabletWidth,
  maxTabletWidth as defaultMaxTabletWidth
} from 'styles/common/_constants.scss';

export default function useIsTablet() {
  function checkWindowWidth() {
    const minTabletWidth = defaultMinTabletWidth || maxMobileWidth + 1;
    const maxTabletWidth = defaultMaxTabletWidth;
    return window?.innerWidth >= minTabletWidth && window?.innerWidth <= maxTabletWidth;
  }

  const [isTablet, setIsTablet] = useState(checkWindowWidth);

  useEffect(() => {
    function handleResize() {
      setIsTablet(checkWindowWidth());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return isTablet;
}
