import { createReducer } from '@rootstrap/redux-tools';

import { getScheduledLiveEvent } from 'state/actions/liveEventsActions';

const initialState = {
  scheduledLive: {}
};

export default createReducer(initialState, {
  [getScheduledLiveEvent.success]: (state, { payload }) => {
    state.scheduledLive = payload;
  }
});
