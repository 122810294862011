import { useEffect, useCallback } from 'react';
import mixpanel from 'mixpanel-browser';

export default (event, options = {}) => {
  const createAlias = uid => {
    mixpanel.alias(uid);
  };

  const identify = uid => {
    mixpanel.identify(uid);
  };

  const setPeople = (...params) => {
    mixpanel.people.set(...params);
  };

  const register = (...params) => {
    mixpanel.register(...params);
  };

  const trackEvent = useCallback((event, options) => mixpanel.track(event, options), []);

  useEffect(() => {
    event && trackEvent(event, options);
  }, [event, options, trackEvent]);

  return {
    trackEvent,
    createAlias,
    identify,
    setPeople,
    register
  };
};
