/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import searchService from 'services/searchService';
import Loading from 'components/common/Loading';
import CreatorCard from './CreatorCard';

const CreatorPage = ({ dateFilter, categoryFilter }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState();
  const searchParams = new URLSearchParams(location.search);
  const searchTerm = searchParams.get('search');
  useEffect(() => {
    const fetchSearchResults = async () => {
      if (searchTerm) {
        setIsLoading(true);
        try {
          const { data } = await searchService.search(searchTerm, '', dateFilter, categoryFilter);
          if (data.search) {
            setResults(data.search);
          }
        } catch (error) {
          console.error('Error fetching search results:', error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchSearchResults();
  }, [searchTerm, dateFilter, categoryFilter]);

  if (isLoading) {
    return (
      <div className="home-loading">
        <Loading />
      </div>
    );
  }
  return (
    <div>
      {results && results.celebrities.length > 0 ? (
        <div className={cn('creator-container')}>
          {results?.celebrities?.map((item, index) => <CreatorCard data={item} key={index} />)}
        </div>
      ) : (
        <div className="no-results-message">Matching records not found</div>
      )}
    </div>
  );
};

export default CreatorPage;
