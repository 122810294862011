import React from 'react';
import { number, string } from 'prop-types';

import { secondaryColor } from 'styles/common/_constants.scss';

const XIcon = ({ width = 18, height = 18, color = secondaryColor }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="logoTitle logoDesc"
    role="img"
  >
    <title id="logoTitle">X (formerly Twitter)</title>
    <desc id="logoDesc">X (formerly Twitter) logo</desc>

    <path
      d="M10.706 7.623 17.4 0h-1.586L9.998 6.617 5.356 0H0l7.022 10.007L0 18h1.587l6.138-6.99L12.63 18h5.356l-7.28-10.377Zm-2.174 2.472-.713-.997-5.66-7.927h2.437l4.57 6.4.71.997 5.938 8.318h-2.437l-4.845-6.79Z"
      fill={color}
    />
  </svg>
);

XIcon.propTypes = {
  width: number,
  height: number,
  color: string
};

export default XIcon;
