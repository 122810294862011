import React from 'react';
import { number, bool, string } from 'prop-types';

import { white } from 'styles/common/_constants.scss';

const PlayIcon = ({ width = 17, height = 20, color = white, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 17 20"
    fill={fill ? color : 'none'}
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="logoTitle logoDesc"
    role="img"
  >
    <title id="logoTitle">Play</title>
    <desc id="logoDesc">Play icon</desc>
    <path
      d="M1.5 1L15.5 10L1.5 19V1Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

PlayIcon.propTypes = {
  width: number,
  height: number,
  color: string,
  fill: bool
};

export default PlayIcon;
