/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { LOADING, SUCCESS, ERROR, useStatus } from '@rootstrap/redux-tools';
import Button from 'components/common/Button/Button';
import Input from 'components/common/Input/Input';
import Loading from 'components/common/Loading';
import { useForm, useTextInputProps, useValidation, useToast } from 'hooks';
import { updatesubscriptionamount } from 'state/actions/celebritiesActions';
import { creatorValidations } from 'utils/constraints';
import UserService from 'services/userService';
import parseError from 'utils/parseError';

const fields = {
  membershipPrice: 'membershipPrice'
};

const MembershipAmountForm = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { showErrorToast, showToast } = useToast();
  const { status, error } = useStatus(updatesubscriptionamount);
  const [profileData, setProfileData] = useState(null);
  const [profileLoading, setProfileLoading] = useState(true);
  const [profileError, setProfileError] = useState(null);
  const [membershipPrice, setMembershipPrice] = useState('');

  const validator = useValidation(creatorValidations);
  const getProfile = async () => {
    setProfileLoading(true);
    try {
      const { data } = await UserService.getProfile();
      setProfileData(data.user);
      setMembershipPrice(`$${data.user.membershipPlan.price}`);
      setValues({
        [fields.membershipPrice]: Number(data.user.membershipPlan.price)
      });
      setProfileError(null);
    } catch ({ response }) {
      const error = parseError(response);
      setProfileError(error);
      showErrorToast(error);
    } finally {
      setProfileLoading(false);
    }
  };
  const onSubmit = async formValues => {
    if (!profileData) {
      showErrorToast('Profile data is not available');
      return;
    }

    const payload = {
      membership_plan: {
        price: Number(formValues[fields.membershipPrice])
      }
    };

    try {
      await dispatch(updatesubscriptionamount(profileData.id, payload));
      getProfile();
    } catch (error) {
      showErrorToast('An error occurred while updating the membership amount');
    }
  };
  const { values, errors, handleValueChange, handleSubmit, handleBlur, setValues } = useForm(
    {
      onSubmit,
      validator,
      validateOnBlur: true,
      validateOnChange: true
    },
    [onSubmit]
  );

  const inputProps = useTextInputProps(handleValueChange, handleBlur, values);

  useEffect(() => {
    getProfile();
  }, []);

  useEffect(() => {
    if (status === SUCCESS) {
      showToast('Membership amount updated successfully');
      dispatch(updatesubscriptionamount.reset());
    } else if (status === ERROR) {
      showErrorToast(error || 'Failed to update membership amount');
      dispatch(updatesubscriptionamount.reset());
    }
  }, [status, error, dispatch, showToast, showErrorToast]);
  const onChangeMembershipPrice = event => {
    let value = event.target.value.replace(/[^0-9.]/g, '');

    // Limit to 2 decimal places
    if (value.includes('.')) {
      const [whole, decimal] = value.split('.');
      value = `${whole}.${decimal.slice(0, 2)}`;
    }

    if (value.length > 0) {
      value = `$${value}`;
      const valueWithoutDollar = value.slice(1);
      handleValueChange(fields.membershipPrice, valueWithoutDollar);
      setMembershipPrice(value);
    } else {
      setMembershipPrice('');
      handleValueChange(fields.membershipPrice, '');
    }
  };
  if (profileLoading)
    return (
      <div className="not-member-landing-loading">
        <Loading />
      </div>
    );
  if (profileError) return <div className="membership-error-message">{profileError}</div>;

  return (
    <div className="membership-amount-card">
      <h2 className="membership-amount-title">MEMBERSHIP AMOUNT</h2>
      <Input
        name={fields.membershipPrice}
        placeholder={intl.formatMessage({
          id: 'form.price',
          defaultMessage: 'Please Enter Membership Amount'
        })}
        errors={errors[fields.membershipPrice]}
        {...inputProps(fields.membershipPrice)}
        className="membership-amount-input"
        value={membershipPrice}
        onChange={onChangeMembershipPrice}
      />
      <Button
        labelId="form.update"
        onClick={handleSubmit}
        type="primary"
        size="small"
        disabled={status === LOADING || !values[fields.membershipPrice]}
      >
        {status === LOADING && <Loading type="ball-clip-rotate" />}
      </Button>
      {error && <div className="membership-error-message">{error}</div>}
    </div>
  );
};

export default MembershipAmountForm;
