import { createThunk } from '@rootstrap/redux-tools';
import narrativeService from 'services/narrativeService';
import parseError from 'utils/parseError';

export const createNarrative = createThunk('CREATE_NARRATIVE', async (celebrityId, narrative) => {
  try {
    const { data } = await narrativeService.createNarrative(celebrityId, narrative);
    return data.narrative;
  } catch ({ response }) {
    throw parseError(response);
  }
});

export const processNarrative = createThunk(
  'PROCESS_NARRATIVE',
  async (celebrityId, narrativeId, videoUri) => {
    try {
      await narrativeService.processNarrative(celebrityId, narrativeId);
      return videoUri;
    } catch ({ response }) {
      throw parseError(response);
    }
  }
);

export const deleteNarrative = createThunk('DELETE_NARRATIVE', async (celebrityId, narrativeId) => {
  try {
    await narrativeService.deleteNarrative(celebrityId, narrativeId);
  } catch ({ response }) {
    throw parseError(response);
  }
});

export const updateNarrative = createThunk(
  'UPDATE_NARRATIVE',
  async ({ id, userId, ...narrative }) => {
    try {
      await narrativeService.updateNarrative(userId, id, narrative);
    } catch ({ response }) {
      throw parseError(response);
    }
  }
);

export const reportNarrative = createThunk('REPORT_NARRATIVE', async ({ narrativeId, params }) => {
  try {
    await narrativeService.reportNarrative({ narrativeId, params });
  } catch ({ response }) {
    throw parseError(response);
  }
});

export const resetUploadingNarrative = createThunk('RESET_UPLOADING_NARRATIVE');
