import { createThunk } from '@rootstrap/redux-tools';
import videoAds from 'services/videoAds';
import parseError from 'utils/parseError';

export const createVideoAds = createThunk('CREATE_VIDEOADS', async (celebrityId, videoads) => {
  try {
    const { data } = await videoAds.createvideoAds(celebrityId, videoads);
    return data.videoAd;
  } catch ({ response }) {
    throw parseError(response);
  }
});

export const updatevideoAd = async ({ id, userId, ...videoad }) => {
  try {
    const { data } = await videoAds.updatevideoAd(userId, id, videoad);
    return data.videoAd;
  } catch ({ response }) {
    throw parseError(response);
  }
};
