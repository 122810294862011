import React from 'react';
import cn from 'classnames';

import { defineMessages, useIntl } from 'react-intl';
import AddBannerForm from 'components/adManagement/AddBannerForm';
import { useParams } from 'react-router-dom';
import { BackArrowIcon } from 'components/icons';

const messages = defineMessages({
  title: { id: 'title.uploadBannerAd' }
});

const AddBannerAdPage = () => {
  const intl = useIntl();
  const { bannerId, type, id } = useParams();
  return (
    <div className={cn('add-banner')}>
      <div className="add-banner-gradient" />
      <div className="layer content-container">
        <div className="add-banner-content">
          <div className="container">
            <div className="back-button" onClick={() => window.history.back()}>
              <BackArrowIcon />
            </div>
            <h1 className="title">
              {bannerId ? 'Edit Banner Ad' : intl.formatMessage(messages.title)}
            </h1>
            <AddBannerForm bannerId={bannerId} contentType={type} contentid={id} />
          </div>
        </div>
      </div>
    </div>
  );
};

AddBannerAdPage.loadData = async () => {};

export default AddBannerAdPage;
