export const customDropDownStyles = {
  input: provided => ({
    ...provided,
    color: '#fff',
    margin: '0 !important',
    padding: '0',
    background: 'black',
    '& input': {
      margin: '0 !important',
      boxSizing: 'border-box',
      boxShadow: 'none !important',
      textShadow: 'none !important'
    }
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  control: baseStyles => ({
    ...baseStyles,
    backgroundColor: 'black',
    color: 'white',
    minHeight: '55px',
    borderColor: '#5C5B5B',
    fontFamily: 'Raleway',
    boxShadow: 'none !important',
    '&:hover': {
      borderColor: '#5C5B5B'
    }
  }),
  singleValue: baseStyles => ({
    ...baseStyles,
    color: 'white'
  }),
  menu: baseStyles => ({
    ...baseStyles,
    backgroundColor: 'white',
    fontFamily: 'Raleway'
  }),
  option: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: state.isFocused ? '#D3DFF1' : 'white',
    color: 'black'
  })
};
