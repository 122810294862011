import React from 'react';
import { number, string } from 'prop-types';

import { white } from 'styles/common/_constants.scss';

const VideoIcon = ({ width = 17, height = 17, color = white }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="logoTitle logoDesc"
    role="img"
  >
    <title id="logoTitle">Video</title>
    <desc id="logoDesc">Video icon</desc>
    <g clipPath="url(#clip0)">
      <path
        d="M1.33301 9.33337V3.33337H11.333V6.66671"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M11.3332 9.33341L15.3332 10.6667V4.66675L11.3332 6.00008V12.0001H0.666504"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

VideoIcon.propTypes = {
  width: number,
  height: number,
  color: string
};

export default VideoIcon;
