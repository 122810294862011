import { createReducer } from '@rootstrap/redux-tools';
import { addBannerAd, resetUploadingBanner } from 'state/actions/bannerAdActions';

const initialState = {
  uploadingBannerAd: {}
};

export default createReducer(initialState, {
  [addBannerAd.success]: (state, { payload }) => {
    state.uploadingBannerAd = payload.bannerAd;
  },
  [resetUploadingBanner.request]: state => {
    state.uploadingBannerAd = {};
  }
});
