import React, { useEffect } from 'react';
import { useParams, Redirect } from 'react-router-dom';

import { useDispatch, useCelebrity, useAnalytics, useSession } from 'hooks';
import useWatchLiveSocket from 'hooks/liveEvents/useWatchLiveSocket';
import useCelebrityLive from 'hooks/celebrities/useCelebrityLive';
import useProfile from 'hooks/users/useProfile';
import { MIXPANEL_EVENTS } from 'constants/constants';
import { getCelebrity } from 'state/actions/celebritiesActions';
import { getFullName, routeWithProps } from 'utils/helpers';

import LivePlayer from 'components/Players/VideoJs/LivePlayer';
import Loading from 'components/common/Loading';
import { routesPaths } from 'constants/routesPaths';

const LivePlayerPage = () => {
  const { celebrityId } = useParams();
  const { trackEvent } = useAnalytics();

  const { authenticated } = useSession();
  const { requestProfile, loading: profileLoading } = useProfile();

  const getCelebrityRequest = useDispatch(getCelebrity);
  const { celebrity, loading: celebrityLoading, status: celebrityStatus } = useCelebrity();

  const { error, loading: urlLoading, celebrityLive, requestLiveUrl, reset } = useCelebrityLive(
    celebrityId
  );

  const { activeLiveEvent, id, firstName, lastName } = celebrity;
  const {
    videoUrl,
    scheduledLiveEvent,
    celebrities,
    sponsorUrl,
    ppv,
    paidByUser,
    id: eventId
  } = celebrityLive;

  const { offLine, isLive } = useWatchLiveSocket({
    status: celebrityStatus,
    eventId,
    celebrityId
  });

  useEffect(() => {
    authenticated && requestProfile();
    getCelebrityRequest(celebrityId);
  }, []);

  useEffect(() => {
    id &&
      celebrity.id == id &&
      trackEvent(MIXPANEL_EVENTS.liveView, { 'Creator Name': getFullName(firstName, lastName) });
  }, [celebrity]);

  useEffect(() => {
    activeLiveEvent && requestLiveUrl();
  }, [activeLiveEvent]);

  useEffect(() => {
    if (isLive) {
      getCelebrityRequest(celebrityId);
      requestLiveUrl();
    }
  }, [isLive]);

  useEffect(() => {
    if (offLine) {
      reset();
    }
  }, [offLine]);

  if (profileLoading || celebrityLoading || urlLoading) {
    return (
      <div className="live-loading">
        <Loading />
      </div>
    );
  }

  if (ppv && !paidByUser) {
    return <Redirect to={routeWithProps(routesPaths.scheduledLive, { id: eventId })} />;
  }

  return (
    <div className="video-frame">
      {celebrity?.id && (
        <LivePlayer
          celebrities={scheduledLiveEvent ? celebrities : celebrity}
          liveUrl={videoUrl}
          offline={!activeLiveEvent || error || offLine}
          isScheduled={scheduledLiveEvent}
          sponsorUrl={sponsorUrl}
        />
      )}
    </div>
  );
};

LivePlayerPage.loadData = async (store, match) => {
  const {
    params: { celebrityId }
  } = match;
  await store.dispatch(getCelebrity(celebrityId));
};

export default LivePlayerPage;
