/* eslint-disable react/button-has-type */
import React, { useEffect, useState, useRef } from 'react';
import './FlexiblePlans.scss';
import Button from 'components/common/Button/Button';
import membershipService from 'services/membershipService';
import { useModal, useSession, useClickOutside, useIsMobile } from 'hooks';
import Modal from 'components/common/Modal/Modal';
import { zIndexModalThird, white, black40 } from 'styles/common/_constants.scss';
import Loading from 'components/common/Loading';
import Correntarrow from 'assets/correct.png';
import useMembership from 'hooks/celebrities/useMembership';
import { useDispatch } from 'react-redux';
import UserService from 'services/userService';
import CurrentPlanIcon from 'assets/plan-2.png';
import NoPlanIcon from 'assets/Rectangle-30.png';
import Close from 'components/common/Close/Close';
import parseError from 'utils/parseError';
import AdPayment from './AdPayment';

const FlexiblePlans = () => {
  const isMobile = useIsMobile();
  const [Plans, setPlans] = useState([]);
  const { user } = useSession();
  const [currentSelectedPlans, setSelectedPlans] = useState(null);
  const [currentPlan, setcurrentPlan] = useState(null);
  const [isCancelDisabled, setIsCancelDisabled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userProfile, setUserProfile] = useState();

  // Modals for plan selection and cancel subscription
  const { toggle, isShowing } = useModal();
  const { toggle: toggleCancel, isShowing: isShowingCancel } = useModal();

  const { cancelMembership } = useMembership();
  const dispatch = useDispatch();

  const cancelModalRef = useRef(null); // Reference to the cancel modal

  // Add useClickOutside logic for the cancel modal only
  useClickOutside(cancelModalRef, () => {
    if (isShowingCancel) toggleCancel(); // Ensure it's only applied to cancel modal
  });

  const getProfile = async () => {
    try {
      const { data } = await UserService.getProfile();
      setUserProfile(data?.user);
    } catch ({ response }) {
      throw parseError(response);
    }
  };
  useEffect(() => {
    getProfile();
    membershipService.getAdmembership().then(response => {
      setPlans(response.data.membershipPlans);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    setcurrentPlan(Plans?.find(plan => plan.currentMembership));
  }, [Plans]);

  const cancelAdmembership = async () => {
    try {
      setIsCancelDisabled(true);
      const membership = userProfile?.memberships.find(
        membership =>
          membership.membershipPlan.id === currentPlan.id && membership.status === 'active'
      );

      const payload = {
        id: membership?.id,
        celebrity: { id: user.id }
      };

      await dispatch(cancelMembership(payload));
      setIsCancelDisabled(false);
      toggleCancel();
    } catch (error) {
      // console.error('Error canceling membership:', error);
    }
  };

  return (
    <div>
      {!loading ? (
        <div style={{ justifyContent: 'flex-start' }} className="flexible-plans">
          <h1 className="title">Flexible Plans</h1>
          <p className="subtitle">Choose a plan that works best for you.</p>
          <div className="plans">
            {Plans.map(plan => (
              <div className="plan" key={plan.id}>
                <div className="headerplan">
                  <div className="plan-icon">
                    {plan.currentMembership ? (
                      <img src={CurrentPlanIcon} className="current-plan-image" alt="currentPlan" />
                    ) : (
                      <img src={NoPlanIcon} className="current-plan-image" alt="currentPlan" />
                    )}
                  </div>
                  <div>
                    <div
                      style={{ whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '150px' }}
                      className="plan-name"
                    >
                      {plan.name}
                    </div>
                    <div className="plan-price">
                      ${plan.price}
                      <span className="title-month"> /Month</span>
                    </div>
                  </div>
                </div>
                <ul className="plan-features">
                  {plan.planDetails.map(Details => (
                    <li key={Details.title}>
                      <img
                        src={Correntarrow}
                        height="10px"
                        width="20px"
                        style={{ marginRight: '15px' }}
                        alt="info"
                      />{' '}
                      {Details.count} {Details.title}
                    </li>
                  ))}
                </ul>
                {plan.currentMembership ? (
                  <Button labelId="CURRENT PLAN" type="secondary" className="profile-button" />
                ) : (
                  <Button
                    labelId={`Subscribe to ${plan.name}`}
                    type="primary"
                    className="profile-button"
                    onClick={() => {
                      setSelectedPlans(plan);
                      toggle();
                    }}
                  />
                )}
              </div>
            ))}
          </div>
          {Plans.some(plan => plan.currentMembership) && (
            <div className="cancelAdmembership">
              <Button
                labelId="CANCEL MY AD MEMBERSHIP"
                type="primary"
                className="profile-button"
                onClick={() => toggleCancel()}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="not-member-landing-loading">
          <Loading />
        </div>
      )}
      {/* Modal for upgrading */}
      <Modal hide={toggle} isShowing={isShowing} topModal zIndex={zIndexModalThird} leftAnimation>
        <AdPayment plans={currentSelectedPlans} />
      </Modal>

      {/* Cancel subscription modal */}
      <Modal hide={toggleCancel} isShowing={isShowingCancel} className="original-modal">
        <div className="cancel-modal" ref={cancelModalRef}>
          <div className="cancel-modal-header">
            Cancel subscription
            <Close onClick={toggleCancel} color={isMobile ? white : black40} />
          </div>
          <div className="layer cancel-modal-header-content">
            <div className="cancel-content-div">
              <span style={{ display: 'flex', justifyContent: 'center' }}>
                Cancel this subscription?
              </span>
              <div className="buttons">
                <Button className="cancel-btn" onClick={toggleCancel}>
                  Go Back
                </Button>
                <Button
                  type="primary"
                  labelId="Yes, Cancel"
                  className="delete-btn"
                  onClick={cancelAdmembership}
                  disabled={isCancelDisabled}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default FlexiblePlans;
