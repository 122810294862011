import { useState } from 'react';

const useModal = (initialState = false) => {
  const [isShowing, setIsShowing] = useState(initialState);

  const toggle = () => setIsShowing(!isShowing);

  return {
    isShowing,
    toggle
  };
};

export default useModal;
