import React, { useEffect } from 'react';
import { bool, func } from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import cn from 'classnames';

import useNotifications from 'hooks/notifications/useNotifications';

import NotificationItem from 'components/notifications/NotificationItem/NotificationItem';
import EmptyMessage from 'components/common/EmptyMessage/EmptyMessage';
import Loading from 'components/common/Loading';
import ModalHeader from 'components/common/ModalHeader/ModalHeader';

import { white } from 'styles/common/_constants.scss';

const Notifications = ({ isModal, hide }) => {
  const { notifications, loading, readAllNotifications, requestNotifications } = useNotifications();

  useEffect(() => {
    requestNotifications();
    return async () => {
      await readAllNotifications();
      await requestNotifications();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showLoading = !notifications.length && loading;

  return (
    <div
      className={cn(
        { notifications: !isModal },
        { 'notifications-modal': isModal },
        { loading: showLoading }
      )}
    >
      {isModal ? (
        <ModalHeader titleId="notifications.title" hideModal={hide} />
      ) : (
        <span className="triangle" />
      )}
      {showLoading && <Loading color={white} />}
      {!showLoading &&
        (isEmpty(notifications) ? (
          <EmptyMessage titleId="notifications.empty" substitleId="notifications.empty.message" />
        ) : (
          <div className="notifications-items">
            {notifications.map(notification => (
              <NotificationItem notification={notification} key={notification.id} />
            ))}
          </div>
        ))}
      {notifications.length > 0 && <div className="gradient" />}
    </div>
  );
};

Notifications.propTypes = {
  isModal: bool,
  hide: func
};

export default Notifications;
