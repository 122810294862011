import React from 'react';
import { shape, func, string, oneOfType, arrayOf, bool } from 'prop-types';

import { celebrityInfoShape } from 'constants/propTypesShapes';

import PlayerNavigationBar from 'components/Players/Controls/PlayerNavigationBar';

const MiscControls = ({ title, celebrities, goBack, controlsVisible, id }) => (
  <div
    className="controls custom-controls"
    style={{ visibility: controlsVisible ? 'visible' : 'hidden' }}
  >
    <PlayerNavigationBar
      celebrities={celebrities}
      disableSharing
      customGoBack={goBack}
      title={title}
      closeIcon
      isReport
      reportTitle="Report Series"
      type="isSeries"
      id={id}
    />
  </div>
);

MiscControls.propTypes = {
  title: string.isRequired,
  controlsVisible: bool,
  celebrities: oneOfType([shape(celebrityInfoShape), arrayOf(shape(celebrityInfoShape))]),
  goBack: func
};

export default MiscControls;
