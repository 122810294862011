import { createThunk } from '@rootstrap/redux-tools';

import categoriesService from 'services/categoriesService';
import parseError from 'utils/parseError';

export const getCategoriesFeatured = createThunk('GET_CATEGORIES_FEATURED', async () => {
  try {
    const { data } = await categoriesService.getCategoriesFeatured();
    return data.categories;
  } catch ({ response }) {
    throw parseError(response);
  }
});

export const getCategory = createThunk('GET_CATEGORY', async slug => {
  try {
    const { data } = await categoriesService.getCategory(slug);
    return data;
  } catch ({ response }) {
    throw parseError(response);
  }
});

export const getCategories = createThunk('GET_CATEGORIES', async () => {
  try {
    const { data } = await categoriesService.getCategories();
    return data.categories;
  } catch ({ response }) {
    throw parseError(response);
  }
});
