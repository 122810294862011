import React from 'react';
import cn from 'classnames';

import { BackArrowIcon } from 'components/icons';
import { useParams } from 'react-router-dom';
import AddPodcastForm from 'components/podcast/AddPodcastForm';

const AddPodcast = () => {
  const { podcastId, celebrityId } = useParams();
  return (
    <div className={cn('add-podcast')}>
      <div className="add-podcast-gradient" />
      <div className="layer content-container">
        <div className="add-podcast-content">
          <div className="container" style={{ overflow: 'hidden' }}>
            <div className="back-button" onClick={() => window.history.back()}>
              <BackArrowIcon />
            </div>
            <h1 className="title">{podcastId ? 'Edit Podcast' : 'Add Podcast'}</h1>
            <AddPodcastForm podcastId={podcastId} celId={celebrityId} />
          </div>
        </div>
      </div>
    </div>
  );
};

AddPodcast.loadData = async () => {};

export default AddPodcast;
