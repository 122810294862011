import React, { memo, useMemo } from 'react';
import { string, func, oneOf, object, bool, node } from 'prop-types';
import { useIntl } from 'react-intl';
import cn from 'classnames';

const Button = ({
  labelId,
  onClick,
  type,
  icon,
  className,
  lightFont,
  smallBoldFont,
  disabled,
  children,
  size,
  title
}) => {
  const intl = useIntl();

  const hasLabel = useMemo(() => labelId || title, [labelId, title]);

  return (
    <div
      className={cn(
        'button',
        type,
        className,
        `button-${size}`,
        { disabled },
        { 'no-label': !hasLabel },
        { 'with-children': children }
      )}
      onClick={disabled ? undefined : onClick}
    >
      {children || (
        <>
          {icon}
          {hasLabel &&
            (lightFont || smallBoldFont ? (
              <h6 className={cn({ semibold: !smallBoldFont }, { bold: smallBoldFont })}>
                {title || intl.formatMessage({ id: labelId })}
              </h6>
            ) : (
              <h5 className="bold">{title || intl.formatMessage({ id: labelId })}</h5>
            ))}
        </>
      )}
    </div>
  );
};

Button.propTypes = {
  title: string,
  labelId: string,
  onClick: func,
  type: oneOf(['primary', 'secondary', 'tetriary', 'red']),
  icon: object,
  size: oneOf(['large', 'medium', 'small', 'xsmall', 'xxsmall']),
  className: string,
  lightFont: bool,
  smallBoldFont: bool,
  disabled: bool,
  children: node
};

Button.defaultProps = {
  size: 'small'
};

export default memo(Button);
