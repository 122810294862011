import React from 'react';
import { string, shape, bool } from 'prop-types';
import cn from 'classnames';

import { podcastShape } from 'constants/propTypesShapes';

import PodcastTag from 'components/common/Brand/PodcastTag';

const PodcastInfo = ({ podcast, className, hideDescription }) => {
  const { title, bannerImageUrl } = podcast;

  return (
    <div className={cn('serie-info', className)}>
      <PodcastTag />
      <img src={bannerImageUrl} alt="series" className="serie-logo" />
      {!hideDescription && <h4 className="semibold serie-description">{title}</h4>}
    </div>
  );
};

PodcastInfo.propTypes = {
  podcast: shape(podcastShape).isRequired,
  className: string,
  hideDescription: bool
};

export default PodcastInfo;
