import React, { useEffect } from 'react';
import { string, bool, func, number } from 'prop-types';
import cn from 'classnames';

import { useToggle } from 'hooks';

import { AvatarHeaderWide, AvatarHeaderSquare } from 'components/icons';
import Button from 'components/common/Button/Button';

import { warning, primaryColor } from 'styles/common/_constants.scss';

const ANIMATION_DURATION = 1500;

const ProfilePicture = ({
  profileImage,
  isSquare = true,
  height = 140,
  width = 140,
  isLive,
  onWatchLive,
  className
}) => {
  const [animationVisible, toggleAnimation] = useToggle(true);

  useEffect(() => {
    const interval = setInterval(() => {
      isLive && toggleAnimation();
    }, ANIMATION_DURATION);
    return () => {
      clearInterval(interval);
    };
  }, [animationVisible, isLive]);

  return (
    <div className={cn('profile-picture', { live: isLive }, className)} style={{ height, width }}>
      {isSquare ? (
        <AvatarHeaderSquare
          height={height}
          width={width}
          className={cn('profile-header-bkg', { hidden: !animationVisible })}
          color={isLive ? warning : primaryColor}
        />
      ) : (
        <AvatarHeaderWide
          height={height}
          width={width}
          className={cn('profile-header-bkg', { hidden: !animationVisible })}
          color={isLive ? warning : primaryColor}
        />
      )}
      <img
        src={profileImage}
        alt="profile"
        height={height - height * 0.2}
        width={width - width * 0.2}
      />
      {isLive && (
        <Button
          labelId="live.watch"
          onClick={onWatchLive}
          size="xsmall"
          type="red"
          className="watch-live-btn desktop-only"
        />
      )}
    </div>
  );
};

ProfilePicture.propTypes = {
  height: number,
  width: number,
  profileImage: string.isRequired,
  isLive: bool,
  isSquare: bool,
  onWatchLive: func,
  className: string
};

export default ProfilePicture;
