import { useState } from 'react';
import { useDispatch } from 'react-redux';

import useSession from 'hooks/useSession';
import { changeCreatorProfileImage } from 'state/actions/userActions';

export default () => {
  const [loading, setLoading] = useState(false);
  const [coverLoading, setCoverLoading] = useState(false);

  const {
    user: { id: celebrityId }
  } = useSession();
  const dispatch = useDispatch();

  const onChangeProfileImage = async (image, type) => {
    type === 'banner' ? setCoverLoading(true) : setLoading(true);

    try {
      const imageFormData = new FormData();
      imageFormData.append(type, image);

      await dispatch(changeCreatorProfileImage(celebrityId, imageFormData));
    } finally {
      setLoading(false);
      setCoverLoading(false);
    }
  };

  return {
    onChangeProfileImage,
    loading,
    coverLoading
  };
};
