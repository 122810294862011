import React, { memo } from 'react';
import { number, func, string } from 'prop-types';
import cn from 'classnames';

import { CrossIcon } from 'components/icons';
import { white } from 'styles/common/_constants.scss';

const Close = ({ width, height, onClick, color, className }) => (
  <div className={cn('close', className)} onClick={onClick}>
    <CrossIcon width={width} height={height} color={color} />
  </div>
);

Close.defaultProps = {
  width: 24,
  height: 24,
  color: white,
  className: ''
};

Close.propTypes = {
  width: number,
  height: number,
  color: string,
  onClick: func.isRequired,
  className: string
};

export default memo(Close);
