import React from 'react';
import cn from 'classnames';
import { useParams } from 'react-router-dom';
import { defineMessages, useIntl } from 'react-intl';
import AddWidgetForm from 'components/adManagement/AddWidgetForm';
import { BackArrowIcon } from 'components/icons';

const messages = defineMessages({
  title: { id: 'title.uploadWidgetAd' },
  update: { id: 'title.updateWidgetAd' }
});

const AddWidgetAdPage = () => {
  const intl = useIntl();
  const { widgetId, celebrityId, type, id } = useParams();
  return (
    <div className={cn('add-banner')}>
      <div className="add-banner-gradient" />
      <div className="layer content-container">
        <div className="add-banner-content">
          <div className="container">
            <div className="back-button" onClick={() => window.history.back()}>
              <BackArrowIcon />
            </div>
            <h1 className="title">
              {widgetId ? intl.formatMessage(messages.update) : intl.formatMessage(messages.title)}
            </h1>
            <AddWidgetForm id={widgetId} celId={celebrityId} contentType={type} contentid={id} />
          </div>
        </div>
      </div>
    </div>
  );
};

AddWidgetAdPage.loadData = async () => {};

export default AddWidgetAdPage;
