/* eslint-disable jsx-a11y/media-has-caption */
import { LOADING, SUCCESS, useStatus } from '@rootstrap/redux-tools';
import Button from 'components/common/Button/Button';
import Input from 'components/common/Input/Input';
import Loading from 'components/common/Loading';
import { contentOptions, imageSizes, videoUploadsType } from 'constants/constants';
import { useForm, useNavigation, useTextInputProps, useToast, useValidation } from 'hooks';
import { podcastValidation } from 'utils/constraints';
import React, { memo, useEffect, useRef, useState } from 'react';
import {
  checkImageDimensions,
  getTimeFromISO,
  handleDateKeyDown,
  routeWithProps,
  validateVideo
} from 'utils/helpers';
import Select from 'react-select';
import cn from 'classnames';
import ImageCropperModal from 'components/common/ImageCropper/ImageCropperModal';
import { customDropDownStyles } from 'styles/customDropDownStyles';
import { getTodayDate } from 'utils/date';
import { some } from 'lodash';
import { routesPaths } from 'constants/routesPaths';
import parseError from 'utils/parseError';
import CustomCategory from 'components/CustomCategory/CustomCategory';
import Switch from 'react-switch';
import { ValidationErrors } from 'utils/validationErrors';
import { useDispatch } from 'react-redux';
import { createPodcast, processPodcast } from 'state/actions/podcastActions';
import UserService from 'services/userService';
import PodcastService from 'services/podcastService';
import axios from 'axios';
import useUploadingPodcast from 'hooks/podcast/useUploadingPodcast';
import { Line } from 'rc-progress';
import categoriesService from 'services/categoriesService';
import useUpdatePodcast from 'hooks/podcast/useUpdatePodcast';

const fields = {
  title: 'title',
  description: 'description',
  start_date: 'start_date',
  duration: 'duration',
  timezone: 'timezone',
  start_time: 'start_time',
  members_price: 'members_price',
  non_members_price: 'non_members_price',
  banner_image_url: 'banner_image_url',
  notify_subscribers: 'notify_subscribers',
  category_ids: 'category_ids',
  lead_cast: 'lead_cast',
  credits: 'credits',
  contentViewerCategory: 'contentViewerCategory',
  podcastVideo: 'podcastVideo'
};

const AddPodcastForm = ({ podcastId, celId }) => {
  const dispatch = useDispatch();
  const { goToWithSearch } = useNavigation();
  const podcastRef = useRef(null);
  const username = useRef();
  const desktopCoverRef = useRef(null);
  const [desktopCoverPic, setDesktopCoverPic] = useState(null);
  const [podcast, setPodcast] = useState(null);
  const celebrityId = useRef();
  const { error, status } = useStatus(createPodcast);
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [croppingUrl, setCroppingUrl] = useState();
  const [aspectRatio, setAspectRatio] = useState();
  const checkType = useRef();
  const { showErrorToast } = useToast();
  const validator = useValidation(podcastValidation);
  const [subscribedFans, setSubScribedFans] = useState(true);
  const [duration, setDuration] = useState('');
  const [membersPrice, setMembersPrice] = useState('');
  const [nonMembersPrice, setNonMembersPrice] = useState('');
  const [podcastData, setPodcastData] = useState();
  const [selectedContent, setSelectedContent] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isDesktopBannerFalse, setIsDesktopBannerFalse] = useState(false);
  const [audio, setAudio] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [categories, setCategories] = useState();
  const [processLoading, setProcessLoading] = useState(false);
  const { loading, uploadingPodcast } = useUploadingPodcast();
  const { updatePodcast, isLoading } = useUpdatePodcast();

  const handleFormSubmit = async data => {
    if (podcastId) {
      const categoryIds = selectedCategories.map(({ value }) => value);
      data.category_ids = categoryIds;
      data.contentViewerCategory = selectedContent.value;
      data.audioOnly = audio;
      updatePodcast(celebrityId.current, podcastId, data);
    } else {
      const categoryIds = selectedCategories.map(({ value }) => value);
      data.audio_only = audio;
      const formData = new FormData();
      formData.append('podcast[title]', data.title);
      formData.append('podcast[description]', data.description);
      categoryIds.forEach(id => {
        formData.append('podcast[category_ids][]', id);
      });
      formData.append('podcast[lead_cast]', data.lead_cast || '');
      formData.append('podcast[content_viewer_category]', data.contentViewerCategory);
      formData.append('podcast[credits]', data.credits || '');
      formData.append('podcast[start_time]', data.start_time);
      formData.append('podcast[start_date]', data.start_date);
      formData.append('podcast[duration]', duration);
      formData.append('podcast[members_price]', data.members_price ?? 0);
      formData.append('podcast[non_members_price]', data.non_members_price ?? 0);
      formData.append('podcast[notify_subscribers]', data.notify_subscribers);
      formData.append('podcast[audio_only]', audio);
      formData.append('podcast[banner]]', data.banner_image_url);
      dispatch(createPodcast(celebrityId, formData));
    }
  };
  const { values, errors, handleValueChange, handleSubmit, handleBlur, setValues } = useForm(
    {
      onSubmit: handleFormSubmit,
      validateOnBlur: true,
      validateOnChange: true,
      initialValues: {
        [fields.notify_subscribers]: true,
        [fields.audio_only]: false
      },
      validator
    },
    [handleFormSubmit]
  );

  const inputProps = useTextInputProps(handleValueChange, handleBlur, values);

  const onCompleteVideoUpload = () => {
    dispatch(processPodcast(celebrityId.current, uploadingPodcast.id, podcast));
  };

  const { status: processPodcastStatus } = useStatus(processPodcast);

  useEffect(() => {
    const processPodcastAsync = async () => {
      if (processPodcastStatus === SUCCESS) {
        await dispatch(processPodcast.reset());
        setProcessLoading(false);
        const route = routeWithProps(routesPaths.celebrityId, {
          username: username.current.toLowerCase()
        });
        goToWithSearch(route, `?tab=4`);
      }
    };
    processPodcastAsync();
  }, [dispatch, celebrityId, processPodcastStatus, goToWithSearch]);

  const uploadFile = async url => {
    try {
      const totalSize = podcast.size;

      await axios.put(url, podcast, {
        headers: {
          'Content-Type': podcast.type
        },
        onUploadProgress: progressEvent => {
          if (progressEvent.lengthComputable) {
            const uploadedSize = progressEvent.loaded;
            const progress = Math.round((uploadedSize / totalSize) * 100);
            setUploadProgress(progress);
          }
        }
      });
      setProcessLoading(false);
      setUploadProgress(100);
      onCompleteVideoUpload && onCompleteVideoUpload();
    } catch (error) {
      showErrorToast('Something went wrong, try again please!');
      setProcessLoading(false);
      setUploadProgress(0);
    }
  };
  useEffect(() => {
    if (status === SUCCESS && uploadingPodcast?.uploadUrl) {
      dispatch(createPodcast.reset());
      setProcessLoading(true);
      uploadFile(uploadingPodcast?.uploadUrl);
    }
  }, [dispatch, status, uploadingPodcast?.uploadUrl]);

  const handleContentChange = e => {
    setSelectedContent(e);
    handleValueChange(fields.contentViewerCategory, e.value);
  };

  const handleCategoryChange = selectedOptions => {
    setSelectedCategories(selectedOptions);
    handleValueChange(fields.category_ids, selectedOptions);
  };

  const handleDesktopCoverUpload = () => {
    desktopCoverRef.current.click();
  };

  const handleDesktopCoverChange = async e => {
    const file = e.target.files[0];
    const result = await checkImageDimensions(
      file,
      imageSizes.eventDesktopCover.width,
      imageSizes.eventDesktopCover.height
    );
    if (result.isValid) {
      setCropModalOpen(true);
      setAspectRatio(16 / 9);
      setCroppingUrl(URL.createObjectURL(file));
      checkType.current = 'desktop';
    } else {
      showErrorToast(result.error);
    }
  };

  const onChangeMembersPrice = event => {
    let value = event.target.value.replace(/[^0-9.]/g, '');
    if (value.length > 0) {
      value = `$${value}`;
      const valueWithoutDollar = value.slice(1);
      handleValueChange(fields.members_price, valueWithoutDollar);
      setMembersPrice(value);
    } else {
      setMembersPrice('');
      handleValueChange(fields.members_price, '');
    }
  };

  const onChangeNonMembersPrice = event => {
    let value = event.target.value.replace(/[^0-9.]/g, '');
    if (value.length > 0) {
      value = `$${value}`;
      const valueWithoutDollar = value.slice(1);
      handleValueChange(fields.non_members_price, valueWithoutDollar);
      setNonMembersPrice(value);
    } else {
      setNonMembersPrice('');
      handleValueChange(fields.non_members_price, '');
    }
  };

  const handleCancel = () => {
    setCropModalOpen(false);
    setCroppingUrl();
    setAspectRatio();
    checkType.current = null;
  };

  const handleCrop = cropImg => {
    if (checkType.current === 'desktop') {
      setDesktopCoverPic(cropImg);
      handleValueChange(fields.banner_image_url, cropImg);
    }
  };

  const onChangeDuration = event => {
    let value = event.target.value.replace(/[^0-9]/g, '');
    if (value.length > 2) {
      value = `${value.slice(0, 2)}:${value.slice(2)}`;
    }
    if (value.length > 5) {
      value = `${value.slice(0, 5)}:${value.slice(5, 7)}`;
    }
    handleValueChange(fields.duration, value);
    setDuration(value);
  };

  const handleAudioToggle = () => {
    setAudio(!audio);
  };

  const resetPodcastInput = () => {
    if (podcastRef.current) {
      podcastRef.current.value = '';
    }
  };

  const handlePodcastChange = async e => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('video/')) {
      const { isValid, isResolutionValid, isAspectRatioValid, error } = await validateVideo(
        file,
        videoUploadsType.Common
      );
      if (error) {
        showErrorToast(ValidationErrors.loadingVideo);
        resetPodcastInput();
      } else if (!isResolutionValid) {
        showErrorToast(ValidationErrors.resolutionValidation);
        resetPodcastInput();
      } else if (!isAspectRatioValid) {
        showErrorToast(ValidationErrors.aspectRatioValidationForShorts);
        resetPodcastInput();
      } else if (isValid) {
        setPodcast(file);
        handleValueChange(fields.podcastVideo, file);
      }
    } else {
      showErrorToast(ValidationErrors.validFile);
      resetPodcastInput();
    }
  };

  const handlePodcastUpload = () => {
    if (podcastRef.current) {
      podcastRef.current.click();
    }
  };

  useEffect(() => {
    if (podcastData && categories) {
      setDuration(podcastData?.duration);
      setMembersPrice(`$${podcastData?.membersPrice}`);
      setNonMembersPrice(`$${podcastData?.nonMembersPrice}`);
      setDesktopCoverPic(podcastData?.bannerImageUrl);
      setAudio(podcastData?.audioOnly);
      setPodcast(podcastData?.video?.link);
      const selectedCats = categories?.filter(item => podcastData?.categoryIds.includes(item.value));

      const selectedContent = contentOptions.filter(
        item => item.value === podcastData?.video?.contentViewerCategory
      );
      setSelectedCategories(selectedCats);
      handleValueChange(fields.category_ids, selectedCats);
      setSelectedContent(selectedContent[0]);
      setValues({
        ...podcastData?.video,
        [fields.lead_cast]: podcastData.video.leadCast,
        [fields.start_date]: podcastData.startDate
          ? new Date(podcastData.startDate).toISOString().split('T')[0]
          : null,
        [fields.start_time]: podcastData.startTime ? getTimeFromISO(podcastData.startTime) : null,
        [fields.members_price]: podcastData.membersPrice,
        [fields.non_members_price]: podcastData.nonMembersPrice,
        [fields.duration]: podcastData.duration
      });
    }
  }, [podcastData, categories]);

  const getPodcastData = async () => {
    try {
      const { data } = await PodcastService.getPodcastById(podcastId, celId);
      setPodcastData(data.podcast);
    } catch ({ response }) {
      throw parseError(response);
    }
  };

  useEffect(() => {
    if (podcastId && celId) {
      getPodcastData();
    }
  }, [podcastId]);

  const getCategories = async () => {
    try {
      const { data } = await categoriesService.getCategories();
      const transformedData = data.categories.map(item => ({
        value: item.id,
        label: item.name
      }));
      setCategories(transformedData);
    } catch ({ response }) {
      throw parseError(response);
    }
  };

  const getProfile = async () => {
    try {
      const { data } = await UserService.getProfile();
      username.current = data.user.username;
      celebrityId.current = data.user.id;
    } catch ({ response }) {
      throw parseError(response);
    }
  };

  const handleMainCancel = () => {
    const route = routeWithProps(routesPaths.celebrityId, {
      username: username.current.toLowerCase()
    });
    goToWithSearch(route, `?tab=4`);
  };

  const isGlobalLoading = status === LOADING || loading || isLoading || processLoading;
const hasValidationErrors = some(errors);
const isFormIncomplete =
  !values[fields.title] ||
  !values[fields.description] ||
  !values[fields.members_price] ||
  !values[fields.contentViewerCategory] ||
  !values[fields.category_ids] ||
  !values[fields.non_members_price] ||
  !desktopCoverPic ||
  !podcast;


const isDisabled = isGlobalLoading || hasValidationErrors || isFormIncomplete;

  useEffect(() => {
    getCategories();
    getProfile();
  }, []);

  return (
    <div className="add-podcast-form">
      <div className="add-podcast-form-fields">
        <Input
          name="title"
          placeholder="Podcast Title"
          label="Title"
          errors={errors[fields.title]}
          {...inputProps(fields.title)}
        />
        <Input
          name="description"
          placeholder="Podcast Description"
          label="Description"
          errors={errors[fields.description]}
          {...inputProps(fields.description)}
        />
        <Input
          name="lead_cast"
          placeholder="Lead cast name"
          label="Lead Cast (optional)"
          errors={errors[fields.lead_cast]}
          {...inputProps(fields.lead_cast)}
        />
        <Input
          name="credits"
          placeholder="Credits for Podcast"
          label="Credit For Podcast (optional)"
          errors={errors[fields.credits]}
          {...inputProps(fields.credits)}
        />
        <CustomCategory value={selectedCategories} handleCategoryChange={handleCategoryChange} />
        <div className={cn('custom-short-content')}>
          <span className="custom-short-content-label">Content Viewers</span>
          <div className="single-select-short">
            <Select
              value={selectedContent}
              styles={customDropDownStyles}
              options={contentOptions}
              isSearchable={false}
              onChange={handleContentChange}
            />
          </div>
        </div>
        <Input
          name="duration"
          placeholder="HH:MM:SS"
          label="Duration"
          errors={errors[fields.duration]}
          {...inputProps(fields.duration)}
          maxLength={8}
          value={duration}
          onChange={onChangeDuration}
        />
        <div className="input-container">
          <div className="input-card">
            <div className="d-flex">
              <Input
                name="date"
                type="date"
                placeholder="DD/MM/YYYY"
                label="Date"
                value={values[fields.start_date]}
                onKeyDown={handleDateKeyDown}
                onChange={e => handleValueChange(fields.start_date, e.target.value)}
                min={getTodayDate()}
              />
              <Input
                name="time"
                type="time"
                placeholder="HH:MM:SS"
                label="Time"
                value={values[fields.start_time]}
                onKeyDown={handleDateKeyDown}
                onChange={e => handleValueChange(fields.start_time, e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="input-container">
          <div className="d-flex">
            <Input
              name="memberPrice"
              placeholder="$0"
              label="Member price"
              errors={errors[fields.members_price]}
              {...inputProps(fields.members_price)}
              onChange={onChangeMembersPrice}
              value={membersPrice}
            />
            <Input
              name="nonMemberPrice"
              placeholder="$0"
              label="non-member price"
              errors={errors[fields.non_members_price]}
              {...inputProps(fields.non_members_price)}
              onChange={onChangeNonMembersPrice}
              value={nonMembersPrice}
            />
          </div>
        </div>
        <input
          type="file"
          ref={desktopCoverRef}
          style={{ display: 'none' }}
          accept="image/*"
          name="profile_image"
          onChange={handleDesktopCoverChange}
        />
        <div className="switch-container">
          <div className="switch-part">
            <span>
              <Switch
                checked={audio}
                onChange={handleAudioToggle}
                onColor="#2400FC"
                offColor="#ccc"
                checkedIcon={false}
              />
            </span>
            <span className="switch-label">Audio Only</span>
          </div>
          {audio && <span className="support-margin">Supported files - MP4</span>}
        </div>
        <div>
          <span className={desktopCoverPic?.name ? 'white-custom-label' : 'custom-label'}>
            Podcast Cover
          </span>
          {podcastId && desktopCoverPic && !isDesktopBannerFalse && (
            <div className="display-loaded-pic">
              <img className="url-pic" src={podcastData?.bannerImageUrl} alt="desktop-banner" />
              <Button
                labelId="Change"
                type="secondary"
                onClick={() => {
                  setDesktopCoverPic(null);
                  setIsDesktopBannerFalse(true);
                  handleDesktopCoverUpload();
                }}
                className="change-button"
              />
            </div>
          )}
          {desktopCoverPic && (!podcastId || isDesktopBannerFalse) && (
            <div className="white-label">
              <span className="white-name">{desktopCoverPic?.name}</span>
              <span
                className="white-name"
                style={{ cursor: 'pointer' }}
                onClick={() => setDesktopCoverPic(null)}
              >
                x
              </span>
            </div>
          )}
          {!desktopCoverPic && (
            <div className="button-margin">
              <Button
                title="UPLOAD PODCAST COVER IMAGE"
                type="secondary"
                onClick={handleDesktopCoverUpload}
                className="profile-button"
              />
              <span className="label-margin">Recommended dimension: 800px X 450px (16:9)</span>
            </div>
          )}
        </div>
        <input
          type="file"
          ref={podcastRef}
          style={{ display: 'none' }}
          accept="video/*"
          name="podcast"
          onChange={handlePodcastChange}
        />
        <div>
          <span className="custom-label">UPLOAD PODCAST</span>
          {podcast ? (
            <div>
              <video className="video-preview" preload="metadata" playsInline autoPlay>
                <source
                  src={typeof podcast === 'string' ? podcast : URL.createObjectURL(podcast)}
                  type={podcast.type}
                />
                Your browser does not support the video tag.
              </video>
            </div>
          ) : (
            <div className="button-margin">
              <Button
                labelId="UPLOAD PODCAST"
                type="secondary"
                onClick={handlePodcastUpload}
                className="profile-button"
              />
              <span className="label-margin">
                Recommended dimension: (1:1, 3:2, 4:3, 16:9, 2:3, 3:4, 9:16)
              </span>
            </div>
          )}
        </div>
        {!podcastId && (
          <div className={cn('notify-box')}>
            <input
              type="checkbox"
              checked={subscribedFans}
              onChange={() => {
                setSubScribedFans(!subscribedFans);
                handleValueChange(fields.notify_subscribers, !subscribedFans);
              }}
            />
            Notify all paid fans
          </div>
        )}
      </div>
      <div className="creator-form-error p1">{error}</div>
      <div className="btn-container">
        <Button
          title="Cancel"
          className="profile-button"
          type="secondary"
          onClick={handleMainCancel}
        />
        <Button
          title={podcastId ? 'Save Changes' : 'Save Podcast'}
          className="submit-button"
          onClick={handleSubmit}
          type="submit"
          disabled={
           isDisabled
          }
        />
      </div>
      {(status === LOADING || isLoading || processLoading) && <Loading type="ball-clip-rotate" />}
      {uploadProgress > 0 && (
        <div style={{ marginTop: '10px' }}>
          <div style={{ textAlign: 'center', marginTop: '5px', color: 'white' }}>
            Finishing Upload
          </div>
          <Line percent={uploadProgress} strokeWidth={1} strokeColor="#2400FC" />
          <div style={{ textAlign: 'center', marginTop: '15px', color: 'white' }}>
            {uploadProgress}% Complete
          </div>
        </div>
      )}
      {cropModalOpen && (
        <ImageCropperModal
          visible={cropModalOpen}
          imageUrl={croppingUrl}
          onCancel={handleCancel}
          onCrop={handleCrop}
          aspectRatio={aspectRatio}
        />
      )}
    </div>
  );
};

export default memo(AddPodcastForm);
