import httpClient from 'httpClient';
import { CONTENT_TYPE, MULTIPART_FORM_DATA } from './userService';

class AdService {
  static addBannerAd(celebrityId, banner) {
    return httpClient.post(`/celebrities/${celebrityId}/banner_ads`, banner, {
      headers: { [CONTENT_TYPE]: MULTIPART_FORM_DATA },
      transformRequest: () => banner
    });
  }

  static updateBannerAd(celebrityId, banner, id) {
    return httpClient.put(`/celebrities/${celebrityId}/banner_ads/${id}`, banner, {
      headers: { [CONTENT_TYPE]: MULTIPART_FORM_DATA },
      transformRequest: () => banner
    });
  }

  static getBannerById(bannerId, celebrityId) {
    return httpClient.get(`/celebrities/${celebrityId}/banner_ads/${bannerId}`);
  }

  static addWidgetAd(celebrityId, widget) {
    return httpClient.post(`/celebrities/${celebrityId}/widget_ads`, widget, {
      headers: { [CONTENT_TYPE]: MULTIPART_FORM_DATA },
      transformRequest: () => widget
    });
  }

  static updateWidgetAd(celebrityId, widget, id) {
    return httpClient.put(`/celebrities/${celebrityId}/widget_ads/${id}`, widget, {
      headers: { [CONTENT_TYPE]: MULTIPART_FORM_DATA },
      transformRequest: () => widget
    });
  }

  static getBannerAdList(celebrityId) {
    return httpClient.get(`/celebrities/${celebrityId}/banner_ads`);
  }

  static deleteBanner(celebrityId, bannerId) {
    return httpClient.delete(`/celebrities/${celebrityId}/banner_ads/${bannerId}`);
  }

  static deleteWidget(celebrityId, widgetId) {
    return httpClient.delete(`/celebrities/${celebrityId}/widget_ads/${widgetId}`);
  }

  static getVideoAdList(celebrityId) {
    return httpClient.get(`/celebrities/${celebrityId}/video_ads`);
  }

  static getWidgetAdList(celebrityId) {
    return httpClient.get(`/celebrities/${celebrityId}/widget_ads`);
  }

  static getWidgetAdByID(widgetId, celebrityId) {
    return httpClient.get(`/celebrities/${celebrityId}/widget_ads/${widgetId}`);
  }

  static deleteNarrativeIndivualwidgetAd(celebrityId, narrativeId, widgetId) {
    return httpClient.delete(
      `/celebrities/${celebrityId}/narratives/${narrativeId}/widget_ads/${widgetId}`
    );
  }

  static deleteSeriesIndivualwidgetAd(celebrityId, episodeId, widgetId) {
    return httpClient.delete(
      `/celebrities/${celebrityId}/episodes/${episodeId}/widget_ads/${widgetId}`
    );
  }

  static disableNarrativewidgetAd(celebrityId, narrativeId, widgetId) {
    return httpClient.put(
      `/celebrities/${celebrityId}/narratives/${narrativeId}/widget_ads/${widgetId}/disable`
    );
  }

  static disableSerieswidgetAd(celebrityId, episodeId, widgetId) {
    return httpClient.put(
      `/celebrities/${celebrityId}/episodes/${episodeId}/widget_ads/${widgetId}/disable`
    );
  }

  static deleteNarrativeIndivualbannertAd(celebrityId, narrativeId, bannertId) {
    return httpClient.delete(
      `/celebrities/${celebrityId}/narratives/${narrativeId}/banner_ads/${bannertId}`
    );
  }

  static deleteSeriesIndivualbannerAd(celebrityId, episodeId, bannertId) {
    return httpClient.delete(
      `/celebrities/${celebrityId}/episodes/${episodeId}/banner_ads/${bannertId}`
    );
  }

  static disableNarrativebannerAd(celebrityId, narrativeId, bannertId) {
    return httpClient.put(
      `/celebrities/${celebrityId}/narratives/${narrativeId}/banner_ads/${bannertId}/disable`
    );
  }

  static disableSeriesbannerAd(celebrityId, episodeId, bannertId) {
    return httpClient.put(
      `/celebrities/${celebrityId}/episodes/${episodeId}/banner_ads/${bannertId}/disable`
    );
  }
}

export default AdService;
