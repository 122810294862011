import { useDispatch } from 'react-redux';
import mixpanel from 'mixpanel-browser';

import { MIXPANEL_EVENTS } from 'constants/constants';
import useSession from 'hooks/useSession';
import { logout, unregisterDevice } from 'state/actions/userActions';

export default () => {
  const dispatch = useDispatch();
  const { token } = useSession();

  const logoutRequest = async () => {
    // await dispatch(unregisterDevice(token));
    dispatch(logout());
    mixpanel.reset();
    mixpanel.unregister(MIXPANEL_EVENTS.loggedIn);
    localStorage.clear();
  };

  return {
    logout: logoutRequest
  };
};
