import React, { useState } from 'react';
import { func } from 'prop-types';

import { useClickOutside } from 'hooks';

import Login from 'components/user/Login/Login';
import SignUp from 'components/user/SignUp/SignUp';
import VerifyPhone from 'components/user/VerifyPhone/VerifyPhone';
import Close from 'components/common/Close/Close';

const LOGIN = 1;
const SIGN_UP = 2;
const VERIFY_PHONE = 3;

const AuthModal = ({ hide, onSuccess }) => {
  const [visiblePage, setVisiblePage] = useState(LOGIN);
  const [isSignUp, setIsSignUp] = useState(false);
  const ref = useClickOutside(hide, visiblePage);

  const onVerifySuccess = async () => {
    hide();
    !!onSuccess && onSuccess();
  };

  const renderContent = visiblePage => {
    switch (visiblePage) {
      case LOGIN:
        return (
          <Login
            isModal
            redirectSignUp={() => setVisiblePage(SIGN_UP)}
            redirectVerifyPhone={() => setVisiblePage(VERIFY_PHONE)}
          />
        );
      case SIGN_UP:
        return (
          <SignUp
            isModal
            redirectLogin={() => setVisiblePage(LOGIN)}
            redirectVerifyPhone={() => {
              setIsSignUp(true);
              setVisiblePage(VERIFY_PHONE);
            }}
          />
        );
      case VERIFY_PHONE:
        return <VerifyPhone isSignUp={isSignUp} isModal onVerifySuccess={onVerifySuccess} />;
      default:
    }
  };

  return (
    <div ref={ref} className="auth-modal">
      <div className="auth-modal-gradient" />
      <Close onClick={hide} />
      {renderContent(visiblePage)}
    </div>
  );
};

AuthModal.propTypes = {
  hide: func,
  onSuccess: func
};

export default AuthModal;
