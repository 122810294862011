import { useSelector, useDispatch } from 'react-redux';
import { useStatus, ERROR, SUCCESS, useLoading } from '@rootstrap/redux-tools';

import { getCelebrity } from 'state/actions/celebritiesActions';
import { useCallback } from 'react';
import useSession from './useSession';

export default id => {
  const { status } = useStatus(getCelebrity);
  const dispatch = useDispatch();
  const {
    user: { id: currentUserId }
  } = useSession();

  const reset = () => {
    dispatch(getCelebrity.reset());
  };

  const request = useCallback(
    celebrityId => {
      const creatorId = celebrityId || id;
      dispatch(getCelebrity(creatorId, creatorId !== currentUserId));
    },
    [currentUserId, dispatch, id]
  );

  return {
    celebrity: useSelector(({ celebrities: { celebrity } }) => celebrity),
    error: status === ERROR,
    refresh: celebrityId => request(celebrityId),
    success: status === SUCCESS,
    loading: useLoading(getCelebrity),
    status,
    reset
  };
};
