import React from 'react';
import Loader from 'react-loaders';
import { string } from 'prop-types';

import { secondaryColor } from 'styles/common/_constants.scss';

const Loading = ({ type, color }) => (
  <div className="loading-wrapper">
    <Loader type={type} active color={color} />
  </div>
);

Loading.propTypes = {
  type: string,
  color: string
};

Loading.defaultProps = {
  type: 'ball-scale-multiple',
  color: secondaryColor
};

export default Loading;
