import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { useIsMobile } from 'hooks';

import { ReducedLogoIcon } from 'components/icons';
import { string } from 'prop-types';

const LOGO_BIG = 153;
const LOGO_SMALL = 60;

const NotificationsEmpty = ({ titleId, subtitleId }) => {
  const intl = useIntl();
  const isMobile = useIsMobile();

  const logoSize = useMemo(() => (isMobile ? LOGO_BIG : LOGO_SMALL), [isMobile]);

  return (
    <div className="empty-message">
      <ReducedLogoIcon width={logoSize} height={logoSize} opacity={0.1} />
      <h2 className="title">{intl.formatMessage({ id: titleId })}</h2>
      {!!subtitleId && <div className="p2 subtitle">{intl.formatMessage({ id: subtitleId })}</div>}
    </div>
  );
};

NotificationsEmpty.propTypes = {
  titleId: string,
  subtitleId: string
};

export default NotificationsEmpty;
