import React from 'react';
import { string } from 'prop-types';

import { primaryColor } from 'styles/common/_constants.scss';

const NotificationLabel = ({ label, color }) => (
  <div className="notification-label notif-bubble" style={{ background: color }}>
    {label}
  </div>
);

NotificationLabel.propTypes = {
  label: string.isRequired,
  color: string
};

NotificationLabel.defaultProps = {
  color: primaryColor
};

export default NotificationLabel;
