import React, { useEffect, useMemo, useState } from 'react';
import { shape, bool, string, func } from 'prop-types';
import { SUCCESS } from '@rootstrap/redux-tools';

import { celebrityShape } from 'constants/propTypesShapes';
import { useIsMobile, useModal } from 'hooks';
import useProfile from 'hooks/users/useProfile';
import { zIndexModalSecond } from 'styles/common/_constants.scss';
import { checkSubscription } from 'utils/helpers';
import useSubscribeContext from 'hooks/account/useSubscribeContext';

import AuthModal from 'components/user/AuthModal/AuthModal';
import MembershipDetails from 'components/membership/MembershipDetails/MembershipDetails';
import PurchaseModal from 'components/payments/PurchaseModal/PurchaseModal';
import Button from 'components/common/Button/Button';
import Modal from 'components/common/Modal/Modal';
import Crown from 'components/icons/membership/Crown';

const ICON_SIZE = 20;

const SubscribeButton = ({ celebrity, title, size, onSuccess, smallBoldFont, hideCrownMobile }) => {
  const { closeMenu, paymentsVisible } = useSubscribeContext();
  const isMobile = useIsMobile();

  const { toggle: toggleAuth, isShowing: isShowingAuth } = useModal();
  const { toggle: toggleSubscribe, isShowing: isShowingSubscribe } = useModal();
  const { toggle: togglePayments, isShowing: isShowingPayments } = useModal(
    isMobile && paymentsVisible
  );
  const [showedAuth, setShowedAuth] = useState(false);

  const {
    profile: { memberships },
    requestProfile,
    status,
    reset
  } = useProfile();

  const isSubscribed = checkSubscription(memberships, celebrity.id);
  const showCrownIcon = useMemo(() => isSubscribed || !hideCrownMobile || !isMobile, [
    isSubscribed,
    hideCrownMobile,
    isMobile
  ]);

  const showAuthModal = () => {
    setShowedAuth(true);
    toggleAuth();
  };

  const hidePaymentsModal = () => {
    closeMenu();
    togglePayments();
  };

  useEffect(() => {
    if (status === SUCCESS) {
      if (showedAuth) {
        isSubscribed && !!onSuccess && onSuccess();
        !isSubscribed && togglePayments();
      }
      reset();
      setShowedAuth(false);
    }
  }, [status]);

  return (
    <>
      <Button
        size={size}
        type={isSubscribed ? 'black' : 'primary'}
        icon={showCrownIcon && <Crown width={ICON_SIZE} height={ICON_SIZE} />}
        title={title}
        labelId={isSubscribed ? 'membership.subscribed' : 'membership.subscribe'}
        onClick={toggleSubscribe}
        smallBoldFont={smallBoldFont}
        className="subscribe-button"
      />
      <Modal hide={toggleAuth} isShowing={isShowingAuth} zIndex={zIndexModalSecond}>
        <AuthModal isModal onSuccess={requestProfile} />
      </Modal>
      <Modal hide={toggleSubscribe} isShowing={isShowingSubscribe}>
        <MembershipDetails
          celebrity={celebrity}
          togglePayments={togglePayments}
          redirectToAuth={showAuthModal}
        />
      </Modal>
      <Modal hide={hidePaymentsModal} isShowing={isShowingPayments}>
        <PurchaseModal celebrity={celebrity} onSuccess={onSuccess} />
      </Modal>
    </>
  );
};

SubscribeButton.defaultProps = {
  size: 'xxsmall'
};

SubscribeButton.propTypes = {
  celebrity: shape(celebrityShape).isRequired,
  onSuccess: func,
  title: string,
  size: string,
  smallBoldFont: bool,
  hideCrownMobile: bool
};

export default SubscribeButton;
