/* eslint-disable camelcase */
/* eslint-disable no-useless-catch */
/* eslint-disable no-plusplus */
/* eslint-disable no-await-in-loop */
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useStatus, SUCCESS, ERROR } from '@rootstrap/redux-tools';

import { useNavigation, useSession, useToast } from 'hooks';
import capitalize from 'lodash/capitalize';
import parseError from 'utils/parseError';
import UserService from 'services/userService';
import { addBannerAd } from 'state/actions/bannerAdActions';
import { routeWithProps } from 'utils/helpers';
import { routesPaths } from 'constants/routesPaths';
import { useParams } from 'react-router-dom';

export default () => {
  const dispatch = useDispatch();
  const {
    user: { id: celebrityId }
  } = useSession();
  const username = useRef();
  const { goTo } = useNavigation();
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const { status, error } = useStatus(addBannerAd);
  const { type } = useParams();
  const [isOverLimit, setIsOverLimit] = useState(false);
  const onSubmit = useCallback(
    async data => {
      setLoading(true);
      const formData = new FormData();
      Object.keys(data).forEach(key => {
        if (key === 'episode_ids' || key === 'narrative_ids') {
          if (Array.isArray(data[key])) {
            data[key].forEach(id => {
              formData.append(`banner_ad[${key}][]`, id);
            });
          } else if (typeof data[key] === 'string') {
            const ids = data[key].split(',').map(id => id.trim());
            ids.forEach(id => {
              formData.append(`banner_ad[${key}][]`, id);
            });
          }
        } else if (data[key] instanceof File) {
          formData.append(`banner_ad[${key}]`, data[key], data[key].name);
        } else {
          formData.append(`banner_ad[${key}]`, data[key]);
        }
      });
      try {
        const result = await dispatch(addBannerAd(celebrityId, formData));
        if (result.error && result.error.overlimit) {
          setIsOverLimit(true);
          showToast('You have already uploaded the maximum number of ads.');
        }
      } catch (error) {
        console.error('Error submitting banner ad:', error);
      } finally {
        setLoading(false);
      }
    },
    [dispatch, showToast]
  );

  const getProfile = async () => {
    try {
      const { data } = await UserService.getProfile();
      username.current = data.user.username;
    } catch ({ response }) {
      throw parseError(response);
    }
  };

  useEffect(() => {
    getProfile();
  }, []);

  useEffect(() => {
    if (status === SUCCESS) {
      showToast('Banner Ad Created!');
      if (type) {
        window.history.back();
      } else {
        goTo(routeWithProps(routesPaths.adManager, { tab: 'bannerAds' }));
      }
      dispatch(addBannerAd.reset());
    }
    if (status === ERROR) {
      if (error === 'You have already uploaded maximum number of ads.') {
        setIsOverLimit(true);
      }
      dispatch(addBannerAd.reset());
    }
  }, [status, dispatch, showToast, error]);

  return {
    onSubmit,
    status,
    loading,
    isOverLimit,
    error: capitalize(error)
  };
};
