import React from 'react';
import { string, object } from 'prop-types';

import BenefitSquare from 'components/icons/brand/BenefitSquare';

const MembershipBenefit = ({ icon, title, description }) => (
  <div className="benefit">
    <div className="benefit-square">
      <BenefitSquare />
      <div className="benefit-icon">{icon}</div>
    </div>
    <div className="benefit-content">
      <h5 className="benefit-title bold">{title}</h5>
      <h6 className="benefit-description">{description}</h6>
    </div>
  </div>
);

MembershipBenefit.propTypes = {
  title: string.isRequired,
  description: string.isRequired,
  icon: object.isRequired
};

export default MembershipBenefit;
