import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useStatus, LOADING } from '@rootstrap/redux-tools';

import { getCelebrityLiveURL } from 'state/actions/celebritiesActions';

export default id => {
  const dispatch = useDispatch();

  const { status, error } = useStatus(getCelebrityLiveURL);

  const requestLiveUrl = useCallback(() => {
    dispatch(getCelebrityLiveURL.reset());
    dispatch(getCelebrityLiveURL(id));
  }, [dispatch, id]);

  const reset = useCallback(() => {
    dispatch(getCelebrityLiveURL.reset());
  }, [dispatch]);

  return {
    error,
    status,
    loading: status === LOADING,
    celebrityLive: useSelector(({ celebrities: { celebrityLive } }) => celebrityLive),
    requestLiveUrl,
    reset
  };
};
