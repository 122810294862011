import React from 'react';
import { number, string } from 'prop-types';

import { white } from 'styles/common/_constants.scss';

const BackArrowIcon = ({ width = 16, height = 16, color = white }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="logoTitle logoDesc"
    role="img"
  >
    <title id="logoTitle">Back</title>
    <desc id="logoDesc">Back icon</desc>
    <path d="M15 8H1" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M8 15L1 8L8 1"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

BackArrowIcon.propTypes = {
  width: number,
  height: number,
  color: string
};

export default BackArrowIcon;
