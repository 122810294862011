import React, { useEffect, useState } from 'react';
import TrashIcon from 'assets/trash.png';
import cn from 'classnames';
import { useNavigation, useToast, useModal } from 'hooks';
import { routeWithProps } from 'utils/helpers';
import { routesPaths } from 'constants/routesPaths';
import VidoeAdService from 'services/videoAds';
import { useParams } from 'react-router-dom';
import { zIndexModalThird } from 'styles/common/_constants.scss';
import Modal from 'components/common/Modal/Modal';
import UploadIcon from '../../assets/plus.png';
import sample from '../../assets/samplevideo.png';
import CommonSwitch from '../common/Switch/Switch';
import DeleteAd from './DeleteAd/DeleteAd';

const ManageVideoAds = ({ VideoAds }) => {
  const { narrativeId, celebrityId, episodeId } = useParams();
  const [beforevideodisSwitch, beforevideosetdisSwitch] = useState(false);
  const [aftervideodisSwitch, aftervideosetdisSwitch] = useState(false);
  const [customIntervalAddisSwitch, customIntervalAdsetdisSwitch] = useState(false);
  const [specificIntervalAddisSwitch, specificIntervalAdsetdisSwitch] = useState(false);
  const { toggle, isShowing } = useModal();
  const { goTo } = useNavigation();
  const beforeVideoAd = VideoAds?.find(ad => ad.placementOption === 'before_video');
  const afterVideoAd = VideoAds?.find(ad => ad.placementOption === 'after_video');
  const customIntervalAd = VideoAds?.find(ad => ad.placementOption === 'custom_intervals');
  const specificIntervalAd = VideoAds?.find(ad => ad.placementOption === 'specific_interval');
  useEffect(() => {}, [VideoAds]);
  const [adStates, setAdStates] = useState({
    before_video: !beforeVideoAd?.disable,
    after_video: !afterVideoAd?.disable,
    custom_intervals: !customIntervalAd?.disable,
    specific_interval: !specificIntervalAd?.disable,
  });
  useEffect(() => {
    setAdStates({
      before_video: beforeVideoAd ? !beforeVideoAd.disable : false,
      after_video: afterVideoAd ? !afterVideoAd.disable : false,
      custom_intervals: customIntervalAd ? !customIntervalAd.disable : false,
      specific_interval: specificIntervalAd ? !specificIntervalAd.disable : false,
    });
  }, [beforeVideoAd, afterVideoAd, customIntervalAd, specificIntervalAd]);
  const [currentId, setCurrentId] = useState();
  const { showErrorToast, showToast } = useToast();
  function convertMinutesToHHMMSS(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = Math.floor(minutes % 60);
    const seconds = Math.floor((minutes % 1) * 60);
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(remainingMinutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }
  const handleApiCall = () => {
    // getVideoAdList();
    window.location.reload();
  };
  const handleSwitchChange = async (nextChecked, adType, adData) => {
    if (adType === 'before_video') {
      beforevideosetdisSwitch(true);
    } else if (adType === 'after_video') {
      aftervideosetdisSwitch(true);
    } else if (adType === 'custom_intervals') {
      customIntervalAdsetdisSwitch(true);
    } else if (adType === 'specific_interval') {
      specificIntervalAdsetdisSwitch(true);
    }
    setAdStates(prevState => ({
      ...prevState,
      [adType]: nextChecked
    }));
    if (narrativeId) {
      try {
        const { data } = await VidoeAdService.disableNarrativevideoAd(
          celebrityId,
          narrativeId,
          adData.id
        );
        showToast(data.message);
        if (adType === 'before_video') {
          beforevideosetdisSwitch(false);
        } else if (adType === 'after_video') {
          aftervideosetdisSwitch(false);
        } else if (adType === 'custom_intervals') {
          customIntervalAdsetdisSwitch(false);
        } else if (adType === 'specific_interval') {
          specificIntervalAdsetdisSwitch(false);
        }
        window.location.reload();
      } catch ({ response }) {
        showErrorToast('Something went wrong!');
        if (adType === 'before_video') {
          beforevideosetdisSwitch(false);
        } else if (adType === 'after_video') {
          aftervideosetdisSwitch(false);
        } else if (adType === 'custom_intervals') {
          customIntervalAdsetdisSwitch(false);
        } else if (adType === 'specific_interval') {
          specificIntervalAdsetdisSwitch(false);
        }
      }
    } else {
      try {
        const { data } = await VidoeAdService.disableSeriesvideoAd(
          celebrityId,
          episodeId,
          adData.id
        );
        showToast(data.message);
        if (adType === 'before_video') {
          beforevideosetdisSwitch(false);
        } else if (adType === 'after_video') {
          aftervideosetdisSwitch(false);
        } else if (adType === 'custom_intervals') {
          customIntervalAdsetdisSwitch(false);
        } else if (adType === 'specific_interval') {
          specificIntervalAdsetdisSwitch(false);
        }
        window.location.reload();
      } catch ({ response }) {
        showErrorToast('Something went wrong!');
        if (adType === 'before_video') {
          beforevideosetdisSwitch(false);
        } else if (adType === 'after_video') {
          aftervideosetdisSwitch(false);
        } else if (adType === 'custom_intervals') {
          customIntervalAdsetdisSwitch(false);
        } else if (adType === 'specific_interval') {
          specificIntervalAdsetdisSwitch(false);
        }
      }
    }
  };

  const adTarget = narrativeId ? 'Shorts' : 'Movies & Series';

  const handleClick = adPlacement => {
    goTo(
      routeWithProps(routesPaths.individualaddvideoAds, {
        type: narrativeId ? 'narrative' : 'seriesid',
        id: narrativeId || episodeId,
        handlePlacement: adPlacement
      })
    );
  };
  return (
    <div style={{ padding: '0 0 5rem 0' }}>
      {beforeVideoAd != null ? (
        <>
          <span className="headings"> Before the video </span>
          <div className="loadVideoAds">
            <div className="video-info">
              <div>
                <img
                  className="Videothumbnail"
                  src={beforeVideoAd.thumbnailsUrls?.[0].src || sample}
                  alt="thumbnail"
                />
              </div>
              <div className="details-manages">
                <span className="custom-title video-custom-title">{beforeVideoAd.title}</span>
                <span className="custom-des">00:00:{beforeVideoAd.videoLength}</span>
              </div>
            </div>
            <div className="control-manages">
              <button
                type="button"
                style={{ cursor: 'pointer', marginRight: '10px' }}
                className="action-button"
                onClick={() => {
                  setCurrentId(beforeVideoAd.id);
                  toggle();
                }}
              >
                <img height="20px" width="20px" src={TrashIcon} alt="trash" />
              </button>
              <CommonSwitch
                checked={adStates.before_video}
                checkedIcon={false}
                uncheckedIcon={false}
                disabled={beforevideodisSwitch}
                onColor="#2400FC"
                onChange={nextChecked =>
                  handleSwitchChange(nextChecked, 'before_video', beforeVideoAd)
                }
              />
            </div>
          </div>
          <hr />
        </>
      ) : (
        <>
          <span className="headings"> Before the video </span>
          <div className="newVideoAds">
            <div className="video-desc">
              <span className="custom-title">
                Video Ad before your {adTarget}
              </span>
              <span className="custom-des">
                Upload a video Ad that you want to showcase just before your{' '}
                {adTarget}.
              </span>
            </div>
            <div>
              <div className={cn('button-container')}>
                <button
                  type="button"
                  className={cn('upload-button')}
                  onClick={() => {
                    handleClick('before_video');
                  }}
                >
                  <img
                    height="20x"
                    width="20px"
                    className={cn('upload-icon')}
                    src={UploadIcon}
                    alt="upload"
                  />{' '}
                  UPLOAD VIDEO
                </button>
              </div>
            </div>
          </div>
          <hr />
        </>
      )}

      {afterVideoAd != null ? (
        <>
          <span className="headings"> After the video </span>
          <div className="loadVideoAds">
            <div className="video-info">
              <img
                className="Videothumbnail"
                src={afterVideoAd.thumbnailsUrls?.[0].src || sample}
                alt="thumbnail"
              />
              <div className="details-manages">
                <span className="custom-title video-custom-title">{afterVideoAd.title}</span>
                <span className="custom-des">00:00:{afterVideoAd?.videoLength}</span>
              </div>
            </div>
            <div className="control-manages">
              <button
                type="button"
                style={{ cursor: 'pointer', marginRight: '10px' }}
                className="action-button"
                onClick={() => {
                  setCurrentId(afterVideoAd.id);
                  toggle();
                }}
              >
                <img height="20px" width="20px" src={TrashIcon} alt="trash" />
              </button>
              <CommonSwitch
                checked={adStates.after_video}
                checkedIcon={false}
                uncheckedIcon={false}
                disabled={aftervideodisSwitch}
                onColor="#2400FC"
                onChange={nextChecked =>
                  handleSwitchChange(nextChecked, 'after_video', afterVideoAd)
                }
              />
            </div>
          </div>
          <hr />
        </>
      ) : (
        <>
          <span className="headings"> After the video </span>
          <div className="newVideoAds">
            <div className="video-desc">
              <span className="custom-title">
                Video Ad after your {adTarget}
              </span>
              <span className="custom-des">
                Upload a video Ad that you want to showcase just after your{' '}
                {adTarget}.
              </span>
            </div>
            <div>
              <div className={cn('button-container')}>
                <button
                  type="button"
                  className={cn('upload-button')}
                  onClick={() => {
                    handleClick('after_video');
                  }}
                >
                  <img
                    height="20x"
                    width="20px"
                    className={cn('upload-icon')}
                    src={UploadIcon}
                    alt="upload"
                  />{' '}
                  UPLOAD VIDEO
                </button>
              </div>
            </div>
          </div>
          <hr />
        </>
      )}

      {customIntervalAd != null ? (
        <>
          <span className="headings"> At specific interval </span>
          <div className="loadVideoAds">
            <div className="video-info">
              <img
                className="Videothumbnail"
                src={customIntervalAd.thumbnailsUrls?.[0].src || sample}
                alt="thumbnail"
              />
              <div className="details-manages">
                <span className="custom-title video-custom-title">{customIntervalAd.title}</span>

                {/* <span className="headings1">Specific Interval</span> */}
                <span className="custom-des">
                  {convertMinutesToHHMMSS(customIntervalAd.specificInterval)}
                </span>
              </div>
            </div>
            <div className="control-manages">
              <button
                type="button"
                style={{ cursor: 'pointer', marginRight: '10px' }}
                className="action-button"
                onClick={() => {
                  setCurrentId(customIntervalAd.id);
                  toggle();
                }}
              >
                <img height="20px" width="20px" src={TrashIcon} alt="trash" />
              </button>
              <CommonSwitch
                checked={adStates.custom_intervals}
                onColor="#2400FC"
                checkedIcon={false}
                uncheckedIcon={false}
                disabled={customIntervalAddisSwitch}
                onChange={nextChecked =>
                  handleSwitchChange(nextChecked, 'custom_intervals', customIntervalAd)
                }
              />
            </div>
          </div>
          <hr />
        </>
      ) : (
        <>
          <span className="headings"> At specific interval </span>
          <div className="newVideoAds">
            <div className="video-desc">
              <span className="custom-title">Video Ad at specific interval</span>
              <span className="custom-des">
                Upload a video Ad that you want to showcase at specific interval e.g. at every 5
                min, 10 min, 15 min..
              </span>
            </div>
            <div>
              <div className={cn('button-container')}>
                <button
                  type="button"
                  className={cn('upload-button')}
                  onClick={() => {
                    handleClick('custom_intervals');
                  }}
                >
                  <img
                    height="20x"
                    width="20px"
                    className={cn('upload-icon')}
                    src={UploadIcon}
                    alt="upload"
                  />{' '}
                  UPLOAD VIDEO
                </button>
              </div>
            </div>
          </div>
          <hr />
        </>
      )}
      {specificIntervalAd != null ? (
        <>
          <span className="headings"> Custom Time </span>
          <div className="loadVideoAds">
            <div className="video-info">
              <img
                className="Videothumbnail"
                src={specificIntervalAd.thumbnailsUrls?.[0].src || sample}
                alt="thumbnail"
              />
              <div className="details-manages">
                <span className="custom-title video-custom-title">{specificIntervalAd.title}</span>
                {/* <span className="headings1">Custom Duration</span> */}
                {specificIntervalAd.customIntervals.map(interval => {
                  return <span className="custom-des">{interval}</span>;
                })}
              </div>
            </div>
            <div className="control-manages">
              <button
                type="button"
                style={{ cursor: 'pointer', marginRight: '10px' }}
                className="action-button"
                onClick={() => {
                  setCurrentId(specificIntervalAd.id);
                  toggle();
                }}
              >
                <img height="20px" width="20px" src={TrashIcon} alt="trash" />
              </button>
              <CommonSwitch
                checked={adStates.specific_interval}
                onColor="#2400FC"
                checkedIcon={false}
                uncheckedIcon={false}
                disabled={specificIntervalAddisSwitch}
                onChange={nextChecked =>
                  handleSwitchChange(nextChecked, 'specific_interval', specificIntervalAd)
                }
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <span className="headings">Custom time</span>
          <div className="newVideoAds">
            <div className="video-desc">
              <span className="custom-title">Video Ad at custom time</span>
              <span className="custom-des">
                Upload a video Ad that you want to showcase at custom time.
              </span>
            </div>
            <div>
              <div className={cn('button-container')}>
                <button
                  type="button"
                  className={cn('upload-button')}
                  onClick={() => {
                    handleClick('specific_interval');
                  }}
                >
                  <img
                    height="20x"
                    width="20px"
                    className={cn('upload-icon')}
                    src={UploadIcon}
                    alt="upload"
                  />{' '}
                  UPLOAD VIDEO
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      <Modal hide={toggle} isShowing={isShowing} topModal zIndex={zIndexModalThird}>
        <DeleteAd
          handleApiCall={handleApiCall}
          celebrityId={celebrityId}
          currentId={currentId}
          id={narrativeId || episodeId}
          type={adTarget}
          adtype="video"
        />
      </Modal>
    </div>
  );
};

export default ManageVideoAds;
