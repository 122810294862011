import React from 'react';
import { number, string } from 'prop-types';

import { black60 } from 'styles/common/_constants.scss';

const SearchIcon = ({ width = 18, height = 18, color = black60, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="logoTitle logoDesc"
    role="img"
    className={className}
  >
    <title id="logoTitle">Search</title>
    <desc id="logoDesc">Search icon</desc>
    <path
      d="M9.5 16C13.0899 16 16 13.0899 16 9.5C16 5.91015 13.0899 3 9.5 3C5.91015 3 3 5.91015 3 9.5C3 13.0899 5.91015 16 9.5 16Z"
      stroke={color}
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path d="M14 14L21 21" stroke={color} strokeWidth="2" strokeMiterlimit="10" />
  </svg>
);

SearchIcon.propTypes = {
  width: number,
  height: number,
  color: string,
  className: string
};

export default SearchIcon;
