import React from 'react';
import cn from 'classnames';

import { defineMessages, useIntl } from 'react-intl';
import VideoAdsForm from 'components/VideoAds/VideoAdsForm';
import { BackArrowIcon } from 'components/icons';
import { useParams } from 'react-router-dom';

const messages = defineMessages({
  title: { id: 'title.addvideoAds' },
  update: { id: 'update.addvideoAds' }
});

const AddNarratives = () => {
  const intl = useIntl();
  const { type, id, handlePlacement, videoId, celebrityId } = useParams();
  return (
    <div className={cn('become-creator')}>
      <div className="become-creator-gradient" />
      <div className="layer content-container">
        <div className="become-creator-content">
          <div className="container">
            <div className="back-button" onClick={() => window.history.back()}>
              <BackArrowIcon />
            </div>
            <h1 className="title">
              {videoId ? intl.formatMessage(messages.update) : intl.formatMessage(messages.title)}
            </h1>
            <VideoAdsForm
              id={videoId}
              celId={celebrityId}
              handlePlacement={handlePlacement}
              contentType={type}
              contentid={id}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

AddNarratives.loadData = async () => {};

export default AddNarratives;
