/* eslint-disable radix */
/* eslint-disable no-new */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
import CustomCategory from 'components/CustomCategory/CustomCategory';
import Button from 'components/common/Button/Button';
import Input from 'components/common/Input/Input';
import Loading from 'components/common/Loading';
import { useForm, useModal, useTextInputProps, useToast, useValidation } from 'hooks';
import useUploadSeries from 'hooks/series/useUploadSeries';
import useEditSeries from 'hooks/series/useEditSeries';
import { some } from 'lodash';
import React, { memo, useEffect, useRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
// import { useDispatch } from 'react-redux';
import { createSeriesValidation } from 'utils/constraints';
import Modal from 'components/common/Modal/Modal';
import cn from 'classnames';
import SeriesService from 'services/seriesService';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CloseIcon from 'components/icons/others/Close';
import LineDoubleIcon from 'components/icons/others/LineDouble';
import { useSelector } from 'react-redux';
import { LOADING } from '@rootstrap/redux-tools';
import { Line } from 'rc-progress';
import parseError from 'utils/parseError';
import Select from 'react-select';
import {
  contentOptions,
  imageSizes,
  moviesImageTypes,
  videoUploadsType
} from 'constants/constants';
import { ValidationErrors } from 'utils/validationErrors';
import { checkImageDimensions, validateVideo } from 'utils/helpers';
import { customDropDownStyles } from 'styles/customDropDownStyles';
import ImageCropperModal from 'components/common/ImageCropper/ImageCropperModal';
import AddEpisodeForm from './AddEpisodeForm';

const fields = {
  id: 'id',
  title: 'title',
  description: 'description',
  logo_image: 'logo_image',
  desktop_image: 'desktop_image',
  mobile_image: 'mobile_image',
  categoryIds: 'categoryIds',
  trailer: 'trailer',
  lead_cast: 'lead_cast',
  credits: 'credits',
  content_viewer_category: 'content_viewer_category',
  notify_subscribers: 'notify_subscribers',
  season: 'season'
};

const messages = defineMessages({
  description: { id: 'form.description' },
  descriptionPlaceholder: { id: 'form.seriesDescriptionPlaceholder' },
  title: { id: 'form.title' },
  titlePlaceholder: { id: 'form.seriesTitlePlaceholder' },
  logo: { id: 'form.logo' },
  uploadLogo: { id: 'form.uploadLogo' },
  trailer: { id: 'form.trailer' },
  uploadTrailer: { id: 'form.uploadTrailer' },
  cover: { id: 'form.cover' },
  uploadCover: { id: 'form.uploadCover' },
  coverMobile: { id: 'form.coverMobile' },
  uploadCoverMobile: { id: 'form.uploadCoverMobile' },
  categories: { id: 'form.categories' },
  addBtn: { id: 'form.add' },
  shareTo: { id: 'form.shareTo' },
  episodes: { id: 'label.episodes' },
  addEpisode: { id: 'btn.addEpisode' },
  newEpisode: { id: 'form.newEpisode' },
  video: { id: 'form.video' },
  nameYourEpisodePlaceholder: { id: 'title.nameYourEpisodePlaceholder' },
  descriptionEpisodePlaceholder: { id: 'form.descriptionEpisodePlaceholder' },
  creditStartTime: { id: 'form.creditStartTime' },
  hhmmssPlaceholder: { id: 'form.hhmmssPlaceholder' },
  date: { id: 'form.date' },
  time: { id: 'form.time' },
  releaseDateTimeLabel: { id: 'form.releaseDateTimeLabel' },
  setPriceLabel: { id: 'form.setPriceLabel' },
  nonMembers: { id: 'form.nonMembers' },
  members: { id: 'form.members' },
  chooseVideo: { id: 'form.chooseVideo' },
  earlyReleaseMembers: { id: 'form.earlyReleaseMembers' },
  normalRelease: { id: 'form.normalRelease' }
});
const CreateSeriesForm = ({ id, celId, episodeId }) => {
  const intl = useIntl();
  const { toggle, isShowing } = useModal();
  const validator = useValidation(createSeriesValidation);
  const logoRef = useRef(null);
  const trailerRef = useRef(null);
  const desktopCoverRef = useRef(null);
  const mobileCoverRef = useRef(null);
  const [logo, setLogo] = useState(null);
  const { showErrorToast } = useToast();
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [croppingUrl, setCroppingUrl] = useState();
  const [aspectRatio, setAspectRatio] = useState();
  const currentType = useRef();
  // const [categories, setCategories] = useState();
  // const [series, setSeries] = useState(null);
  const [seriesData, setSeriesData] = useState();
  const [trailer, setTrailer] = useState(null);
  const [selectedContent, setSelectedContent] = useState([]);
  // const [selectedSeason, setSelectedSeason] = useState([]);
  const [desktopCover, setDesktopCover] = useState(null);
  const [mobileCover, setMobileCover] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [subscribedFans, setSubScribedFans] = useState(true);
  const [editEpisodeData, setEditEpisodeData] = useState(null);
  const { episode } = useSelector(({ episodes: { episode } }) => ({ episode }));
  const {
    onSubmit: uploadSeries,
    status: createStatus,
    error: createError,
    loading: createLoading,
    uploadProgress: createUploadProgress,
    uploadedVideos: createUploadVideos,
    totalVideos: createTotalVideos,
    uploadingStatus: createUploadingStatus
  } = useUploadSeries();

  const {
    onSubmit: editSeries,
    status: editStatus,
    error: editError,
    loading: editLoading,
    uploadProgress: editUploadProgress,
    uploadedVideos: editUploadVideos,
    totalVideos: editTotalVideos,
    uploadingStatus: edituploadingStatus
  } = useEditSeries();

  const handleFormSubmit = formData => {
    if (!id) {
      uploadSeries(formData);
    } else {
      const categoryIds = selectedCategories.map(({ value }) => value);
      const newForData = {
        ...formData,
        trailer,
        logo_image: logo,
        desktop_image: desktopCover,
        mobile_image: mobileCover,
        episodes,
        category_ids: categoryIds,
        content_viewer_category: selectedContent.value,
        notify_subscribers: subscribedFans
      };
      editSeries(newForData);
    }
  };

  const { values, errors, handleValueChange, handleSubmit, handleBlur, setValues } = useForm(
    {
      onSubmit: handleFormSubmit,
      validator,
      validateOnBlur: true,
      validateOnChange: true,
      initialValues: {
        notify_subscribers: true
      }
    },
    [handleFormSubmit]
  );
  const [episodes, setEpisodes] = useState([]);

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(episodes);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setEpisodes(items);
  }

  function removeCharacter(index) {
    const updatedCharacters = [...episodes];
    updatedCharacters.splice(index, 1);
    setEpisodes(updatedCharacters);
  }
  const inputProps = useTextInputProps(handleValueChange, handleBlur, values);

  const handleLogoUpload = () => {
    logoRef.current.click();
  };

  const handleTrailerUpload = () => {
    trailerRef.current.click();
  };

  const handleDesktopCoverUpload = () => {
    desktopCoverRef.current.click();
  };

  const handleMobileCoverUpload = () => {
    mobileCoverRef.current.click();
  };

  const handleContentChange = e => {
    setSelectedContent(e);
    handleValueChange(fields.content_viewer_category, e.value);
  };

  const isUrl = string => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  const extractFilename = (url, extension) => {
    if (isUrl(url)) {
      const decodedUrl = decodeURIComponent(url);
      const matches = decodedUrl.match(/\/([^\/?#]+)(?=\?|$)/i);
      const fileName = matches && matches[1] ? matches[1] : null;
      return `${fileName}.${extension}`;
    } else {
      return url?.name;
    }
  };

  // const handleSeasonChange = e => {
  // setSelectedSeason(e);
  // handleValueChange(fields.season, e.value);
  // };

  const handleLogoChange = async e => {
    const file = e.target.files[0];
    const result = await checkImageDimensions(file, imageSizes.logo.width, imageSizes.logo.height);
    if (result.isValid) {
      setCropModalOpen(true);
      setAspectRatio(1 / 1);
      setCroppingUrl(URL.createObjectURL(file));
      currentType.current = moviesImageTypes.Logo;
    } else {
      showErrorToast(result.error);
    }
  };

  const handleCancel = () => {
    setCropModalOpen(false);
    setCroppingUrl();
    setAspectRatio();
    currentType.current = null;
  };

  const handleCrop = async croppedFile => {
    if (currentType.current === moviesImageTypes.Logo) {
      setLogo(croppedFile);
      handleValueChange(fields.logo_image, croppedFile);
    } else if (currentType.current === moviesImageTypes.coverArtMobile) {
      setMobileCover(croppedFile);
      handleValueChange(fields.mobile_image, croppedFile);
    } else {
      setDesktopCover(croppedFile);
      handleValueChange(fields.desktop_image, croppedFile);
    }
  };

  const resetTrailerInput = () => {
    if (trailerRef.current) {
      trailerRef.current.value = '';
    }
  };

  const handleTrailerChange = async e => {
    const file = e.target.files[0];
    // if (file && file.type.startsWith('video/')) {
    // setTrailer(file);
    // handleValueChange(fields.trailer, file);
    // } else {
    // showErrorToast('Please upload a valid video file.');
    // e.target.value = null;
    // }
    if (file && file.type.startsWith('video/')) {
      const { isValid, isResolutionValid, isAspectRatioValid, error } = await validateVideo(
        file,
        videoUploadsType.Movies
      );
      if (error) {
        showErrorToast(ValidationErrors.loadingVideo);
        resetTrailerInput();
      } else if (!isResolutionValid) {
        showErrorToast(ValidationErrors.resolutionValidation);
        resetTrailerInput();
      } else if (!isAspectRatioValid) {
        showErrorToast(ValidationErrors.aspectRatioValidationForMovies);
        resetTrailerInput();
      } else if (isValid) {
        setTrailer(file);
        handleValueChange(fields.trailer, file);
      }
    } else {
      showErrorToast(ValidationErrors.validFile);
      resetTrailerInput();
    }
  };

  const handleDesktopCoverChange = async e => {
    const file = e.target.files[0];
    const result = await checkImageDimensions(
      file,
      imageSizes.coverWeb.width,
      imageSizes.coverWeb.height
    );
    if (result.isValid) {
      setCropModalOpen(true);
      setAspectRatio(16 / 9);
      setCroppingUrl(URL.createObjectURL(file));
      currentType.current = moviesImageTypes.covertArtDesktop;
    } else {
      showErrorToast(result.error);
    }
  };

  const handleMobileCoverChange = async e => {
    const file = e.target.files[0];
    const result = await checkImageDimensions(
      file,
      imageSizes.coverMobile.width,
      imageSizes.coverMobile.height
    );
    if (result.isValid) {
      setCropModalOpen(true);
      setAspectRatio(1 / 1);
      setCroppingUrl(URL.createObjectURL(file));
      currentType.current = moviesImageTypes.coverArtMobile;
    } else {
      showErrorToast(result.error);
    }
  };

  const handleCategoryChange = selectedOptions => {
    setSelectedCategories(selectedOptions);
    handleValueChange(fields.categoryIds, selectedOptions);
  };

  // const getCategories = async () => {
  // try {
  // const { data } = await categoriesService.getCategories();
  // const transformedData = data.categories.map(item => ({
  // value: item.id,
  // label: item.name
  // }));
  // setCategories(transformedData);
  // } catch ({ response }) {
  // throw parseError(response);
  // }
  // };

  const getSeriesData = async () => {
    try {
      const { data } = await SeriesService.getOneSeries(id);
      setSeriesData(data.series);
      if (data) {
        setEpisodes(data.series.episodes);
        setLogo(data.series.logoImageUrl);
        setMobileCover(data.series.mobileImageUrl);
        setDesktopCover(data.series.desktopImageUrl);
        setTrailer(data.series.bannerUrl);
      }
    } catch ({ response }) {
      throw parseError(response);
    }
  };

  const isDisabled = () => {
    const isLoading = createStatus === LOADING || editStatus === LOADING;
    const hasErrors = some(errors);
    const areRequiredFieldsMissing = [
      !values[fields.title],
      !values[fields.description],
      !logo,
      !trailer,
      !desktopCover,
      !mobileCover,
      selectedCategories.length === 0,
      selectedContent.length === 0,
    ].some(Boolean);
    const isSaving = createLoading || editLoading;
    const hasEpisodes = episodes.length === 0;

    return [isLoading, hasErrors, isSaving, hasEpisodes, areRequiredFieldsMissing].some(Boolean);
  };

  const filterEpisodeData = () => {
    const filteredEpisode = episodes.filter(item => item.episodeId === parseInt(episodeId))[0];
    setEditEpisodeData(filteredEpisode);
  };

  useEffect(() => {
    if (editEpisodeData !== null) {
      toggle();
    }
  }, [editEpisodeData]);

  // Episode id is there
  useEffect(() => {
    if (episodeId && episodes.length > 0) {
      filterEpisodeData();
    }
  }, [episodeId, episodes]);

  useEffect(() => {
    if (seriesData && id) {
      const selectedCats = seriesData?.categories.map(item => ({
        value: item.id,
        label: item.name
      }));
      const selectedContent = contentOptions.filter(
        item => item.value === seriesData?.contentViewerCategory
      );
      setSelectedCategories(selectedCats);
      handleValueChange(fields.categoryIds, selectedCats);
      if (seriesData.contentViewerCategory) setSelectedContent(selectedContent[0]);
      setValues({
        ...seriesData,
        [fields.categoryIds]: selectedCats,
        [fields.lead_cast]:
          seriesData.leadCast && seriesData.leadCast !== 'undefined' ? seriesData.leadCast : '',
        [fields.credits]:
          seriesData.credits && seriesData.credits !== 'undefined' ? seriesData.credits : ''
      });
    }
  }, [seriesData]);

  useEffect(() => {
    if (episode) {
      const newData = { ...episode, mime_type: episode.seriesVideo.type, id: episodes.length + 1 };
      if (episodes.length === 0) {
        setEpisodes([newData]);
      } else {
        setEpisodes([...episodes, newData]);
      }
    }
  }, [episode]);

  useEffect(() => {
    if (id) {
      getSeriesData();
    }
  }, [id]);

  // useEffect(() => {
  // getCategories();
  // }, []);

  return (
    <div className="series-form">
      <div className="series-form-fields">
        <Input
          name="title"
          placeholder={intl.formatMessage(messages.titlePlaceholder)}
          label={intl.formatMessage(messages.title)}
          errors={errors[fields.title]}
          {...inputProps(fields.title)}
        />
        <Input
          name="description"
          placeholder={intl.formatMessage(messages.descriptionPlaceholder)}
          label={intl.formatMessage(messages.description)}
          errors={errors[fields.description]}
          {...inputProps(fields.description)}
        />
        <Input
          name="seriesLeadCast"
          placeholder="Lead cast name"
          label="Lead Cast (optional)"
          errors={errors[fields.lead_cast]}
          {...inputProps(fields.lead_cast)}
        />
        {/* <div style={{ width: '100%' }} className={cn('custom-content')}>
<span className="custom-content-label">Season</span>
<div className="single-select">
<Select
closeMenuOnSelect={false}
value={selectedSeason}
options={seasonOptions}
onChange={handleSeasonChange}
/>
</div>
</div> */}
        <Input
          name="seriesCredits"
          placeholder="Credits for season"
          label="Credits (optional)"
          errors={errors[fields.credits]}
          {...inputProps(fields.credits)}
        />
        <CustomCategory value={selectedCategories} handleCategoryChange={handleCategoryChange} />
        <div style={{ width: '100%' }} className={cn('custom-content')}>
          <span className="custom-content-label">Content Viewers</span>
          <div className="single-select">
            <Select
              value={selectedContent}
              options={contentOptions}
              onChange={handleContentChange}
              styles={customDropDownStyles}
              isSearchable={false}
            />
          </div>
        </div>
        <input
          type="file"
          ref={logoRef}
          style={{ display: 'none' }}
          accept="image/*"
          name="logo_image"
          onChange={handleLogoChange}
        />
        <input
          type="file"
          ref={trailerRef}
          style={{ display: 'none' }}
          accept="video/*"
          onChange={handleTrailerChange}
          name="trailer"
        />
        <input
          type="file"
          ref={desktopCoverRef}
          style={{ display: 'none' }}
          accept="image/*"
          name="desktop_image"
          onChange={handleDesktopCoverChange}
        />
        <input
          type="file"
          ref={mobileCoverRef}
          style={{ display: 'none' }}
          accept="image/*"
          onChange={handleMobileCoverChange}
          name="mobile_image"
        />
        <div className="upload-div">
          <span className={logo?.name ? 'white-custom-label' : 'custom-label'}>
            {intl.formatMessage(messages.logo)}
          </span>
          {logo ? (
            <div className="white-label">
              <span className="white-name">{extractFilename(logo, 'png')}</span>
              <span
                className="white-name"
                style={{ cursor: 'pointer' }}
                onClick={() => setLogo(null)}
              >
                x
              </span>
            </div>
          ) : (
            <div className="button-margin">
              <Button
                labelId={intl.formatMessage(messages.uploadLogo)}
                type="secondary"
                onClick={handleLogoUpload}
                className="trailer-button"
              />
              <span className="label-margin">Recommended dimension: 500px X 500px (1:1)</span>
            </div>
          )}
        </div>
        <div className="upload-div">
          <span className={trailer?.name ? 'white-custom-label' : 'custom-label'}>
            {intl.formatMessage(messages.trailer)}
          </span>
          {trailer ? (
            <div className="white-label">
              <span className="white-name">{extractFilename(trailer, 'mp4')}</span>
              <span
                className="white-name"
                style={{ cursor: 'pointer' }}
                onClick={() => setTrailer(null)}
              >
                x
              </span>
            </div>
          ) : (
            <div className="button-margin">
              <Button
                labelId={intl.formatMessage(messages.uploadTrailer)}
                type="secondary"
                onClick={handleTrailerUpload}
                className="trailer-button"
              />
              <span className="label-margin">Recommended dimension: 1280px X 720px (16:9)</span>
            </div>
          )}
        </div>
        <div className="upload-div">
          <span className={desktopCover?.name ? 'white-custom-label' : 'custom-label'}>
            {intl.formatMessage(messages.cover)}
          </span>
          {desktopCover ? (
            <div className="white-label">
              <span className="white-name">{extractFilename(desktopCover, 'png')}</span>
              <span
                className="white-name"
                style={{ cursor: 'pointer' }}
                onClick={() => setDesktopCover(null)}
              >
                x
              </span>
            </div>
          ) : (
            <div className="button-margin">
              <Button
                labelId={intl.formatMessage(messages.uploadCover)}
                type="secondary"
                onClick={handleDesktopCoverUpload}
                className="trailer-button"
              />
              <span className="label-margin">Recommended dimension: 1280px X 720px (16:9)</span>
            </div>
          )}
        </div>
        <div className="upload-div">
          <span className={mobileCover?.name ? 'white-custom-label' : 'custom-label'}>
            {intl.formatMessage(messages.coverMobile)}
          </span>
          {mobileCover ? (
            <div className="white-label">
              <span className="white-name">{extractFilename(mobileCover, 'png')}</span>
              <span
                className="white-name"
                style={{ cursor: 'pointer' }}
                onClick={() => setMobileCover(null)}
              >
                x
              </span>
            </div>
          ) : (
            <div className="button-margin">
              <Button
                labelId={intl.formatMessage(messages.uploadCoverMobile)}
                type="secondary"
                onClick={handleMobileCoverUpload}
                className="trailer-button"
              />
              <span className="label-margin">Recommended dimension: 720px X 720px (1:1)</span>
            </div>
          )}
        </div>
        <div className={cn('notify-box')}>
          <input
            type="checkbox"
            checked={subscribedFans}
            onChange={() => {
              setSubScribedFans(!subscribedFans);
              handleValueChange(fields.notify_subscribers, !subscribedFans);
            }}
          />
          Notify all paid fans
        </div>
        <div className="dnd-series-list">
          <h2>Episodes ({episodes.length})</h2>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="episodes">
              {provided => (
                <ul className="characters" {...provided.droppableProps} ref={provided.innerRef}>
                  {episodes.map(({ id, title }, index) => {
                    return (
                      <Draggable key={id} draggableId={id.toString()} index={index}>
                        {provided => (
                          <li
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <span>{index + 1}</span>
                            <div>
                              <p>
                                <LineDoubleIcon />
                                <span>{title}</span>
                              </p>
                              <button onClick={() => removeCharacter(index)}>
                                <CloseIcon color="#969696" />
                              </button>
                            </div>
                          </li>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
          <div className="flex-buttons">
            <Button labelId="btn.addEpisode" className="submit-button" onClick={toggle} white />
            <Button
              labelId={id ? 'Save Changes' : 'form.narrative.submit'}
              className="share-button"
              onClick={handleSubmit}
              disabled={isDisabled()}
            >
              {((createStatus || editStatus) === LOADING || (createLoading || editLoading)) && (
                <div style={{ marginTop: '-15px' }}>
                  <Loading type="ball-clip-rotate" />
                </div>
              )}
            </Button>
          </div>
        </div>
        <div className="creator-form-error p1" style={{ color: 'red', margin: '0 1.4rem 2.6rem' }}>
          {createError || editError}
        </div>
      </div>
      <Modal hide={toggle} isShowing={isShowing} preventClose={!!editEpisodeData} className="series-form-modal">
        <AddEpisodeForm handleClose={toggle} editEpisodeData={editEpisodeData} seriesId={id} />
      </Modal>
      {((createUploadProgress || editUploadProgress) > 0 ||
        (createUploadingStatus || edituploadingStatus) === 1) && (
        <div>
          <div style={{ textAlign: 'center', marginTop: '5px', color: 'white' }}>
            Finishing Upload {createUploadVideos || editUploadVideos}/
            {createTotalVideos || editTotalVideos}
          </div>
          <Line
            percent={createUploadProgress || editUploadProgress}
            strokeWidth={1}
            strokeColor="#2400FC"
          />
          <div style={{ textAlign: 'center', marginTop: '5px', color: 'white' }}>
            {createUploadProgress || editUploadProgress}% Complete
          </div>
        </div>
      )}
      {cropModalOpen && (
        <ImageCropperModal
          visible={cropModalOpen}
          imageUrl={croppingUrl}
          onCancel={handleCancel}
          onCrop={handleCrop}
          aspectRatio={aspectRatio}
        />
      )}
    </div>
  );
};

export default memo(CreateSeriesForm);
