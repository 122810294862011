import React, { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { useNavigation, useSession, useIsMobile } from 'hooks';
import useProfile from 'hooks/users/useProfile';
import useAccount from 'hooks/account/useAccount';
import { getProfile } from 'state/actions/userActions';
import { accountTabs } from 'constants/constants';
import { getIdFromTab } from 'utils/dataHelpers';

import { BackArrowIcon } from 'components/icons';
import UserHeader from 'components/account/UserHeader/UserHeader';
import AccountModal from 'components/account/AccountModal/AccountModal';
import Modal from 'components/common/Modal/Modal';
import Tabs from 'components/common/Tabs/Tabs';
import { USER_TYPES } from 'hooks/useSession';

const { SUBSCRIPTIONS, ADSUBSCRIPTION } = accountTabs;

const AccountPage = () => {
  const intl = useIntl();
  const { goBack } = useNavigation();
  const { user } = useSession();
  const isMobile = useIsMobile();
  const { tab } = useParams();
  const { requestProfile, profile } = useProfile();

  const selectedTabId = useMemo(() => tab && getIdFromTab(accountTabs, tab), [tab]);
  const [selectedTab, setSelectedTab] = useState(selectedTabId || SUBSCRIPTIONS.id);

  const { renderAccountContent, changeUrl } = useAccount();

  useEffect(() => {
    requestProfile();
  }, []);

  useEffect(() => {
    setSelectedTab(selectedTabId);
  }, [tab, selectedTabId]);

  const onTabChange = id => {
    changeUrl(id);
    setSelectedTab(id);
  };

  const filteredTabs = useMemo(() => {
    return Object.values(accountTabs).filter(tabItem => {
      if (profile.type === USER_TYPES.user && tabItem.id === ADSUBSCRIPTION.id) {
        return false;
      }
      return true;
    });
  }, [profile]);

  return isMobile ? (
    <Modal isShowing hide={() => goBack()} fadeIn>
      <AccountModal initialTab={selectedTabId} />
    </Modal>
  ) : (
    <div className="account">
      <div className="banner-top-bar">
        <div className="back-button" onClick={goBack}>
          <BackArrowIcon />
          <h5 className="bold">{intl.formatMessage({ id: 'general.back' })}</h5>
        </div>
      </div>
      <div className="account-content">
        <div className="account-content-left">
          <UserHeader user={user} />
          <Tabs
            tabs={Object.values(filteredTabs)}
            selectedTab={selectedTab}
            onTabSelect={onTabChange}
            isVertical={!isMobile}
            className="account-landing-tabs"
          />
        </div>
        <div className="account-content-right">{renderAccountContent(selectedTab)}</div>
      </div>
    </div>
  );
};

AccountPage.loadData = async store => {
  await store.dispatch(getProfile());
};

export default AccountPage;
