import React, { Fragment } from 'react';
import { arrayOf, number, shape, string, bool } from 'prop-types';
import cn from 'classnames';

import { episodeShape } from 'constants/propTypesShapes';
import { routesPaths } from 'constants/routesPaths';
import { routeWithProps } from 'utils/helpers';
import { useIsMobile, useNavigation, useSession } from 'hooks';

import EpisodeCard from 'components/episodes/EpisodeCard/EpisodeCard';
import EpisodeThumbnail from 'components/episodes/EpisodeThumbnail/EpisodeThumbnail';

const EpisodesGrid = ({ serieId, episodes, className, mobileColumn, celebrityId }) => {
  const { goTo } = useNavigation();
  const isMobile = useIsMobile();
  const {
    user: { id: loggedId }
  } = useSession();
  const isLoggedUser = loggedId === celebrityId;
  const onEpisodeClick = id => {
    goTo(
      routeWithProps(routesPaths.series, {
        id: serieId,
        episode: id
      })
    );
  };
  const handleAdManager = episode => {
    goTo(
      routeWithProps(routesPaths.managevideoAdsSeries, {
        tab: 'videoAds',
        celebrityId,
        episodeId: episode.episodeId,
        seriesId: serieId
      })
    );
  };

  return (
    <div className={cn('episode-grid', className, { 'vertical-column': mobileColumn })}>
      {episodes.map(episode => (
        <Fragment key={episode.id}>
          {isMobile && !mobileColumn ? (
            <EpisodeThumbnail
              episode={episode}
              Ads={() => {
                handleAdManager(episode);
              }}
              isLoggedUser={isLoggedUser}
              onClick={() => onEpisodeClick(episode.id)}
            />
          ) : (
            <EpisodeCard
              episode={episode}
              onClick={() => onEpisodeClick(episode.id)}
              Ads={() => {
                handleAdManager(episode);
              }}
              serieId={serieId}
              isLoggedUser={isLoggedUser}
              className={mobileColumn ? 'vertical-grid-card' : ''}
            />
          )}
        </Fragment>
      ))}
    </div>
  );
};

EpisodesGrid.propTypes = {
  episodes: arrayOf(shape(episodeShape)).isRequired,
  serieId: number.isRequired,
  celebrityId: number.isRequired,
  className: string,
  mobileColumn: bool
};

export default EpisodesGrid;
