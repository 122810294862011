import httpClient from 'httpClient';

class PaymentsService {
  createCard(data) {
    return httpClient.post('/credit_cards', data);
  }

  getCards() {
    return httpClient.get('/user/credit_cards');
  }

  setDefaultCreditCard(id) {
    return httpClient.put(`/credit_cards/${id}/default`);
  }
}

export default new PaymentsService();
