import React from 'react';

import { SOCIAL_PLATFORMS } from 'constants/constants';

const SocialPlatforms = () => (
  <div className="social-networks">
    {SOCIAL_PLATFORMS.map(({ name, link, icon }) => (
      <a key={name} href={link} className="icon" target="_blank" rel="noopener noreferrer">
        {icon}
      </a>
    ))}
  </div>
);

export default SocialPlatforms;
