import React from 'react';
import { func, string, shape, bool } from 'prop-types';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import Button from 'components/common/Button/Button';
import Crown from 'components/icons/membership/Crown';

import { getShortPeriod } from 'utils/helpers';
import { membershipPlan } from 'constants/propTypesShapes';

const SubscribeBanner = ({ onClick, className, planDetails, hideText, celebrityName }) => {
  const intl = useIntl();
  const { price, period } = planDetails;

  return (
    <div className={cn('subscribe-banner', className)}>
      {!hideText && (
        <div>
          <h4 className="semibold title">
            {intl.formatMessage(
              { id: 'membership.celebritySubscription' },
              { name: celebrityName }
            )}
          </h4>
          <div className="p1 description">
            {intl.formatMessage({ id: 'membership.price' }, { price: price?.toFixed(2) })}
          </div>
          <div className="p3-regular desktop-only">
            {intl.formatMessage({ id: 'membership.cancellationMessage' })}
          </div>
        </div>
      )}
      <Button
        size="small"
        type="primary"
        title={intl.formatMessage(
          { id: 'membership.subscribePrice' },
          { price, period: getShortPeriod(period) }
        )}
        icon={<Crown width={20} height={20} />}
        onClick={onClick}
        lightFont
      />
      {!hideText && (
        <div className="p3-regular mobile-only">
          {intl.formatMessage({ id: 'membership.cancellationMessage' })}
        </div>
      )}
    </div>
  );
};

SubscribeBanner.propTypes = {
  onClick: func.isRequired,
  className: string,
  planDetails: shape(membershipPlan).isRequired,
  hideText: bool,
  celebrityName: string.isRequired
};

export default SubscribeBanner;
