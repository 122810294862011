import React, { useEffect } from 'react';
import { useStatus, LOADING } from '@rootstrap/redux-tools';
import { useParams } from 'react-router-dom';

import { getCategory } from 'state/actions/categoriesActions';
import { useDispatch, useAnalytics } from 'hooks';
import { useCategories } from 'hooks/useCategories';

import FeaturedHero from 'components/featured/FeaturedHero/FeaturedHero';
import CustomHeader from 'components/header/Header/CustomHeader';
import FeaturedCelebrities from 'components/celebrity/FeaturedCelebrities/FeaturedCelebrities';
import Loading from 'components/common/Loading';
import SubcategorySection from 'components/subcategory/SubcategorySection/SubcategorySection';
import { MIXPANEL_EVENTS } from 'constants/constants';

const CategoryPage = () => {
  const { slug } = useParams();
  const getCategoryRequest = useDispatch(getCategory);
  const { category } = useCategories();
  const { trackEvent } = useAnalytics();
  const { promotedOriginals, featuredCelebrities, subCategories, selectedCategory } =
    category || {};
  const { status } = useStatus(getCategory);

  useEffect(() => {
    (!selectedCategory || selectedCategory.slug !== slug) && getCategoryRequest(slug);
    selectedCategory &&
      selectedCategory.slug == slug &&
      trackEvent(MIXPANEL_EVENTS.category, { categoryName: selectedCategory.name });
  }, [getCategoryRequest, selectedCategory, slug, trackEvent]);

  useEffect(() => {
    window && window.scrollTo(0, 0);
  }, [selectedCategory]);

  if (status === LOADING) {
    return (
      <div className="category-loading">
        <Loading />
      </div>
    );
  }
  return (
    <>
      {selectedCategory && <CustomHeader label={selectedCategory.name} />}
      <FeaturedHero content={promotedOriginals} titleId="featured.mostWatched" />
      <FeaturedCelebrities
        celebrities={featuredCelebrities}
        titleId="featured.creators"
        className="category-featured-celebrities"
      />
      {subCategories.map(subcategory => (
        <SubcategorySection subcategory={subcategory} key={subcategory.id} />
      ))}
    </>
  );
};

CategoryPage.loadData = async (store, match) => {
  const {
    params: { slug }
  } = match;
  await store.dispatch(getCategory(slug));
};

export default CategoryPage;
