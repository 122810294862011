import React from 'react';
import { string } from 'prop-types';

import { useAnalytics, useIsMobile } from 'hooks';
import { MIXPANEL_EVENTS } from 'constants/constants';

import Button from 'components/common/Button/Button';
import { ShoppingIcon } from 'components/icons';

const ShopButton = ({ shopifyLink, celebrityName }) => {
  const { trackEvent } = useAnalytics();
  const isMobile = useIsMobile();

  const onShopClicked = () => {
    trackEvent(MIXPANEL_EVENTS.shopifyClicked, {
      'Creator Name': celebrityName
    });
  };

  return (
    <a href={shopifyLink} target="_blank" rel="noopener noreferrer">
      <Button
        icon={<ShoppingIcon />}
        labelId={isMobile ? undefined : 'celebrity.shop'}
        type="tetriary"
        size="xxsmall"
        className="shop-button"
        onClick={onShopClicked}
      />
    </a>
  );
};

ShopButton.propTypes = {
  shopifyLink: string.isRequired,
  celebrityName: string.isRequired
};

export default ShopButton;
