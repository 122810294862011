/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';
import SearchIcon from 'assets/search.png';
import SearchSilverIcon from 'assets/searchSilver.png';
import SearchLoaderIcon from 'assets/search-loading.png';
import searchService from 'services/searchService';
import { useNavigation } from 'hooks';
import { debounce } from 'lodash';
import { routesPaths } from 'constants/routesPaths';
import { routeWithProps } from 'utils/helpers';

const AutocompleteSearch = ({ value, className }) => {
  const { goToWithSearch, goTo } = useNavigation();
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(null);
  const wrapperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = event => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const debouncedFetchResults = useRef(
    debounce(async query => {
      const trimmedQuery = query.trim();
      if (trimmedQuery.length > 0) {
        setIsLoading(true);
        try {
          const { data } = await searchService.autoComplete(trimmedQuery);
          setResults(data.search.slice(0, 10));
          setIsOpen(true);
        } catch (error) {
          console.error('Error fetching autocomplete results:', error);
        } finally {
          setIsLoading(false);
        }
      } else {
        setResults([]);
        setIsOpen(false);
      }
    }, 300)
  ).current;

  const handleSearch = async value => {
    if (/[!@#$%&^*+=\[\]{}"\\|<>~`]/.test(value)) {
      return;
    }
    setSearchTerm(value);
    debouncedFetchResults(value);
  };

  const handleKeyPress = event => {
    if (/[!@#$%&^*+=\[\]{}"\\|<>~`]/.test(event.key)) {
      event.preventDefault();
      return;
    }
    if (event.key === 'Enter') {
      const trimmedTerm = searchTerm.trim();
      if (trimmedTerm.length > 0) {
        goToWithSearch(`/search/all`, `?search=${encodeURIComponent(trimmedTerm)}`);
      }
    }
  };

  useEffect(() => {
    if (value) {
      setSearchTerm(value);
    }
  }, [value]);

  const handleMouseEnter = index => {
    setHighlightedIndex(index);
  };

  const handleMouseLeave = () => {
    setHighlightedIndex(null);
  };

  const handleNavigate = item => {
    setIsOpen(false);
    setSearchTerm('');
    if (item.contentType === 'Shorts') {
      goTo(
        routeWithProps(routesPaths.narratives, {
          celebrityId: item.celebrityId,
          id: item.id
        })
      );
    }
    if (item.contentType === 'Creator') {
      goTo(
        routeWithProps(routesPaths.celebrityId, {
          username: item.id
        })
      );
    }
    if (item.contentType === 'Movie/Series') {
      const route = routeWithProps(routesPaths.celebrityId, {
        username: item.celebrityId
      });
      goToWithSearch(route, `?seriesId=${item?.id}`);
    }
    if (item.contentType === 'Episode') {
      goTo(
        routeWithProps(routesPaths.celebrityId, {
          username: item.celebrityId
        })
      );
    }
  };

  return (
    <div
      ref={wrapperRef}
      className={cn(className ? 'mobile-search-container' : 'custom-search-container')}
    >
      <div className="search-input-wrapper">
        <span className="search-icon">
          <img src={SearchIcon} height="15px" width="15px" alt="search" />
        </span>
        <input
          type="text"
          value={searchTerm}
          onChange={e => handleSearch(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="Search Creators, Movies & Series, Episodes etc."
          className="search-input"
        />
        {isLoading && (
          <span className="search-loader">
            <img
              src={SearchLoaderIcon}
              height="15px"
              width="15px"
              alt="loader"
              className="rotating"
            />
          </span>
        )}
      </div>
      {isOpen && (
        <div className="results-dropdown">
          {results.length > 0 ? (
            results.slice(0, 10).map((item, index) => (
              <li
                key={index}
                className={cn('result-item', { highlighted: index === highlightedIndex })}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
                onClick={() => handleNavigate(item)}
              >
                <span className="search-icon small">
                  <img src={SearchSilverIcon} height="15px" width="15px" alt="search" />
                </span>
                <span className="item-name">{item.title}</span>
                <span className="item-type">- {item.contentType}</span>
              </li>
            ))
          ) : (
            <li className="result-item">No results found</li>
          )}
        </div>
      )}
    </div>
  );
};

export default AutocompleteSearch;
