import React, { useMemo } from 'react';
import { string, func, bool, node } from 'prop-types';
import { useIntl } from 'react-intl';
import cn from 'classnames';

import { CrossIcon, LogoIcon } from 'components/icons';

import { white } from 'styles/common/_constants.scss';

const ModalHeader = ({ hideModal, titleId, className, showLogo, thirdItem }) => {
  const intl = useIntl();

  const showTitle = useMemo(() => !showLogo && !!titleId, [showLogo, titleId]);

  return (
    <div className={cn('modal-header', { 'align-left': showLogo }, className)}>
      <div className="header-item close-icon" onClick={hideModal}>
        <CrossIcon width={24} height={24} color={white} />
      </div>
      {showLogo && <LogoIcon width={90} height={26} />}
      {showTitle && (
        <h5 className="header-item title bold">{intl.formatMessage({ id: titleId })}</h5>
      )}
      {!!thirdItem && <div className="header-item">{thirdItem}</div>}
    </div>
  );
};

ModalHeader.propTypes = {
  hideModal: func.isRequired,
  titleId: string,
  className: string,
  showLogo: bool,
  thirdItem: node
};

export default ModalHeader;
