import React, { useEffect, useState } from 'react';
import TrashIcon from 'assets/trash.png';
import cn from 'classnames';
import { useNavigation, useToast, useModal } from 'hooks';
import { routeWithProps } from 'utils/helpers';
import { routesPaths } from 'constants/routesPaths';
import AdService from 'services/adService';
import { useParams } from 'react-router-dom';
import { zIndexModalThird } from 'styles/common/_constants.scss';
import Modal from 'components/common/Modal/Modal';
import UploadIcon from '../../assets/plus.png';
import sample from '../../assets/samplevideo.png';
import CommonSwitch from '../common/Switch/Switch';
import DeleteAd from './DeleteAd/DeleteAd';

const ManageWidgetAds = ({ BannerAds }) => {
  const { narrativeId, celebrityId, episodeId } = useParams();
  const { toggle, isShowing } = useModal();
  const { goTo } = useNavigation();
  const [adStates, setAdStates] = useState({
    before_widget: false
  });
  const [BannerData, setBannerData] = useState(null);
  const [disSwitch, setdisSwitch] = useState(false);
  useEffect(() => {
    if (BannerAds) {
      setAdStates(prevState => ({
        ...prevState,
        before_widget: !BannerAds[0]?.disable
      }));
      setBannerData(BannerAds[0]);
    }
  }, [BannerAds]);
  const [currentId, setCurrentId] = useState();
  const { showErrorToast, showToast } = useToast();
  const handleApiCall = () => {
    // getVideoAdList();
    window.location.reload();
  };
  const handleSwitchChange = async (nextChecked, adType, adData) => {
    setdisSwitch(true);
    setAdStates(prevState => ({
      ...prevState,
      [adType]: nextChecked
    }));
    if (narrativeId) {
      try {
        const { data } = await AdService.disableNarrativebannerAd(
          celebrityId,
          narrativeId,
          adData.id
        );
        showToast(data.message);
        setdisSwitch(false);
        window.location.reload();
      } catch ({ response }) {
        showErrorToast('Something went wrong!');
        setdisSwitch(false);
      }
    } else {
      try {
        const { data } = await AdService.disableSeriesbannerAd(celebrityId, episodeId, adData.id);
        showToast(data.message);
        setdisSwitch(false);
        window.location.reload();
      } catch ({ response }) {
        showErrorToast('Something went wrong!');
        setdisSwitch(false);
      }
    }
  };
  const handleClick = () => {
    goTo(
      routeWithProps(routesPaths.individualaddbannerAds, {
        type: narrativeId ? 'narrative' : 'seriesid',
        id: narrativeId || episodeId
      })
    );
  };
  return (
    <div>
      {BannerData != null ? (
        <>
          <div className="banner-title-container">
            <div className="details-manages">
              <span className="custom-title">{BannerData.title}</span>
            </div>
            <div className="control-manages">
              <button
                type="button"
                style={{ cursor: 'pointer', marginRight: '10px' }}
                className="action-button"
                onClick={() => {
                  setCurrentId(BannerData.id);
                  toggle();
                }}
              >
                <img height="20px" width="20px" src={TrashIcon} alt="trash" />
              </button>
              <CommonSwitch
                checked={adStates.before_widget}
                checkedIcon={false}
                uncheckedIcon={false}
                disabled={disSwitch}
                onColor="#2400FC"
                onChange={nextChecked =>
                  handleSwitchChange(nextChecked, 'before_widget', BannerData)
                }
              />
            </div>
          </div>
          <div className="loadVideoAds">
            <div className="video-info">
              <div className="BannerImageContainer">
                <p>Desktop ads</p>
                <img
                  className="Videothumbnail"
                  src={BannerData?.webBannerUrl || sample}
                  alt="thumbnail"
                />
                <br />
                <p style={{ marginTop: '5px' }}> Mobile ads</p>
                <img
                  className="Videothumbnail"
                  src={BannerData?.mobileBannerUrl || sample}
                  alt="thumbnail"
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div>
            <div className={cn('button-container')}>
              <button
                type="button"
                className={cn('upload-button')}
                onClick={() => {
                  handleClick();
                }}
              >
                <img
                  height="20x"
                  width="20px"
                  className={cn('upload-icon')}
                  src={UploadIcon}
                  alt="upload"
                />{' '}
                UPLOAD BANNER
              </button>
            </div>
          </div>
        </>
      )}
      <Modal hide={toggle} isShowing={isShowing} topModal zIndex={zIndexModalThird}>
        <DeleteAd
          handleApiCall={handleApiCall}
          celebrityId={celebrityId}
          currentId={currentId}
          id={narrativeId || episodeId}
          type={narrativeId ? 'Shorts' : 'Movies & Series'}
          adtype="banner"
        />
      </Modal>
    </div>
  );
};

export default ManageWidgetAds;
