import httpClient from 'httpClient';

export const CONTENT_TYPE = 'Content-Type';
export const MULTIPART_FORM_DATA = 'multipart/form-data';
class UserService {
  static requestCode(data) {
    return httpClient.put('/sessions/request_code', data);
  }

  static login(user) {
    return httpClient.post('/users/sign_in', user);
  }

  static logout() {
    return httpClient.delete('/users/sign_out', { data: {} });
  }

  static signUp(user) {
    return httpClient.post('/users', user);
  }

  static getProfile() {
    return httpClient.get('/user/profile');
  }

  static registerDevice(data) {
    return httpClient.post('/devices', data);
  }

  static unregisterDevice(token) {
    return httpClient.delete(`/device/${token}`);
  }

  static upgradeToCreator(creator) {
    return httpClient.post('/user/upgrade_to_creator', creator, {
      headers: { 'Content-Type': 'multipart/form-data' },
      transformRequest: () => creator
    });
  }

  static changeCreatorProfileImage(celebrityId, image) {
    return httpClient.put(`/celebrities/${celebrityId}`, image, {
      headers: { [CONTENT_TYPE]: MULTIPART_FORM_DATA },
      transformRequest: () => image
    });
  }

  static deleteUserAccount() {
    return httpClient.delete('/users');
  }
}

export default UserService;
