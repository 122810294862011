import React from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { bool, func } from 'prop-types';
import cn from 'classnames';

import { renderImage } from 'utils/helpers';
import { routesPaths } from 'constants/routesPaths';

import { LogoIcon } from 'components/icons';
import BackgroundOpacity from 'components/common/BackgroundOpacity/BackgroundOpacity';
import LoginForm from 'components/user/LoginForm';

import loginBackground from 'assets/login-bkg.jpg';
import loginBackgroundMobile from 'assets/login-bkg-mobile.png';

const Login = ({ isModal, redirectSignUp, redirectVerifyPhone }) => {
  const intl = useIntl();

  return (
    <div className={cn('login', { 'login-modal': isModal })}>
      <BackgroundOpacity
        background={renderImage(loginBackground, loginBackgroundMobile)}
        opacity={0.5}
      />
      <div className="login-gradient" />
      <div className="layer content-container">
        <div className="login-content">
          <LogoIcon width={182} height={52} />
          {isModal && (
            <h2 className="auth-message">{intl.formatMessage({ id: 'user.needAccount' })}</h2>
          )}
          <div className="container">
            <LoginForm isModal={isModal} redirectVerifyPhone={redirectVerifyPhone} />
            {isModal ? (
              <div onClick={redirectSignUp}>
                <h5 className="create-account bold">
                  {intl.formatMessage({ id: 'user.createAccount' })}
                </h5>
              </div>
            ) : (
              <Link to={routesPaths.signup}>
                <h5 className="create-account bold">
                  {intl.formatMessage({ id: 'user.createAccount' })}
                </h5>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

Login.propTypes = {
  isModal: bool,
  redirectSignUp: func,
  redirectVerifyPhone: func
};

export default Login;
