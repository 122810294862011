import React from 'react';
import cn from 'classnames';
import BecomeCreatorForm from 'components/becomeCreator/BecomeCreatorForm';
import { renderImage } from 'utils/helpers';
import BackgroundOpacity from 'components/common/BackgroundOpacity/BackgroundOpacity';

import verifyPhoneBackground from 'assets/verifyphone-bkg.jpg';
import verifyPhoneBackgroundMobile from 'assets/verifyphone-bkg-mobile.png';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  creatorTitle: { id: 'title.creator' },
  creatorSubTitle: { id: 'subTitle.creator' }
});

const BecomeCreator = () => {
  const intl = useIntl();
  return (
    <div className={cn('become-creator')}>
      <BackgroundOpacity
        background={renderImage(verifyPhoneBackground, verifyPhoneBackgroundMobile)}
        opacity={0.5}
      />
      <div className="become-creator-gradient" />
      <div className="layer content-container">
        <div className="become-creator-content">
          <div className="container">
            <h1 className="title">{intl.formatMessage(messages.creatorTitle)}</h1>
            <p className="details">{intl.formatMessage(messages.creatorSubTitle)}</p>
            <BecomeCreatorForm />
          </div>
        </div>
      </div>
    </div>
  );
};

BecomeCreator.loadData = async () => {};

export default BecomeCreator;
