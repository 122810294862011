import React from 'react';
import { shape, string, func } from 'prop-types';

import { celebrityShape } from 'constants/propTypesShapes';
import useFollow from 'hooks/celebrities/useFollow';
import { useSession, useModal } from 'hooks';

import Modal from 'components/common/Modal/Modal';
import AuthModal from 'components/user/AuthModal/AuthModal';

const FollowHook = ({ celebrity, label, onModalDisplayed, onExit, onFollow }) => {
  const { follow } = useFollow(celebrity);
  const { toggle, isShowing } = useModal();
  const { authenticated } = useSession();
  const { following } = celebrity;

  const followingAction = () => {
    follow();
    !!onFollow && onFollow();
  };

  const followClicked = () => {
    if (authenticated) {
      followingAction();
    } else {
      !!onModalDisplayed && onModalDisplayed();
      toggle();
    }
  };

  const onHideModal = () => {
    !!onExit && onExit();
    toggle();
  };

  return (
    <>
      <div onClick={!following ? followClicked : undefined} className="follow-hook">
        <h5 className="bold">{label}</h5>
      </div>
      <Modal hide={onHideModal} isShowing={isShowing}>
        <AuthModal isModal onSuccess={followingAction} />
      </Modal>
    </>
  );
};

FollowHook.propTypes = {
  celebrity: shape(celebrityShape).isRequired,
  label: string.isRequired,
  onModalDisplayed: func.isRequired,
  onExit: func.isRequired,
  onFollow: func
};

export default FollowHook;
