import { createReducer } from '@rootstrap/redux-tools';

import { getOneSeries, watchedSerie } from 'state/actions/seriesActions';
import { follow } from 'state/actions/celebritiesActions';
import { filterEpisodes } from 'utils/dataHelpers';

const initialState = {
  serie: {},
  keepWatchingList: []
};

export default createReducer(initialState, {
  [getOneSeries.success]: (state, { payload }) => {
    state.serie = {
      ...payload,
      episodes: payload.episodes,
      earlyReleaseEpisodes: filterEpisodes(payload.episodes, true)
    };
  },
  [watchedSerie.success]: (state, { payload }) => {
    const i = state.keepWatchingList.findIndex(({ id }) => id === payload.id);
    if (i > -1) state.keepWatchingList[i] = payload;
    else state.keepWatchingList.push(payload);
  },
  [follow.success]: (state, { payload }) => {
    const { id } = payload;
    if (state.serie.celebrity?.id == id) {
      state.serie.celebrity.following = true;
    }
  }
});
