import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useStatus, SUCCESS } from '@rootstrap/redux-tools';
import { useIntl } from 'react-intl';

import { useNavigation, useSession, useToast } from 'hooks';
import capitalize from 'lodash/capitalize';
import { routesPaths } from 'constants/routesPaths';
// import { MIXPANEL_EVENTS, MIXPANEL_PARAMS } from 'constants/constants';
import { updateEvent } from 'state/actions/eventActions';
import { routeWithProps } from 'utils/helpers';

export default () => {
  const dispatch = useDispatch();
  // const { trackEvent } = useAnalytics();
  const { goToWithSearch } = useNavigation();
  const history = useHistory();
  const intl = useIntl();
  const { status, error } = useStatus(updateEvent);
  const { showToast } = useToast();
  const { user } = useSession();

  const onSubmit = useCallback(
    (celebrityId, eventId, event) => {
      const formData = new FormData();
      formData.append('event[title]', event.title);
      formData.append('event[description]', event.description);
      formData.append('event[event_date]', event.event_date);
      formData.append('event[duration]', event.duration);
      formData.append('event[timezone]', event.timezone);
      formData.append('event[event_time]', event.event_time);
      formData.append('event[members_price]', event.members_price);
      formData.append('event[non_members_price]', event.non_members_price);
      formData.append('event[web_cover_image]', event.web_cover_image);
      formData.append('event[mobile_cover_image]', event.mobile_cover_image);
      formData.append('event[record_event]', event.record_event);
      formData.append('event[notify_subscribers]', event.notify_subscribers);

      dispatch(updateEvent(celebrityId, eventId, formData));
    },
    [dispatch]
  );

  useEffect(() => {
    if (status === SUCCESS) {
      // trackEvent(MIXPANEL_EVENTS.becomeCreator, {
      //   [MIXPANEL_PARAMS.creatorName]: username
      // });
      const route = routeWithProps(routesPaths.celebrityId, {
        username: user.username.toLowerCase()
      });
      goToWithSearch(route, `?tab=3`);
      showToast('Event successfully updated!');
      dispatch(updateEvent.reset());
    }
  }, [status, dispatch, history, intl, showToast]);

  return {
    onSubmit,
    status,
    error: capitalize(error)
  };
};
