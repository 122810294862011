import React from 'react';
import { string, bool, object } from 'prop-types';
import cn from 'classnames';

import ScrollingArrows from 'components/common/ScrollingArrows';
import EventCard from 'components/events/EventCard/EventCard';

const FeaturedUpcomingEvents = ({ events, title, smallTitle, className, showArrows = true }) => {
  return events?.length > 0 ? (
    <div className={cn('featured-upcoming-events', className)}>
      {!!title && (
        <div className="featured-upcoming-events-title">
          <h3 className={cn({ small: smallTitle })}>{title}</h3>
        </div>
      )}
      <ScrollingArrows disabled={!showArrows} className="featured-upcoming-events-content">
        {events.map(events => (
          <EventCard key={events.id} events={events} isHome />
        ))}
      </ScrollingArrows>
    </div>
  ) : null;
};

FeaturedUpcomingEvents.propTypes = {
  events: object,
  title: string,
  className: string,
  showArrows: bool,
  smallTitle: bool
};

export default FeaturedUpcomingEvents;
