import { useState, useCallback, useRef, useMemo } from 'react';
import { isMobile } from 'react-device-detect';

export default nativeControls => {
  const [controlsVisible, setControlsVisibility] = useState(false);
  const HIDE_BACK_TIMEOUT = useMemo(() => (nativeControls ? 2000 : 4000), [nativeControls]);

  const timeout = useRef();

  const setControlsTimeout = () => {
    timeout.current = setTimeout(() => {
      setControlsVisibility(false);
    }, HIDE_BACK_TIMEOUT);
  };

  const toggleControls = useCallback(() => {
    clearTimeout(timeout.current);
    setControlsVisibility(!controlsVisible);
    !nativeControls && setControlsTimeout();
  }, [controlsVisible]);

  const showControls = useCallback(() => {
    clearTimeout(timeout.current);
    setControlsVisibility(true);
    setControlsTimeout();
  }, []);

  const handleClick = (e, func) => {
    if (isMobile) {
      toggleControls();
    } else {
      !!func && controlsVisible && func(e);
      showControls();
    }
  };

  const handleMouseMove = () => {
    if (!isMobile) {
      showControls();
    }
  };

  return { controlsVisible, toggleControls, showControls, handleMouseMove, handleClick };
};
