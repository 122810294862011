import React from 'react';
import { string, arrayOf } from 'prop-types';
import cn from 'classnames';

const Input = ({ label, name, errors, ...props }) => (
  <div className={cn('input-container', { error: errors })}>
    {label && <div className="tag-bold input-label">{label}</div>}
    <div>
      <input name={name} {...props} autoComplete="off" />
      {errors && <div className="error-label">{errors[0]}</div>}
    </div>
  </div>
);

Input.propTypes = {
  name: string.isRequired,
  label: string,
  errors: arrayOf(string)
};

export default Input;
