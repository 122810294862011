import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStatus, LOADING, SUCCESS, ERROR } from '@rootstrap/redux-tools';
import useToast from 'hooks/useToast';
import { deleteReaction, getReaction, reaction } from 'state/actions/reactionAction';

export default (id, contentType, onSuccess) => {
  const responseData = useSelector(({ reactionReducer }) => reactionReducer.reactionResponse);
  const { showErrorToast } = useToast();
  const dispatch = useDispatch();

  const getReactionData = () => {
    const param = {
      content_id: id,
      content_type: contentType
    };
    dispatch(getReaction(param));
  };

  const onComplete = () => {
    if (onSuccess) {
      onSuccess();
    }
  };

  const { status: statusReaction, error: errorReaction } = useStatus(reaction);
  const requestReaction = useCallback(
    val => {
      const param = {
        content_id: id,
        content_type: contentType,
        reaction: val
      };
      dispatch(reaction(param));
    },
    [dispatch]
  );
  useEffect(() => {
    if (statusReaction === SUCCESS) {
      dispatch(reaction.reset());
      onComplete();
    }
    if (statusReaction === ERROR) {
      showErrorToast(errorReaction);
      dispatch(reaction.reset());
    }
  }, [statusReaction]);

  const { status: statusDeleteReaction, error: errorDeleteReaction } = useStatus(deleteReaction);
  const requestDeleteReaction = useCallback(
    val => {
      const param = {
        content_id: id,
        content_type: contentType,
        reaction: val
      };
      dispatch(deleteReaction(param));
    },
    [dispatch]
  );
  useEffect(() => {
    if (statusDeleteReaction === SUCCESS) {
      dispatch(deleteReaction.reset());
      onComplete();
    }
    if (statusDeleteReaction === ERROR) {
      showErrorToast(errorDeleteReaction);
      dispatch(deleteReaction.reset());
    }
  }, [statusDeleteReaction]);

  return {
    error: errorReaction ?? errorDeleteReaction,
    loading: statusReaction === LOADING || statusDeleteReaction === LOADING,
    requestReaction,
    requestDeleteReaction,
    responseData,
    getReactionData
  };
};