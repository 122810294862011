import { useCallback } from 'react';
import { useIntl } from 'react-intl';

export default (handleValueChange, handleBlur, values) => {
  const intl = useIntl();

  return useCallback(
    fieldKey => ({
      value: values[fieldKey] || '',
      onChangeText: (text, isValid) => {
        const errors = !isValid && [intl.formatMessage({ id: 'form.errors.phoneNumber' })];
        handleValueChange(fieldKey, text, errors);
      },
      onBlur: isValid => {
        const errors = !isValid && [intl.formatMessage({ id: 'form.errors.phoneNumber' })];
        handleBlur(fieldKey, errors);
      }
    }),
    [handleBlur, handleValueChange, values, intl]
  );
};
