import { createThunk } from '@rootstrap/redux-tools';

import homeService from 'services/homeService';
import parseError from 'utils/parseError';

export const getHome = createThunk('GET_HOME', async () => {
  try {
    const { data } = await homeService.getHome();
    return data;
  } catch ({ response }) {
    throw parseError(response);
  }
});
