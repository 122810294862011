import React from 'react';
import cn from 'classnames';
import AllDetails from './AllDetails';

const BannerAdDetails = ({ data }) => {
  return (
    <div className={cn('video-ad-details')}>
      <div className="banner-ad-details">
        <div className="thumbnail-container">
          <div className="banner-flex">
            <span className="banner-title">Mobile Banner</span>
            <img className="mobile-banner" src={data?.mobileBannerUrl} alt="mobile-banner" />
          </div>
          <div className="banner-flex">
            <span className="banner-title">Desktop Banner</span>
            <img className="web-banner" src={data?.webBannerUrl} alt="desktop-banner" />
          </div>
        </div>
        <div className="video-ad-info">
          <AllDetails data={data} type="bannerAds" />
        </div>
      </div>
    </div>
  );
};

export default BannerAdDetails;
