import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './ImageSlider.scss';
import DetailPageArrowIcon from 'assets/detailPageArrow.png';
import { useAnalytics } from 'hooks';
import { MIXPANEL_EVENTS, MIXPANEL_PARAMS } from 'constants/constants';

export const ImageSlider = ({ items, mobileView, sliderId }) => {
  const { trackEvent } = useAnalytics();
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);

  useEffect(() => {
    const container = document.querySelector(`#${sliderId} .widget-slider-container`);
    if (container) {
      // Check if the container is scrollable
      setShowRightArrow(container.scrollWidth > container.clientWidth);
      setShowLeftArrow(container.scrollLeft > 0);
    }
  }, [items, sliderId]);

  const scrollContainer = direction => {
    const container = document.querySelector(`#${sliderId} .widget-slider-container`);
    if (container) {
      if (direction === 'left') {
        container.scrollLeft -= 200;
      } else {
        container.scrollLeft += 200;
      }

      // Update arrow visibility after scrolling
      setTimeout(() => {
        setShowRightArrow(container.scrollWidth > container.clientWidth + container.scrollLeft);
        setShowLeftArrow(container.scrollLeft > 0);
      }, 300); // Adjust timeout duration as needed
    }
  };

  const handleWidgetClick = (actionButtonUrl, id) => {
    trackEvent(MIXPANEL_EVENTS.widgetAdClicked, {
      id,
      typeName: MIXPANEL_PARAMS.widgetAd
    });
    if (actionButtonUrl) {
      let url = actionButtonUrl.replace(/^\/+/, '');
      if (!/^https?:\/\//i.test(url)) {
        url = `https://${url}`;
      }
      window.open(url, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <div className="widget-slider-wrapper" id={sliderId}>
      {showLeftArrow && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: mobileView ? '32px' : '78px',
            height: mobileView ? '60px' : '160px',
            margin: '10px 0px'
          }}
          className="left-arrow-overlay"
        >
          <button onClick={() => scrollContainer('left')}>
            <img
              style={{
                cursor: 'pointer',
                height: mobileView ? '20px' : '30px',
                width: mobileView ? '20px' : '30px'
              }}
              src={DetailPageArrowIcon}
              alt="leftArrow"
            />
          </button>
        </div>
      )}
      <div style={{gap: mobileView ? '8px' : '20px',}} className="widget-slider-container">
        {items.map((item, index) => (
          <div key={index} className="widget-slider-item">
            <div
              onClick={() =>
                item?.actionButtonUrl
                  ? handleWidgetClick(item?.actionButtonUrl, item?.widgetId)
                  : undefined
              }
            >
              <img
                style={{
                  height: mobileView ? '60px' : '160px',
                  width: mobileView ? '90px' : '240px',
                  cursor: item?.actionButtonUrl ? 'pointer' : '',
                }}
                src={item.widgetAdImageUrl}
                alt={item.buttonText}
              />
              <p>{item.buttonText}</p>
            </div>
          </div>
        ))}
      </div>
      {showRightArrow && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: mobileView ? '32px' : '78px',
            height: mobileView ? '60px' : '160px',
            margin: '10px 0px'
          }}
          className="right-arrow-overlay"
        >
          <button onClick={() => scrollContainer('right')}>
            <img
              style={{
                cursor: 'pointer',
                height: mobileView ? '20px' : '30px',
                width: mobileView ? '20px' : '30px'
              }}
              src={DetailPageArrowIcon}
              alt="rightArrow"
            />
          </button>
        </div>
      )}
    </div>
  );
};

ImageSlider.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      widgetAdImageUrl: PropTypes.string.isRequired,
      buttonText: PropTypes.string.isRequired,
      actionButtonUrl: PropTypes.string
    })
  ).isRequired,
  mobileView: PropTypes.bool.isRequired,
  sliderId: PropTypes.string.isRequired
};
