import { createThunk } from '@rootstrap/redux-tools';
import PodcastService from 'services/podcastService';
import parseError from 'utils/parseError';

export const createPodcast = createThunk('CREATE_PODCAST', async (celebrityId, podcast) => {
  try {
    const { data } = await PodcastService.createPodcast(celebrityId, podcast);
    return data.podcast;
  } catch ({ response }) {
    throw parseError(response);
  }
});

export const processPodcast = createThunk(
  'PROCESS_PODCAST',
  async (celebrityId, podcastId, videoUri) => {
    try {
      await PodcastService.processPodcast(celebrityId, podcastId);
      return videoUri;
    } catch ({ response }) {
      throw parseError(response);
    }
  }
);

export const resetUploadingPodcast = createThunk('RESET_UPLOADING_PODCAST');

export const deletePodcast = createThunk('DELETE_PODCAST', async (celebrityId, podcastId) => {
  try {
    await PodcastService.deletePodcast(celebrityId, podcastId);
  } catch ({ response }) {
    throw parseError(response);
  }
});

export const reportPodcast = createThunk('REPORT_PODCAST', async ({ podcastId, params }) => {
  try {
    await PodcastService.reportPodcast({ podcastId, params });
  } catch ({ response }) {
    throw parseError(response);
  }
});

export const updatePodcast = createThunk(
  'UPDATE_PODCAST',
  async (celebrityId, podcastId, podcast) => {
    try {
      await PodcastService.updatePodcast(celebrityId, podcastId, podcast);
    } catch ({ response }) {
      throw parseError(response);
    }
  }
);

export const getOnePodcast = createThunk('GET_ONE_PODCAST', async (id, celebrityId) => {
  try {
    const { data } = await PodcastService.getPodcastById(id, celebrityId);
    console.log('get Podcast', data);
    return data.podcast;
  } catch ({ response }) {
    throw parseError(response);
  }
});
