import React, { useEffect, useMemo } from 'react';
import { string, func, object } from 'prop-types';
import Select from 'react-select';
import cn from 'classnames';

import countries from 'constants/countries';

const MAX_MENU_HEIGHT = 190;
const INPUT_HEIGHT = 56;

const CountryDropdown = ({ label, defaultCountryCode, className, ...props }) => {
  const countryOptions = useMemo(
    () => countries.map(({ code, name }) => ({ value: code, label: name })),
    []
  );

  const defaultCountry = useMemo(
    () => countryOptions.find(({ value }) => value === defaultCountryCode),
    [defaultCountryCode, countryOptions]
  );

  const onCountryChange = country => {
    const { onChange } = props;
    const { value } = country;
    onChange({ target: { value } });
  };

  useEffect(() => {
    onCountryChange(defaultCountry);
  }, []);

  const customStyles = {
    control: base => ({
      ...base,
      minHeight: 'initial'
    }),
    valueContainer: base => ({
      ...base,
      height: `${INPUT_HEIGHT - 2}px`,
      padding: '0 0.8rem'
    }),
    clearIndicator: base => ({
      ...base,
      padding: `${(INPUT_HEIGHT - 22) / 2}px`
    }),
    dropdownIndicator: base => ({
      ...base,
      padding: `${(INPUT_HEIGHT - 22) / 2}px`
    })
  };

  return (
    <div className={cn('country-dropdown', className)}>
      {label && <div className="country-dropdown-label tag-bold">{label}</div>}
      <Select
        options={countryOptions}
        styles={customStyles}
        defaultValue={defaultCountry}
        onChange={onCountryChange}
        components={{
          IndicatorSeparator: () => null
        }}
        maxMenuHeight={MAX_MENU_HEIGHT}
        classNamePrefix="country-dropdown"
      />
    </div>
  );
};

CountryDropdown.defaultProps = {
  defaultCountryCode: 'US',
  label: ''
};

CountryDropdown.propTypes = {
  label: string,
  onChange: func,
  defaultCountryCode: string,
  props: object,
  className: string
};

export default CountryDropdown;
