import { createReducer } from '@rootstrap/redux-tools';
import {
  createPodcast,
  getOnePodcast,
  processPodcast,
  resetUploadingPodcast
} from 'state/actions/podcastActions';

const initialState = {
  podcasts: [],
  podcastDetails: {},
  uploadingPodcast: {},
  processingPodcast: {},
  podcast: {}
};

export default createReducer(initialState, {
  [createPodcast.success]: (state, { payload }) => {
    state.uploadingPodcast = payload;
  },
  [processPodcast.success]: (state, { payload }) => {
    state.processingPodcast[state.uploadingPodcast.id] = payload;
    state.uploadingPodcast = {};
  },
  [resetUploadingPodcast.request]: state => {
    state.uploadingPodcast = {};
  },
  [getOnePodcast.success]: (state, { payload }) => {
    state.podcast = {
      ...payload
    };
  }
});
