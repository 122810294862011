import React from 'react';
import cn from 'classnames';
import DummyImg from 'assets/dummy.png';
import AllDetails from './AllDetails';

const VideoAdDetails = ({ data }) => {
  return (
    <div className={cn('video-ad-details')}>
      <div className="video-image-info">
        <img src={data?.thumbnailsUrls?.[0]?.src || DummyImg} alt="video-info" />
      </div>
      <div className="video-ad-info">
        <AllDetails type="videoAds" data={data} />
      </div>
    </div>
  );
};

export default VideoAdDetails;
