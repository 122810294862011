import React from 'react';
import { string } from 'prop-types';

import { VIDEO_SHORT_DESCRIPTION_LENGTH } from 'constants/constants';
import MoreText from 'components/common/MoreText/MoreText';

const NarrativeCardInfo = ({ title, description }) => (
  <div className="narrative-card-info">
    <h5 className="bold">{title}</h5>
    <div className="p1">
      <MoreText text={description} limitLength={VIDEO_SHORT_DESCRIPTION_LENGTH} />
    </div>
  </div>
);

NarrativeCardInfo.propTypes = {
  title: string.isRequired,
  description: string.isRequired
};

export default NarrativeCardInfo;
