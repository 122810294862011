import React, { useState } from 'react';
import { oneOfType, arrayOf, node } from 'prop-types';

const PodcastContext = React.createContext([{}, () => {}]);

const PodcastContextProvider = ({ children }) => {
  const [podcast, setPodcast] = useState(null);
  const [flowConfig, setFlowConfig] = useState({});

  return (
    <PodcastContext.Provider value={{ podcast, setPodcast, flowConfig, setFlowConfig }}>
      {children}
    </PodcastContext.Provider>
  );
};

PodcastContextProvider.propTypes = {
  children: oneOfType([arrayOf(node), node])
};

export { PodcastContext, PodcastContextProvider };
