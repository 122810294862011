import { useContext } from 'react';

import { ModalContext } from 'contexts/ModalContext';
import useNavigation from 'hooks/useNavigation';

import { routeWithProps } from 'utils/helpers';
import { accountTabs } from 'constants/constants';
import { routesPaths } from 'constants/routesPaths';

const { PAYMENTS } = accountTabs;

const useSubscribeContext = () => {
  const [menuState, setMenuState] = useContext(ModalContext);
  const { isMenuOpen } = menuState;
  const { goTo } = useNavigation();

  const setMenuOpen = () => setMenuState(state => ({ ...state, isMenuOpen: true }));

  const closeMenu = () => setMenuState(state => ({ ...state, isMenuOpen: false }));

  const goToChangePaymentMethod = () => {
    setMenuOpen();
    goTo(routeWithProps(routesPaths.account, { tab: PAYMENTS.tab }), false, {
      backOnClose: true
    });
  };

  return { setMenuOpen, closeMenu, paymentsVisible: isMenuOpen, goToChangePaymentMethod };
};

export default useSubscribeContext;
