import { useCallback } from 'react';

import { MIXPANEL_EVENTS } from 'constants/constants';
import useAnalytics from 'hooks/useAnalytics';
import { getFullName } from 'utils/helpers';

export default ({ player, celebrity, isScheduled, title }) => {
  const { trackEvent } = useAnalytics();

  const trackTimeViewed = useCallback(() => {
    const options = isScheduled
      ? { 'Event title': title }
      : { Celebrity: getFullName(celebrity?.firstName, celebrity?.lastName) };

    trackEvent(MIXPANEL_EVENTS.videoTimeWatched, {
      Seconds: Math.round(player.getPosition()),
      ...options
    });
  }, [celebrity, isScheduled, player, title]);

  return { trackTimeViewed };
};
