/* eslint-disable react/no-multi-comp */
import React from 'react';
import SearchBar from 'components/SearchBar/SearchBar';
import Dropdown from 'components/header/Dropdown/Dropdown';

const SearchDropdown = () => {
  return (
    <>
      <Dropdown className="navbar-dropdown search-dropdown" triangleLeft>
        <SearchBar className="mobile-search" />
      </Dropdown>
    </>
  );
};

export default SearchDropdown;
