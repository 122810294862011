import React from 'react';
import { number, string } from 'prop-types';

const LiveHeroArrows = ({ width = 100, height = 100, rotation, className }) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ transform: `rotate(${rotation}deg)`, transformOrigin: '50% 50%' }}
    className={className}
  >
    <mask
      id="path-1-outside-1"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width={width}
      height={height}
      fill="black"
    >
      <rect fill="white" width={width} height={height} />
      <path fillRule="evenodd" clipRule="evenodd" d="M16 72V84H28H84V72L28 72L28 16H16L16 72Z" />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 72V84H28H84V72L28 72L28 16H16L16 72Z"
      fill="#FF001C"
    />
    <path
      d="M16 84H0V100H16V84ZM16 72L0 72V72H16ZM84 84V100H100V84H84ZM84 72H100V56L84 56V72ZM28 72L12 72L12 88H28L28 72ZM28 16L44 16L44 0H28V16ZM16 16V0H1.14441e-05L8.58307e-06 16L16 16ZM32 84V72H0V84H32ZM28 68H16V100H28V68ZM84 68H28V100H84V68ZM68 72V84H100V72H68ZM28 88L84 88V56L28 56L28 88ZM12 16L12 72L44 72L44 16L12 16ZM16 32H28V0H16V32ZM32 72L32 16L8.58307e-06 16L0 72L32 72Z"
      fill="black"
      mask="url(#path-1-outside-1)"
    />
  </svg>
);

LiveHeroArrows.defaultProps = {
  rotation: 0
};

LiveHeroArrows.propTypes = {
  width: number,
  height: number,
  rotation: number,
  className: string
};

export default LiveHeroArrows;
