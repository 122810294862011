import React, { memo, Fragment, useRef, useEffect } from 'react';
import { arrayOf, shape, number, string, func, bool } from 'prop-types';
import cn from 'classnames';
import { useIntl } from 'react-intl';

const Tabs = ({ tabs, selectedTab, onTabSelect, isVertical, className, noAutoScroll }) => {
  const intl = useIntl();

  const tabsRef = useRef(null);

  useEffect(() => {
    if (tabsRef.current && !isVertical) {
      const selectedTabElement = tabsRef.current.querySelector('.tab-selected');
      if (selectedTabElement) {
        const container = tabsRef.current;
        const containerWidth = container.offsetWidth;
        const tabWidth = selectedTabElement.offsetWidth;
        const tabLeft = selectedTabElement.offsetLeft;

        // Calculate the scroll position to center the tab
        const scrollPosition = tabLeft - containerWidth / 2 + tabWidth / 2;

        // Ensure we don't scroll beyond bounds
        const maxScroll = container.scrollWidth - containerWidth;
        const finalScroll = Math.max(0, Math.min(scrollPosition, maxScroll));

        container.scrollTo({
          left: finalScroll,
          behavior: 'smooth'
        });
      }
    }
  }, [selectedTab, isVertical]);
  return (
    <div
      ref={!noAutoScroll ? tabsRef : undefined}
      className={cn('tabs', className, { 'tabs-vertical': isVertical })}
    >
      {tabs.map(({ id, localeId, count }, index) => (
        <Fragment key={`tab${index}`}>
          <div
            className={cn('tab', {
              'tab-selected': selectedTab === id
            })}
            onClick={() => onTabSelect(id)}
          >
            <h4 className="h4-medium">{intl.formatMessage({ id: localeId })}</h4>
            {count !== undefined && <span className="tab-count">({count})</span>}
          </div>
          {!isVertical && index !== tabs.length && <div className="separator">|</div>}
        </Fragment>
      ))}
    </div>
  );
};

Tabs.propTypes = {
  tabs: arrayOf(
    shape({
      id: number,
      localeId: string
    })
  ),
  selectedTab: number,
  onTabSelect: func.isRequired,
  isVertical: bool,
  className: string,
  noAutoScroll: bool
};

export default memo(Tabs);
