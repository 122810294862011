import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import includes from 'lodash/includes';
import { SUPPORTED_LANGUAGES, DEFAULT_LANGUAGE, OPEN_SANS_SRC } from 'constants/constants';

export default Component => {
  const WithStripe = props => {
    const stripePromise = loadStripe(process.env.STRIPE_API_KEY);
    const usersLocale = navigator.language.split('-')[0];
    const supportedUserLocale = includes(SUPPORTED_LANGUAGES, usersLocale);
    const locale = supportedUserLocale ? usersLocale : DEFAULT_LANGUAGE;

    return (
      <>
        <Elements
          stripe={stripePromise}
          options={{
            fonts: [
              {
                cssSrc: OPEN_SANS_SRC
              }
            ],
            locale
          }}
        >
          <Component {...props} />
        </Elements>
      </>
    );
  };

  return WithStripe;
};
