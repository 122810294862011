import { useEffect, useRef, useState, useCallback } from 'react';
import videojs from 'video.js';
import 'videojs-playlist';
import '@videojs/http-streaming';
import 'videojs-playlist-ui';
import 'styles/video-js.css';
import 'styles/videojs-playlist-ui.css';
import { MIXPANEL_EVENTS, MIXPANEL_PARAMS } from 'constants/constants';
import { getFullName } from 'utils/helpers';
import { useAnalytics } from 'hooks';
import { isMobile } from 'react-device-detect';

const VideoJS = ({
  options,
  onReady,
  preRollSource,
  postRollSource,
  midRollSource,
  midRollInterval,
  customDurationAds,
  onPlayNextUpdate,
  link,
  mimeType
}) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const [player, setPlayer] = useState(null);
  const [IsAds, setIsAds] = useState(false);
  const [mainContentEnded, setMainContentEnded] = useState(false);
  const [AdsDetail, setAdsDetail] = useState(null);
  const [PlayNext, setPlayNext] = useState(false);
  const [isPreRollPlaying, setIsPreRollPlaying] = useState(false);
  const lastMidRollTime = useRef(0);
  const mainContentTimeRef = useRef(0);
  const playedAds = useRef([]);
  const [orientation, setOrientation] = useState(window.screen.orientation?.type || 'portrait-primary');
  const [savedPosition, setSavedPosition] = useState(0);
  const { trackEvent } = useAnalytics();

  // useEffect(() => {
  //   const handleContextMenu = e => {
  //     e.preventDefault();
  //   };

  //   document.addEventListener('contextmenu', handleContextMenu);

  //   return () => {
  //     document.removeEventListener('contextmenu', handleContextMenu);
  //   };
  // }, []);

  const trackTiming = useCallback(
    time => {
      let payload = {};
      if (options.tracks.type == 'Series') {
        payload = {
          Seconds: Math.round(time),
          'Series Name': options.tracks.seriesname,
          'Episode Name': options.tracks.episodename,
          id: options.tracks.id,
          typeName: MIXPANEL_PARAMS.Episode
        };
      } else {
        const { firstName, lastName } = options.tracks.celebrity;
        payload = {
          Seconds: Math.round(time),
          'Celebrity Name': getFullName(firstName, lastName),
          'Narrative Name': options.tracks.title,
          id: options.tracks.id,
          typeName: MIXPANEL_PARAMS.Narrative
        };
      }
      trackEvent(MIXPANEL_EVENTS.videoTimeWatched, payload);
      console.log('Triggger Events');
    },
    [options]
  );
  const setMainContentTime = time => {
    mainContentTimeRef.current = time;
  };
  const setPlayNextvideo = value => {
    setPlayNext(value);
    if (onPlayNextUpdate) {
      onPlayNextUpdate(value);
    }
  };
  const resetPlayerState = () => {
    setMainContentEnded(false);
    setMainContentTime(0);
    lastMidRollTime.current = 0;
    playedAds.current = []; // Reset played ads when player state resets
  };

  const timeToSeconds = time => {
    const [hours, minutes, seconds] = time.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  };

  const playPostRollAd = () => {
    if (postRollSource && !player.isPlayingAd) {
      player.isPlayingAd = true;
      setIsAds(true);
      setAdsDetail(postRollSource);
      player.src({ type: 'video/mp4', src: postRollSource.link });
      
      player.controls(false); // Disable controls during post-roll ad
  
      player
        .play()
        .then(() => {
          const endTime = postRollSource.videoLength;
          const checkTime = () => {
            if (player.currentTime() >= endTime) {
              player.off('timeupdate', checkTime);
              player.pause();
              setIsAds(false);
              player.isPlayingAd = false;
              player.controls(true); // Re-enable controls after ad
              setPlayNextvideo(true);
            }
          };
          player.on('timeupdate', checkTime);
        })
        .catch(error => console.error('Post-roll playback failed:', error));
  
      player.one('ended', () => {
        setIsAds(false);
        player.isPlayingAd = false;
        player.controls(true); // Re-enable controls after ad
        setPlayNextvideo(true);
      });
    } else {
      setPlayNextvideo(true);
    }
  };
  

  const playMidRollAd = useCallback(() => {
    if (midRollSource && !player.isPlayingAd) {
      player.isPlayingAd = true;
      setIsAds(true);
      setAdsDetail(midRollSource);
      player.pause();
      player.src({ type: 'video/mp4', src: midRollSource.link });
      
      player.controls(false); // Disable controls during mid-roll ad
  
      player
        .play()
        .then(() => {
          const endTime = midRollSource.videoLength;
          const checkTime = () => {
            if (player.currentTime() >= endTime) {
              player.off('timeupdate', checkTime);
              setIsAds(false);
              player.isPlayingAd = false;
              player.controls(true); // Re-enable controls after ad
              lastMidRollTime.current = mainContentTimeRef.current;
              playMainContent();
            }
          };
          player.on('timeupdate', checkTime);
        })
        .catch(error => console.error('Mid-roll playback failed:', error));
  
      player.one('ended', () => {
        setIsAds(false);
        player.isPlayingAd = false;
        player.controls(true); // Re-enable controls after ad
        lastMidRollTime.current = mainContentTimeRef.current;
        playMainContent();
      });
    }
  }, [player, midRollSource, options.sources]);
  

  const playPreRollAd = () => {
    if (preRollSource && savedPosition === 0) {
      setIsAds(true);
      // Preload main content source while playing pre-roll
      player.src({ type: 'video/mp4', src: preRollSource.link });
      player.controls(false);
      player.autoplay(true);
      player.muted(true);
    
      // Preload main content
      const preloadPlayer = document.createElement("video");
      preloadPlayer.src = options.sources;
      preloadPlayer.load();
    
      player
        .play()
        .then(() => {
          player.muted(false);
        })
        .catch((error) => console.error("Pre-roll playback failed:", error));
    
      player.one("ended", () => {
        console.log("Pre-roll ad ended");
        setIsPreRollPlaying(false);
        setIsAds(false);
        playMainContent();
      });
    } else {
      playMainContent();
    }
    
  };
  
  
  const playMainContent = () => {
    console.log("Playing main content");
  
    if (mainContentEnded || player.isPlayingAd) return;
  
    // Reset ad state
    player.isPlayingAd = false;
    setIsAds(false);
    setAdsDetail(null);
    setPlayNextvideo(false);
  
    // Set main content source
    player.src(options.sources);
  
    // Ensure metadata is loaded before playing
    player.one("loadedmetadata", () => {
      player.currentTime(savedPosition || mainContentTimeRef.current); // Resume from saved time
      player.controls(true); // Enable controls for main content
      player.autoplay(true);
      player.muted(false);
  
      player
        .play()
        .then(() => {
          console.log("Main content playback started");
        })
        .catch((error) => console.error("Main content playback failed:", error));
    });
  
    // Handle main content playback completion
    player.off("ended");
    player.one("ended", () => {
      console.log("Main content ended");
      trackTiming(player.currentTime());
      if (!player.isPlayingAd) {
        setMainContentEnded(true);
        setMainContentTime(player.currentTime());
  
        // Play post-roll ad if available
        if (postRollSource) {
          playPostRollAd();
        } else {
          setPlayNextvideo(true);
        }
      }
    });
  };
  
  
  
  

  const playCustomDurationAd = useCallback(
    ad => {
      if (!player.isPlayingAd) {
        player.isPlayingAd = true;
        setIsAds(true);
        setAdsDetail(ad);
        player.pause();
        player.src({ type: 'video/mp4', src: ad.link });
  
        player.controls(false); // Disable controls during custom ad
  
        player
          .play()
          .then(() => {
            const endTime = ad.videoLength;
            const checkTime = () => {
              if (player.currentTime() >= endTime) {
                player.off('timeupdate', checkTime);
                setIsAds(false);
                player.isPlayingAd = false;
                player.controls(true); // Re-enable controls after ad
                playMainContent();
              }
            };
            player.on('timeupdate', checkTime);
          })
          .catch(error => console.error('Custom duration ad playback failed:', error));
  
        player.one('ended', () => {
          setIsAds(false);
          player.isPlayingAd = false;
          player.controls(true); // Re-enable controls after ad
          playMainContent();
        });
      }
    },
    [player]
  );


  useEffect(() => {
    const handleOrientationChange = () => {
      const newOrientation = window.screen.orientation?.type;
      
      // If orientation changes, save current position and update state
      if (newOrientation !== orientation && player) {
        setSavedPosition(player.currentTime());
        setOrientation(newOrientation);
        player.pause(); // Pause to prevent restart
      }
    };
  
    window.addEventListener('orientationchange', handleOrientationChange);
  
    return () => window.removeEventListener('orientationchange', handleOrientationChange);
  }, [orientation, player]);
  

  useEffect(() => {
    if (!videoRef.current) return;
    const videoElement = videoRef.current;
    if (!videoElement) return;

    const playerInstance = videojs(videoElement, options, () => {
      videojs.log('player is ready');
      onReady && onReady(playerInstance);
    });

    playerRef.current = playerInstance;
    setPlayer(playerInstance);
  }, [videoRef, IsAds, AdsDetail, PlayNext]);

  useEffect(() => {
    if (!player) return;
    resetPlayerState();
    if (preRollSource != null) {
      playPreRollAd();
    } else {
      playMainContent();
    }
  }, [options, player, preRollSource]);

  useEffect(() => {
    if (!player || !midRollSource || !midRollInterval || mainContentEnded) return;

    const checkMidRollInterval = () => {
      const currentTime = player.currentTime();
      const midRollIntervalInSeconds = midRollInterval * 60; // Convert interval to seconds
      if (
        currentTime - lastMidRollTime.current >= midRollIntervalInSeconds &&
        !player.isPlayingAd
      ) {
        setMainContentTime(currentTime);
        playMidRollAd();
      }
    };

    const intervalId = setInterval(checkMidRollInterval, 1000); // Check every second

    return () => clearInterval(intervalId);
  }, [player, midRollSource, midRollInterval, playMidRollAd, mainContentEnded]);

  useEffect(() => {
    if (!player || mainContentEnded || isPreRollPlaying) {
      return;
    }

    if (!customDurationAds || !customDurationAds.customIntervals) {
      return;
    }

    const checkAdIntervals = () => {
      if (player.isPlayingAd || isPreRollPlaying) {
        return;
      }

      const currentTime = player.currentTime();

      customDurationAds.customIntervals.forEach((interval, index) => {
        const start = timeToSeconds(interval);

        // Check if the interval has already been played
        if (currentTime >= start && currentTime < start + 1 && !playedAds.current.includes(start)) {
          playedAds.current.push(start); // Mark this interval as played
          setMainContentTime(currentTime);
          playCustomDurationAd({
            ...customDurationAds,
            index
          });
        }
      });
    };

    const intervalId = setInterval(checkAdIntervals, 1000); // Check every second

    return () => clearInterval(intervalId);
  }, [player, customDurationAds, playCustomDurationAd, mainContentEnded, isPreRollPlaying]);

  useEffect(() => {
    if (PlayNext) {
      console.log('PlayNext has been updated:', PlayNext);
      // Perform any actions you need when PlayNext becomes true
    }
  }, [PlayNext]);

  return { player, videoRef, IsAds, AdsDetail, PlayNext };
};

export default VideoJS;
