import React from 'react';
import { arrayOf, bool, shape } from 'prop-types';

import SerieBlock from 'components/series/SerieBlock/SerieBlock';
import { serieShape } from 'constants/propTypesShapes';

const OriginalsTab = ({ series, isLoggedUser }) =>
  series.map((serie, index) => (
    <SerieBlock isLoggedUser={isLoggedUser} key={serie.id} serie={serie} playVideo={index === 0} />
  ));

OriginalsTab.propTypes = {
  series: arrayOf(shape(serieShape)),
  isLoggedUser: bool
};

export default OriginalsTab;
