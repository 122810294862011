import { useEffect, useRef } from 'react';

const useClickOutside = (callback, tracking = true) => {
  const node = useRef();

  useEffect(() => {
    const handleClick = e => {
      // Check if the click was outside of the node's element
      if (node.current && !node.current.contains(e.target)) {
        // Verify that callback is a function before calling it
        if (typeof callback === 'function') {
          callback();
        } else {
          console.error('useClickOutside: Provided callback is not a function');
        }
      }
    };

    // Only add event listener if tracking is enabled
    if (tracking) {
      document.addEventListener('mousedown', handleClick);
    }

    // Cleanup event listener on unmount or tracking state change
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [callback, tracking]);

  // Return the ref to attach to the element you want to track
  return node;
};

export default useClickOutside;
