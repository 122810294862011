import React from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { AppContainer } from 'react-hot-loader';
import { IntlProvider } from 'react-intl';
import includes from 'lodash/includes';
import { PersistGate } from 'redux-persist/lib/integration/react';
import App from 'components/App';
import locales from 'locales';
import { SUPPORTED_LANGUAGES, DEFAULT_LANGUAGE } from 'constants/constants';
import httpClient from 'httpClient';
import applyDefaultInterceptors from 'httpClient/applyDefaultInterceptors';
import { toast } from 'react-toastify';
import mixpanel from 'mixpanel-browser';
import runtime from 'serviceworker-webpack-plugin/lib/runtime';
import smoothscroll from 'smoothscroll-polyfill';
import 'styles/styles.scss';

import { configureStore } from './store';

require('../src/favicon.ico'); // Tell webpack to load favicon.ico
require('../src/robots.txt'); // Tell webpack to load robots.txt
require('../src/ads.txt'); // Tell webpack to load ads.txt

// Load service worker
if (process.env.ENABLE_PWA) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/main-sw.js');
  });
}

if ('serviceWorker' in navigator) {
  // eslint-disable-next-line no-unused-vars
  const registration = runtime.register();
}

// Fix for browsers that don't implement Intl by default e.g.: Safari)
if (!window.Intl) {
  require.ensure(
    [
      '@formatjs/intl-relativetimeformat',
      '@formatjs/intl-relativetimeformat/dist/include-aliases.js',
      '@formatjs/intl-relativetimeformat/dist/locale-data/en.js'
    ],
    require => {
      require('@formatjs/intl-relativetimeformat/polyfill');
      require('@formatjs/intl-relativetimeformat/dist/include-aliases');
      require('@formatjs/intl-relativetimeformat/dist/locale-data/en.js');
    }
  );
}

const usersLocale = navigator.language.split('-')[0];
const supportedUserLocale = includes(SUPPORTED_LANGUAGES, usersLocale);
const locale = supportedUserLocale ? usersLocale : DEFAULT_LANGUAGE;
const messages = locales[locale];
const autoCloseTime = 2000;

smoothscroll.polyfill();

mixpanel.init(process.env.MIXPANEL_TOKEN);

// Grab the state from a global variable injected into the server-generated HTML
const preloadedState = window.__PRELOADED_STATE__;
delete window.__PRELOADED_STATE__;

const { persistor, store } = configureStore(preloadedState);

// eslint-disable-next-line no-undef
if (!__DEV__) {
  console.log = () => {};
}

toast.configure({
  position: 'bottom-right',
  closeOnClick: true,
  pauseOnHover: true,
  autoClose: autoCloseTime,
  toastClassName: 'custom-toast',
  hideProgressBar: true
});

applyDefaultInterceptors(store, httpClient);
const renderApp = Component => {
  hydrate(
    <IntlProvider locale={locale} messages={messages} defaultLocale="en">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AppContainer>
            <Component />
          </AppContainer>
        </PersistGate>
      </Provider>
    </IntlProvider>,
    document.getElementById('app')
  );
};

renderApp(App);

if (module.hot) {
  module.hot.accept();
}
