import httpClient from 'httpClient';

class ReactionService {
  getReaction(data) {
    const params = new URLSearchParams(data);
    return httpClient.get(`reactions/content?${params.toString()}`);
  }

  reaction(data) {
    return httpClient.post(`reactions`, data);
  }

  deleteReaction(data) {
    const params = new URLSearchParams(data);
    return httpClient.delete(`reactions?${params.toString()}`);
  }
}

export default new ReactionService();
