import React from 'react';
import { number } from 'prop-types';

const LogoIcon = ({ width = 17, height = 18, opacity = 0.8 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="logoTitle logoDesc"
    role="img"
  >
    <title id="logoTitle">Id</title>
    <desc id="logoDesc">Identifi reduced logo</desc>
    <g opacity={opacity}>
      <path d="M0 17.8947V4.67618H1.90062V15.9432H17V17.8947H0Z" fill="white" />
      <path
        d="M6.79266 0V1.9883H15.0639V6.6645L14.6063 6.07537C14.008 5.30214 13.2337 4.93394 12.213 4.93394C9.85478 4.93394 8.34132 6.59086 8.34132 9.16829C8.34132 11.6721 9.88997 13.3658 12.1778 13.3658C13.0577 13.3658 13.9728 13.2185 14.7119 12.2244L15.0991 11.7089L15.3103 13.2185H16.9997V0H6.79266ZM12.5649 11.6721C11.2274 11.6721 10.2771 10.6411 10.2771 9.16829C10.2771 7.69547 11.2274 6.6645 12.5649 6.6645C14.184 6.6645 15.0639 7.9164 15.0639 9.16829C15.0639 11.0093 13.7616 11.6721 12.5649 11.6721Z"
        fill="white"
      />
      <path d="M6.30004 4.67618H4.39941V13.2553H6.30004V4.67618Z" fill="white" />
      <path d="M3.87163 0H0V1.9883H3.87163V0Z" fill="white" />
    </g>
  </svg>
);

LogoIcon.propTypes = {
  width: number,
  height: number,
  opacity: number
};

export default LogoIcon;
