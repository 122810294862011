import { createStore, compose, applyMiddleware } from 'redux';
import { thunkMiddleware } from '@rootstrap/redux-tools';
import { persistStore } from 'redux-persist';
import { CookieStorage } from 'redux-persist-cookie-storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import Cookies from 'cookies-js';

import rootReducer from 'state/reducers';

export const persistConfig = {
  key: 'session',
  storage: new CookieStorage(Cookies, {}),
  stateReconciler: autoMergeLevel2,
  whitelist: ['authenticated', 'info', 'user', 'token']
};

export const configureStore = (initialState, server = false) => {
  const middlewares = [thunkMiddleware()];

  const store = createStore(
    rootReducer(persistConfig),
    initialState,
    compose(applyMiddleware(...middlewares))
  );

  if (server) return { store };

  const persistor = persistStore(store);

  return { store, persistor };
};
