import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CardNumberElement } from '@stripe/react-stripe-js';
import { useLoading } from '@rootstrap/redux-tools';
import humps from 'humps';

import { createCard, getCards, setDefaultCreditCard } from 'state/actions/paymentsActions';
import { getFullName } from 'utils/helpers';
import { useToast, useAnalytics } from 'hooks';
import { MIXPANEL_EVENTS } from 'constants/constants';

export default (stripe, elements) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { showErrorToast } = useToast();
  const { trackEvent } = useAnalytics();

  const createCreditCard = async billing => {
    const { firstName, lastName, zipcode, country } = billing;

    trackEvent(MIXPANEL_EVENTS.addPaymentMethodClicked);

    const cardNumberElement = elements.getElement(CardNumberElement);
    setLoading(true);

    const address = {
      name: getFullName(firstName, lastName),
      addressZip: zipcode,
      addressCountry: country
    };

    const { token, error } = await stripe.createToken(
      cardNumberElement,
      humps.decamelizeKeys(address)
    );

    if (error) {
      const { message } = error;
      trackEvent(MIXPANEL_EVENTS.creditCardRejected, { Reason: message });
      showErrorToast(message);
    } else {
      const { card, id } = token;

      const { brand, addressZip, addressCountry, last4, expYear, expMonth } = humps.camelizeKeys(
        card
      );

      const data = {
        stripeToken: id,
        brand,
        country: addressCountry,
        zipCode: addressZip,
        last_4: last4,
        cardHolderFirstName: firstName,
        cardHolderLastName: lastName,
        expMonth,
        expYear
      };

       await dispatch(createCard(data));
    }

    setLoading(false);
  };

  const attemptCreateToken = async () => {
    const cardNumberElement = elements.getElement(CardNumberElement);
    await stripe.createToken(cardNumberElement);
  };

  const reset = () => {
    dispatch(createCard.reset());
  };

  const getCreditCards = () => {
    dispatch(getCards());
  };

  const setAsDefaultCard = async id => {
    await dispatch(setDefaultCreditCard(id));
    trackEvent(MIXPANEL_EVENTS.creditCardSelected);
    getCreditCards();
  };

  return {
    createCreditCard,
    loading,
    stripe,
    getCreditCards,
    getCardsLoading: useLoading(getCards),
    setAsDefaultCard,
    setDefaultLoading: useLoading(setDefaultCreditCard),
    creditCard: useSelector(({ payments: { creditCard } }) => creditCard),
    creditCards: useSelector(({ payments: { creditCards } }) => creditCards),
    reset,
    attemptCreateToken
  };
};
