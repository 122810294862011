import { useEffect, useState } from 'react';

import { SUCCESS } from '@rootstrap/redux-tools';

import useCable from 'hooks/useCable';
import useToggle from 'hooks/useToggle';

const SCHEDULED_CHANNEL = 'ScheduledLiveEventChannel';
const CREATOR_CHANNEL = 'LiveCelebrityChannel';

const STARTED = 'started';
const STOPPED = 'stopped';

export default ({ status, eventId, celebrityId, isScheduled = false }) => {
  const [isLive, setIsLive] = useState(false);
  const [offLine, setOffLine] = useState(false);
  const [connected, setConnected] = useToggle(false);

  const { connect, disconnect } = useCable();

  const channel = isScheduled ? SCHEDULED_CHANNEL : CREATOR_CHANNEL;
  const idKey = isScheduled ? 'scheduled_live_event_id' : 'celebrity_id';

  useEffect(() => {
    if (status === SUCCESS && !connected) {
      connect({
        channel: {
          channel,
          [idKey]: isScheduled ? eventId : celebrityId
        },
        onReceived: ({ state }) => {
          if (state === STOPPED) {
            setOffLine(true);
            setIsLive(false);
          }
          if (state === STARTED) {
            setIsLive(true);
            setOffLine(false);
          }
        },
        onConnect: () => setConnected(true)
      });
    }
  }, [status]);

  useEffect(() => () => disconnect(), []);

  return {
    isLive,
    offLine,
    disconnect
  };
};
