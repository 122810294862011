import React from 'react';
import cn from 'classnames';
import DummyImg from 'assets/dummy.png';
import AllDetails from './AllDetails';

const WidgetAdDetails = ({ data }) => {
  return (
    <div className={cn('video-ad-details')}>
      <div className="widget-image-container">
        {data?.widgetAdImages?.map((item, index) => {
          return (
            <img
              className="widget-image"
              key={index}
              src={item?.widgetAdImageUrl || DummyImg}
              alt={item?.buttonText}
            />
          );
        })}
      </div>
      <div className="video-ad-info">
        <AllDetails data={data} />
      </div>
    </div>
  );
};

export default WidgetAdDetails;
