import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import Tabs from 'components/common/Tabs/Tabs';
import { adManagementTabs } from 'constants/constants';
import { getIdFromTab } from 'utils/dataHelpers';
import useManageAds from 'hooks/ManageAds/useManageAds';

const RightSide = data => {
  const { tab } = useParams();
  const selectedTabId = useMemo(() => tab && getIdFromTab(adManagementTabs, tab), [tab]);
  const [selectedTab, setSelectedTab] = useState(selectedTabId || adManagementTabs.VIDEO_ADS.id);
  const { renderAdManagementContent, changeUrl } = useManageAds();
  const onTabChange = id => {
    changeUrl(id);
    setSelectedTab(id);
  };
  return (
    <div>
      <Tabs
        tabs={Object.values(adManagementTabs)}
        selectedTab={selectedTab}
        onTabSelect={onTabChange}
        className="manage-ad-tabs"
      />
      <div>{renderAdManagementContent(selectedTab, data)}</div>
    </div>
  );
};

export default RightSide;
