/* eslint-disable import/no-mutable-exports */
import * as firebase from 'firebase/app';
import 'firebase/messaging';

const firebaseConfig = JSON.parse(process.env.FIREBASE_CONFIG);

let messaging = null;

if (!process.platform && firebase?.messaging?.isSupported()) {
  const initializedFirebaseApp = firebase.initializeApp(firebaseConfig);
  messaging = initializedFirebaseApp.messaging();
  messaging.usePublicVapidKey(process.env.FIREBASE_PUBLIC_VAPID_KEY);
}

const getToken = async () =>
  messaging?.requestPermission().then(() => {
    return messaging.getToken();
  });

export { messaging, getToken };
