import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { string, oneOfType, array, func, object, bool } from 'prop-types';
import cn from 'classnames';
import { parsePhoneNumberFromString } from 'libphonenumber-js/mobile';
import ReactCountryFlag from 'react-country-flag';

import countries from 'constants/countries';
import { useToggle, useClickOutside } from 'hooks';

import { ArrowIcon } from 'components/icons';
import CountriesDropdown from 'components/common/CountryPhoneInput/CountriesDropdown/CountriesDropdown';

import { black60 } from 'styles/common/_constants.scss';

const CountryPhoneInput = ({
  name,
  label,
  errors,
  placeholder,
  defaultCountryCode,
  onChangeText,
  onBlur,
  ...props
}) => {
  const defaultCountry = useMemo(() => countries.find(({ code }) => code === defaultCountryCode), [
    defaultCountryCode
  ]);

  const [dropdownVisible, toggleDropdwon] = useToggle(false);
  const [currentValue, setCurrentValue] = useState('');
  const [currentCountry, setCurrentCountry] = useState(defaultCountry);
  const { dialCode, code } = currentCountry;

  const ref = useClickOutside(toggleDropdwon, dropdownVisible);

  const getNumberInfo = useCallback(phoneNumber => {
    const internationalNumber = phoneNumber.formatInternational();
    const { countryCallingCode: countryCode, number } = phoneNumber;
    const numberWithoutCode = internationalNumber.slice(countryCode.length + 1);
    const isValid = phoneNumber.isValid();
    return { isValid, number, numberWithoutCode };
  }, []);

  const onChange = useCallback(
    value => {
      const phoneNumber = parsePhoneNumberFromString(value, code);
      if (phoneNumber) {
        const { isValid, number, numberWithoutCode } = getNumberInfo(phoneNumber);
        setCurrentValue(numberWithoutCode);
        onChangeText && onChangeText(number, isValid);
      } else if (!value) {
        setCurrentValue('');
        onChangeText && onChangeText('', false);
      } else {
        setCurrentValue(value);
        onChangeText && onChangeText(value, false);
      }
    },
    [code, onChangeText, getNumberInfo]
  );

  const onBlurInput = useCallback(() => {
    const phoneNumber = parsePhoneNumberFromString(currentValue, code);
    const isValid = phoneNumber?.isValid();
    onBlur && onBlur(isValid);
  }, [currentValue, onBlur, code]);

  const onCountryChange = country => {
    setCurrentCountry(country);
    toggleDropdwon();
  };

  useEffect(() => {
    onChange(currentValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCountry]);

  return (
    <div className="country-phone">
      {label && <div className="country-phone-label tag-bold">{label}</div>}
      <div className={cn('country-phone-input', { error: errors })}>
        <div className="country-area" onClick={toggleDropdwon} ref={ref}>
          <div className="flag">
            <ReactCountryFlag countryCode={code} svg />
          </div>
          <ArrowIcon color={black60} />
          {dropdownVisible && (
            <CountriesDropdown
              options={countries}
              onClick={onCountryChange}
              toggle={toggleDropdwon}
              withSearch
              ref={ref}
            />
          )}
        </div>
        <div className="phone-area p1">
          <div className="p1 area-code">({dialCode})</div>
          <input
            {...props}
            name={name}
            value={currentValue}
            placeholder={placeholder}
            onChange={({ target: { value } }) => onChange(value)}
            onBlur={onBlurInput}
            autoComplete="off"
          />
        </div>
      </div>
      {errors && <div className="error-label">{errors[0]}</div>}
    </div>
  );
};

CountryPhoneInput.defaultProps = {
  defaultCountryCode: 'US',
  label: ''
};

CountryPhoneInput.propTypes = {
  label: string,
  name: string,
  errors: oneOfType([string, array, bool]),
  placeholder: string,
  onChangeText: func,
  onBlur: func,
  defaultCountryCode: string,
  props: object
};

export default CountryPhoneInput;
