import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { useSession, useAnalytics } from 'hooks';
import { routesPaths } from 'constants/routesPaths';
import { MIXPANEL_EVENTS } from 'constants/constants';

import SignUp from 'components/user/SignUp/SignUp';

const SignUpPage = () => {
  const { authenticated } = useSession();
  const { trackEvent } = useAnalytics();

  useEffect(() => {
    trackEvent(MIXPANEL_EVENTS.signUp);
  }, [trackEvent]);

  if (authenticated) {
    return <Redirect to={routesPaths.home} />;
  }

  return <SignUp />;
};

export default SignUpPage;
