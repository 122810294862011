/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useLocation } from 'react-router-dom';
import Loading from 'components/common/Loading';
import searchService from 'services/searchService';
import { convertPathName } from 'utils/helpers';
import ContentCard from './ContentCard';

const ContentPage = ({ dateFilter, categoryFilter }) => {
  const { pathname } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState([]);
  const searchParams = new URLSearchParams(location.search);
  const searchTerm = searchParams.get('search');
  const popPathName = pathname
    .split('/')
    .filter(Boolean)
    .pop();

  useEffect(() => {
    const fetchSearchResults = async () => {
      if (searchTerm) {
        setIsLoading(true);
        try {
          const { data } = await searchService.search(
            searchTerm,
            convertPathName(popPathName),
            dateFilter,
            categoryFilter || ''
          );
          if (data.search) {
            setResults(data.search.content);
          }
        } catch (error) {
          console.error('Error fetching search results:', error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchSearchResults();
  }, [searchTerm, popPathName, categoryFilter]);

  if (isLoading) {
    return (
      <div className="home-loading">
        <Loading />
      </div>
    );
  }
  return (
    <div>
      {results.length > 0 ? (
        <div className={cn('content-container')}>
          {results.map((item, index) => (
            <ContentCard data={item} key={index} />
          ))}
        </div>
      ) : (
        <div className="no-results-message">Matching records not found</div>
      )}
    </div>
  );
};

export default ContentPage;
