import { useState, useEffect } from 'react';
import { maxMobileWidth } from 'styles/common/_constants.scss';

export default minWidth => {
  function checkWindowWidth() {
    const width = minWidth || maxMobileWidth;
    return window?.innerWidth <= width;
  }

  const [isMobile, setIsMobile] = useState(checkWindowWidth);

  useEffect(() => {
    function handleResize() {
      setIsMobile(checkWindowWidth());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return isMobile;
};
