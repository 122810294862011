import * as React from 'react';

const AdIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      fill="#fff"
      d="M18 11.5v1-1ZM19 3a1 1 0 1 0-2 0h2Zm2 11.5h1-1Zm-6 0h1-1Zm2 6.5a1 1 0 1 0 2 0h-2Zm2-9.5V3h-2v8.5h2Zm-1 1a2 2 0 0 1 2 2h2a4 4 0 0 0-4-4v2Zm2 2a2 2 0 0 1-2 2v2a4 4 0 0 0 4-4h-2Zm-2 2a2 2 0 0 1-2-2h-2a4 4 0 0 0 4 4v-2Zm-2-2a2 2 0 0 1 2-2v-2a4 4 0 0 0-4 4h2Zm1 3V21h2v-3.5h-2ZM6 12.5v-1 1ZM7 21a1 1 0 1 1-2 0h2ZM9 9.5h1-1Zm-6 0h1-1ZM5 3a1 1 0 0 1 2 0H5Zm2 9.5V21H5v-8.5h2Zm-1-1a2 2 0 0 0 2-2h2a4 4 0 0 1-4 4v-2Zm2-2a2 2 0 0 0-2-2v-2a4 4 0 0 1 4 4H8Zm-2-2a2 2 0 0 0-2 2H2a4 4 0 0 1 4-4v2Zm-2 2a2 2 0 0 0 2 2v2a4 4 0 0 1-4-4h2Zm1-3V3h2v3.5H5Z"
    />
  </svg>
);
export default AdIcon;
