import React from 'react';
import { Helmet } from 'react-helmet';
import { shape, string } from 'prop-types';

import { DEFAULT_PAGE_TITLE } from 'constants/constants';

const Metadata = ({ metadata, socialMediaMetadata, canonicalUrl }) => {
  const { description, title } = metadata || {};

  let meta = [];

  if (socialMediaMetadata) {
    const { description, title, image } = socialMediaMetadata;

    const twitterTags = [
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: title },
      { name: 'twitter:description', content: description },
      { name: 'twitter:image', content: image }
    ];

    const facebookTags = [
      { property: 'og:url', content: canonicalUrl },
      { property: 'og:type', content: 'website' },
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
      { property: 'og:image', content: image }
    ];

    meta = [...twitterTags, ...facebookTags];
  }

  description && meta.push({ name: 'description', content: description });

  return (
    <Helmet
      title={title || DEFAULT_PAGE_TITLE}
      meta={meta}
      link={canonicalUrl ? [{ href: canonicalUrl, rel: 'canonical' }] : []}
    />
  );
};

Metadata.propTypes = {
  metadata: shape({
    title: string,
    description: string
  }),
  socialMediaMetadata: shape({
    title: string,
    description: string,
    image: string
  }),
  canonicalUrl: string
};

export default Metadata;
