import { useSelector } from 'react-redux';
import { useLoading } from '@rootstrap/redux-tools';

import { getHome } from 'state/actions/homeActions';

export default () =>
  useSelector(({ home: { celebrities, featuredCategories, content } }) => ({
    celebrities,
    featuredCategories,
    content,
    loading: useLoading(getHome)
  }));
