export const PRIVACY_POLICY = [
  {
    title: 'IDENTIFI HOLDINGS, INC. PRIVACY & DATA POLICY – COMMITMENT TO YOUR PRIVACY',
    body:
      `This site is owned and operated by Identifi Holdings, Inc. (“Identifi”). Your privacy on the Internet is of the ` +
      `utmost importance to us. At Identifi, we want to make your experience online satisfying and safe. \n \nBecause we gather certain types of information about you as users who visit our website ` +
      `(www.weidentifi.com, the “Site”) and any of our related services, content or applications (the Site and ` +
      `these services, content and applications together will be described as the "Service"), we feel you should ` +
      `fully understand our policy and the terms and conditions surrounding the capture and use of that ` +
      `information. This privacy statement discloses what information we gather and how we use it.\n\nThis Privacy Policy is part of, and is governed by, the terms and conditions set forth in the Identifi Terms ` +
      `of Service located at www.weidentifi.com/legal/terms. If you disagree with any of the terms in this Privacy ` +
      `Policy, you may not use the Identifi Service.\n\nIn the event that Identifi changes the terms of this Privacy Policy, Identifi may attempt to contact you in ` +
      `advance of the effective date of such changes. Identifi may attempt to contact you by email, if you have ` +
      `provided Identifi with a current email address or by other means through Identifi’s platform. `
  },
  {
    title: 'INFORMATION IDENTIFI GATHERS AND TRACKS',
    body:
      `Identifi gathers information about users of our Service: \n \n ` +
      `• Information that users provide through optional, voluntary submissions. These are voluntary ` +
      `submissions to receive our electronic newsletters, to participate in our message boards or chat ` +
      `forums, to email a friend, and from participation in direct messaging, polls and surveys: \n \n ` +
      `• Information Identifi gathers through aggregated tracking information derived mainly by tallying ` +
      `views of pages, videos and images throughout our sites. This information allows us to better tailor ` +
      `our content to user needs and to help advertisers and sponsors better understand our audience. ` +
      `Under no circumstances does Identifi divulge any information about an individual user to a third ` +
      `party. \n \n ` +
      `• When you log into Identifi by logging in to the Services through a third party social media service, ` +
      `such as Facebook, Instagram, X, TikTok or Snapchat you are giving us permission to access, ` +
      `store and use any information that you permit the applicable third party social media service to ` +
      `share with us in accordance with the privacy policy of that specific company’s service and the ` +
      `privacy settings that are applicable to your account on that company’s service. We encourage ` +
      `you to review, and if necessary, adjust, your privacy settings on the applicable social media ` +
      `service before logging in to the Identifi Services through a social media service. Information that ` +
      `we receive from your third party social media service is stored and used by Identifi in accordance ` +
      `with this Privacy Policy.`
  },
  {
    title: `Identifi Gathers User Information In The Following Processes:`,
    body:
      `Information about your activity in our Services, which we use to do things like recommend a Creators, ` +
      `series and videos you might like. The activity information we collect may include: \n\n ` +
      `• Search terms you enter \n\n` +
      `• Videos you watch \n\n` +
      `• Creators you watch, follow and subscribe to \n\n` +
      `• Views and interactions with content and ads \n\n` +
      `• Purchase activity \n\n` +
      `• People with whom you communicate or share content`
  },
  {
    title: 'Optional Voluntary Information',
    body:
      `We offer the following free services, which require some type of voluntary submission of personal information by users: \n \n ` +
      `1. Electronic newsletters policy (Dispatches) ` +
      `We will offer a free electronic newsletter to users. Identifi, Inc. gathers the email addresses of ` +
      `users who voluntarily subscribe. Users may remove themselves from this mailing list by following ` +
      `the link provided in every newsletter that points users to the subscription management page. ` +
      `Users can also subscribe to the newsletters at the time of registration. \n \n ` +
      `2. Message and chat boards/forums policy ` +
      `Users of the site’s Message Boards, Live Chat features and Forums must register separately for ` +
      `these services in order to post messages, although they are not required to register to visit the ` +
      `site. During registration the user is required to supply a name, password, and phone number or ` +
      `email address. \n \n` +
      `3. Polling ` +
      `We may offer interactive polls to users so they can easily share their opinions with other users ` +
      `and see what our audience thinks about important issues. Opinions or other responses to polls ` +
      `are aggregated and are not identifiable to any particular user. Identifi, Inc. may use a system to ` +
      `“tag” users after they have voted, so they can vote only once on a particular question. This tag is ` +
      `not correlated with information about individual users. \n \n ` +
      `4. Surveys ` +
      `Identifi may occasionally conduct user surveys to better target our content to our audience. We ` +
      `sometimes share the aggregated demographic information in these surveys with our sponsors, ` +
      `advertisers and partners. We never share any of this information about specific individuals with ` +
      `any third party. \n \n ` +
      `5. Usage tracking ` +
      `Identifi, Inc. tracks user traffic patterns throughout all of our sites. However, we do not correlate ` +
      `this information with data about individual users. Identifi does break down overall usage statistics ` +
      `according to a user’s domain name, browser type, and MIME type by reading this information ` +
      `from the browser string (information contained in every user’s browser). \n \n` +
      `Identifi may track and catalog the search terms users enter in our Search function, but this tracking is ` +
      `never associated with individual users. We use tracking information to determine which areas of our sites ` +
      `users like and don’t like based on traffic to those areas. We do not track what individual users read, but ` +
      `rather how well each page performs overall. This helps us continue to build a better service for you.`
  },
  {
    title: 'Cookies',
    body:
      'We use cookies to offer you a better browsing experience, analyze site traffic, personalize content, and serve targeted advertisements. \n \n We may place a text file called a “cookie” in the browser files of your computer. The cookie itself does not contain Personal Information although it will enable us to relate your use of this site to information that you have specifically and knowingly provided. But the only personal information a cookie can contain is information you supply ' +
      'yourself. A cookie can’t read data off your hard disk or read cookie files created by other sites. Identifi, Inc. uses cookies to track user traffic patterns (as described above). We may use data we collect automatically to recognize you as a return visitor, to serve relevant online advertising to you or to analyze trends that will improve your experience of the Identifi Services. \n \nYou can refuse cookies by turning them off in your browser. If you’ve set your browser to warn ' +
      'you before accepting cookies, you should receive the warning message with each cookie. You do not need to have cookies turned on to use this site. However, you do need cookies to participate actively in message boards, forums, polling and surveys. If you set your browser to limit or remove cookies or otherwise block our use of cookies, some features of the Services may be unavailable or unable to function properly. In addition, if you access the Services through a mobile device, we may also be able to identify the location of your mobile device. You may choose not to share your location details with us by adjusting your mobile device’s location services settings. If you require assistance in turning off cookies, please contact either your service provider or device manufacturer directly.'
  },
  {
    title: 'USE OF INFORMATION',
    body:
      `Do we disclose any information to outside parties? \n \nWe do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This ` +
      `does not include trusted third parties and network partners who assist us in operating our Service, ` +
      `conducting our business, or servicing you, so long as those parties agree to keep this information ` +
      `confidential. We may also release your information when we believe release is appropriate to comply with ` +
      `law, enforce our Service policies, or protect ours or others rights, property, or safety. However, nonpersonally identifiable visitor information may be provided to other parties for marketing, advertising, or ` +
      `other uses.`
  },
  {
    title: 'California Online Privacy Protection Act Compliance',
    body: `Because we value your privacy we have taken the necessary precautions to be in compliance with the California Online Privacy Protection Act. We therefore will not distribute your personal information to outside parties without your consent.`
  },
  {
    title: 'Childrens Online Privacy Protection Act Compliance',
    body:
      `We are in compliance with the requirements of COPPA (Childrens Online Privacy Protection Act), we do ` +
      `not collect any information from anyone under 13 years of age. Our website, products and services are all ` +
      `directed to people who are at least 13 years old or older. The Services may include unmonitored content ` +
      `posted by users or sourced from other third parties and such content may be unsuitable for children. We ` +
      `do not knowingly collect or distribute information from or about children under age 13. \n \n` +
      `Identifi uses any information voluntarily given by our users to enhance their experience in our site, ` +
      `whether to provide interactive or personalized elements on the sites or to better prepare future content ` +
      `based on the interests of our users.\n\n` +
      `As stated above, we use information that users voluntarily provide in order to send out electronic ` +
      `newsletters and to enable users to participate in polls, surveys, chats, message boards, and forums. We ` +
      `send out newsletters to subscribers, notifications, updates, alerts, and occasionally send out special ` +
      `editions when we think subscribers might be particularly interested in something we are doing, an event ` +
      `or new content. Identifi, Inc. never shares newsletter or subscriber mailing lists with any third parties, ` +
      `including advertisers, sponsors or partners.\n\n` +
      `When we use tracking information to determine which areas of our sites users like and don’t like based on ` +
      `traffic to those areas. We do not track what individual users read, but rather how well each page performs ` +
      `overall. This helps us continue to build a better service for you. We track search terms entered in Search ` +
      `function as one of many measures of what interests our users. But we don’t track which terms a particular ` +
      `user enters.\n\n` +
      `Identifi, Inc. may create aggregate reports on user demographics and traffic patterns for advertisers, ` +
      `sponsors and partners. This allows our advertisers to advertise more effectively, and allows our users to ` +
      `receive advertisements that are pertinent to their needs.`
  },
  {
    title: 'SHARING OF THE INFORMATION',
    body:
      `Identifi, Inc. uses the above-described information to tailor our content to suit your needs and help our ` +
      `advertisers better understand our audience’s demographics. This is essential to keeping our service free. ` +
      `We will not share information about individual users with any third party, except to comply with applicable ` +
      `law or valid legal process or to protect the personal safety of our users or the public.`
  },
  {
    title: 'Measurement vendors',
    body:
      `We may use third party vendors for measurement and analytics reports of activity and engagement with ` +
      `content and advertisements. We share information (like whether people saw an ad or engaged with it) ` +
      `with our vendors, who aggregate it to provide their reports.`
  },
  {
    title: 'Marketing vendors',
    body:
      `We share information about you with our marketing vendors. For example, we share your device ` +
      `identifier or other identifiers with marketing vendors to help us serve you ads most relevant to your ` +
      `interests.\n\n` +
      `Marketing vendors support our marketing and advertising efforts by:\n\n` +
      `• Serving our advertisements across the internet, including on mobile, desktop and connected ` +
      `television devices\n\n` +
      `• Tracking and categorizing your online and mobile app activity\n\n` +
      `• Providing us information about your interests in creators and content, and advertising interactions\n\n` +
      `These marketing vendors help us understand who might find our advertising most relevant to their ` +
      `interests, and which of our Products might interest you. This information can be used to personalize which ` +
      `of our ads are shown to you. Marketing vendors also use this information to measure response to our ` +
      `marketing efforts and the effectiveness of any advertising on Identifi.`
  },
  {
    title: 'Service providers',
    body:
      `Service providers provide services to us that help us provide the Identifi Service to you. We share the information we have about you to receive these services, which include:\n\n` +
      `• Investigating suspicious activity ` +
      `• Detecting and stopping threats to our personnel and property ` +
      `• Facilitating payments ` +
      `• Providing customer support ` +
      `• Improving the functionality of our Service ` +
      `• Providing technical infrastructure services ` +
      `• Analyzing how our Services are used ` +
      `• Conducting research and surveys ` +
      `• Marketing and promoting our Service and content on Identifi`
  },
  {
    title: 'Information transferred to other countries',
    body:
      `We share the information Identifi collects globally, both internally across data centers, and externally with our third party partners and service providers. Identifi is a global Service, with users, creators, partners and employees around the world, and data transfers are necessary for a variety of reasons, including:\n\n` +
      `• Operating and delivering the Service you’re using and this Policy. This includes allowing you to share information and content with anyone around the globe.` +
      `• Fix, analyze and improve our Service`
  },
  {
    title: 'SECURITY',
    body:
      `Identifi operates secure data networks protected by industry standard firewall and password protection ` +
      `systems. Our security and privacy policies are periodically reviewed and enhanced as necessary and only ` +
      `authorized individuals have access to the information provided by our users. Identifi cannot guarantee or ` +
      `warrant the security of any information you transmit on or through the Service and you do so at your own ` +
      `risk.`
  },
  {
    title: 'OPT-OUT POLICY',
    body:
      `We give users options wherever necessary and practical. Such choices include: \n \n ` +
      `• Opting not to register to receive our emails and other alerts and electronic notifications. \n \n` +
      `• Opting not to participate in certain interactive areas, which completely alleviates the need to gather any personally identifiable information from our users.`
  },
  {
    title: 'YOUR CONSENT',
    body:
      `By using this site and/or the Service, you consent to the collection and use of this information by Identifi ` +
      `Holdings, Inc. If we decide to change our Privacy Policy, we will post those changes on this page so that ` +
      `you are always aware of what information we collect, how we use it, and under what circumstances we ` +
      `disclose it.`
  },
  {
    title: 'LEGAL DISLOSURE',
    body:
      `We will access, preserve, use and share personal information outside of Identifi if we have a good-faith ` +
      `belief that disclosure of the information is reasonably necessary to:\n\n` +
      `• Respond to any applicable law, regulation, legal process, or enforceable governmental request, ` +
      `like search warrants, court orders, production orders or subpoenas. These requests come from ` +
      `such as civil litigants, law enforcement and other government authorities. about when we respond ` +
      `to legal requests.\n\n` +
      `• Enforce applicable Terms of Service, including investigation of potential violations.\n\n` +
      `• Detect, prevent, or otherwise address fraud, security, or technical issues.\n\n` +
      `• Protect against harm to the rights, property or safety of Identifi, our employees, users, or the ` +
      `public.\n\n` +
      `We may share non-personally identifiable information publicly and with our partners — like publishers, ` +
      `advertisers, developers, or rights holders.\n\n` +
      `If Identifi is involved in a merger, acquisition, or sale of assets, we’ll continue to ensure the confidentiality ` +
      `of your personal information and give affected users notice before personal information is transferred or ` +
      `becomes subject to a different privacy policy.`
  },
  {
    title: 'Google Ads',
    googleAd: true,
    body:
      `• Third party vendors, including Google, use cookies to serve ads based on a users’ prior visits to the ` +
      `Service.\n\n` +
      `• Google's use of the DoubleClick cookie enables it and its partners to serve ads to your users based on ` +
      `each user’s visit to the Identifi site and/or Service.\n\n` +
      `• Users may opt out of the use of the DoubleClick cookie for interest-based advertising by visiting Ad ` +
      `Settings. You may also opt out of a third-party vendor's use of cookies for interest-based advertising by ` +
      `visiting aboutads.info.\n\n`
  },
  {
    title: 'Privacy notice for United States residents',
    body:
      'You can learn more about the consumer privacy rights that may be available to you by reviewing the United States Regional Privacy Notice below.'
  },
  {
    title: 'How to contact Identifi with questions',
    body:
      `If you have questions about this Policy or have questions, complaints or requests regarding your ` +
      `information, you can contact us as described below.\n\n` +
      `You can contact us or by mail at:\n\n` +
      `Identifi Holdings, Inc.\n` +
      `ATTN: Legal\n` +
      `23622 Calabasas Rd, STE 319\n` +
      `Calabasas, CA 91302\n` +
      `Or via email at:`
  }
];

export const LEGEND =
  `Identifi, www.weidentifi.com and related digital properties are operated and published by identifi Holdings, ` +
  `Inc.`;
export const SUPPORT_EMAIL = 'support@weidentifi.com';
const supportEmailLink = `mailto:${SUPPORT_EMAIL}`;
const supportEmailFullLink = `[link:${SUPPORT_EMAIL}](${supportEmailLink})`;

export const MEDIA_EMAIL = 'media@weidentifi.com';
const mediaEmailLink = `mailto:${MEDIA_EMAIL}`;
const mediaEmailFullLink = `[link:${MEDIA_EMAIL}](${mediaEmailLink})`;

export const WEB_URL_LINK = 'https://www.weidentifi.com';
export const WEB_URL_TEXT = 'www.weidentifi.com';

export const ADS_SETTINGS_LINK = 'https://www.google.com/settings/ads';
export const ADS_SETTINGS_TEXT = 'Ad Settings';

export const ABOUT_ADS_LINK = 'https://youradchoices.com';
export const ABOUT_ADS_TEXT = 'aboutads.info';

export const PRIVACY_POLICY_2ND_PART = [
  {
    title: 'United States Regional Privacy Notice',
    body:
      `Effective October 1, 2024\n\n` +
      `Read the for more details about how we handle “Consumer Health Data” under the Washington My ` +
      `Health My Data Act and the Nevada Health Privacy Act.\n\n` +
      `This United States Regional Privacy Notice (“Notice”) is for people living in the United States and ` +
      `supplements the Identifi Privacy Policy, Identifi Terms of Service and Identifi Marketplace Policy.\n\n` +
      `This Notice explains how we, Identifi, collect, use, and disclose your Personal Information. It also ` +
      `describes how to exercise your rights under applicable U.S. privacy laws. When we say “Personal Information” in this Notice, we mean information that identifies, relates to, describes, is reasonably ` +
      `capable of being associated with, or could reasonably be linked with you, directly or indirectly. Personal ` +
      `Information does not include information that cannot be reasonably linked to you. When data is ` +
      `deidentified (as defined under applicable U.S. privacy laws), we maintain it in deidentified form and do not ` +
      `attempt to reidentify the information.`
  },
  {
    title: 'How we collect, use, and disclose Personal Information',
    body:
      `To provide the products or features offered by Identifi (the “Service"), we process information about you, ` +
      `including Personal Information, whether or not you have an account or are logged in. The best way to ` +
      `learn about the types of information we collect and how we use them is to review the and the privacy ` +
      `policies of our other products.\n\n` +
      `Subject to the limitations we describe in our privacy policies, we may disclose your Personal Information ` +
      `for business purposes, with strict restrictions on how our partners can use and disclose the data we ` +
      `provide. We may also disclose it at your direction or in other ways that are in accordance with applicable ` +
      `U.S. privacy laws. We don’t “share” your Personal Information, as defined in the California Consumer ` +
      `Privacy Act (“CCPA”). We also don’t sell any of your Personal Information, and we never will.\n\n` +
      `The information we collect, use and disclose about you will vary depending on how you interact with ` +
      `Identifi and our products. For the products covered by this Notice, here’s a summary of:\n\n` +
      `• The categories of Personal Information we may have collected about you over the past 12 ` +
      `months\n\n` +
      `• How we may use your Personal Information\n\n` +
      `• To whom we may have disclosed that information\n\n`
  },
  {
    title: `Categories of Personal Information we collect may include:`,
    body:
      `• Identifiers;\n\n` +
      `• Characteristics of ` +
      `protected classifications;\n\n` +
      `• Commercial ` +
      `information;\n\n` +
      `• Photos and videos, ` +
      `which may include face ` +
      `imagery;\n\n` +
      `• Internet or other ` +
      `electronic network activity ` +
      `information, including ` +
      `browser and app logs, ` +
      `content you view or engage ` +
      `with, and app, browser and ` +
      `device information;\n\n` +
      `• Location-related ` +
      `information;\n\n` +
      `• Audio or visual ` +
      `information, including ` +
      `photos, videos, and voice `
  },
  {
    title: `Examples of how Personal Information may be used include:`,
    body:
      `• Providing, personalizing, and improving our products, including to;\n\n` +
      `• Providing measurement, analytics, and other business services;\n\n` +
      `• Promoting safety, integrity, and security;\n\n` +
      `• Providing marketing communications to you;\n\n` +
      `• Communicating with you; and\n\n` +
      `• Researching and innovating for social good.\n\n` +
      `For categories of sensitive personal information that we collect, we will only use or disclose it either with your specific consent when required, or as otherwise permitted by law, including the CCPA. about the permitted `
  },
  {
    title: `Parties with whom each category of Personal Information may be disclosed include:`,
    body:
      `• People and accounts you share and communicate with;\n\n` +
      `• People and accounts with which others share or reshare content about you;\n\n` +
      `• Apps, websites, and third-party integrations on or using our products;\n\n` +
      `• New owners in the event of a change of ownership or control of all or part of our products or their assets changes;\n\n` +
      `• Partners, including partners offering goods and services on our `
  },
  {
    title: `Categories of Personal Information we collect may include:`,
    body:
      `recordings;\n\n` +
      `• Professional or employment information;\n\n` +
      `• Information derived from other Personal Information about you, which could include your preferences, interests, and other information used to personalize your experience; and\n\n` +
      `• Other information you provide.\n\n` +
      `We may also collect sensitive personal information (as defined by applicable U.S. privacy laws), which may include:\n\n` +
      `• Social security, driver’s license, state identification card or passport number;\n\n` +
      `• Precise geolocation;\n\n` +
      `• Information about your racial or ethnic origin or religious views or union membership;\n\n` +
      `• The content of messages you send and receive, which are considered sensitive personal information under CCPA;\n\n` +
      `• Information about your sexual orientation;\n\n` +
      `• Information about your health; and\n\n` +
      `• Face imagery or voice recordings which may be used to identify you when you use relevant features.\n\n`
  },
  {
    title: `Examples of how Personal Information may be used include:`,
    body: `purposes under CCPA.`
  },
  {
    title: `Parties with whom each category of Personal Information may be disclosed include:`,
    body:
      `products, as explained in our;\n\n` +
      `• Vendors, including measurement and marketing vendors;\n\n` +
      `• Service providers;\n\n` +
      `• Third parties, including external researchers and academics;\n\n` +
      `• Law enforcement or other third parties in connection with legal requests, to comply with applicable law or to prevent harm; and`
  },
  {
    title: `Sources of Personal Information`,
    body:
      `Depending on how you interact with Identifi and our products, the categories of sources from which we’ve collected Personal Information about you may include:\n\n` +
      `• You: We receive Personal Information from the information that you provide. For example, we ` +
      `collect the content, communications, and other information you provide when you use our ` +
      `products, including when you sign up for an account, create or share content, and message or ` +
      `communicate with others. We collect information about the people, pages, accounts, hashtags, ` +
      `and groups you are connected to, and how you interact with them across our products, such as ` +
      `the people you communicate with the most or the groups you are part of. We also collect ` +
      `information about how you use our products, including ads we serve on and off our products, ` +
      `such as the types of content you view or engage with, the features you use, the actions you take, ` +
      `the people or accounts you interact with, and the time, frequency, and duration of your activities.\n\n` +
      `• Other people: We may also receive and analyze content, communications, and information about ` +
      `you that other people provide when they use our products, such as when others share or ` +
      `comment on a photo of you, send a message to you, or upload, sync, or import your contact ` +
      `information.\n\n` +
      `• Your device(s): We collect information from and about the computers, phones, connected TVs, ` +
      `and other web-connected devices you use that integrate with our products, and we combine this ` +
      `information across different devices you use.\n\n` +
      `• Identifi Companies: We receive information from other according to their terms and policies and ` +
      `as permitted by applicable law. In some cases, we act as a service provider for other Identifi ` +
      `Companies, and act on their behalf and in accordance with their instructions and terms.\n\n` +
      `• Partners, vendors and third parties: Advertisers, app developers, and publishers and other ` +
      `partners can send us information for business purposes. These partners provide information ` +
      `about your activities on and off our products—including information about your device, websites ` +
      `you visit, apps you use, games you play, and purchases you make. We also receive information ` +
      `about your online and offline actions, and purchases from third-party data providers who have the ` +
      `rights to provide us with your information. These partners collect your information when you visit ` +
      `their websites or use their services or through third parties they work with. We require each of ` +
      `these partners to have rights to collect, use, and disclose your information before providing any ` +
      `information to us. We also obtain information from publicly available sources, research institutions ` +
      `and professional and non-profit groups, including companies or organizations that provide content ` +
      `including videos, photos, or audio.`
  },
  {
    title: `How long do we keep your Personal Information?`,
    body: `We keep Personal Information, including sensitive Personal Information, as long as we need it to provide our products, comply with legal obligations or protect our or other’s interests. We decide how long we need information on a case-by-case basis.`
  },
  {
    title: `Here’s what we consider when we decide:`,
    body:
      `• If we need it to operate or provide our products.\n\n` +
      `• The feature we use it for, and how that feature works.\n\n` +
      `• How long we need to retain the information to comply with certain legal obligations.\n\n` +
      `• If we need it for other legitimate purposes, such as to prevent harm; investigate possible ` +
      `violations of our terms or policies; promote safety, security and integrity; or protect ourselves, ` +
      `including our rights, property or products.\n\n` +
      `Learn more in the “Why we may preserve your information longer” section of the Identifi Privacy Policy.`
  },
  {
    title: `How can you exercise your rights provided under applicable U.S. privacy laws?`,
    body:
      `Depending on where you live and subject to certain exceptions, you may have some or all of the following rights:\n\n` +
      `• Right to Know: The right to request that we disclose to you the Personal Information we collect, use, or disclose, and information about our data practices.\n\n` +
      `• Right to Request Correction: The right to request that we correct inaccurate Personal Information that we maintain about you.\n\n` +
      `• Right to Request Deletion: The right to request that we delete your Personal Information that we have collected from or about you.\n\n` +
      `• Right to Opt Out of Targeted Advertising: The right to opt out of the processing of your Personal Information for purposes that may be considered “targeted advertising” under applicable U.S. privacy laws, including information obtained from your activities on nonaffiliated websites or online applications.\n\n` +
      `• Right to Non-Discrimination: The right not to receive discriminatory treatment for exercising your privacy rights.\n\n`
  },
  {
    title: `To submit a request to exercise your rights, and as applicable, to appeal a consumer rights action, please contact us at support@weidentifi.com.`,
    body:
      `Here’s how you can exercise the right to opt out of targeted advertising:\n\n` +
      `• For ads you see on other apps and websites about Identifi Services, use the Digital Advertising Alliance and the NIA industry opt-outs.\n\n` +
      `• Depending on where you live, you may have the right to opt out of targeted advertising through a ` +
      `Global Privacy Control (GPC) enabled browser setting. If you have this right, when you visit and ` +
      `log in to our websites in a GPC-enabled browser, we will treat our initial receipt of the GPC signal ` +
      `as a valid request to opt-out of targeted advertising, as defined by applicable U.S. privacy laws ` +
      `granting this right. Please note that if you are logged out, our processing of the signal will be ` +
      `limited to the specific browser that you are using. You may need to renew your opt-out choice if ` +
      `you use a different browser to access our websites. Other than GPC, we do not recognize any ` +
      `"do not track" signals.\n\n` +
      `Please note that to protect your information and the integrity of our products, we may need to verify your ` +
      `identity before processing your request. In some cases, we may need to collect additional information to ` +
      `verify your identity, such as a government issued ID. Under certain U.S. privacy laws, you may also ` +
      `designate an authorized agent to make these requests on your behalf. If you use an authorized agent to ` +
      `submit a request, we may need to collect additional information, such as a government issued ID, to verify ` +
      `your identity before processing your request to protect your information. In most cases, we will facilitate ` +
      `your request through automated tools available through your password-protected account.`
  },
  {
    title: `Contact for more information`,
    body: `If you have additional questions about this Notice or how to exercise your rights under applicable U.S. privacy laws, please contact us at`
  }
];

export const TERMS_OF_SERVICES = [
  {
    title: 'Identifi Terms of Service',
    body:
      `Last Updated: August 20, 2024 (“Effective Date”)\n\n` +
      `These Terms of Service (“Terms”) are a contract between you and Identifi Holdings, Inc. (“Identifi,” “we,” or ` +
      `“us”). These Terms govern your use of our “Service” or “Services," which refers to the services provided by ` +
      `Identifi for discovering, watching, and interacting with our content, publishing, monetizing and otherwise ` +
      `disseminating audio and/or audiovisual material you may upload to the Service, which includes our mobile ` +
      `applications, TV applications, and similar audiovisual players (“Apps”), our websites, and our marketing and ` +
      `promotions materials and campaigns. By using the Service with no further action required, you agree accept ` +
      `and agreed to abide and be bound by these Terms. If you don’t agree to any of these Terms, you are not ` +
      `permitted to use the Services. If you violate the terms, you are subject to forfeiting your account, your ` +
      `content and your ability to access the Service.\n\n` +
      `TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, YOU AGREE THAT ALL DISPUTES ` +
      `EXCEPT FOR INJUNCTIVE RELIEF AND DISPUTES ELIGIBLE FOR SMALL CLAIMS COURT WILL BE ` +
      `RESOLVED BY BINDING ARBITRATION AND NOT COURTS, JUDGES, OR JURIES, AND YOUR ` +
      `CLAIMS CANNOT BE BROUGHT AS A CLASS ACTION AGAINST US. Please review Section 22 of the ` +
      `Terms (“Disputes”) for full details.\n\n` +
      `We can change these Terms at any time. If we believe a change is material or that we are legally required to ` +
      `provide notice, we will use commercially reasonable efforts to let you know before the change takes effect, ` +
      `such as through a notice by email or through the Services. Changes to these Terms that we believe are ` +
      `immaterial are effective immediately upon posting of the new Terms on the Services. By using the Services ` +
      `on or after that effective date, you agree to the new Terms. If you don’t agree with any changes to these ` +
      `Terms, you must immediately stop using the Services, including cancelling any subscriptions and deleting ` +
      `any account(s), as any use of the Services after the effective date of the new Terms will be subject to the ` +
      `new Terms.`
  },
  {
    title: '1. Who Can and Can’t Use Identifi:',
    body:
      `The Identifi Service open and supportive of free speech as ` +
      `possible, but we also want it to be secure and in accordance with the law. We need you to commit ` +
      `to a few restrictions in order to be part of Identifi. By using the Services, you affirm that:\n\n` +
      `(a) You must be at least 13 years old. The Identifi Services are not available to persons under the ` +
      `age of 13. If you are between the ages of 13 and the age of legal majority in your jurisdiction of ` +
      `residence, you may only use the Identifi Services under the supervision of a parent or legal ` +
      `guardian who agrees to be bound by these Terms of Service.\n\n` +
      `(b) You must not be prohibited from receiving any aspect of our Service under applicable laws or ` +
      `engaging in payments related Services if you are on an applicable denied party list.\n\n` +
      `(c) We must not have previously disabled your account for violation of law or any of our Terms or ` +
      `Privacy Policies.\n\n` +
      `(d) You are located in one of the geographic territories to which Identifi directs the Services.\n\n` +
      `(e) You can't impersonate other people or accounts, assume the identity of another ` +
      `person, group, brand or entity or provide inaccurate information. You don't have to ` +
      `disclose your personally identifiable information publicly on Identifi, but you must provide us ` +
      `with accurate and up to date information (including registration information). Also, you may not ` +
      `impersonate someone you are not, and you can't create an account for someone else unless ` +
      `you have their express written permission.\n\n` +
      `BY DOWNLOADING, INSTALLING, OR OTHERWISE USING THE IDENTIFI SERVICES, ` +
      `YOU REPRESENT AND WARRANT THAT YOU ARE AT LEAST 13 YEARS OF AGE, THAT ` +
      `YOUR PARENT OR LEGAL GUARDIAN AGREES TO BE BOUND BY THESE TERMS OF ` +
      `SERVICE IF YOU ARE BETWEEN 13 AND THE AGE OF LEGAL MAJORITY IN YOUR ` +
      `JURISDICTION OF RESIDENCE, AND THAT YOU HAVE NOT BEEN PREVIOUSLY ` +
      `REMOVED FROM AND ARE NOT PROHIBITED FROM RECEIVING THE IDENTIFI ` +
      `SERVICES.`
  },
  {
    title: '2. Restricted Uses of Identifi:',
    body:
      `Providing a global streaming video Service for the world that upholds ` +
      `the law based on high moral standards where the highest level of quality programming can be ` +
      `distributed requires that we maintain and enforce those standards, and limit certain violating people, ` +
      `illegal content and harmful behaviors. This is an express list of what you cannot do on Identifi:\n\n` +
      `(a) You can't do anything unlawful, misleading, fraudulent or for an illegal or unauthorized ` +
      `purpose.\n\n` +
      `(b) You can't violate (or help or encourage others to violate) these Terms or our policies.\n\n` +
      `(c) You can’t engage in the trade of pornography through the Service.\n\n` +
      `(d) You can't do anything to interfere with or impair the intended operation of the Service.\n\n` +
      `(e) You can't attempt to create accounts or access or collect information in unauthorized ways. ` +
      `This includes creating accounts or collecting information in an automated way without our ` +
      `express permission.\n\n` +
      `(f) You can't access or attempt to access any content from creators in unauthorized ways, other ` +
      `means of access or without payment of established fees.\n\n` +
      `(g) You can't attempt to buy, sell, or transfer any aspect of your account (including your ` +
      `username) or solicit, collect, or use login credentials or badges of other users.\n\n` +
      `(h) You can't publish private or confidential information that violates someone else's rights, ` +
      `including intellectual property.\n\n` +
      `(i) You can’t violate or infringe on anyone else’s rights, including privacy, copyright, trademark ` +
      `and any other intellectual property rights.\n\n` +
      `(j) You can't use a domain name or URL in your username without our prior written consent.`
  },
  {
    title: '3. Restricted Content on Identifi:',
    body:
      `Obscene content, as defined under United States Federal Law, is not allowed on Identifi. Publishing ` +
      `pornography or any type of sexually obscene content may result in removal of the violating content or user ` +
      `account. We place the highest value on freedom of speech and expression on Identifi, but do not permit or ` +
      `protect works or content, which, taken as a whole, appeal to the prurient interest in sex, portray sexual ` +
      `conduct in a patently offensive way, or do not have serious literary, artistic, political, or scientific value. ` +
      `Identifi applies guidance and definitions provided by the United States Supreme Court on the issue of legally ` +
      `obscene material appealing to prurient interest as “material having a tendency to excite lustful thoughts,” ` +
      `and defined prurient interest as “a shameful or morbid interest in nudity, sex, or excretion.” Identifi ` +
      `evaluates potential obscene content and violations of Terms of Service on the standard of whether a ` +
      `reasonable person would find literary, artistic, political, or scientific value in the material, taken as a whole.\n\n` +
      `However, there are no exceptions or consideration for any sexually explicit content featuring a minor or that ` +
      `sexually exploits minors in any way. This content is strictly forbidden and condemned. Identifi will report any ` +
      `content containing child sexual or exploitative imagery to the [link:National Center for Missing and Exploited Children](https://www.missingkids.org/home), ` +
      `which collaboratives with global law enforcement agencies to bring criminal child pornography ` +
      `criminals to justice. Any offending accounts will be terminated and banned on the Service.\n\n` +
      `If you find content that violates Identifi’s policy against obscene content, then report it to us. You can select ` +
      `“Report” from the menu in the upper-right corner of every video to notify Identifi of any violating content or ` +
      `users. `
  },
  {
    title: '4. Permissions You Give to Us as A “Creator” on the Service.',
    body:
      `As part of our agreement, you as a ` +
      `“Creator” on the Service also give us permissions that we need to provide the Service.\n\n` +
      `(a) We do not claim ownership of your content, but you grant us a license to use and ` +
      `disseminate it. ` +
      `Nothing is changing about your rights in your content. We do not claim ownership of your ` +
      `audiovisual content that you publish or distribute on or through the Service. Instead, when you ` +
      `share, publish, or upload content that is covered by intellectual property rights (like photos or ` +
      `videos) on or in connection with our Service, you hereby grant to us a non-exclusive, royalty-free, ` +
      `transferable, sub-licensable, worldwide license to host, use, distribute, modify, run, copy, publicly ` +
      `perform or display, translate, and create derivative works of your content (consistent with your ` +
      `privacy and application settings). You can end this license anytime by deleting your content or ` +
      `account. However, content will continue to appear if you shared it with others and they have not ` +
      `deleted it.\n\n` +
      `(b) Permission to use your username, profile picture, and information about your relationships ` +
      `and actions with accounts, ads, and sponsored content. ` +
      `You give us permission to show your username, profile picture, and information about your actions ` +
      `(such as likes) or relationships (such as follows) next to or in connection with accounts, ads, offers, ` +
      `and other sponsored content that you follow or engage, without any compensation to you.\n\n` +
      `(c) You agree that we can download and install updates to the Service on your device.`
  },
  {
    title: '5. Account',
    body:
      `You may need to register for an account to use all or part of the Services, such as the Apps. When you ` +
      `register for an account or sign up for our mailing list, you may be required to provide us with some ` +
      `information about yourself, including personal information such as your email address, password, and/or ` +
      `year of birth. You represent and warrant that the information you provide to us is accurate. We may require ` +
      `that you change your password or any other information that you provide to us in registering for an account. ` +
      `Your login is for your personal use only and may not be shared by you for others to access your account. ` +
      `You agree to use a strong, unique password for Services that is not used on other services and ` +
      `acknowledge that reusing passwords may lead to others being able to access your account. You, and not ` +
      `Identifi, are responsible for any use or misuse of your account or password, and you must promptly notify us ` +
      `of any confidentiality breach or unauthorized use of your login information or your Services account. ` +
      `Accounts may only be created on certain devices and may then be used across websites and devices made ` +
      `available in your region.`
  },
  {
    title: '6. Subscriptions',
    body:
      `We offer some Services to you for free while other Services require you to first enable a subscription or payper-view (PPV) purchases that may require payment (collectively, “Subscription”). Subscriptions may have ` +
      `differing conditions and limitations, which will be disclosed at your sign-up or in other communications with ` +
      `you. Subscription offerings may vary by jurisdiction, including in availability, pricing, and enabled features ` +
      `and content. Any recurring or monthly Subscription will continue and automatically renew until terminated or ` +
      `as otherwise stated during sign-up or other communications with you. You can cancel your Subscription ` +
      `before it renews as set out below in the “Cancellation” section. You can find specific details regarding your ` +
      `Subscription in the Settings of the Apps.\n\n` +
      `By signing up for a Subscription , you agree that Identifi or its payment processor may charge the fees ` +
      `shown to you during sign up on a periodic basis to the payment method you specify at the time of your initial ` +
      `purchase. All fees for the Subscription are non-refundable to the fullest extent permitted under applicable ` +
      `law. You acknowledge and agree that any fees for the Subscription may increase at any time, subject to any ` +
      `prior notice that we may provide. Additional fees may apply for new features, content, or additions to the ` +
      `Apps that may be made available from time to time, in which case Identifi will provide you with notice in ` +
      `advance of charging the additional fees. In the event Identifi charges additional fees in connection with the ` +
      `Subscription, you will have an opportunity to review and accept the additional fees that you will be charged, ` +
      `prior to being charged. If you do not accept any such additional fees, Identifi may discontinue your access to ` +
      `the Subscription. You acknowledge and agree that Identifi may use Apple Pay, Google Pay, or other third party payment processors to process fees for the Subscription on our behalf, and that Identifi will not be ` +
      `liable for any errors caused by such third-party payment processors.`
  },
  {
    title: '7. Trials',
    body:
      `We may sometimes offer free or discounted trials to our Subscriptions . If we offer you a trial, the duration, ` +
      `pricing (including whether it’s free), and other details of your trial will be stated either in the material ` +
      `describing the particular trial or during your sign-up for the trial. The terms of such trial offers may change ` +
      `and such offers may expire at any time. Trial offers may vary based on region and the specific user. If you ` +
      `cancel or upgrade your subscription during a trial or while using a promotional code or other credits, your ` +
      `trial may end and the offer may no longer be available.\n\n` +
      `Some trials will auto-enroll users into normal Subscription fees after the trial period expires. For such plans, ` +
      `you will receive a prompt to accept those terms and the payment processor may charge a small amount to ` +
      `verify that your payment method is valid. Under these plans, once your trial period ends, we will ` +
      `automatically begin billing your payment method for your periodic Subscription fees (plus any applicable ` +
      `taxes), unless you cancel prior to the end of your trial. For that reason, unless otherwise indicated in the trial ` +
      `description, you will be asked to set up a valid payment method when redeeming a trial offer. For existing ` +
      `subscribers who accept a trial to the Apps, at the end of the trial, you may be charged a prorated amount to ` +
      `cover the period between the date the trial ends and the date your next billing period begins. If you don’t ` +
      `want to be charged after your trial period under an auto-enroll trial plan, please cancel your trial Subscription ` +
      `no later than the day before the last day of your free trial period.`
  },
  {
    title: '8. Cancellation',
    body:
      `Your Subscription will continue on a recurring basis according to its terms unless and until you cancel your ` +
      `Subscription or are otherwise suspended or discontinued pursuant to these terms. You must cancel your ` +
      `Subscription before your next renewal date in order to avoid billing for each renewal period. We or our ` +
      `payment processor will bill the periodic Subscription fee plus any applicable taxes to the active payment ` +
      `method at the time. If you modify your Subscription to switch from one Subscription plan to another during ` +
      `your billing period, you may not have continued access to your original Subscription plan. If you cancel your ` +
      `Subscription, cancellation will be effective at the end of the current billing period -- this means that you will ` +
      `have continued access to your Subscription for the remainder of that period, but you will not receive a ` +
      `refund. You will also forfeit any service, referral, or redeemed gift card credits upon cancellation, including if ` +
      `you switch your billing method from Identifi’s direct billing to a third-party payment processor like Apple Pay ` +
      `or Google Pay, or vice versa. You can cancel any Subscription by logging into your account online at ` +
      `[link:${WEB_URL_TEXT}](${WEB_URL_LINK}) and following the instructions in the settings of your account. If you pay for your ` +
      `Subscription through your account with a third party and want to cancel your Subscription or manage your ` +
      `billing, you may need to do so through your account with such third party.`
  },
  {
    title: '9. Ownership of Content and Services',
    body:
      `You acknowledge and agree that Identifi owns all rights, titles, and interest in and to the Services, including:\n\n` +
      `(a) all videos, audio, images, graphics, text, interfaces, information, data, software, and all other elements of ` +
      `the Services, and the design, selection, look, feel, and arrangement thereof; and \n\n` +
      `(b) all intellectual property ` +
      `and other legal rights (including, but not limited to, any and all copyrights, patents, patent applications, trade ` +
      `secrets, trademarks and other intangible rights) therein and thereto. You may not publish, reproduce, ` +
      `distribute, display, perform, edit, adapt, modify, or otherwise exploit any part of the Services or any content ` +
      `or information made available through the Services without Identifi’s written consent or the use of Identifi’s ` +
      `sharing features. You will not acquire or earn any ownership rights in any copyrights, patents, trade secrets, ` +
      `trademarks or other intellectual property rights on account of these Terms or any access to or use of the ` +
      `Services. You agree that all content provided to you through the Services is owned or licensed by Identifi, its ` +
      `service providers, and/or other third parties, and that you will have no ownership rights in such content.`
  },
  {
    title: '10. Comments',
    body:
      `Some content on Identifi may allow interactive commenting or messaging. By commenting in a Identifi show ` +
      `or live stream, you agree that Identifi, its affiliates, and their respective officers, directors, employees, ` +
      `contractors, and agents (collectively, the "Administrators") will not be liable for any technical, administrative, ` +
      `or other problems that may cause commenting to be disrupted or corrupted, or for errors in any promotional ` +
      `or marketing materials related to commenting. If for any reason commenting is not executed as planned or ` +
      `the Administrators have concerns about the fairness, integrity, or other aspects of the commenting, the ` +
      `Administrators may, in their sole discretion, take any action they deem appropriate, including \n\n` +
      `(a) terminating ` +
      `or suspending commenting at any point, or\n\n` +
      `(b) disqualifying, blocking or removing any comments for any ` +
      `reason. By commenting, you agree to release, indemnify and hold the Administrators harmless against any ` +
      `and all claims and liabilities of any kind arising directly or indirectly out of your participation in the ` +
      `commenting.`
  },
  {
    title: '11. License Grant and Restrictions',
    body:
      `Subject to your compliance with these Terms and payment of all applicable fees, Identifi hereby grants you ` +
      `a limited, personal, nonexclusive, non-transferable, non-sublicensable, and revocable license to access and ` +
      `use the Services solely for your personal, noncommercial use, as provided herein. The following uses of ` +
      `Services are prohibited:\n\n` +
      `1. transferring or otherwise permitting any other person or entity to access the Services using your ` +
      `account, which you acknowledge and agree is personal to you and is non-transferable;\n\n` +
      `2. publishing, reproducing, distributing, displaying, performing, editing, adapting, modifying, creating ` +
      `derivative works of, reselling, or otherwise exploiting the Services or any content distributed thereon ` +
      `except through the use of Identifi's sharing features;\n\n` +
      `3. circumventing or disabling any content protection system or digital rights management technology ` +
      `used with the Services;\n\n` +
      `4. decompiling, reverse engineering, disassembling, or otherwise reducing the Services or any ` +
      `software or technology provided to you in connection with the Services, to a human-readable form, ` +
      `except to the extent expressly permitted by applicable law notwithstanding this restriction;\n\n` +
      `5. removing identification, copyright, trademark, or other proprietary notices from content or materials ` +
      `provided on the Services;\n\n` +
      `6. accessing or using the Services in an unlawful or unauthorized manner;\n\n` +
      `7. accessing or tampering with non-public areas of the Services, our computer systems, or the ` +
      `systems of our service providers or partners;\n\n` +
      `8. accessing or searching the Services by any means other than the generally available, published ` +
      `interfaces (e.g., APIs) that we provide;\n\n` +
      `9. forging any TCP/IP packet header or any part of the header information in any email or posting, or ` +
      `in any way use the Services to send altered, deceptive, or false information;\n\n` +
      `10. impersonating or otherwise posing as someone you aren’t in communicating with us, creating an ` +
      `account, or otherwise using the Services;\n\n` +
      `11. interfering with, or disrupting, the access of any user, host, or network, including sending a virus, ` +
      `overloading, flooding, spamming, mail-bombing the Services, or by scripting the creation of content ` +
      `or accounts in such a manner as to interfere with or create an undue burden on the Services;\n\n` +
      `12. conducting facial recognition on any content or other visual material available on the Services; and\n\n` +
      `13. attempting to do, or assisting any third party in doing, any of the foregoing.\n\n` +
      `You may access our Services only via our Apps, supported devices for casting or mirroring, or standard ` +
      `browsers such as Chrome, Safari, Firefox, Internet Explorer, and not with scripts, bots, or other automation ` +
      `code that access our Services to extract content or information ("Crawling"). In addition to the rules about ` +
      `Crawling above, you must not conduct or support Crawling of our Services except with prior written notice to ` +
      `us and in full compliance with the provisions of our robots.txt file. We reserve the right to withdraw ` +
      `permission granted for Crawling for any and all reasons, including, without limitation, our assessment that ` +
      `your Crawling places an excessive load or burden on the Services (as determined by Identifi).\n\n` +
      `Our Services (including any software, content, or other copyrighted materials) are licensed, not sold to you. ` +
      `You must not copy, adapt, distribute, publicly display or publicly perform any copyrighted materials we make ` +
      `available as part of our Services, except in full compliance with these Terms, which constitute license ` +
      `conditions and limitations, and contractual covenants. You acknowledge that fees, such as those from your ` +
      `mobile carrier or Internet service provider, may apply to the use and operation of your device in connection ` +
      `with your use of the Services, and that you are solely responsible for any such third-party terms and fees.\n\n` +
      `We will not take legal action against security researchers for violations of relevant restrictions on the use of ` +
      `Service to the extent that they are in compliance with the rules and policies in our responsible vulnerability ` +
      `disclosure program.`
  },
  {
    title: '12. Access to Services',
    body:
      `You acknowledge the following:\n\n` +
      `1. Identifi requires high-speed Internet access and a Identifi-compatible device. The quality of the ` +
      `content may differ based on your device, settings, and network quality. Not all devices are ` +
      `supported for casting and mirroring.\n\n` +
      `2. Certain features, content, Subscription plans, or Services may not be available or may be different ` +
      `based on the time, geographic location, device, account settings, or behavior on the Apps;\n\n` +
      `3. Access to the Services may be interrupted for reasons within or beyond the control of Identifi, and ` +
      `that Identifi cannot and does not guarantee you will be able to access or use the Services whenever ` +
      `you wish to do so;\n\n` +
      `4. Identifi offers its Services in locations, times, and on platforms in its sole discretion, and availability ` +
      `may be unavailable, prohibited, revoked, or discontinued at any time; and\n\n` +
      `5. Features, content, or Services offered may be changed or removed at any time. ` +
      `To the fullest extent permitted under applicable law, we will not be liable for any change to or any ` +
      `discontinuation of the Services. You agree that no refunds, discounts, or other consideration shall be ` +
      `provided to you by Identifi or its partners for being unable to access the Services or part of the Services ` +
      `unless legally required.`
  },
  {
    title: '13. Third Party Services and Links',
    body:
      `The Services may contain links to third-party content and integrations with third parties, such as advertisers ` +
      `and social media sites. Identifi does not control, endorse, sponsor, recommend, or otherwise accept ` +
      `responsibility for any loss or damage that may arise from your use of such third-party content and ` +
      `integrations. These links and integrations are provided only as a convenience, and Identifi does not make ` +
      `any representations or warranties with respect to third-party links and integrations. Use of any linked third party content and integrations is at your own risk and subject to the terms of use for such third-party content.`
  },
  {
    title: '14. Security',
    body: `If you find a security vulnerability in the Services, you must report the issue to us at ${supportEmailFullLink} and keep it confidential until we have fixed the vulnerability. `
  },
  {
    title: '15. Identifi’s Trademarks',
    body:
      `The word “Identifi,” the Identifi logo, the phrase “We Identifi” and other Identifi marks, graphics, and logos ` +
      `are trademarks of Identifi. None of the Identifi trademarks may be used, copied, downloaded, or otherwise ` +
      `exploited without Identifi’s prior written consent in each instance. You may not use our trade names, ` +
      `trademarks, service marks or logos in connection with any product or service that is not ours, or in any ` +
      `manner that is likely to cause confusion. Nothing contained on the Services should be construed as granting ` +
      `any right to use any trade names, trademarks, service marks or logos without the express prior written ` +
      `consent of Identifi or the owner of the marks, as applicable.`
  },
  {
    title: '16. Promotions',
    body:
      `Any sweepstakes, contests, raffles, surveys, games, or similar promotions (collectively, “Promotions”) made ` +
      `available through the Services will be governed by rules that are separate from these Terms. If you ` +
      `participate in any Promotions, please carefully review any promotion-specific rules as well as our Privacy ` +
      `Policy. If and to the extent those rules conflict with these Terms or the Privacy Policy, the Promotion rules ` +
      `will govern.`
  },
  {
    title: '17. Feedback',
    body:
      `Identifi may use any comments, information, ideas, concepts, suggestions, reviews, techniques, or any ` +
      `other material contained in any communication you send to us (“Feedback”), including customer support ` +
      `inquiries or responses to surveys, worldwide and in perpetuity without compensation, acknowledgment or ` +
      `payment to you for any purpose whatsoever, including, but not limited to, developing, manufacturing, and ` +
      `marketing products and creating, modifying, or improving the Identifi Services. In addition, you agree to ` +
      `waive and not to enforce any “moral rights” in and to the Feedback, to the extent permitted by applicable ` +
      `law.`
  },
  {
    title: '18. Communications with You',
    body:
      `As permitted by applicable law, Identifi, Identifi’s service providers, and/or Identifi partners may send you ` +
      `communications that\n\n` +
      `(i) solicit Feedback via email, surveys, bug reports, or other methods Identifi may ` +
      `determine;\n\n` +
      `(ii) collect additional information regarding issues you report in your Feedback;\n\n` +
      `(iii) notify you of ` +
      `changes to the Services or these Terms; and\n\n` +
      `(iv) tell you about future Identifi programs, products or ` +
      `services.`
  },
  {
    title: '19. Unsolicited Materials',
    body:
      `Identifi does not accept unsolicited materials or ideas for content. Should you send any unsolicited materials ` +
      `or ideas, you do so with the understanding that (a) there is no implied agreement of any sort between you ` +
      `and Identifi regarding such materials or ideas, (b) no consideration of any sort will be provided to you, (c) ` +
      `neither Identifi nor any of its affiliates is responsible for the alleged or actual similarity of any content or ` +
      `programming in any media to such unsolicited materials, and (d) you are hereby waiving and releasing any ` +
      `and all moral rights or other claims against Identifi and its affiliates related to such materials and ideas, even ` +
      `if any content or programming developed, produced or exploited by Identifi or its affiliates in any manner is ` +
      `substantially similar to the material or idea you submitted to us. Identifi’s standard policy is to return ` +
      `unsolicited materials submitted in writing (and delete any materials submitted electronically) without ` +
      `reviewing them or keeping a copy.`
  },
  {
    title: '20. Customer Support',
    body:
      `Identifi cares about your issues, thoughts, and concerns. To help us find the best person to address your ` +
      `issue, please email the appropriate contact below:\n\n` +
      `• Report a bug, security vulnerability, privacy issue or feedback: ${supportEmailFullLink}\n\n` +
      `• Media, advertising or sponsorship inquiries or issues: ${mediaEmailFullLink}\n\n` +
      `• Submissions for content: n/a (we do not accept unsolicited submissions, see above)`
  },
  {
    title: '21. Complaints',
    body:
      `If you have a question or complaint regarding our Services, please send an e-mail to ` +
      `${supportEmailFullLink}. Please note that e-mail communications will not necessarily be secure; ` +
      `accordingly you should not include credit card information or other sensitive information in your e-mail ` +
      `correspondence with us.\n\nCalifornia residents may reach the Complaint Assistance Unit of the Division of ` +
      `Consumer Services of the California Department of Consumer Affairs by mail at\n1625 North Market Blvd.,\n` +
      `Sacramento,\nCA 95834,\n\nor by telephone at\n(916) 445-1254 or\n(800) 952-5210.`
  },
  {
    title: '22. Disputes',
    body:
      `If you have an issue or concern with the Services, we’d like to try to resolve it informally first. Before filing a ` +
      `legal complaint against Identifi, you agree to try to resolve the dispute informally by contacting ` +
      `${supportEmailFullLink}. We’ll try to resolve the dispute informally by responding to you by email. If the ` +
      `dispute is not resolved within 15 business days of submission, you or Identifi may bring a formal proceeding.\n\n` +
      `Venue. You and Identifi agree that any judicial proceeding to resolve claims relating to these terms or the ` +
      `Service will be brought in the federal or state courts of Los Angeles County, California, subject to the ` +
      `mandatory arbitration provisions below. Both you and Identifi consent to venue and personal jurisdiction in ` +
      `such courts for any matters not resolved by arbitration as provided below. If you reside in a country (e.g., in ` +
      `the EU or Canada) with laws that give consumers the right to bring disputes in their local courts, this ` +
      `paragraph doesn’t affect those rights.\n\n` +
      `Arbitration. Unless you are a consumer located in a jurisdiction that prohibits the exclusive use of arbitration ` +
      `for dispute resolution or class action waivers, you also agree to the following mandatory arbitration ` +
      `provisions and class action waivers to the extent they are not prohibited under local, applicable law:\n\n` +
      `a. We both agree to arbitrate. You and Identifi each agree to resolve any claims relating to these ` +
      `Terms or the Services (“Disputes”) through final and binding arbitration by a single arbitrator, except ` +
      `as set forth under Exceptions to Agreement to Arbitrate below. This includes disputes arising out of ` +
      `or relating to interpretation or application of this “Mandatory Arbitration Provisions” section, including ` +
      `its enforceability, revocability, or validity. YOU ARE GIVING UP THE RIGHT TO LITIGATE (OR ` +
      `PARTICIPATE IN AS A PARTY OR CLASS MEMBER) ALL DISPUTES IN COURT BEFORE A ` +
      `JUDGE OR JURY. INSTEAD, ALL DISPUTES WILL BE RESOLVED BEFORE A NEUTRAL ` +
      `ARBITRATOR, WHOSE DECISION WILL BE FINAL EXCEPT FOR A LIMITED RIGHT OF ` +
      `APPEAL UNDER THE FEDERAL ARBITRATION ACT OR SIMILAR LEGISLATION IN THE ` +
      `JURISDICTION WHERE THE ARBITRATION IS SEATED.\n\n` +
      `b. 30-day right to opt-out of agreement to arbitrate. You can decline this agreement to arbitrate by ` +
      `emailing us at ${supportEmailFullLink} from the email address associated with your account (if you ` +
      `have one), including your name and a statement of your intention to opt out in the body of the email. ` +
      `Your opt-out notice must be sent to us no later than thirty (30) days after your first becoming subject ` +
      `to this arbitration agreement; otherwise, you will be bound to arbitrate all Disputes in accordance ` +
      `with this Section 18. If you opt out of this agreement to resolve all Disputes by arbitration, Identifi ` +
      `also will not be bound by this agreement to arbitrate Disputes. Your election to opt out of this ` +
      `arbitration provision will apply only to Disputes that arise after the date you opt out. Any Disputes ` +
      `arising on or before the date you opt out of arbitration will continue to be governed by this arbitration ` +
      `agreement. If you have previously opted out of this arbitration agreement under a prior version of ` +
      `these Terms, you do not need to opt out again.\n\n` +
      `c. Arbitration procedures. Any dispute, claim or controversy arising out of or relating to these Terms or ` +
      `the breach, termination, enforcement, interpretation or validity thereof, including the determination ` +
      `of the scope or applicability of this agreement to arbitrate, shall be determined exclusively by ` +
      `arbitration in Los Angeles, California in the United States of America before an arbitrator. The ` +
      `arbitration shall be administered by JAMS pursuant to its JAMS’ Streamlined Arbitration Rules and ` +
      `Procedures. The language to be used in the arbitral proceedings will be English. Judgment on the ` +
      `Award may be entered in any court having jurisdiction. This clause shall not preclude parties from ` +
      `seeking provisional remedies in aid of arbitration from a court of appropriate jurisdiction.\n\n` +
      `d. Exceptions to the agreement to arbitrate. Either you or Identifi may assert Disputes, if they qualify, ` +
      `in small claims court in Los Angeles, CA, or any United States county where you live or work. ` +
      `Identifi may bring a lawsuit solely for injunctive relief to stop unauthorized use or abuse of the ` +
      `Services, or intellectual property infringement (for example, trademark, trade secret, copyright, or ` +
      `patent rights) without first engaging in arbitration or the informal dispute-resolution process ` +
      `described above. If the agreement to arbitrate is found not to apply to you or your claim, you agree ` +
      `to the exclusive jurisdiction of the state and federal courts in Los Angeles County, California to ` +
      `resolve your claim to the fullest extent permitted by applicable law.\n\n` +
      `e. NO CLASS ACTIONS. TO THE FULLEST EXTENT OF THE LAW, YOU AND IDENTIFI AGREE ` +
      `THAT ANY PROCEEDINGS TO RESOLVE OR LITIGATE ANY DISPUTE IN ANY FORUM WILL ` +
      `BE CONDUCTED SOLELY ON AN INDIVIDUAL BASIS, AND NEITHER YOU NOR IDENTIFI ` +
      `WILL SEEK TO HAVE ANY DISPUTE HEARD AS A CLASS ACTION OR IN ANY OTHER ` +
      `PROCEEDING IN WHICH EITHER PARTY ACTS OR PROPOSES TO ACT IN A ` +
      `REPRESENTATIVE CAPACITY, EXCEPT THE ARBITRATOR MAY AWARD PUBLIC ` +
      `INJUNCTIVE RELIEF IN THE ARBITRATION IF APPLICABLE. No arbitration or proceeding will be ` +
      `combined with another without the prior written consent of all parties to all affected arbitrations or ` +
      `proceedings.\n\n` +
      `f. Filing period. TO THE FULLEST EXTENT PERMITTED BY LAW, ANY DISPUTE UNDER THESE ` +
      `TERMS MUST BE FILED WITHIN ONE (1) YEAR, EITHER IN AN ARBITRATION PROCEEDING. ` +
      `The one-year period begins when the events giving rise to the Dispute first occur. If a claim is not ` +
      `submitted within one year, it is permanently barred. This period can only be extended by the written ` +
      `consent of both parties. No statutes or provisions of law that would toll or otherwise affect the time ` +
      `in which a party may bring a claim shall operate to extend the period limited in this Section, and any ` +
      `such statutes and provisions are hereby waived, to the fullest extent permitted by law.\n\n` +
      `g. Modifications. You have the right to reject any changes to this arbitration provision, except for a ` +
      `change to Identifi’s contact information. You may reject a change by sending us written notice within ` +
      `30 days. This will result in your account on the Services being immediately terminated. Note that ` +
      `this arbitration provision, as it was prior to the rejected changes, will remain in effect.`
  },
  {
    title: '23. Disclaimer of Warranties',
    body:
      `YOU EXPRESSLY AGREE THAT YOUR USE OF THE SERVICES IS AT YOUR OWN RISK. THE ` +
      `SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, TO THE FULLEST EXTENT ` +
      `PERMITTED BY LAW, AND ARE PROVIDED WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, ` +
      `WHETHER EXPRESS, IMPLIED, OR STATUTORY. WITHOUT LIMITING THE FOREGOING, IDENTIFI, ` +
      `THEIR RESPECTIVE AFFILIATES, SUCCESSORS AND ASSIGNS, OR ANY OF THEIR RESPECTIVE ` +
      `INVESTORS, DIRECTORS, OFFICERS, EMPLOYEES, PROVIDERS (INCLUDING THOSE WHO HELP  ` +
      `ADMINISTER AND OPERATE THOSE SERVICES), AGENTS, AND SUPPLIERS (COLLECTIVELY, THE ` +
      `“IDENTIFI PARTIES”) DO NOT MAKE ANY WARRANTIES OF FITNESS FOR A PARTICULAR ` +
      `PURPOSE, TITLE, MERCHANTABILITY, COMPLETENESS, AVAILABILITY, COMPATIBILITY, OR NONINFRINGEMENT; OR THAT THE SERVICES WILL BE UNINTERRUPTED, FREE OF VIRUSES AND ` +
      `OTHER HARMFUL COMPONENTS, ACCURATE, ERROR FREE, OR RELIABLE; OR AS TO THE ` +
      `ACCURACY, CURRENCY, OR COMPLETENESS OF ANY INFORMATION MADE AVAILABLE ` +
      `THROUGH THE SERVICES, WHETHER PROVIDED IN VIDEO, AUDIO, TEXTUAL, GRAPHICAL, OR ` +
      `OTHER FORM (THE “SERVICE CONTENT”). IDENTIFI PARTIES NO NOT MAKE ANY ` +
      `REPRESENTATIONS OR WARRANTIES WITH RESPECT TO ANY PROVIDERS. IDENTIFI PARTIES ` +
      `ARE NOT RESPONSIBLE OR LIABLE FOR ANY DECISIONS YOU MAY MAKE IN RELIANCE ON THE ` +
      `SERVICES OR SERVICE CONTENT, OR FOR ANY DEFECTS OR ERRORS IN THE SERVICE, FOR ` +
      `YOUR USE OF THE APPS WITH HARDWARE THAT DOES NOT MEET THE MINIMUM ` +
      `REQUIREMENTS SPECIFIED BY IDENTIFI, OR FOR YOUR USE OF ANY VERSION OF THE APPS ` +
      `OTHER THAN THE MOST RECENT GENERALLY AVAILABLE RELEASE OF SUCH APPS. YOU ` +
      `EXPRESSLY AGREE THAT YOU ARE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR ` +
      `PROPERTY, TO THE FULLEST EXTENT PERMITTED BY LAW, INCLUDING YOUR COMPUTER ` +
      `SYSTEM OR MOBILE DEVICE, OR THE LOSS OF DATA, THAT RESULTS FROM USE OF THE ` +
      `SERVICES.`
  },
  {
    title: '24. Limitations of Liability',
    body:
      `This paragraph is inapplicable in Quebec: TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO ` +
      `EVENT SHALL IDENTIFI BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, ` +
      `CONSEQUENTIAL, OR OTHER DAMAGES, INCLUDING LOSS OF PROFITS, LOSS OF SAVINGS OR ` +
      `REVENUE, LOSS OF USE, LOSS OF LIFE OR HEALTH, THE CLAIMS OF THIRD PARTIES, AND ANY ` +
      `COST OF ANY SUBSTITUTE SERVICES, ARISING OUT OF OR IN ANY WAY RELATED TO THE ` +
      `SERVICES, WHETHER BASED IN CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, ` +
      `OR ANY OTHER THEORY, EVEN IF THE IDENTIFI PARTIES HAVE BEEN ADVISED OF THE ` +
      `POSSIBILITY OF DAMAGES. TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT SHALL ` +
      `THE IDENTIFI PARTIES’ TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES AND CAUSES OF ` +
      `ACTION, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE EXCEED ` +
      `THE GREATER OF THE ACTUAL AMOUNT YOU PAID FOR THE SERVICES (IF ANY) AND USD $100. ` +
      `THE PARTIES AGREE THAT THE FOREGOING LIMITATIONS ARE ESSENTIAL TO THE BASIS OF ` +
      `THE BARGAIN CONTEMPLATED BY THESE TERMS.\n\n` +
      `THE FOREGOING LIMITATIONS SHALL APPLY ONLY TO THE EXTENT PERMISSIBLE UNDER ` +
      `APPLICABLE LAW (SOME JURISDICTIONS DO NOT PERMIT DISCLAIMERS OF IMPLIED ` +
      `WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES, SO THESE TERMS ` +
      `MAY NOT APPLY TO YOU).`
  },
  {
    title: '25. Indemnity',
    body:
      `To the fullest extent permitted under applicable law, you agree to defend, indemnify, and hold harmless the ` +
      `Identifi Parties (as defined above) and their respective successors and assigns, from and against all claims, ` +
      `liabilities, damages, judgments, awards, losses, costs, expenses and fees (including attorneys’ fees) arising ` +
      `out of or relating to\n\n(a) your use of, or activities in connection with the Services (including all Feedback);\n\n` +
      `(b) any violation or alleged violation of these Terms by you, and\n\n` +
      `(c) your fraud, intentional misconduct, ` +
      `negligence, or other tortious or criminal acts or omissions. Identifi reserves the right to employ separate ` +
      `counsel and assume the exclusive defense and control of any matter otherwise subject to indemnification by ` +
      `you. In such event, you shall provide Identifi with such information and assistance as Identifi reasonably ` +
      `requests.`
  },
  {
    title: '26. Termination',
    body:
      `Identifi may terminate or suspend your use of the Services at any time and without prior notice, for any or no ` +
      `reason, including if Identifi believes that you have violated or acted inconsistently with the letter or spirit of ` +
      `these Terms. Upon any such termination or suspension, your right to use the Service will immediately ` +
      `cease, and Identifi may, without liability to you or any third party to the fullest extent permitted by applicable ` +
      `law, immediately deactivate or delete your account, and all associated data and materials, without any ` +
      `obligation to provide any further access to such data or materials. Identifi reserves the right to pursue all ` +
      `rights and remedies available at law or equity. These terms shall survive the termination of your account, ` +
      `deletion of data, and opt out by the user.\n\n` +
      `We reserve the right to report any activity that we believe to be illegal or otherwise in contravention of this ` +
      `User Agreement and we will respond to any verified requests relating to a criminal investigation (i.e., a ` +
      `subpoena, court order or substantially similar legal procedure) from local and foreign law enforcement or ` +
      `regulatory agencies, other government officials or authorized third-parties`
  },
  {
    title: '27. Governing Law',
    body: `These Terms are governed by the laws of the United States (including federal arbitration law) and the State of Delaware, U.S.A., without regard to its principles of conflicts of law, and regardless of your location.`
  },
  {
    title: '28. Miscellaneous',
    body:
      `1. Identifi’s Relationship with You. These Terms do not, and shall not be construed to, create any ` +
      `partnership, joint venture, employer-employee, agency or franchisor-franchisee relationship ` +
      `between you and Identifi.\n\n` +
      `2. No waiver. Identifi’s failure to enforce any provisions of these Terms or respond to a violation of ` +
      `these by any person does not waive Identifi’s right to subsequently enforce any terms or conditions ` +
      `of the Terms or respond to any such violations.\n\n` +
      `3. Severability. If any provision of these terms is found invalid by a court of competent jurisdiction, you ` +
      `agree that such provision shall be deemed severable from these Terms, that the court should try to ` +
      `give effect to the parties’ intentions as reflected in the provision, and that all other provisions of the ` +
      `Terms will remain in full effect.\n\n` +
      `4. Assignment, Transfer, and Sublicensing. You may not assign, transfer or sublicense any or all of ` +
      `your rights or obligations under these Terms without our express prior written consent. We may ` +
      `assign, transfer or sublicense any or all of our rights or obligations under these Terms without ` +
      `restriction.\n\n` +
      `5. Headings. Any heading, caption or section title contained herein is for convenience only, and in no ` +
      `way defines or explains or affects the interpretation of any section or provision or the rights of any ` +
      `party.\n\n` +
      `6. Interpretation. All terms defined in the singular shall have the same meanings when used in the ` +
      `plural, where appropriate and unless otherwise specified. Any use of the term “including” or ` +
      `variations thereof in these Terms shall be construed as if followed by the phrase “without limitation.”\n\n` +
      `7. Force Majeure. Identifi will not be responsible for any failure to fulfill any obligation due to any cause ` +
      `beyond its reasonable control.\n\n` +
      `8. Entire agreement. These Terms (including any document incorporated by reference into them) are ` +
      `the whole agreement between Identifi and you concerning the Services.\n\n` +
      `9. Notices. Notices to you (including notices of changes to these Terms) may be made via posting to ` +
      `the Services or by e-mail (including in each case via links), or by regular mail.\n\n` +
      `10. Admissibility. Without limitation, a printed version of these Terms and of any notice given in ` +
      `electronic form shall be admissible in judicial or administrative proceedings based upon or relating ` +
      `to these Terms to the same extent and subject to the same conditions as other business documents ` +
      `and records originally generated and maintained in printed form.\n\n` +
      `11. Export Control. You hereby represent and warrant that\n\n` +
      `(i) you are not located in a country that is ` +
      `subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a ` +
      `“terrorist supporting” country; and\n\n(ii) you are not listed on any U.S. Government list of prohibited or ` +
      `restricted parties.\n\n` +
      `12. Language. The parties have requested and agreed that this contract and all related documents ` +
      `shall be drafted in English only.`
  },
  {
    title: '29. Device Specific Additional Terms',
    body:
      `iOS - Apple\n\n` +
      `These Mobile Device Terms are an agreement between you and us, and not with Apple. Apple is not ` +
      `responsible for the App and the content thereof.\n\n` +
      `We grant you the right to use the App only on an iOS product that you own or control and as permitted by ` +
      `the App Store Terms of Service.\n\n` +
      `Apple has no obligation whatsoever to furnish any maintenance and support services with respect to the ` +
      `App.\n\n` +
      `Apple is not responsible for the investigation, defense, settlement, and discharge of any third party ` +
      `intellectual property infringement claim.\n\n` +
      `Apple is not responsible for addressing any claims by you or any third party relating to the App or your ` +
      `possession and/or use of the App, including but not limited to:\n\n(a) product liability claims;\n\n(b) any claim that ` +
      `the App fails to conform to any applicable legal or regulatory requirement; and\n\n(c) claims arising under ` +
      `consumer protection or similar legislation.\n\n` +
      `In the event of any failure of the App to conform to any applicable warranty, you may notify Apple, and Apple ` +
      `will refund the purchase price, if applicable, for the App to you; and to the maximum extent permitted by ` +
      `applicable law, Apple will have no other warranty obligation whatsoever with respect to the App.\n\n` +
      `Apple and Apple's subsidiaries are third party beneficiaries of these Mobile Device Terms, and, upon your ` +
      `acceptance, Apple as a third party beneficiary thereof will have the right (and will be deemed to have ` +
      `accepted the right) to enforce these Mobile Device Terms against you.\n\n` +
      `Android - Google\n\n` +
      `These Mobile Device Terms are an agreement between you and us, and not with Google. Google is not ` +
      `responsible for the App and the content thereof. We grant you the right to use the App only on an Android ` +
      `product that you own or control and as permitted by the Google Play Terms of Service.\n\n` +
      `Google has no obligation whatsoever to furnish any maintenance and support services with respect to the ` +
      `App.\n\n` +
      `Google is not responsible for the investigation, defense, settlement, and discharge of any third party ` +
      `intellectual property infringement claim.\n\n` +
      `Google is not responsible for addressing any claims by you or any third party relating to the App or your ` +
      `possession and/or use of the App, including but not limited to:\n\n(a) product liability claims;\n\n(b) any claim that ` +
      `the Application fails to conform to any applicable legal or regulatory requirement; and\n\n(c) claims arising ` +
      `under consumer protection or similar legislation.\n\n` +
      `In the event of any failure of the App to conform to any applicable warranty, you may notify Google, and ` +
      `Google will refund the purchase price, if applicable, for the App to you; and to the maximum extent permitted ` +
      `by applicable law, Google will have no other warranty obligation whatsoever with respect to the App.\n\n` +
      `Google and Google's subsidiaries are third party beneficiaries of these Mobile Device Terms, and, upon ` +
      `your acceptance, Google as a third party beneficiary thereof will have the right (and will be deemed to have ` +
      `accepted the right) to enforce these Mobile Device Terms against you.\n\n` +
      `© 2024 Identifi Holdings, Inc. unless otherwise noted. All rights reserved. Identifi and its Logos are ` +
      `trademarks of Identifi Holdings, Inc. All other product names, company names, marks, logos, and symbols ` +
      `are trademarks of their respective owners.\n\n` +
      `Identifi`
  }
];

// export const LEGEND =
//   ' \n The weidentifi.com and related digital properties are operated and published by identifi, Inc.';

// export const ADS_SETTINGS_LINK = 'https://www.google.com/settings/ads';
// export const ADS_SETTINGS_TEXT = 'Ad Settings';

// export const ABOUT_ADS_LINK = 'https://youradchoices.com';
// export const ABOUT_ADS_TEXT = 'aboutads.info';
