import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import Tabs from 'components/common/Tabs/Tabs';
import cn from 'classnames';
import { adManagementTabs } from 'constants/constants';
import useAdManager from 'hooks/adManager/useAdManager';
import UploadIcon from 'assets/uploadAd.png';
import { useNavigation } from 'hooks';
import { routeWithProps } from 'utils/helpers';
import { routesPaths } from 'constants/routesPaths';
import { getIdFromTab } from 'utils/dataHelpers';

const AdManagerList = () => {
  const { tab } = useParams();
  const selectedTabId = useMemo(() => tab && getIdFromTab(adManagementTabs, tab), [tab]);
  const [selectedTab, setSelectedTab] = useState(selectedTabId || adManagementTabs.VIDEO_ADS.id);
  const { renderAdManagementContent, changeUrl } = useAdManager();
  const { goTo } = useNavigation();
  const onTabChange = id => {
    changeUrl(id);
    setSelectedTab(id);
  };

  useEffect(() => {
    setSelectedTab(selectedTabId);
  }, [tab, selectedTabId]);

  const handleNavigate = () => {
    if (selectedTab === 2) {
      goTo(routeWithProps(routesPaths.addBannerAd, {}));
    }
    if (selectedTab === 1) {
      goTo(routeWithProps(routesPaths.addvideoAds, {}));
    }
    if (selectedTab === 3) {
      goTo(routeWithProps(routesPaths.addWidgetAd, {}));
    }
  };
  return (
    <div>
      <div>
        <Tabs
          tabs={Object.values(adManagementTabs)}
          selectedTab={selectedTab}
          onTabSelect={onTabChange}
          className="ad-Manager-tabs"
        />
      </div>
      <div className={cn('button-container')}>
        <button type="button" className={cn('upload-button')} onClick={handleNavigate}>
          <img
            height="20x"
            width="20px"
            className={cn('upload-icon')}
            src={UploadIcon}
            alt="upload"
          />{' '}
          UPLOAD NEW AD
        </button>
      </div>
      <div>{renderAdManagementContent(selectedTab)}</div>
    </div>
  );
};

export default AdManagerList;
