import { createThunk } from '@rootstrap/redux-tools';
import adService from 'services/adService';
import parseError from 'utils/parseError';

export const addWidgetAd = createThunk('ADD_WIDGET_AD', async (celebrityId, widget) => {
  try {
    const { data } = await adService.addWidgetAd(celebrityId, widget);
    return data;
  } catch ({ response }) {
    throw parseError(response);
  }
});

export const updateWidgetAd = createThunk('UPDATE_WIDGET_AD', async (celebrityId, widget, id) => {
  try {
    const { data } = await adService.updateWidgetAd(celebrityId, widget, id);
    return data;
  } catch ({ response }) {
    throw parseError(response);
  }
});

export const resetUploadingWidget = createThunk('RESET_UPLOADING_WIDGET');
