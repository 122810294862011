import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLoading, useStatus } from '@rootstrap/redux-tools';
import { getProfile } from 'state/actions/userActions';

export default () => {
  const dispatch = useDispatch();

  const requestProfile = useCallback(async () => dispatch(getProfile()), [dispatch]);

  const reset = () => dispatch(getProfile.reset());

  const profile = useSelector(({ session }) => session.profile || {});

  const checkPurchasedPPV = useCallback(
    eventId => {
      const { ppvs } = profile;
      return ppvs?.some(
        ({ scheduledLiveEventPpv }) => scheduledLiveEventPpv?.scheduledLiveEventId == eventId
      );
    },
    [profile]
  );

  return {
    requestProfile,
    loading: useLoading(getProfile),
    profile,
    status: useStatus(getProfile).status,
    reset,
    checkPurchasedPPV
  };
};
