import React from 'react';
import { useHistory } from 'react-router-dom';

import { adManagementTabs } from 'constants/constants';

import BannerAdsList from 'components/AdManager/BannerAdsList';
import WidgetAdList from 'components/AdManager/WidgetAdList';
import VideoAdsList from 'components/AdManager/VideoAdsList';
import { routeWithProps } from 'utils/helpers';
import { routesPaths } from 'constants/routesPaths';
import { getTabNameFromId } from 'utils/dataHelpers';

export default () => {
  const { replace } = useHistory();
  const renderAdManagementContent = (tab, isModal = false) => {
    const { VIDEO_ADS, BANNER_ADS, WIDGET_ADS } = adManagementTabs;
    switch (tab) {
      case VIDEO_ADS.id:
        return <VideoAdsList isModal={isModal} />;
      case BANNER_ADS.id:
        return <BannerAdsList isModal={isModal} />;
      case WIDGET_ADS.id:
        return <WidgetAdList isModal={isModal} />;
      default:
    }
  };

  const changeUrl = id => {
    const tab = id ? getTabNameFromId(adManagementTabs, id) : '';
    replace(routeWithProps(routesPaths.adManager, { tab }));
  };

  return {
    renderAdManagementContent,
    changeUrl
  };
};
