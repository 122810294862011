import React, { useState } from 'react';
import cn from 'classnames';
import Tabs from 'components/common/Tabs/Tabs';
import { settingTabs, settingTabsForUser } from 'constants/constants';
import useProfile from 'hooks/users/useProfile';
import { USER_TYPES } from 'hooks/useSession';
import { useNavigation } from 'hooks';
import { useLocation } from 'react-router-dom';
import ModalHeader from 'components/common/ModalHeader/ModalHeader';
import ManageAccount from './ManageAccount';
import ChangeProfile from './ChangeProfile';
import ChangeMembershipAmount from './ChangeMembershipAmount';

const Settings = ({ isModal, hide }) => {
  const { profile } = useProfile();
  const { CHANGE_PROFILE_IMAGE, MANAGE_ACCOUNT, CHANGE_MEMBERSHIP_AMOUNT } = settingTabs;
  const { MANAGE_ACCOUNT: USER_ACCOUNT_MANAGE } = settingTabsForUser;

  const [selectedTab, setSelectedTab] = useState(MANAGE_ACCOUNT.id);

  const onTabSelect = id => {
    setSelectedTab(id);
  };

  const renderContent = tab => {
    switch (tab) {
      case MANAGE_ACCOUNT.id:
        return <ManageAccount />;
      case USER_ACCOUNT_MANAGE.id:
        return <ManageAccount />;
      case CHANGE_PROFILE_IMAGE.id:
        return <ChangeProfile />;
      case CHANGE_MEMBERSHIP_AMOUNT.id:
        return <ChangeMembershipAmount />;
      default:
    }
  };
  const { goBack } = useNavigation();
  const { state = {} } = useLocation();
  const { backOnClose = false } = state;

  return (
    <div className={cn('settings', { 'settings-methods-modal': isModal })}>
      {isModal ? (
        <ModalHeader titleId="Settings" hideModal={backOnClose ? goBack : hide} />
      ) : (
        <h1 className="title">Settings</h1>
      )}

      <div className="setting-content">
        {profile.type === USER_TYPES.user && (
          <Tabs
            tabs={Object.values(settingTabsForUser)}
            selectedTab={selectedTab}
            onTabSelect={onTabSelect}
            className="setting-tabs"
          />
        )}
        {profile.type === USER_TYPES.celebrity && (
          <Tabs
            tabs={Object.values(settingTabs)}
            selectedTab={selectedTab}
            onTabSelect={onTabSelect}
            className="setting-tabs"
          />
        )}
        {renderContent(selectedTab)}
      </div>
    </div>
  );
};

export default Settings;
