import React from 'react';
import { bool, string } from 'prop-types';
import cn from 'classnames';

const PurchaseItem = ({ title, description, price, withBorder = false }) => (
  <div className={cn('purchase-item-container', withBorder && 'purchase-item-container-border')}>
    <div className="purchase-item-container-left">
      <span className="purchase-item-title">{title}</span>
      <h2>{price}</h2>
    </div>
    <span className="purchase-item-description">{description}</span>
  </div>
);

PurchaseItem.propTypes = {
  title: string.isRequired,
  description: string.isRequired,
  price: string.isRequired,
  withBorder: bool
};

export default PurchaseItem;
