import React from 'react';
import { object } from 'prop-types';

import BackgroundOpacity from 'components/common/BackgroundOpacity/BackgroundOpacity';
import Crown from 'components/icons/membership/Crown';
import { convertDateFormatInStandard, convertUtcToLocalTime, getFullName } from 'utils/helpers';

const EventSubscriptionCard = ({ event, celebrity }) => {
  const { title, eventTime, eventDate } = event;
  const { profileImageUrl, firstName, lastName, bannerUrl } = celebrity;

  return (
    <div className="membership-card">
      <div className="membership-card-banner">
        <BackgroundOpacity background={bannerUrl} />
        <div className="gradient" />
      </div>
      <div className="membership-card-content">
        <div className="flex content-row">
          <img className="membership-card-avatar" src={profileImageUrl} alt="avatar" />
          <div className="category-semibold">{getFullName(firstName, lastName)}</div>
        </div>
        <div className="flex-end content-row">
          <div className="flex-1">
            <h4 className="semi-bold membership-title">{title}</h4>
            <div className="p1">
              {convertDateFormatInStandard(eventDate)}, {convertUtcToLocalTime(eventTime)}
            </div>
            {/* {status === ACTIVE && (
              <div className="p1">
                {`${intl.formatMessage(
                  { id: 'membershipCard.periodLabel' },
                  { period }
                )} ${getDayAndMonth(currentPeriodEndsAt)} `}
                {intl.formatMessage(
                  { id: 'membershipCard.timeToNextPayment' },
                  {
                    expiry: `${getDiffDay(currentPeriodEndsAt)} ${intl.formatMessage(
                      { id: 'date.days' },
                      { amount: getDiffDay(currentPeriodEndsAt) }
                    )}`
                  }
                )}
              </div>
            )} */}
            {/* {status === ACTIVE_CANCELED && (
              <div className="p1">
                <span className="red">
                  {`${intl.formatMessage({ id: 'membershipCard.canceled' })}. `}
                </span>
                {`${intl.formatMessage({ id: 'membershipCard.activeUntil' })} ${getDayAndMonth(
                  currentPeriodEndsAt
                )} `}
              </div>
            )} */}
          </div>
          <div className="subscription-label">
            <Crown />
          </div>
        </div>
      </div>
    </div>
  );
};

EventSubscriptionCard.propTypes = {
  event: object
};

export default EventSubscriptionCard;
