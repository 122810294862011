import React from 'react';
import { string, oneOf, bool } from 'prop-types';
import cn from 'classnames';

const CelebrityAvatar = ({ backgroundImage, title, size, isLive }) => (
  <div className={cn('celebrity-avatar', { live: isLive })}>
    <img src={backgroundImage} alt="Creator Avatar" className={`img-${size}`} />
    {!!title && <div className="p3 title">{title}</div>}
  </div>
);

CelebrityAvatar.propTypes = {
  backgroundImage: string.isRequired,
  title: string,
  size: oneOf(['medium', 'small']),
  isLive: bool
};

CelebrityAvatar.defaultProps = {
  size: 'medium'
};

export default CelebrityAvatar;
