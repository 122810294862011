import { createThunk } from '@rootstrap/redux-tools';
import adService from 'services/adService';
import parseError from 'utils/parseError';

export const addBannerAd = createThunk('ADD_BANNER_AD', async (celebrityId, banner) => {
  try {
    const { data } = await adService.addBannerAd(celebrityId, banner);
    return data;
  } catch ({ response }) {
    throw parseError(response);
  }
});

export const updateBannerAd = createThunk('UPDATE_BANNER_AD', async (celebrityId, banner, id) => {
  try {
    const { data } = await adService.updateBannerAd(celebrityId, banner, id);
    return data;
  } catch ({ response }) {
    throw parseError(response);
  }
});
export const resetUploadingBanner = createThunk('RESET_UPLOADING_BANNER');
