import { createReducer } from '@rootstrap/redux-tools';
import {
  createNarrative,
  processNarrative,
  resetUploadingNarrative
} from 'state/actions/narrativeActions';

const initialState = {
  uploadingNarrative: {},
  processingNarratives: {}
};

export default createReducer(initialState, {
  [createNarrative.success]: (state, { payload }) => {
    state.uploadingNarrative = payload;
  },
  [processNarrative.success]: (state, { payload }) => {
    state.processingNarratives[state.uploadingNarrative.id] = payload;
    state.uploadingNarrative = {};
  },
  [resetUploadingNarrative.request]: state => {
    state.uploadingNarrative = {};
  }
});
