import axios from 'axios';
import { createThunk } from '@rootstrap/redux-tools';

import { JWPLAYER_LIVE_CHANNELS_URL, JWPLAYER_MEDIA_URL } from 'constants/constants';
import liveEventsService from 'services/liveEventsService';
import parseError from 'utils/parseError';

export const getScheduledLiveEvent = createThunk('GET_SCHEDULE_LIVE', async (id, isLive) => {
  try {
    const { data } = await liveEventsService.getScheduledLiveEvent(id);

    if (isLive) {
      const { data: liveEventId } = await axios.get(
        `${JWPLAYER_LIVE_CHANNELS_URL}${data.liveStream.jwplayerStreamId}.json`
      );
      const { data: event } = await axios.get(`${JWPLAYER_MEDIA_URL}${liveEventId.current_event}`);
      return {
        ...data.liveStream,
        videoUrl: event?.playlist[0]?.sources[0]?.file
      };
    }
    return data.liveStream;
  } catch ({ response }) {
    throw parseError(response);
  }
});

export const purchaseScheduledLiveEvent = createThunk(
  'PURCHASE_SCHEDULE_LIVE',
  async (cardId, scheduledLiveId) => {
    try {
      const { data } = await liveEventsService.purchaseScheduledLiveEvent(cardId, scheduledLiveId);
      return data.liveStream;
    } catch ({ response }) {
      throw parseError(response);
    }
  }
);
