import React from 'react';
import { number } from 'prop-types';

const Video = ({ width = 24, height = 24 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2 14V5H17V10" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
    <path d="M17 14L23 16V7L17 9V18H1" stroke="white" strokeWidth="2" strokeMiterlimit="10" />
  </svg>
);

Video.propTypes = {
  width: number,
  height: number
};

export default Video;
