export const ValidationErrors = {
  imageDimensions: 'Image does not meet the minimum pixel size requirement',
  loadingVideo: 'Error loading video. Please try again.',
  validFile: 'Please upload a valid video file.',
  resolutionValidation: 'The video resolution must be at least 720p.',
  durationValidation: 'Video duration must not exceed 1 minute.',
  aspectRatioValidationForCommon:
    'The video does not match the required aspect ratios (1:1, 4:5, 9:16, 16:9)',
  aspectRatioValidationForMovies:
    'The video does not match the required aspect ratios. Please ensure it matches the aspect ratio of 16:9',
  aspectRatioValidationForShorts:
    'The video does not match the required aspect ratios. Please ensure it matches the aspect ratio of 9:16',
  aspectRationValidation:
    'The video does not match the required aspect ratios (1:1, 3:2, 4:3, 9:16, 16:9)'
};
