import { createThunk } from '@rootstrap/redux-tools';

import notificationsService from 'services/notificationsService';
import parseError from 'utils/parseError';

export const getNotifications = createThunk('GET_NOTIFICATIONS', async () => {
  try {
    const { data } = await notificationsService.getNotifications();
    return data.notifications;
  } catch ({ response }) {
    throw parseError(response);
  }
});

export const readNotifications = createThunk('READ_NOTIFICATIONS', async ids => {
  try {
    await notificationsService.readNotifications({ user: { notifications: ids } });
  } catch ({ response }) {
    throw parseError(response);
  }
});
