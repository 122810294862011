import React, { memo } from 'react';
import { arrayOf, shape, number, string, func, bool } from 'prop-types';
import cn from 'classnames';
import { useIntl } from 'react-intl';

const Pill = ({ tabs, selectedTab, onTabSelect, isVertical, className }) => {
  const intl = useIntl();
  return (
    <div className={cn('pills', className, { 'pill-vertical': isVertical })}>
      {tabs.map(({ id, localeId }, index) => (
        <div key={`tab${index}`} className="single-pill">
          <div
            className={cn('pill', {
              'pill-selected': selectedTab === id
            })}
            onClick={() => onTabSelect(id)}
          >
            <h4 className="h4-medium">{intl.formatMessage({ id: localeId })}</h4>
          </div>
        </div>
      ))}
    </div>
  );
};

Pill.propTypes = {
  tabs: arrayOf(
    shape({
      id: number,
      localeId: string
    })
  ),
  selectedTab: number,
  onTabSelect: func.isRequired,
  isVertical: bool,
  className: string
};

export default memo(Pill);
