import React from 'react';
import { string, shape, bool } from 'prop-types';
import cn from 'classnames';

import { serieShape } from 'constants/propTypesShapes';

import OriginalTag from 'components/common/Brand/OriginalTag';

const SerieInfo = ({ serie, className, hideDescription }) => {
  const { title, logoImageUrl } = serie;

  return (
    <div className={cn('serie-info', className)}>
      <OriginalTag />
      <img src={logoImageUrl} alt="series" className="serie-logo" />
      {!hideDescription && <h4 className="semibold serie-description">{title}</h4>}
    </div>
  );
};

SerieInfo.propTypes = {
  serie: shape(serieShape).isRequired,
  className: string,
  hideDescription: bool
};

export default SerieInfo;
