import React from 'react';
import { number, string, node } from 'prop-types';

const BenefitSquare = ({ width = 94, height = 66, className, children }) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 94 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="12" y="12" width="70" height="42" fill="#1E1E1E" />
    <rect y="60" width="94" height="6" fill="#2400FC" />
    <rect x="22" width="72" height="6" fill="#2400FC" />
    <rect width="16" height="6" fill="#2400FC" />
    <rect y="66" width="54" height="6" transform="rotate(-90 0 66)" fill="#2400FC" />
    <rect x="88" y="54" width="54" height="6" transform="rotate(-90 88 54)" fill="#2400FC" />
    {children}
  </svg>
);

BenefitSquare.propTypes = {
  width: number,
  height: number,
  className: string,
  children: node
};

export default BenefitSquare;
