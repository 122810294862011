import React from 'react';
import cn from 'classnames';
import DummyRoundProfileImg from 'assets/dummyRoundProfile.png';
import { useNavigation } from 'hooks';
import { routeWithProps } from 'utils/helpers';
import { routesPaths } from 'constants/routesPaths';

const CreatorCard = ({ data }) => {
  const { goTo } = useNavigation();
  const handleRedirect = () => {
    goTo(
      routeWithProps(routesPaths.celebrityId, {
        username: data.id
      })
    );
  };
  return (
    <div className={cn('creator-card')} onClick={() => handleRedirect()}>
      <div className="round-profile">
        <img
          src={data?.profileImageUrl || DummyRoundProfileImg}
          alt="dummy"
          className="round-img-box"
        />
      </div>
      <div className="profile-info">
        <div className="title">{data?.name}</div>
        <div className="description">{data?.bio}</div>
      </div>
    </div>
  );
};

export default CreatorCard;
