/* eslint-disable react/no-multi-comp */
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import Dropdown from 'components/header/Dropdown/Dropdown';
import DropdownRow from 'components/header/Dropdown/DropdownRow';
import { useModal } from 'hooks';

import VideoImg from 'assets/icons/Video/Video.png';
import MessageImg from 'assets/icons/Message/Message.png';
import CloudImg from 'assets/icons/UploadCloud/UploadCloud.png';
import Modal from 'components/common/Modal/Modal';
import GoLiveModal from './GoLiveModal';
import UploadModal from './UploadModal';
import DmModal from './DmModal';

const OriginalDropdown = ({ handleCloseDropDown }) => {
  const intl = useIntl();
  const { toggle: modalToggle, isShowing } = useModal();
  const [type, setType] = useState('');

  const routes = [
    {
      label: { id: 'navbar.goLive' },
      key: 'goLive',
      icon: <img src={VideoImg} alt="videoImg" width={20} height={20} />
    },
    {
      label: { id: 'navbar.upload' },
      key: 'upload',
      icon: <img src={CloudImg} alt="videoImg" width={20} height={20} />
    },
    {
      label: { id: 'navbar.dm' },
      key: 'dm',
      icon: <img src={MessageImg} alt="videoImg" width={20} height={20} />
    }
  ];

  const renderModal = () => {
    if (type === 'goLive') {
      return <GoLiveModal />;
    }
    if (type === 'upload') {
      return <UploadModal />;
    }
    return <DmModal handleCloseDropDown={handleCloseDropDown} />;
  };

  return (
    <>
      <Dropdown className="navbar-dropdown original-dropdown" triangleLeft>
        {routes.map(({ label, route, icon, key }) => (
          <DropdownRow
            label={intl.formatMessage(label)}
            key={route}
            onClick={e => {
              e.preventDefault();
              modalToggle();
              setType(key);
            }}
            icon={icon}
          />
        ))}
      </Dropdown>
      <Modal hide={modalToggle} isShowing={isShowing} className="original-modal">
        {renderModal()}
      </Modal>
    </>
  );
};

export default OriginalDropdown;
