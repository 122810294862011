import React from 'react';
import { string, number } from 'prop-types';
import cn from 'classnames';

import { PlayIcon } from 'components/icons';

import { white } from 'styles/common/_constants.scss';

const PlayIconCircle = ({ className, width, height }) => (
  <div className={cn('play-icon-circle', 'flex-center', className)}>
    <PlayIcon fill color={white} width={width} height={height} />
  </div>
);

PlayIconCircle.propTypes = {
  className: string,
  width: number,
  height: number
};

PlayIconCircle.defaultProps = {
  width: 15.56,
  height: 20
};

export default PlayIconCircle;
