import { createReducer } from '@rootstrap/redux-tools';
import {
  addEpisode,
  clearEpisodes,
  getVideoUrlForJWPlayer,
  reassignEpisodes,
  removeEpisode
} from 'state/actions/episodesActions';

const initialState = {
  episode: null,
  episodes: []
};

export default createReducer(initialState, {
  [addEpisode]: (state, { payload }) => {
    state.episodes = [...state.episodes, payload];
  },
  [clearEpisodes]: state => {
    state.episodes = [];
  },
  [reassignEpisodes]: (state, { payload }) => {
    state.episodes = payload;
  },
  [removeEpisode]: (state, { payload }) => {
    state.episodes = state.episodes.filter(({ mediaId }) => mediaId !== payload);
  },
  [getVideoUrlForJWPlayer.success]: (state, { payload }) => {
    state.episode = payload;
  },
  [getVideoUrlForJWPlayer.reset]: state => {
    state.episode = null;
  }
});
