import React from 'react';
import Close from 'components/common/Close/Close';
import { white, black40 } from 'styles/common/_constants.scss';
import { useClickOutside, useIsMobile, useNavigation } from 'hooks';
import { defineMessages, useIntl } from 'react-intl';
import Button from 'components/common/Button/Button';
import { func, string } from 'prop-types';
import useDeleteNarrative from 'hooks/narrative/useDeleteNarrative';
import useDeleteSeries from 'hooks/series/useDeleteSeries';
import { routeWithProps } from 'utils/helpers';
import { routesPaths } from 'constants/routesPaths';
import useDeleteEvent from 'hooks/event/useDeleteEvent';
import useDeletePodcast from 'hooks/podcast/useDeletePodcast';

const messages = defineMessages({
  dmPlaceholder: { id: 'form.dmPlaceholder' },
  deleteNarrative: { id: 'title.deleteNarrative' },
  deleteOriginals: { id: 'title.deleteOriginals' },
  deleteEvent: { id: 'title.deleteEvent' },
  deletePodcast: { id: 'title.deletePodcast' },
  message: { id: 'form.dmMessage' }
});
const DeletePublication = ({ hide, type, celebrityId, refresh, id, username }) => {
  const intl = useIntl();
  const isMobile = useIsMobile();
  const ref = useClickOutside(hide);
  const { goToWithSearch } = useNavigation();
  const deleteNarrative = useDeleteNarrative(refresh);
  const deleteSeries = useDeleteSeries(refresh);
  const deleteEvent = useDeleteEvent(refresh);
  const deletePodcast = useDeletePodcast(refresh);
  const handleSubmit = async () => {
    if (type === 'narrative') {
      await deleteNarrative(celebrityId, id);
      const route = routeWithProps(routesPaths.celebrityId, {
        username
      });
      goToWithSearch(route, `?tab=2`);
    } else if (type === 'event') {
      await deleteEvent(celebrityId, id);
      const route = routeWithProps(routesPaths.celebrityId, {
        username
      });
      goToWithSearch(route, `?tab=3`);
    } else if (type === 'podcast') {
      await deletePodcast(celebrityId, id);
      const route = routeWithProps(routesPaths.celebrityId, {
        username
      });
      goToWithSearch(route, `?tab=4`);
    } else {
      deleteSeries(id);
    }
  };

  const title = () => {
    if (type === 'narrative') {
      return intl.formatMessage(messages.deleteNarrative);
    }
    if (type === 'event') {
      return intl.formatMessage(messages.deleteEvent);
    }
    if (type === 'podcast') {
      return intl.formatMessage(messages.deletePodcast);
    }
    return intl.formatMessage(messages.deleteOriginals);
  };

  const DeleteType = () => {
    if (type === 'narrative') {
      return 'shorts';
    }
    if (type === 'event') {
      return 'event';
    }
    if (type === 'podcast') {
      return 'podcast';
    }
    return 'movies & series';
  };

  return (
    <div className="delete-modal" ref={ref}>
      <div className="delete-modal-header">
        {title()}
        <Close onClick={hide} color={isMobile ? white : black40} />
      </div>
      <div className="layer delete-modal-header-content">
        <div className="delete-content-div">
          <span style={{ display: 'flex', justifyContent: 'center' }}>
            Are you sure you want to delete this {DeleteType()}?
          </span>
          <div className="buttons">
            <Button className="cancel-btn" onClick={hide}>
              Cancel
            </Button>
            <Button
              type="primary"
              labelId="Yes, delete"
              className="delete-btn"
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

DeletePublication.propTypes = {
  hide: func.isRequired,
  type: string,
  celebrityId: string,
  id: string,
  refresh: func
};

export default DeletePublication;
