import React, { useState } from 'react';
import Close from 'components/common/Close/Close';
import { white, black40 } from 'styles/common/_constants.scss';
import { useClickOutside, useIsMobile } from 'hooks';
import cn from 'classnames';
import { func, object } from 'prop-types';
import { contentTabs } from 'constants/constants';
import Tabs from 'components/common/Tabs/Tabs';
import useContent from 'hooks/adManager/useContent';

const SelectContentModal = ({ content, hide, onSaveContent }) => {
  const isMobile = useIsMobile();
  const ref = useClickOutside(hide);
  const [selectedTab, setSelectedTab] = useState(1);
  const { renderVideoSelectContent } = useContent();
  const onTabChange = id => {
    setSelectedTab(id);
  };
  return (
    <div className={cn('select-content-modal')} ref={ref}>
      <div className="select-content-modal-header">
        Select Content
        <Close onClick={hide} color={isMobile ? white : black40} />
      </div>
      <div className="layer select-content-header-content">
        <div className="select-content-div">
          <Tabs
            tabs={Object.values(contentTabs)}
            selectedTab={selectedTab}
            onTabSelect={onTabChange}
            isVertical={isMobile}
            className="content-tabs"
          />
        </div>
        <div>{renderVideoSelectContent(selectedTab, true, onSaveContent, hide, content)}</div>
      </div>
    </div>
  );
};

SelectContentModal.propTypes = {
  hide: func.isRequired,
  onSaveContent: func,
  content: object
};

export default SelectContentModal;
