import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { string, number } from 'prop-types';

import { trimString } from 'utils/helpers';
import { useToggle } from 'hooks';

const MoreText = ({ text, limitLength }) => {
  const intl = useIntl();
  const [showMore, toggleShowMore] = useToggle(false);

  const { shortWord: shortBio, hasMore } = useMemo(() => trimString(text, limitLength), [
    text,
    limitLength
  ]);

  return (
    <>
      <span>{showMore ? text : shortBio}</span>
      {hasMore && (
        <span className="showMore" onClick={toggleShowMore}>
          {intl.formatMessage({ id: showMore ? 'general.less' : 'general.more' })}
        </span>
      )}
    </>
  );
};

MoreText.propTypes = {
  text: string,
  limitLength: number
};

export default MoreText;
