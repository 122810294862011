import React from 'react';
import { number } from 'prop-types';

const Bell = ({ width = 24, height = 24 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 15V11C18 7.7 16 4 11.5 4C7 4 5 7.7 5 11V18H18.8285"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M14 19C14 20.4 12.9 21.5 11.5 21.5C10.1 21.5 9 20.4 9 19"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M13 4.2V2.5C13 1.7 12.3 1 11.5 1C10.7 1 10 1.7 10 2.5V4.2"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
);

Bell.propTypes = {
  width: number,
  height: number
};

export default Bell;
