import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useStatus, SUCCESS, ERROR } from '@rootstrap/redux-tools';

import useToast from 'hooks/useToast';
import { reportNarrative } from 'state/actions/narrativeActions';
import useAnalytics from 'hooks/useAnalytics';
import { MIXPANEL_EVENTS } from 'constants/constants';
import { replaceError } from 'utils/helpers';

export default () => {
  const dispatch = useDispatch();
  const { trackEvent } = useAnalytics();
  const { status, error } = useStatus(reportNarrative);

  const { showErrorToast, showToast } = useToast();

  useEffect(() => {
    if (status === SUCCESS) {
      trackEvent(MIXPANEL_EVENTS.reportNarrative);
      showToast('The content was successfully reported!');
      dispatch(reportNarrative.reset());
    }
    if (status === ERROR) {
      showErrorToast(replaceError(error, /^reason /));
      dispatch(reportNarrative.reset());
    }
  }, [dispatch, showErrorToast, showToast, status, error]);

  return useCallback(
    ({ narrativeId, params }) => dispatch(reportNarrative({ narrativeId, params })),
    [dispatch]
  );
};
