import React from 'react';
import { string, number, func, bool, object } from 'prop-types';
import MasonryGrid from 'components/common/MasonryGrid/MasonryGrid';
import EventCard from 'components/events/EventCard/EventCard';

const EventsTab = ({ events, celebrityId, celebrityName, refresh, username, isLoggedUser }) => (
  <>
    <MasonryGrid className="narratives-grid max-width" columnClassName="narratives-grid-column">
      {events.map(events => (
        <EventCard
          key={events.id}
          events={events}
          celebrityId={celebrityId}
          celebrityName={celebrityName}
          username={username}
          refresh={refresh}
          isLoggedUser={isLoggedUser}
        />
      ))}
    </MasonryGrid>
  </>
);

EventsTab.propTypes = {
  events: object,
  celebrityId: number.isRequired,
  celebrityName: string.isRequired,
  refresh: func,
  username: string,
  isLoggedUser: bool
};

export default EventsTab;
