import React from 'react';
import ArrowRightIcon from 'assets/Icon.png';

const AdItem = ({ thumbnail, title, link, onDelete, index }) => {
  return (
    <div className="ad-item-widget">
      <img className="widget-thumbnail-image" src={thumbnail} alt="thumbnail" />
      <div className="widget-ad-details">
        <div className="widget-ad-title">{title}</div>
        <div
          style={{
            wordWrap: 'break-word',
            overflowWrap: 'break-word',
            wordBreak: 'break-all',
            whiteSpace: 'normal'
          }}
          className="link"
        >
          {link}
        </div>
      </div>
      <div className="actions">
        <div onClick={() => onDelete(index)}>
          <img
            src={ArrowRightIcon}
            alt="arrow"
            style={{ marginLeft: '8px', width: '24px', height: '24px' }}
          />
        </div>
      </div>
    </div>
  );
};

export default AdItem;
