import React from 'react';
import { useIntl } from 'react-intl';
import cn from 'classnames';
import { string, bool } from 'prop-types';

import { VideoIcon, CrownIcon } from 'components/icons';

const ICON_SIZE = 12;

const NotificationTag = ({ label, isLive }) => {
  const intl = useIntl();

  return (
    <div className={cn('notification-tag', { live: isLive })}>
      {isLive ? (
        <VideoIcon height={ICON_SIZE} width={ICON_SIZE} />
      ) : (
        <CrownIcon height={ICON_SIZE} width={ICON_SIZE} />
      )}
      <span className="notif-bubble notification-tag-text">
        {intl.formatMessage({ id: label })}
      </span>
    </div>
  );
};

NotificationTag.propTypes = {
  label: string.isRequired,
  isLive: bool
};

export default NotificationTag;
