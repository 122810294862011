import React, { useEffect } from 'react';
import { string, shape, bool } from 'prop-types';
import { SUCCESS, LOADING } from '@rootstrap/redux-tools';
import { useLocation } from 'react-router-dom';

import { useNavigation, useModal, useSession, useScheduleLive, useAnalytics } from 'hooks';
import { routeWithProps } from 'utils/helpers';
import { routesPaths } from 'constants/routesPaths';
import { contentShape } from 'constants/propTypesShapes';

import PayPerViewDetails from 'components/payPerView/PayPerViewDetails';
import Button from 'components/common/Button/Button';
import AuthModal from 'components/user/AuthModal/AuthModal';
import PurchaseModal from 'components/payments/PurchaseModal/PurchaseModal';
import Modal from 'components/common/Modal/Modal';
import { PayPerViewIcon } from 'components/icons';
import { MIXPANEL_EVENTS } from 'constants/constants';
import usePayPerViewButton from 'hooks/ppv/usePayPerViewButton';

const PayPerViewButton = ({ event, className, type, isLanding }) => {
  const { goTo } = useNavigation();
  const { pathname } = useLocation();
  const { trackEvent } = useAnalytics();
  const { toggle: togglePPV, isShowing: isShowingPPV } = useModal(false);
  const { toggle: toggleAuth, isShowing: isShowingAuth } = useModal(false);
  const { toggle: togglePayments, isShowing: isShowingPayments } = useModal(false);
  const { getScheduled, getScheduledStatus, getScheduledReset } = useScheduleLive();
  const { getTitle, purchased, isLive, isHost } = usePayPerViewButton(event, isLanding);

  const { id, title } = event;

  useEffect(() => {
    if (getScheduledStatus === SUCCESS) {
      getScheduledReset();
      purchased || isHost ? goTo(routeWithProps(routesPaths.scheduledLive, { id })) : togglePPV();
    }
  }, [getScheduledStatus]);

  const { authenticated } = useSession();

  const onPayClick = () => {
    trackEvent(isLive ? MIXPANEL_EVENTS.playScheduledLive : MIXPANEL_EVENTS.buyInfoPPVClicked, {
      'Event Name:': title
    });
    if (!authenticated) {
      toggleAuth();
    } else {
      getScheduled(id, isLive);
    }
  };

  const onAuthSuccess = () => {
    getScheduled(id, isLive);
  };

  const onPurchaseClick = () => {
    trackEvent(MIXPANEL_EVENTS.buyNowPPVClicked, { 'Event Name:': title });
    togglePayments();
  };

  const onPurchaseSuccess = () => {
    const url = routeWithProps(routesPaths.scheduledLive, { id });
    if (pathname == url) {
      getScheduled(id, isLive);
    } else {
      goTo(url);
    }
  };

  return (
    <>
      <Button
        title={getTitle}
        type={isLive ? 'red' : type}
        disabled={getScheduledStatus === LOADING || (isLanding && (purchased || isHost))}
        icon={<PayPerViewIcon />}
        onClick={onPayClick}
        className={className}
      />
      <Modal hide={togglePPV} isShowing={isShowingPPV}>
        <PayPerViewDetails event={event} togglePayments={onPurchaseClick} />
      </Modal>
      <Modal hide={toggleAuth} isShowing={isShowingAuth}>
        <AuthModal onSuccess={onAuthSuccess} />
      </Modal>
      <Modal hide={togglePayments} isShowing={isShowingPayments}>
        <PurchaseModal liveEvent={event} onSuccess={onPurchaseSuccess} />
      </Modal>
    </>
  );
};

PayPerViewButton.propTypes = {
  event: shape(contentShape),
  className: string,
  type: string,
  isLanding: bool
};

PayPerViewButton.defaultProps = {
  type: 'secondary'
};

export default PayPerViewButton;
