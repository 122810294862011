import { useSelector } from 'react-redux';
import { useLoading } from '@rootstrap/redux-tools';
import { createNarrative } from 'state/actions/narrativeActions';

export default () => {
  return {
    uploadingNarrative: useSelector(({ narratives }) => narratives.uploadingNarrative),
    loading: useLoading(createNarrative)
  };
};
