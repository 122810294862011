import { LOADING } from '@rootstrap/redux-tools';
import Button from 'components/common/Button/Button';
import Hyperlink from 'components/common/Hyperlink/Hyperlink';
import Input from 'components/common/Input/Input';
import Loading from 'components/common/Loading';
import { imageSizes, legalTabs } from 'constants/constants';
import { routesPaths } from 'constants/routesPaths';
import { useForm, useTextInputProps, useToast, useValidation } from 'hooks';
import { creator as creatorValidations } from 'utils/constraints';
import useCreator from 'hooks/creator/useCreator';
import { some } from 'lodash';
import React, { memo, useRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { checkImageDimensions, routeWithProps } from 'utils/helpers';
import ImageCropperModal from 'components/common/ImageCropper/ImageCropperModal';

const messages = defineMessages({
  bio: { id: 'form.bio' },
  bioPlaceholder: { id: 'form.bioPlaceholder' },
  username: { id: 'form.username' },
  usernamePlaceholder: { id: 'form.usernamePlaceholder' },
  price: { id: 'form.price' },
  pricePlaceholder: { id: 'form.pricePlaceholder' },
  profilePicture: { id: 'form.profilePicture' },
  banner: { id: 'form.banner' },
  uploadProfilePicture: { id: 'form.uploadProfilePicture' },
  uploadCoverPicture: { id: 'form.uploadCoverPicture' }
});

const fields = {
  username: 'username',
  bio: 'bio',
  membershipPrice: 'membershipPrice',
  profile_image: 'profile_image',
  banner: 'banner'
};

const BecomeCreatorForm = () => {
  const intl = useIntl();
  const profilePictureRef = useRef(null);
  const coverPictureRef = useRef(null);
  const [profilePic, setProfilePic] = useState(null);
  const [coverPic, setCoverPic] = useState(null);
  const { onSubmit, status, error } = useCreator();
  const [membershipPrice, setMembershipPrice] = useState('');
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [croppingUrl, setCroppingUrl] = useState();
  const [aspectRatio, setAspectRatio] = useState();
  const checkType = useRef();
  const { showErrorToast } = useToast();
  const validator = useValidation(creatorValidations);
  const { values, errors, handleValueChange, handleSubmit, handleBlur } = useForm(
    {
      onSubmit,
      validator,
      validateOnBlur: true,
      validateOnChange: true
    },
    [onSubmit]
  );

  const inputProps = useTextInputProps(handleValueChange, handleBlur, values);
  const handleProfilePictureUpload = () => {
    profilePictureRef.current.click();
  };
  const handleCoverPictureUpload = () => {
    coverPictureRef.current.click();
  };

  const handleProfileChange = async e => {
    const file = e.target.files[0];
    const result = await checkImageDimensions(
      file,
      imageSizes.profile.width,
      imageSizes.profile.height
    );
    if (result.isValid) {
      setCropModalOpen(true);
      setAspectRatio(1 / 1);
      setCroppingUrl(URL.createObjectURL(file));
      checkType.current = 'profile';
    } else {
      showErrorToast(result.error);
    }
  };

  const handleCoverChange = async e => {
    const file = e.target.files[0];
    const result = await checkImageDimensions(
      file,
      imageSizes.coverWeb.width,
      imageSizes.coverWeb.height
    );
    if (result.isValid) {
      setCropModalOpen(true);
      setAspectRatio(16 / 9);
      setCroppingUrl(URL.createObjectURL(file));
      checkType.current = 'cover';
    } else {
      showErrorToast(result.error);
    }
  };

  const onChangeMembershipPrice = event => {
    let value = event.target.value.replace(/[^0-9.]/g, '');
    if (value.length > 0) {
      value = `$${value}`;
      const valueWithoutDollar = value.slice(1);
      handleValueChange(fields.membershipPrice, valueWithoutDollar);
      setMembershipPrice(value);
    } else {
      setMembershipPrice('');
      handleValueChange(fields.membershipPrice, '');
    }
  };

  const handleCancel = () => {
    setCropModalOpen(false);
    setCroppingUrl();
    setAspectRatio();
    checkType.current = null;
  };

  const handleCrop = cropImg => {
    if (checkType.current === 'cover') {
      setCoverPic(cropImg);
      handleValueChange(fields.banner, cropImg);
    } else {
      setProfilePic(cropImg);
      handleValueChange(fields.profile_image, cropImg);
    }
  };
  return (
    <div className="creator-form">
      <div className="creator-form-fields">
        <Input
          name="username"
          placeholder={intl.formatMessage(messages.usernamePlaceholder)}
          label={intl.formatMessage(messages.username)}
          errors={errors[fields.username]}
          {...inputProps(fields.username)}
        />
        <Input
          name="bio"
          placeholder={intl.formatMessage(messages.bioPlaceholder)}
          label={intl.formatMessage(messages.bio)}
          errors={errors[fields.bio]}
          {...inputProps(fields.bio)}
        />
        <Input
          name="price"
          placeholder={intl.formatMessage(messages.pricePlaceholder)}
          label={intl.formatMessage(messages.price)}
          errors={errors[fields.membershipPrice]}
          {...inputProps(fields.membershipPrice)}
          value={membershipPrice}
          onChange={onChangeMembershipPrice}
        />
        <input
          type="file"
          ref={profilePictureRef}
          style={{ display: 'none' }}
          accept="image/*"
          name="profile_image"
          onChange={handleProfileChange}
        />
        <input
          type="file"
          ref={coverPictureRef}
          style={{ display: 'none' }}
          accept="image/*"
          onChange={handleCoverChange}
          name="banner"
        />
        <div>
          <span className={profilePic?.name ? 'white-custom-label' : 'custom-label'}>
            {intl.formatMessage(messages.profilePicture)}
          </span>
          {profilePic ? (
            <div className="white-label">
              <span className="white-name">{profilePic?.name}</span>
              <span
                className="white-name"
                style={{ cursor: 'pointer' }}
                onClick={() => setProfilePic(null)}
              >
                x
              </span>
            </div>
          ) : (
            <div className="button-margin">
              <Button
                labelId={intl.formatMessage(messages.uploadProfilePicture)}
                type="secondary"
                onClick={handleProfilePictureUpload}
                className="profile-button"
              />
              <span className="label-margin">Recommended dimension: 500px X 500px (1:1)</span>
            </div>
          )}
        </div>
        <div>
          <span className={coverPic?.name ? 'white-custom-label' : 'custom-label'}>
            {intl.formatMessage(messages.banner)}
          </span>
          {coverPic ? (
            <div className="white-label">
              <span className="white-name">{coverPic?.name}</span>
              <span
                className="white-name"
                style={{ cursor: 'pointer' }}
                onClick={() => setCoverPic(null)}
              >
                x
              </span>
            </div>
          ) : (
            <div className="button-margin">
              <Button
                labelId={intl.formatMessage(messages.uploadCoverPicture)}
                type="secondary"
                onClick={handleCoverPictureUpload}
                className="profile-button"
              />
              <span className="label-margin">Recommended dimension: 1280px X 720px (16:9)</span>
            </div>
          )}
        </div>
      </div>
      <div className="creator-form-error p1">{error}</div>
      <div className="p2 legal-text">
        {intl.formatMessage({ id: 'legalMessages.creator' })}
        <Hyperlink
          text={intl.formatMessage({ id: 'terms.title' })}
          link={routeWithProps(routesPaths.legal, { tab: legalTabs.TERMS.tab })}
        />
        {intl.formatMessage({ id: 'legalMessages.creator2' })}
        <Hyperlink
          text={intl.formatMessage({ id: 'privacyPolicies.name' })}
          link={routeWithProps(routesPaths.legal, { tab: legalTabs.PRIVACY.tab })}
        />
        .
      </div>
      <Button
        labelId="form.creator.submit"
        className="submit-button"
        onClick={handleSubmit}
        disabled={
          status === LOADING ||
          some(errors) ||
          !values[fields.username] ||
          !values[fields.membershipPrice] ||
          !profilePic ||
          !coverPic
        }
      />
      {status === LOADING && <Loading type="ball-clip-rotate" />}
      {cropModalOpen && (
        <ImageCropperModal
          visible={cropModalOpen}
          imageUrl={croppingUrl}
          onCancel={handleCancel}
          onCrop={handleCrop}
          aspectRatio={aspectRatio}
        />
      )}
    </div>
  );
};

export default memo(BecomeCreatorForm);
