import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useStatus, LOADING, ERROR, SUCCESS } from '@rootstrap/redux-tools';
import { useIntl } from 'react-intl';

import {
  createMembership,
  cancelMembership,
  subscribeAdTier
} from 'state/actions/membershipActions';
import { getCelebrityMembership } from 'state/actions/celebritiesActions';

import { useToast, useAnalytics } from 'hooks';
import useProfile from 'hooks/users/useProfile';
import { MIXPANEL_EVENTS } from 'constants/constants';
import UserService from 'services/userService';
import parseError from 'utils/parseError';

export default () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { status, error } = useStatus(getCelebrityMembership);
  const { status: cancelStatus, error: cancelError } = useStatus(cancelMembership);
  const { status: AdMembershipStatus, error: AdMembershipStatusError } = useStatus(subscribeAdTier);
  const { showErrorToast, showToast } = useToast();
  const { requestProfile } = useProfile();
  const { trackEvent } = useAnalytics();
  // const [canceledMembership, setCanceledMembership] = useState();

  const getMembership = id => {
    dispatch(getCelebrityMembership(id));
  };
  newMembership;

  const newMembership = (celebrityId, membershipId, creditCardId) => {
    trackEvent(MIXPANEL_EVENTS.completeSubscriptionClicked);
    dispatch(createMembership(celebrityId, membershipId, creditCardId));
  };

  const reset = () => {
    dispatch(getCelebrityMembership.reset());
  };

  const resetMembership = () => {
    dispatch(subscribeAdTier.reset());
  };

  const subscribeAd = async membership => {
    const { creditCardId, membershipPlanId, celebrityId } = membership;
    dispatch(subscribeAdTier(celebrityId, membershipPlanId, creditCardId));
    requestProfile();
  };
  // const getProfile = async () => {
  //   try {
  //     await UserService.getProfile();
  //   } catch ({ response }) {
  //     throw parseError(response);
  //   }
  // };
  const cancel = async membership => {
    const {
      id: membershipId,
      celebrity: { id: celebrityId }
    } = membership;
    // setCanceledMembership(membership);
    await dispatch(cancelMembership(celebrityId, membershipId));
    requestProfile();
  };

  useEffect(() => {
    if (cancelStatus === SUCCESS) {
      showToast(intl.formatMessage({ id: 'membership.cancel.success' }));
      // const {
      //   celebrity: { firstName, lastName },
      //   membershipPlan: { name }
      // } = canceledMembership;
      // setCanceledMembership();
      // trackEvent(MIXPANEL_EVENTS.membershipCanceled, {
      //   'Creator Name': getFullName(firstName, lastName),
      //   'Membership type': name
      // });
      dispatch(cancelMembership.reset());
      window.location.reload();
    }
    if (cancelStatus === ERROR) {
      showErrorToast(cancelError);
      // setCanceledMembership();
      dispatch(cancelMembership.reset());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelStatus]);

  // useEffect(() => {
  //   if (AdMembershipStatus === SUCCESS) {
  //     // showToast(intl.formatMessage({ id: 'membership.ad.success' }));
  //     dispatch(subscribeAdTier.reset());
  //     // window.location.href = '/ad-manager/videoAds';
  //     window.location.reload();
  //     // goTo(routeWithProps(routesPaths.adManager, { tab: 'videoAds' }));
  //   }
  //   if (AdMembershipStatus === ERROR) {
  //     showErrorToast(AdMembershipStatusError);
  //     dispatch(subscribeAdTier.reset());
  //   }
  //   // getProfile();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [AdMembershipStatus]);

  return {
    loading: status === LOADING,
    membershipPlans: useSelector(({ celebrities: { membershipPlans } }) => membershipPlans),
    createMembershipStatus: useStatus(createMembership),
    cancelMembership: cancel,
    subscribeAdTier: subscribeAd,
    error,
    getMembership,
    newMembership,
    reset,
    cancelStatus,
    AdMembershipStatus,
    AdMembershipStatusError,
    resetMembership
  };
};
