export const routesPaths = {
  home: '/',
  celebrityId: '/id/:username',
  series: '/series/:id/:episode?',
  narratives: '/narratives/:celebrityId/:id?',
  category: '/category/:slug',
  login: '/login',
  signup: '/signup',
  verifyPhone: '/verify',
  legal: '/legal/:tab',
  live: '/live/:celebrityId',
  account: '/account/:tab?',
  scheduledLive: '/identifi-live/:id',
  becomeCreator: '/become-creator',
  addNarrative: '/add-narratives',
  editNarrative: '/edit-narratives/:narrativeId/:celebrityId',
  addOriginals: '/add-originals',
  editOriginals: '/edit-originals/:seriesId/:celebrityId',
  editEpisode: '/edit-episode/:episodeId/:seriesId/:celebrityId',
  metrics: '/metrics',
  addBannerAd: '/add-banner-ad',
  editBannerAd: '/edit-banner-ad/:bannerId',
  addWidgetAd: '/add-widget-ad',
  addvideoAds: '/add-video-Ads',
  individualaddvideoAds: '/add-video-Ads/:type/:id/:handlePlacement',
  individualaddwidgetAds: '/add-widget-ad/:type/:id',
  individualaddbannerAds: '/add-banner-ad/:type/:id',
  adManager: '/ad-manager/:tab?',
  editWidgetAds: '/edit-widget-Ads/:widgetId/:celebrityId',
  editvideoAds: '/edit-video-Ads/:videoId/:celebrityId',
  managevideoAdsNarrative: '/ManageAds/:tab?/:celebrityId/:narrativeId',
  managevideoAdsSeries: '/ManageAds/:tab?/series/:celebrityId/:episodeId/:seriesId',
  adDetails: '/adDetails/:type?/:id?',
  plans: '/plans',
  goLive: '/go-live/:role/:room/:username/:celebrityId/:eventId',
  searchPage: '/search/:tab?',
  addEvent: '/add-event',
  goLiveNow: '/go-live-now',
  editEvent: '/edit-event/:eventId/:celebrityId',
  contentPage: '/content-marketplace-policy',
  addPodcast: '/add-podcast',
  editPodcast: '/edit-podcast/:podcastId/:celebrityId',
  podcast: '/podcast/:podcastId/:celebrityId'
};

export const invRoutes = {
  '/': 'home'
};
