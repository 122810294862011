import React from 'react';
import { useIntl } from 'react-intl';
import cn from 'classnames';
import { func, string, shape, bool } from 'prop-types';

import Button from 'components/common/Button/Button';
import Crown from 'components/icons/membership/Crown';

import { PURCHASE_TYPES } from 'constants/constants';
import { membershipPlan } from 'constants/propTypesShapes';

import useEpisode from 'hooks/useEpisode';

import { getShortPeriod } from 'utils/helpers';

const UnlockSubscribeBanner = ({
  onClick,
  className,
  planDetails,
  hideText,
  celebrityName,
  episode
}) => {
  const intl = useIntl();
  const { flowConfig } = useEpisode();
  const { price, period } = planDetails;

  const PURCHASE_MEMBERSHIP_AND_EPISODE =
    flowConfig.purchaseType === PURCHASE_TYPES.MEMBERSHIP_EPISODE;

  return (
    <div className={cn('unlock-subscribe-banner', className)}>
      {!hideText && (
        <div>
          <h4 className="semibold title">
            {intl.formatMessage(
              { id: 'membership.celebritySubscription' },
              { name: celebrityName }
            )}
          </h4>
          <div className="p1 description">
            <span className="bold">+ {intl.formatMessage({ id: 'general.episode' })}: </span>
            <span>{episode}</span>
          </div>
          <div className="p3-regular desktop-only">
            {intl.formatMessage({ id: 'membership.cancellationMessage' })}
          </div>
        </div>
      )}
      <Button
        size="small"
        type="primary"
        title={
          PURCHASE_MEMBERSHIP_AND_EPISODE
            ? intl.formatMessage({ id: 'unlock.checkout' })
            : intl.formatMessage(
                { id: 'membership.subscribePrice' },
                { price, period: getShortPeriod(period) }
              )
        }
        icon={<Crown width={20} height={20} />}
        onClick={onClick}
        lightFont
      />
      {!hideText && (
        <div className="p3-regular mobile-only">
          {intl.formatMessage({ id: 'membership.cancellationMessage' })}
        </div>
      )}
    </div>
  );
};

UnlockSubscribeBanner.propTypes = {
  onClick: func.isRequired,
  className: string,
  planDetails: shape(membershipPlan).isRequired,
  hideText: bool,
  celebrityName: string.isRequired,
  episode: string.isRequired
};

export default UnlockSubscribeBanner;
