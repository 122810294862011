import { createReducer } from '@rootstrap/redux-tools';
import { addEvent } from 'state/actions/eventActions';

const initialState = {
  eventData: {}
};

export default createReducer(initialState, {
  [addEvent.success]: (state, { payload }) => {
    state.eventData = payload;
  }
});
