import React, { useEffect, useState, useMemo } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import { useDispatch } from 'react-redux';
import Loading from 'components/common/Loading';
import Metadata from 'components/common/Metadata';
import PodcastVideoPlayer from 'components/podcast/podcastVideoPlayer';
import { PodcastContextProvider } from 'contexts/PodcastContext';
import UnlockPodcastLanding from 'components/unlock/UnlockPodcast';
import NotFoundPage from 'pages/NotFoundPage';
import { useCelebrity, useToast, useAnalytics, useSession } from 'hooks';
// import PodcastService from 'services/podcastService';
import { checkSubscription, getFullName } from 'utils/helpers';
import {  MIXPANEL_EVENTS } from 'constants/constants';
import useProfile from 'hooks/users/useProfile';
import { getCelebrity } from 'state/actions/celebritiesActions';
import { routesPaths } from 'constants/routesPaths';

const PodcastPage = () => {
  const intl = useIntl();
  const { showErrorToast } = useToast();
  const { trackEvent } = useAnalytics();
  const { podcastId, celebrityId } = useParams();
  const { authenticated } = useSession();
  // const [podcast, setPodcast] = useState(null);
  // const [loading, setLoading] = useState(false);
  // const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const {
    requestProfile,
    loading: profileLoading,
    profile: { memberships },
  } = useProfile();

  const { celebrity, error } = useCelebrity();
  const { podcasts } = celebrity;

  const resetCelebrity = celebrity.id !== Number(celebrityId);
  const podcast = useMemo(() => podcasts?.find(item => item.id == podcastId), [podcasts, podcastId]);
  const podcastUnavailable = !podcast && !resetCelebrity;

  useEffect(() => {
    authenticated && requestProfile();
    (!celebrity || resetCelebrity) && dispatch(getCelebrity(celebrityId));
  }, []);

  useEffect(() => {
    isEmpty(celebrity) && dispatch(getCelebrity(celebrityId));
  }, [celebrity?.firstName]);

  useEffect(() => {
    if (podcastUnavailable) {
      showErrorToast(intl.formatMessage({ id: 'podcasts.podcastUnavailable' }));
    }
    if (podcast && !resetCelebrity) {
      const { firstName, lastName } = celebrity;
      trackEvent(MIXPANEL_EVENTS.podcastPlayerView, {
        'Creator Name': getFullName(firstName, lastName),
        'Podcast Title': podcast.title,
        id: podcast.id,
        typeName: 'podcast'
      });
    }
  }, [podcast, resetCelebrity, podcastUnavailable]);

  // useEffect(() => {
  //   const fetchPodcast = async () => {
  //     try {
  //       setLoading(true);
  //       const { data } = await PodcastService.getPodcastById(podcastId, celebrityId);
  //       setPodcast(data?.podcast || null);
  //     } catch (err) {
  //       setError(err);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchPodcast();
  //   requestProfile(); 
  // }, [podcastId, celebrityId, requestProfile]);


  const podcastPrice = useMemo(() => {
    if (!podcast) return null;
    return checkSubscription(memberships, celebrity?.id)
      ? podcast.membersPrice
      : podcast.nonMembersPrice;
  }, [podcast, memberships]);


  const NEED_BUY_EPISODE = useMemo(
    () => !!podcastPrice && !podcast?.video?.link,
    [podcastPrice, podcast]
  );

  const SHOW_PLAYER = useMemo(
    () => podcast?.video?.link,
    [podcast]
  );

  const SHOW_UNLOCK_CONTENT = useMemo(
    () => NEED_BUY_EPISODE,
    [NEED_BUY_EPISODE]
  );

  
  const UNLOCK_CONFIG = useMemo(() => {
    if (NEED_BUY_EPISODE) {
      return {
        title: intl.formatMessage({ id: 'unlock.podcast' }),
        buttonLabel: intl.formatMessage({ id: 'unlock.podcast' }),
        price: podcastPrice,
        episodeName: podcast?.title,
        purchaseType: 'PODCAST',
      };
    }
    return {};
  }, [NEED_BUY_EPISODE, intl, podcastPrice, podcast]);


  // if (loading || profileLoading) {
  //   return (
  //     <div className={cn({ 'home-loading': loading, 'video-frame': !loading })}>
  //       <Loading />
  //     </div>
  //   );
  // }

  
  // if (error || !podcast) {
  //   return <NotFoundPage />;
  // }


  if (error) return <NotFoundPage />;

  if (isEmpty(podcasts) || resetCelebrity || profileLoading) {
    return (
      <div className="home-loading">
        <Loading />
      </div>
    );
  }

  if (podcastUnavailable) {
    return <Redirect to={routesPaths.home} />;
  }

  
  return (
    <PodcastContextProvider>
      <Metadata socialMediaMetadata={UNLOCK_CONFIG} />
      {SHOW_UNLOCK_CONTENT && (
        <UnlockPodcastLanding podcast={podcast} celebrity={celebrity} config={UNLOCK_CONFIG} />
      )}
      {SHOW_PLAYER && (
        <PodcastVideoPlayer authenticated={authenticated} podcast={podcast} celebrity={celebrity} />
      )}
    </PodcastContextProvider>
  );
};

export default PodcastPage;
