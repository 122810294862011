import { toast } from 'react-toastify';

const NOTIFICATION_CLOSE_TIME = 10000;

export default () => {
  const showToast = label => {
    toast.success(label);
  };

  const showErrorToast = label => {
    toast.error(label);
  };

  const showNotificationToast = label => {
    const options = {
      position: 'top-right',
      hideProgressBar: true,
      autoClose: NOTIFICATION_CLOSE_TIME
    };
    toast.info(label, options);
  };

  return { showToast, showErrorToast, showNotificationToast };
};
