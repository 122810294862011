import React from 'react';
import cn from 'classnames';
import DummyImg from 'assets/dummy.png';

const ActiveInfoCard = ({ data }) => {
  return (
    <div className={cn('active-info-card')}>
      <div className="img-container">
        <img src={data?.thumbnailsUrls?.[0]?.src || DummyImg} alt="dummy" />
      </div>
      <div className="info-title">{data?.title}</div>
      <div className="info-type">{data?.type}</div>
    </div>
  );
};

export default ActiveInfoCard;
