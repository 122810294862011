import { createReducer } from '@rootstrap/redux-tools';

import { filterSeries } from 'utils/dataHelpers';
import { getHome } from 'state/actions/homeActions';

const initialState = {
  celebrities: [],
  featuredCategories: [],
  content: []
};

export default createReducer(initialState, {
  [getHome.success]: (state, { payload: { celebrities, featuredCategories, content } }) => {
    state.celebrities = celebrities;
    state.featuredCategories = featuredCategories;
    state.content = filterSeries(content);
  }
});
