import React, { useState } from 'react';
import Close from 'components/common/Close/Close';
import { white, black40 } from 'styles/common/_constants.scss';
import { useClickOutside, useIsMobile, useToast } from 'hooks';
import Button from 'components/common/Button/Button';
import { func, string } from 'prop-types';
import AdService from 'services/adService';
import VidoeAdService from 'services/videoAds';

const DeleteAd = ({ hide, currentId, type, celebrityId, handleApiCall, page, handleRedirect }) => {
  const isMobile = useIsMobile();
  const ref = useClickOutside(hide);
  const { showToast, showErrorToast } = useToast();
  const [loading, setLoading] = useState(false);

  const onDeleteSuccess = () => {
    hide();
    setLoading(false);
    showToast(`${type} ad deleted successfully!`);
    if (page === 'details') {
      handleRedirect();
    } else {
      handleApiCall();
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (type === 'Video') {
        await VidoeAdService.deletevideoAd(celebrityId, currentId);
      } else if (type === 'Banner') {
        await AdService.deleteBanner(celebrityId, currentId);
      } else if (type === 'Widget') {
        await AdService.deleteWidget(celebrityId, currentId);
      }
      onDeleteSuccess();
    } catch ({ responses }) {
      setLoading(false);
      throw showErrorToast(responses);
    }
  };

  return (
    <div className="delete-modal" ref={ref}>
      <div className="delete-modal-header">
        {`Delete ${type} ad`}
        <Close onClick={hide} color={isMobile ? white : black40} />
      </div>
      <div className="layer delete-modal-header-content">
        <div className="delete-content-div">
          <span>Are you sure you want to delete your ad?</span>
          <div className="buttons">
            <Button className="cancel-btn" onClick={hide}>
              Cancel
            </Button>
            <Button
              type="primary"
              labelId="Yes, delete"
              className="delete-btn"
              onClick={handleSubmit}
              disabled={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

DeleteAd.propTypes = {
  hide: func.isRequired,
  currentId: string,
  celebrityId: string,
  type: string,
  handleApiCall: func
};

export default DeleteAd;
