import React from 'react';
import { shape, string, bool, oneOfType, arrayOf } from 'prop-types';
import cn from 'classnames';
import { isMobile } from 'react-device-detect';
import { checkSubscription } from 'utils/helpers';
import { celebrityInfoShape } from 'constants/propTypesShapes';
import useProfile from 'hooks/users/useProfile';
import LiveControls from 'components/Players/Controls/LiveControls';
import OfflineScreen from 'components/celebrity/OfflineScreen/OfflineScreen';
import { useDisappearingControls } from 'hooks';
import useVideoPlayer from './useVideoPlayer';

const LivePlayer = ({ celebrities, liveUrl, offline, isScheduled, sponsorUrl, title }) => {
  const {
    profile: { memberships }
  } = useProfile();

  checkSubscription(memberships, celebrities.id);
  const { controlsVisible, handleMouseMove, handleClick } = useDisappearingControls(true);

  // const disableAds = useMemo(() => !isScheduled && isSubscribed, [isScheduled, isSubscribed]);

  const videoJsOptions = {
    autoplay: true,
    loop: false,
    controls: true,
    responsive: true,
    fluid: true,
    aspectRatio: '16:8',
    sources: [
      {
        src: liveUrl,
        type: 'application/x-mpegURL'
      }
    ]
  };

  const { player, videoRef } = useVideoPlayer({
    options: videoJsOptions,
    onReady: player => {
      console.log('Player is ready', player);
      console.log(liveUrl);
      // You can store the player instance for further use if needed
    }
  });
  if (offline) return <OfflineScreen celebrity={celebrities} isScheduled={isScheduled} />;

  return (
    <div
      className={cn('video-container live-video-container', { 'no-pointer': isMobile })}
      onMouseMove={handleMouseMove}
      onClick={handleClick}
    >
      {/* <div id={playerDiv} /> */}
      <video ref={videoRef} className="video-js vjs-default-skin">
        <track kind="captions" srcLang="en" label="English" default />
      </video>
      {player && (
        <LiveControls
          player={player}
          celebrities={celebrities}
          sponsorUrl={sponsorUrl}
          isScheduled={isScheduled}
          title={title}
          controlsVisible={controlsVisible}
        />
      )}
    </div>
  );
};

LivePlayer.propTypes = {
  celebrities: oneOfType([shape(celebrityInfoShape), arrayOf(shape(celebrityInfoShape))]),
  liveUrl: string,
  offline: bool,
  isScheduled: bool,
  sponsorUrl: string,
  title: string
};

export default LivePlayer;
