import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useStatus, SUCCESS } from '@rootstrap/redux-tools';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { useToast, useAnalytics } from 'hooks';
import { requestCode, setUserToConfirm } from 'state/actions/userActions';
import { routesPaths } from 'constants/routesPaths';
import { MIXPANEL_EVENTS } from 'constants/constants';
import { encryptWithRSA } from 'utils/encryption';

export default customRequestSuccess => {
  const dispatch = useDispatch();
  const { status, error } = useStatus(requestCode);
  const history = useHistory();
  const intl = useIntl();
  const { showToast } = useToast();
  const { register } = useAnalytics();

  const onSubmit = useCallback(
    user => {
      dispatch(requestCode(encryptWithRSA(user.phone)));
      dispatch(setUserToConfirm(user));
    },
    [dispatch]
  );

  useEffect(() => () => dispatch(requestCode.reset()), [dispatch]);

  useEffect(() => {
    if (status === SUCCESS) {
      dispatch(requestCode.reset());
      if (customRequestSuccess) {
        customRequestSuccess();
      } else {
        history.push(routesPaths.verifyPhone);
      }
      showToast(intl.formatMessage({ id: 'verifyPhone.sendCodeSuccess' }));
      register({ [MIXPANEL_EVENTS.loggedIn]: true });
    }
  }, [status, dispatch, showToast, intl, history, customRequestSuccess, register]);

  return {
    onSubmit,
    status,
    error
  };
};
