import { createReducer } from '@rootstrap/redux-tools';

import {
  getCelebrity,
  follow,
  unFollow,
  getCelebrityLiveURL,
  getCelebrityMembership,
  getCelebrityWithSeriesId
} from 'state/actions/celebritiesActions';

const initialState = {
  celebrity: {},
  celebrityLive: {},
  membershipPlans: []
};

export default createReducer(initialState, {
  [getCelebrity.success]: (state, { payload }) => {
    state.celebrity = { ...payload, series: payload.series };
  },
  [getCelebrityWithSeriesId.success]: (state, { payload }) => {
    state.celebrity = { ...payload, series: payload.series };
  },
  [getCelebrity.reset]: state => {
    state.celebrity = {};
  },
  [getCelebrityWithSeriesId.reset]: state => {
    state.celebrity = {};
  },
  [getCelebrityLiveURL.success]: (state, { payload }) => {
    state.celebrityLive = payload;
  },
  [getCelebrityLiveURL.reset]: state => {
    state.celebrityLive = {};
  },
  [getCelebrityMembership.success]: (state, { payload }) => {
    state.membershipPlans = payload;
  },
  [getCelebrityMembership.reset]: state => {
    state.membershipPlans = [];
  },
  [follow.request]: state => {
    state.celebrity.following = true;
  },
  [follow.error]: state => {
    state.celebrity.following = false;
  },
  [unFollow.request]: state => {
    state.celebrity.following = false;
  },
  [unFollow.error]: state => {
    state.celebrity.following = true;
  }
});
