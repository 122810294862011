import React from 'react';
import { object } from 'prop-types';

import BackgroundOpacity from 'components/common/BackgroundOpacity/BackgroundOpacity';
import Crown from 'components/icons/membership/Crown';
import { convertDateFormatInStandard, convertUtcToLocalTime, getFullName } from 'utils/helpers';

const SubscribedPodcastCard = ({ podcast, celebrity }) => {
  const { title, startTime, startDate } = podcast;
  const { profileImageUrl, firstName, lastName, bannerUrl } = celebrity;

  return (
    <div className="membership-card">
      <div className="membership-card-banner">
        <BackgroundOpacity background={bannerUrl} />
        <div className="gradient" />
      </div>
      <div className="membership-card-content">
        <div className="flex content-row">
          <img className="membership-card-avatar" src={profileImageUrl} alt="avatar" />
          <div className="category-semibold">{getFullName(firstName, lastName)}</div>
        </div>
        <div className="flex-end content-row">
          <div className="flex-1">
            <h4 className="semi-bold membership-title">{title}</h4>
            <div className="p1">
              {convertDateFormatInStandard(startDate)}, {convertUtcToLocalTime(startTime)}
            </div>
          </div>
          <div className="subscription-label">
            <Crown />
          </div>
        </div>
      </div>
    </div>
  );
};

SubscribedPodcastCard.propTypes = {
  podcast: object
};

export default SubscribedPodcastCard;
