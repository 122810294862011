import axios from 'axios';
import { createThunk } from '@rootstrap/redux-tools';

import { JWPLAYER_LIVE_CHANNELS_URL, JWPLAYER_MEDIA_URL } from 'constants/constants';
import celebritiesService from 'services/celebritiesService';
import parseError from 'utils/parseError';

export const getCelebrity = createThunk('GET_CELEBRITY', async username => {
  try {
    const { data } = await celebritiesService.getCelebrity(username);
    return data.celebrity;
  } catch ({ response }) {
    throw parseError(response);
  }
});

export const getCelebrityWithSeriesId = createThunk('GET_CELEBRITY', async (username, seriesId) => {
  try {
    const { data } = await celebritiesService.getCelebrityBySeriesId(username, seriesId);
    return data.celebrity;
  } catch ({ response }) {
    throw parseError(response);
  }
});

export const getCelebrityById = createThunk('GET_CELEBRITY', async (id, filterData) => {
  try {
    const { data } = await celebritiesService.getCelebrityById(id);
    return { ...data.celebrity, filterData };
  } catch ({ response }) {
    throw parseError(response);
  }
});

export const getCelebrityMembership = createThunk('GET_CELEBRITY_MEMBERSHIP', async id => {
  try {
    const { data } = await celebritiesService.getCelebrityMembership(id);
    return data.membershipPlans;
  } catch ({ response }) {
    throw parseError(response);
  }
});

export const getCelebrityLiveURL = createThunk('GET_CELEBRITY_LIVE_URL', async id => {
  try {
    const { data } = await celebritiesService.getCelebrityLiveURL(id);
    const { data: liveEventId } = await axios.get(
      `${JWPLAYER_LIVE_CHANNELS_URL}${data.liveStream.jwplayerStreamId}.json`
    );
    const { data: event } = await axios.get(`${JWPLAYER_MEDIA_URL}${liveEventId.current_event}`);

    return {
      ...data.liveStream,
      videoUrl: event?.playlist[0]?.sources[0]?.file
    };
  } catch ({ response }) {
    throw parseError(response);
  }
});

export const follow = createThunk('FOLLOW', async id => {
  try {
    await celebritiesService.follow(id);
    return { id };
  } catch ({ response }) {
    throw parseError(response);
  }
});

export const unFollow = createThunk('UNFOLLOW', async id => {
  try {
    await celebritiesService.unFollow(id);
  } catch ({ response }) {
    throw parseError(response);
  }
});

export const deleteCreatorAccount = createThunk('DELETE_USER_ACCOUNT', async creatorId => {
  try {
    await celebritiesService.deleteCreatorAccount(creatorId);
  } catch ({ response }) {
    throw parseError(response);
  }
});

export const updatesubscriptionamount = createThunk(
  'UPDATE_SUBSCRIPTION_AMOUNT',
  async (creatorId, payload) => {
    try {
      await celebritiesService.updatesubscriptionamount(creatorId, payload);
    } catch ({ response }) {
      throw parseError(response);
    }
  }
);

export const reportCelebrity = createThunk('REPORT_CELEBRITY', async ({ celebrityId, params }) => {
  try {
    await celebritiesService.reportCelebrity({ celebrityId, params });
  } catch ({ response }) {
    throw parseError(response);
  }
});
