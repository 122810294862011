import React from 'react';
import { shape } from 'prop-types';

import { podcastShape, celebrityInfoShape } from 'constants/propTypesShapes';
import {  useIsMobile } from 'hooks';
import PodcastPlayer from 'components/Players/VideoJs/PodcastPlayer';

const PodcastVideoPlayer = ({ authenticated, podcast, celebrity }) => {
  const isMobile = useIsMobile();


  return (
    <div
      className="slider-container"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        overflow: 'hidden'
      }}
    >
      <div
        className="slider-content"
        style={{
          position: 'relative',
          width: '100%',
          height: '100vh',
          maxWidth: isMobile ? '100vw' : '80vw',
          maxHeight: '100vh',
          borderRadius: '0',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <div
          style={{
            width: '100%',
            height: '100vh',
            flexGrow: undefined
          }}
        >
          <PodcastPlayer
            className="slide"
            indexId={`jwplayer-${podcast.id}`}
            podcast={podcast}
            play
            celebrity={celebrity}
            authenticated={authenticated}
            width="100%"
            height="100%"
          />
        </div>
      </div>
    </div>
  );
};

PodcastVideoPlayer.propTypes = {
  podcast: shape(podcastShape).isRequired,
  celebrity: shape(celebrityInfoShape)
};

export default PodcastVideoPlayer;
