import React from 'react';
import { arrayOf, shape, string, number, func, bool } from 'prop-types';

import { narrativeShape } from 'constants/propTypesShapes';

import MasonryGrid from 'components/common/MasonryGrid/MasonryGrid';
import NarrativeCard from 'components/narratives/NarrativeCard/NarrativeCard';

const NarrativesTab = ({
  narratives,
  celebrityId,
  celebrityName,
  refresh,
  username,
  isLoggedUser
}) => (
  <>
    <MasonryGrid className="narratives-grid max-width" columnClassName="narratives-grid-column">
      {narratives.map(narrative => (
        <NarrativeCard
          key={narrative.id}
          narrative={narrative}
          celebrityId={celebrityId}
          celebrityName={celebrityName}
          username={username}
          refresh={refresh}
          isLoggedUser={isLoggedUser}
        />
      ))}
    </MasonryGrid>
  </>
);

NarrativesTab.propTypes = {
  narratives: arrayOf(shape(narrativeShape)),
  celebrityId: number.isRequired,
  celebrityName: string.isRequired,
  refresh: func,
  username: string,
  isLoggedUser: bool
};

export default NarrativesTab;
