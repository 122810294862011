// import { JSEncrypt } from 'jsencrypt';

// export function encryptWithRSA(plaintext) {
//   const publicKey = process.env.PUBLIC_KEY.replace(/\\n/g, '\n');
//   if (typeof window !== 'undefined') {
//     const encryptor = new JSEncrypt();
//     encryptor.setPublicKey(publicKey);

//     if (!encryptor.getKey()) {
//       console.error('Invalid public key');
//       return null;
//     }

//     const maxLength = 342;
//     let encrypted = '';

//     for (let i = 0; i < plaintext.length; i += maxLength) {
//       const chunk = plaintext.slice(i, i + maxLength);
//       const encryptedChunk = encryptor.encrypt(chunk);

//       if (!encryptedChunk) {
//         console.error(`Encryption failed for chunk starting at index ${i}`);
//         return null;
//       }

//       encrypted += `${encryptedChunk}.`;
//     }

//     return encrypted.slice(0, -1);
//   }
//   return null;
// }

import forge from 'node-forge';

export function encryptWithRSA(plaintext) {
  const publicKeyV = process.env.PUBLIC_KEY.replace(/\\n/g, '\n');
  const publicKey = forge.pki.publicKeyFromPem(publicKeyV);

  // Define the maximum length based on key size and padding
  const maxLength = 375; // For a 3072-bit key with PKCS1 v1.5 padding
  let encrypted = '';

  // Split plaintext into chunks
  for (let i = 0; i < plaintext.length; i += maxLength) {
    const chunk = plaintext.slice(i, i + maxLength);
    try {
      // Encrypt each chunk
      const encryptedChunk = publicKey.encrypt(chunk, 'RSAES-PKCS1-V1_5');
      encrypted += `${forge.util.encode64(encryptedChunk)}.`;
    } catch (error) {
      console.error(`Encryption failed for chunk starting at index ${i}: ${error}`);
      return null;
    }
  }

  // Remove trailing dot
  return encrypted.slice(0, -1);
}
