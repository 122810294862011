/* eslint-disable no-restricted-globals */
/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import Loading from 'components/common/Loading';
import searchService from 'services/searchService';
import CreatorCard from './CreatorCard';
import ContentCard from './ContentCard';

const AllContentPage = ({ dateFilter, categoryFilter }) => {
  const [isLoading, setIsLoading] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const searchTerm = searchParams.get('search');
  const [filteredData, setFilteredData] = useState();

  const filterAndStructureContent = data => {
    const result = {
      celebrities: data.celebrities,
      shorts: [],
      episodes: [],
      movieSeries: []
    };

    data.content.forEach(item => {
      switch (item.contentType) {
        case 'Shorts':
          result.shorts.push(item);
          break;
        case 'Episode':
          result.episodes.push(item);
          break;
        case 'Movie/Series':
          result.movieSeries.push(item);
          break;
        default:
          console.warn(`Unknown content type: ${item.contentType}`);
      }
    });

    return result;
  };

  useEffect(() => {
    const fetchSearchResults = async () => {
      if (searchTerm) {
        setIsLoading(true);
        try {
          const { data } = await searchService.search(searchTerm, '', dateFilter, categoryFilter);
          if (data.search) {
            const filter = filterAndStructureContent(data.search);
            setFilteredData(filter);
          }
        } catch (error) {
          console.error('Error fetching search results:', error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchSearchResults();
  }, [searchTerm, dateFilter, categoryFilter]);

  if (isLoading) {
    return (
      <div className="home-loading">
        <Loading />
      </div>
    );
  }
  const hasNoContent =
    filteredData &&
    (!filteredData.celebrities?.length &&
      !filteredData.shorts?.length &&
      !filteredData.movieSeries?.length &&
      !filteredData.episodes?.length);

  // If there's filtered data but no content, show "no records" message
  if (hasNoContent) {
    return <div className="no-results-message">Matching records not found</div>;
  }
  return (
    <div>
      {filteredData?.celebrities?.length > 0 && filteredData?.celebrities && (
        <div style={{ margin: '5rem 0rem' }}>
          <h2>Creators</h2>
          <div style={{ margin: '2rem 0rem' }} className={cn('creator-container')}>
            {filteredData?.celebrities?.map((item, index) => {
              return <CreatorCard data={item} key={index} />;
            })}
          </div>
        </div>
      )}
      {filteredData?.shorts?.length > 0 && filteredData?.shorts && (
        <div style={{ margin: '5rem 0rem' }}>
          <h2>Shorts</h2>
          <div style={{ margin: '2rem 0rem' }} className={cn('content-container')}>
            {filteredData?.shorts?.map((item, index) => {
              return <ContentCard data={item} key={index} />;
            })}
          </div>
        </div>
      )}
      {filteredData?.movieSeries?.length > 0 && filteredData?.movieSeries && (
        <div style={{ margin: '5rem 0rem' }}>
          <h2>Movies & Series</h2>
          <div style={{ margin: '2rem 0rem' }} className={cn('content-container')}>
            {filteredData?.movieSeries?.map((item, index) => {
              return <ContentCard data={item} key={index} />;
            })}
          </div>
        </div>
      )}
      {filteredData?.episodes?.length > 0 && filteredData?.episodes && (
        <div style={{ margin: '5rem 0rem' }}>
          <h2>Episodes</h2>
          <div style={{ margin: '2rem 0rem' }} className={cn('content-container')}>
            {filteredData?.episodes?.map((item, index) => {
              return <ContentCard data={item} key={index} />;
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default AllContentPage;
