import React, { useEffect, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { NOT_STARTED } from '@rootstrap/redux-tools';
import { useIntl } from 'react-intl';
import cn from 'classnames';

import Loading from 'components/common/Loading';
import Metadata from 'components/common/Metadata';
import { routesPaths } from 'constants/routesPaths';
import SeriesPlayer from 'components/Players/VideoJs/SeriesPlayer';
import { EpisodeContextProvider } from 'contexts/EpisodeContext';
import UnlockEpisodeLanding from 'components/unlock/UnlockEpisodeLanding';
import NotMemberLanding from 'components/series/NotMemberLanding/NotMemberLanding';

import { CANONICAL_URL, MIXPANEL_EVENTS, PURCHASE_TYPES } from 'constants/constants';

import useOneSeries from 'hooks/useOneSeries';
import useProfile from 'hooks/users/useProfile';
import { useToast, useAnalytics, useSession } from 'hooks';

import NotFoundPage from 'pages/NotFoundPage';

import { checkSubscription, getFullName } from 'utils/helpers';
import { getEpisodeIndex, filterAccessibleEpisodes } from 'utils/dataHelpers';

import { getOneSeries } from 'state/actions/seriesActions';
import useMembership from 'hooks/celebrities/useMembership';

const SeriesPage = () => {
  const intl = useIntl();
  const history = useHistory();
  const { id, episode } = useParams();
  const { serie, loading, error, getSerie, reset, status } = useOneSeries();
  const { showErrorToast } = useToast();
  const {
    requestProfile,
    loading: profileLoading,
    profile: { memberships }
  } = useProfile();
  const { trackEvent } = useAnalytics();
  const { authenticated } = useSession();
  const { membershipPlans } = useMembership();

  const { celebrity } = serie;

  const isMember = checkSubscription(memberships, celebrity?.id);

  useEffect(() => {
    (status == NOT_STARTED || !serie || serie.id !== id) && getSerie(id);
    authenticated && requestProfile();
    return () => reset();
  }, []);

  const filteredSerie = useMemo(() => filterAccessibleEpisodes(serie), [serie]);

  const { episodes, earlyReleaseEpisodes, title, description, id: serieId } = filteredSerie;

  const { episodeIndex, isEarlyRelease, earlyReleaseIndex, needsSubscription } = useMemo(
    () => getEpisodeIndex(filteredSerie, episode),
    [filteredSerie, episode]
  );

  const currentEpisode = episodes?.[episodeIndex];
  const episodeUnavailable = serieId == id && !currentEpisode;

  const showLoading = useMemo(
    () => loading || status == NOT_STARTED || profileLoading || !serie.id || !currentEpisode,
    [loading, status, profileLoading, serie, currentEpisode]
  );

  useEffect(() => {
    if (currentEpisode && !episodeUnavailable && !isEarlyRelease) {
      trackEvent(MIXPANEL_EVENTS.episodePlayerView, {
        'Series Name': title,
        'Episode Name': currentEpisode.title,
        id: currentEpisode.id,
        typeName: 'Episode'
      });
    }
  }, [currentEpisode, episodeUnavailable, isEarlyRelease, title]);

  const CURRENT_EPISODE = serie.episodes?.find(ep => ep.id == episode);

  const IS_EARLY_RELEASE_OR_NEED_SUBSCRIPTION =
    (isEarlyRelease || needsSubscription) && !CURRENT_EPISODE?.membersPrice;

  const NEED_SUBSCRIPTION_AND_BUY_EPISODE =
    needsSubscription && !isMember && !!CURRENT_EPISODE?.membersPrice && !CURRENT_EPISODE?.link;

  const NEED_BUY_EPISODE =
    (isMember ? !!CURRENT_EPISODE?.membersPrice : !!CURRENT_EPISODE?.nonMembersPrice) &&
    !CURRENT_EPISODE?.link;

  const SHOW_UNLOCK_CONTENT = NEED_SUBSCRIPTION_AND_BUY_EPISODE || NEED_BUY_EPISODE;

  const SHOW_PLAYER = CURRENT_EPISODE?.link;

  const UNLOCK_CONFIG = useMemo(() => {
    if (NEED_SUBSCRIPTION_AND_BUY_EPISODE) {
      return {
        title: intl.formatMessage(
          { id: 'unlock.membership.and.episode' },
          {
            name: getFullName(celebrity.firstName, celebrity.lastName),
            episodePrice: isMember
              ? CURRENT_EPISODE.membersPrice?.toFixed(2)
              : CURRENT_EPISODE.nonMembersPrice?.toFixed(2),
            membershipPrice: membershipPlans[0]?.price?.toFixed(2)
          }
        ),
        buttonLabel: intl.formatMessage({ id: 'unlock.checkout' }),
        purchaseType: PURCHASE_TYPES.MEMBERSHIP_EPISODE
      };
    }
    if (NEED_BUY_EPISODE) {
      return {
        title: intl.formatMessage({ id: 'unlock.title' }),
        buttonLabel: intl.formatMessage({ id: 'unlock.episode' }),
        price: isMember
          ? CURRENT_EPISODE.membersPrice?.toFixed(2)
          : CURRENT_EPISODE.nonMembersPrice?.toFixed(2),
        episodeName: CURRENT_EPISODE.title,
        purchaseType: PURCHASE_TYPES.EPISODE
      };
    }
    return {};
  }, [
    NEED_BUY_EPISODE,
    NEED_SUBSCRIPTION_AND_BUY_EPISODE,
    intl,
    celebrity,
    CURRENT_EPISODE,
    membershipPlans
  ]);

  if (error) return <NotFoundPage />;

  if (episodeUnavailable && !isEarlyRelease) {
    showErrorToast(intl.formatMessage({ id: 'serie.episodeUnavailable' }));
    history.push(routesPaths.home);
  }

  const { releaseDateTime } = earlyReleaseEpisodes?.[earlyReleaseIndex] || {};

  return (
    <EpisodeContextProvider>
      <Metadata
        socialMediaMetadata={UNLOCK_CONFIG}
        meta={{ title, description }}
        canonicalUrl={`${CANONICAL_URL}/series/${serie?.id}/${currentEpisode?.id}`}
      />
      {IS_EARLY_RELEASE_OR_NEED_SUBSCRIPTION && !SHOW_UNLOCK_CONTENT && (
        <NotMemberLanding serie={serie} releaseDate={releaseDateTime} />
      )}
      {SHOW_UNLOCK_CONTENT && (
        <UnlockEpisodeLanding serie={serie} episode={episode} config={UNLOCK_CONFIG} />
      )}
      {showLoading && !SHOW_UNLOCK_CONTENT && IS_EARLY_RELEASE_OR_NEED_SUBSCRIPTION && (
        <div className={cn({ 'home-loading': showLoading, 'video-frame': !showLoading })}>
          <Loading />
        </div>
      )}
      {SHOW_PLAYER && <SeriesPlayer series={filteredSerie} episodeIndex={episodeIndex} />}
    </EpisodeContextProvider>
  );
};

SeriesPage.loadData = async (store, match) => {
  const {
    params: { id }
  } = match;
  await store.dispatch(getOneSeries(id));
};

export default SeriesPage;
