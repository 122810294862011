import React from 'react';
import { string, number, func, bool, object } from 'prop-types';
import { useNavigation } from 'hooks';
import { routeWithProps } from 'utils/helpers';
import { routesPaths } from 'constants/routesPaths';
import MasonryGrid from 'components/common/MasonryGrid/MasonryGrid';
import PodcastCard from 'components/podcasts/PodcastCard/PodcastCard';

const PodcastsTab = ({ podcasts, celebrityId, celebrityName, refresh, username, isLoggedUser }) => {
  const { goTo } = useNavigation();

  const onPodcastClick = (podcastId, celebrityId) => {
    goTo(
      routeWithProps(routesPaths.podcast, {
        podcastId,
        celebrityId
      })
    );
  };

  return (
    <>
      <MasonryGrid className="narratives-grid max-width" columnClassName="narratives-grid-column">
        {podcasts.map(podcast => (
          <PodcastCard
            key={podcast.id}
            podcast={podcast}
            celebrityId={celebrityId}
            celebrityName={celebrityName}
            username={username}
            refresh={refresh}
            isLoggedUser={isLoggedUser}
            onClick={() => onPodcastClick(podcast.id, celebrityId)}
          />
        ))}
      </MasonryGrid>
    </>
  );
};

PodcastsTab.propTypes = {
  podcasts: object,
  celebrityId: number.isRequired,
  celebrityName: string.isRequired,
  refresh: func,
  username: string,
  isLoggedUser: bool
};

export default PodcastsTab;
