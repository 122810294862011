import React from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import cn from 'classnames';

import { renderImage } from 'utils/helpers';
import { routesPaths } from 'constants/routesPaths';

import BackgroundOpacity from 'components/common/BackgroundOpacity/BackgroundOpacity';
import SignUpForm from 'components/user/SignUpForm';
import { LogoIcon } from 'components/icons';

import signUpBackground from 'assets/signup-bkg.jpg';
import signUpBackgroundMobile from 'assets/signup-bkg-mobile.png';
import { bool, func } from 'prop-types';

const SignUp = ({ isModal, redirectLogin, redirectVerifyPhone }) => {
  const intl = useIntl();

  return (
    <div className={cn('sign-up', { 'sign-up-modal': isModal })}>
      <BackgroundOpacity
        background={renderImage(signUpBackground, signUpBackgroundMobile)}
        opacity={0.5}
      />
      <div className="sign-up-gradient" />
      <div className="layer content-container">
        <div className="sign-up-content">
          <LogoIcon width={182} height={52} opacity={1} />
          <div className="container">
            <SignUpForm redirectVerifyPhone={redirectVerifyPhone} />
            {isModal ? (
              <div onClick={redirectLogin}>
                <h5 className="login-link bold">
                  {intl.formatMessage({ id: 'user.existingAccount' })}
                </h5>
              </div>
            ) : (
              <Link to={routesPaths.login}>
                <h5 className="login-link bold">
                  {intl.formatMessage({ id: 'user.existingAccount' })}
                </h5>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

SignUp.propTypes = {
  isModal: bool,
  redirectLogin: func,
  redirectVerifyPhone: func
};

export default SignUp;
