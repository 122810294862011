import React, { useState, useMemo } from 'react';
import { shape, string, func } from 'prop-types';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import { narrativeShape, serieShape } from 'constants/propTypesShapes';

import BackgroundOpacity from 'components/common/BackgroundOpacity/BackgroundOpacity';
import PlayIconCircle from 'components/common/PlayIconCircle/PlayIconCircle';
import loginbkp from '../../../assets/login-bkg.jpg';

const SimpleThumbnail = ({ narrative, serie, className, onClick, customLabel }) => {
  const [isHovered, setIsHovered] = useState(false);

  const intl = useIntl();

  const { thumbnailsUrls, description, title, durationMinutes } = narrative || {};
  const {
    title: serieTitle,
    description: serieDescription,
    desktopImageUrl,
    mobileImageUrl,
    episodesCount
  } = serie || {};

  // TODO: Define with backend a default banner image for thumbnails
  const bkgImage =
    thumbnailsUrls && thumbnailsUrls.length > 0
      ? thumbnailsUrls[0].src
      : loginbkp || desktopImageUrl || mobileImageUrl;
  const playLabel = useMemo(
    () =>
      narrative
        ? `${durationMinutes}:00`
        : `${intl.formatMessage({ id: 'content.original' })} (${episodesCount} ${intl.formatMessage(
            { id: 'episodes.abbreviation' }
          )}.)`,
    [episodesCount, narrative, durationMinutes, intl]
  );

  return (
    <div
      className={cn('simple-thumbnail', className)}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <BackgroundOpacity opacity={0.4} background={bkgImage} />
      <div className="simple-thumbnail-gradient" />
      {isHovered && (
        <div className="simple-thumbnail-play">
          <div className="p2">{customLabel || playLabel}</div>
          <PlayIconCircle />
        </div>
      )}
      <div className="simple-thumbnail-info">
        <div className="p1 title">{title || serieTitle}</div>
        {isHovered && (
          <div className="p3-regular description">{description || serieDescription}</div>
        )}
      </div>
    </div>
  );
};

SimpleThumbnail.propTypes = {
  narrative: shape(narrativeShape),
  serie: shape(serieShape),
  className: string,
  onClick: func.isRequired,
  customLabel: string
};

export default SimpleThumbnail;
