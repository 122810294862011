import React from 'react';
import { shape } from 'prop-types';

import { celebrityShape } from 'constants/propTypesShapes';
import useFollow from 'hooks/celebrities/useFollow';
import { useSession, useModal, useIsMobile } from 'hooks';

import Modal from 'components/common/Modal/Modal';
import Button from 'components/common/Button/Button';
import AuthModal from 'components/user/AuthModal/AuthModal';

const FollowButton = ({ celebrity }) => {
  const { follow, unFollow } = useFollow(celebrity);
  const { toggle, isShowing } = useModal();
  const { authenticated } = useSession();
  const { following } = celebrity;
  const isMobile = useIsMobile();

  const followingAction = () => {
    if (authenticated) {
      celebrity.following ? unFollow() : follow();
    } else {
      toggle();
    }
  };

  return (
    <>
      <Button
        labelId={following ? 'user.following' : 'user.follow'}
        type="secondary"
        onClick={followingAction}
        className="follow-button"
        smallBoldFont={isMobile}
      />
      <Modal hide={toggle} isShowing={isShowing}>
        <AuthModal isModal onSuccess={follow} />
      </Modal>
    </>
  );
};

FollowButton.propTypes = {
  celebrity: shape(celebrityShape).isRequired
};

export default FollowButton;
