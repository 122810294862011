import React from 'react';
import { string } from 'prop-types';

const Hyperlink = ({ text, link }) => (
  <a className="hyperlink" href={link} target="_blank" rel="noopener noreferrer">
    {text}
  </a>
);

Hyperlink.propTypes = {
  link: string,
  text: string.isRequired
};

export default Hyperlink;
