import httpClient from 'httpClient';

class NarrativeService {
  static createNarrative(celebrityId, narrative) {
    return httpClient.post(`/celebrities/${celebrityId}/narratives`, { narrative });
  }

  static updateNarrative(celebrityId, narrativeId, narrative) {
    return httpClient.put(`/celebrities/${celebrityId}/narratives/${narrativeId}`, { narrative });
  }

  static processNarrative(celebrityId, narrativeId) {
    return httpClient.post(`/celebrities/${celebrityId}/narratives/${narrativeId}/generate`, {});
  }

  static deleteNarrative(celebrityId, narrativeId) {
    return httpClient.delete(`/celebrities/${celebrityId}/narratives/${narrativeId}`);
  }

  static reportNarrative({ narrativeId, params }) {
    return httpClient.post(`/narratives/${narrativeId}/reported_contents`, params);
  }

  static getNarrativeById(narrativeId, celebrityId) {
    return httpClient.get(`/celebrities/${celebrityId}/narratives/${narrativeId}`);
  }
}

export default NarrativeService;
