import React from 'react';
import { string, number, bool } from 'prop-types';
import cn from 'classnames';
import { black } from 'styles/common/_constants.scss';

const BackgroundOpacity = ({ background, opacity, opacityColor, isVideo, grayscale }) => (
  <>
    {isVideo && (
      <div className="layer background-video">
        <video width="100%" height="100%" autoPlay loop muted key={background}>
          <source src={background} type="video/mp4" />
        </video>
      </div>
    )}
    {!isVideo && (
      <img className={cn('background-image layer', { grayscale })} src={background} alt="bkg" />
    )}
    <div className="bkg-opacity layer" style={{ opacity, backgroundColor: opacityColor }} />
  </>
);

BackgroundOpacity.propTypes = {
  background: string.isRequired,
  opacity: number,
  opacityColor: string,
  isVideo: bool,
  grayscale: bool
};

BackgroundOpacity.defaultProps = {
  opacity: 0.3,
  opacityColor: black
};

export default BackgroundOpacity;
