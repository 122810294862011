import React, { useEffect } from 'react';
import { useLocation, useHistory, Redirect } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import { useSession } from 'hooks';
import { routesPaths } from 'constants/routesPaths';

import VerifyPhone from 'components/user/VerifyPhone/VerifyPhone';

const VerifyPhonePage = () => {
  const history = useHistory();
  const { state: { isSignUp } = {} } = useLocation();
  const { authenticated, userToConfirm } = useSession();

  useEffect(() => {
    if (authenticated) {
      history.push(routesPaths.home);
    }
  }, []);

  if (isEmpty(userToConfirm)) {
    return <Redirect to={routesPaths.login} />;
  }

  return <VerifyPhone isSignUp={isSignUp} />;
};

export default VerifyPhonePage;
