import React from 'react';
import { shape } from 'prop-types';
import { useIntl } from 'react-intl';
import cn from 'classnames';

import { notificationShape } from 'constants/propTypesShapes';
import { getNotificationInfo } from 'utils/helpers';
import { useNavigation } from 'hooks';

import Badge from 'components/common/Badge/Badge';
import NotificationTag from 'components/common/Brand/NotificationTag';
import NotificationLabel from '../NotificationLabel/NotificationLabel';

const NotificationItem = ({ notification }) => {
  const intl = useIntl();
  const { read, dm } = notification;
  const { goTo, goToWithSearch } = useNavigation();
  const {
    title,
    subtitle,
    image,
    redirect,
    message,
    label,
    labelColor,
    member,
    search,
    secondLabel,
    isScheduledLive
  } = getNotificationInfo(notification);

  return (
    <div
      className={cn('notification', { clickable: redirect })}
      onClick={() => (search ? goToWithSearch(redirect, search) : goTo(redirect))}
    >
      <img src={image} alt="Notification" className="notification-img" />
      <div className="notification-info">
        {!!member && <NotificationTag label={label} />}
        {isScheduledLive && <NotificationTag label={secondLabel} isLive />}
        <h6 className={cn('title', { grey: !!dm })}>{title}</h6>
        {!!subtitle && <h6 className="subtitle">{subtitle}</h6>}
        {!!message && <div className="p3-regular message">{message}</div>}
      </div>
      {!!label && !member && (
        <NotificationLabel label={intl.formatMessage({ id: label })} color={labelColor} />
      )}
      {!read && <Badge />}
    </div>
  );
};

NotificationItem.propTypes = {
  notification: shape(notificationShape)
};

export default NotificationItem;
