import { useLocation, useHistory } from 'react-router-dom';
import { routesPaths } from 'constants/routesPaths';

export default () => {
  const { state } = useLocation();
  const history = useHistory();
  const location = useLocation();

  const goBack = () => {
    const { prevPath } = state || {};
    if (prevPath) {
      history.push(prevPath);
    } else {
      history.push(routesPaths.home);
    }
  };

  const getLinkTo = (path, rememberPrevious = false, props) => {
    const { prevPath } = state || {};
    return {
      pathname: path,
      state: { prevPath: rememberPrevious ? prevPath : location?.pathname, ...props }
    };
  };

  const goTo = (path, rememberPrevious = false, props) => {
    history.push(getLinkTo(path, rememberPrevious, props));
  };

  const goToWithSearch = (path, search) => {
    history.push({
      pathname: path,
      state: { prevPath: location?.pathname },
      search
    });
  };

  return { goBack, goTo, getLinkTo, goToWithSearch };
};
