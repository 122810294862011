/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable indent */
import React, { useState } from 'react';
import { func, shape } from 'prop-types';

import { eventInoTabs } from 'constants/constants';
import { serieShape } from 'constants/propTypesShapes';
import { useIsMobile, useClickOutside, useSession, useModal, useCelebrity, useNavigation } from 'hooks';

import Tabs from 'components/common/Tabs/Tabs';
import BackgroundOpacity from 'components/common/BackgroundOpacity/BackgroundOpacity';
import Button from 'components/common/Button/Button';
import Close from 'components/common/Close/Close';
import {
  routeWithProps
} from 'utils/helpers';
import { routesPaths } from 'constants/routesPaths';
import { white, black40, zIndexModalThird } from 'styles/common/_constants.scss';
import Modal from 'components/common/Modal/Modal';
import EventPurchaseModal from 'components/payments/EventPurchaseModal/EventPurchaseModal';
import { USER_TYPES } from 'hooks/useSession';
import Info from './Info/Info';

import EventAbout from './EventAbout/EventAbout';

const EventInfo = ({ event, hide, celebrityId, isAuthenticated }) => {
  const { goTo } = useNavigation();
  const { toggle, isShowing } = useModal();
  const { id, webCoverImageUrl, status } = event;
  const { celebrity } = useCelebrity();
  const { DETAILS } = eventInoTabs;
  const {
    user: { id: loggedId, type, firstName }
  } = useSession();
  const isLoggedUser = loggedId === celebrityId;

  const isMobile = useIsMobile();
  const ref = useClickOutside(hide, id);

  const [selectedTab, setSelectedTab] = useState(DETAILS.id);

  const renderContent = tab => {
    switch (tab) {
      case DETAILS.id:
        return <EventAbout event={event} />;
      default:
    }
  };

  return (
    <div className="event-landing" ref={ref}>
      <div className="event-landing-header">
        <BackgroundOpacity opacity={0.5} background={webCoverImageUrl} />
        <div className="event-landing-header-gradient" />
        <Close onClick={hide} color={isMobile ? white : black40} />
        <div className="layer event-landing-header-content">
          <Info event={event} className="header-info" />
          {status !== 'completed' && event?.roomKey && isAuthenticated && (
            <div className="flex-column header-right-column">
              <Button
                labelId={
                  isLoggedUser
                    ? 'Start Event'
                    : type === USER_TYPES.user && !event.subscribedToEvent
                    ? 'Register Now'
                    : 'Join Event'
                }
                type="primary"
                size="xsmall"
                onClick={()=>{
                  // console.log(celebrity)
                  // isLoggedUser
                    // ? 
                    goTo(
                      routeWithProps(routesPaths.goLive, {
                        role: "Audience",
                        room: event.roomKey,
                        username: firstName,
                        celebrityId: loggedId,
                        eventId: event.id
                      })
                    )
                  }
                    // : type === USER_TYPES.user && !event.subscribedToEvent
                    // ? toggle
                    // : undefined
                }
                className="watch-event"
                lightFont
              />
            </div>
          )}
        </div>
      </div>
      <div className="event-landing-content">
        <>
          <Tabs
            tabs={Object.values(eventInoTabs)}
            selectedTab={selectedTab}
            onTabSelect={setSelectedTab}
            isVertical={!isMobile}
            className="event-landing-tabs"
          />
          <div className="separator" />
          {renderContent(selectedTab)}
        </>
      </div>
      <div className="top-gradient" />
      <div className="bottom-gradient" />
      {isShowing && (
        <Modal hide={toggle} isShowing={isShowing} topModal zIndex={zIndexModalThird}>
          <EventPurchaseModal celebrity={celebrity} event={event} />
          {/* <PurchaseModalHeader
            title="ab"
            image={UploadIcon}
            price="20"
            showProfile={false}
            hide={hide}
            // purchaseItems={PURCHASE_ITEMS}
          /> */}
        </Modal>
      )}
    </div>
  );
};

EventInfo.propTypes = {
  event: shape(serieShape),
  hide: func.isRequired
};

export default EventInfo;
