import React, { useEffect } from 'react';
import { shape, string } from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { serieShape } from 'constants/propTypesShapes';
import { routesPaths } from 'constants/routesPaths';
import { useNavigation } from 'hooks';
import useMembership from 'hooks/celebrities/useMembership';
import useOneSeries from 'hooks/useOneSeries';
import { renderImage, routeWithProps, getShortPeriod, getFullName } from 'utils/helpers';
import { getReleaseDate } from 'utils/date';

// import ProfilePicture from 'components/ids/ProfilePicture/ProfilePicture';
import SerieInfo from 'components/series/SerieInfo/SerieInfo';
import SubscribeButton from 'components/membership/SubscribeButton/SubscribeButton';
import Header from 'components/header/Header/Header';
import Loading from 'components/common/Loading';
import Button from 'components/common/Button/Button';
import BackgroundOpacity from 'components/common/BackgroundOpacity/BackgroundOpacity';

// const PROFILE_IMAGE_SIZE = 140;

const NotMemberLanding = ({ serie, releaseDate }) => {
  const intl = useIntl();
  const { goTo } = useNavigation();
  const { desktopImageUrl, mobileImageUrl, celebrity } = serie;

  const { loading, membershipPlans, getMembership } = useMembership();
  const { id: celebrityId, username, firstName, lastName } = celebrity;

  const { id } = useParams();
  const { getSerie } = useOneSeries();

  useEffect(() => {
    getMembership(celebrityId);
  }, []);

  if (loading || isEmpty(membershipPlans))
    return (
      <div className="not-member-landing-loading">
        <Loading />
      </div>
    );

  // We have a unique plan for now.
  const { price, period } = membershipPlans[0];

  return (
    <>
      <Header />
      <div className="not-member-landing">
        <BackgroundOpacity
          background={renderImage(desktopImageUrl, mobileImageUrl)}
          opacity={0.5}
        />
        <div className="not-member-landing-gradient" />
        <div className="not-member-landing-content">
          <div className="left-content content-item">
            {/* <ProfilePicture
              profileImage={profileImageUrl}
              width={PROFILE_IMAGE_SIZE}
              height={PROFILE_IMAGE_SIZE}
            /> */}
            <h1>
              {releaseDate
                ? intl.formatMessage({ id: 'earlyRelease.warning' })
                : intl.formatMessage(
                    { id: 'exclusive.warning' },
                    { creatorName: getFullName(firstName, lastName), br: <br /> }
                  )}
            </h1>
            <div className="button-container">
              <SubscribeButton
                celebrity={celebrity}
                title={intl.formatMessage(
                  { id: 'membership.subscribePrice' },
                  { price, period: getShortPeriod(period) }
                )}
                size="small"
                onSuccess={() => getSerie(id)}
              />
              <Button
                labelId="earlyRelease.watchAnother"
                type="secondary"
                size="small"
                onClick={() =>
                  goTo(
                    routeWithProps(routesPaths.celebrityId, { username: username.toLowerCase() })
                  )
                }
              />
            </div>
            {releaseDate && (
              <>
                <h4 className="h4-medium">
                  {intl.formatMessage({ id: 'earlyRelease.checkBackIn' })}
                </h4>
                <h4 className="semibold">{getReleaseDate(releaseDate)}</h4>
              </>
            )}
          </div>
          <SerieInfo serie={serie} className="content-item" />
        </div>
      </div>
    </>
  );
};

NotMemberLanding.propTypes = {
  serie: shape(serieShape).isRequired,
  releaseDate: string
};

export default NotMemberLanding;
