import React from 'react';
import { Link } from 'react-router-dom';
import { useIntl, defineMessages } from 'react-intl';

import { routeWithProps } from 'utils/helpers';
import { routesPaths } from 'constants/routesPaths';
import SocialPlatforms from 'components/common/Footer/SocialPlatforms';
import {
  COPYRIGHT_MESSAGE,
  MEDIA_INQUIRIES_EMAIL,
  SUPPORT_EMAIL,
  legalTabs
} from 'constants/constants';

const contentRoutesNames = defineMessages({
  home: { id: 'navbar.home' }
});

const legalRoutes = [
  {
    label: { id: 'navbar.privacyTerms' },
    route: routeWithProps(routesPaths.legal, { tab: legalTabs.PRIVACY.tab }),
    isNew: false
  },
  {
    label: { id: 'navbar.termsOfService' },
    route: routeWithProps(routesPaths.legal, { tab: legalTabs.TERMS.tab }),
    isNew: false
  },
  {
    label: { id: 'navbar.contentMarketPlace' },
    isNew: true
  }
];

const contactLinks = [
  {
    label: { id: 'footer.support' },
    email: SUPPORT_EMAIL
  },
  {
    label: { id: 'footer.mediaInquiries' },
    email: MEDIA_INQUIRIES_EMAIL
  }
];

const Footer = () => {
  const intl = useIntl();

  const handleNewOpen = () => {
    window.open(process.env.CONTENT_PDF_LINK, '_blank');
  };

  return (
    <footer className="footer">
      <div className="footer-pages">
        <div className="footer-column">
          <h5 className="footer-column-title">{intl.formatMessage({ id: 'footer.content' })}</h5>
          {Object.keys(contentRoutesNames).map(route => (
            <Link to={routesPaths[route]} key={route}>
              <h6 className="footer-column-item">
                {intl.formatMessage(contentRoutesNames[route])}
              </h6>
            </Link>
          ))}
          <h6 className="footer-column-item copyright desktop-only">{COPYRIGHT_MESSAGE}</h6>
        </div>
        <div className="footer-column">
          <h5 className="footer-column-title">{intl.formatMessage({ id: 'footer.legal' })}</h5>
          {legalRoutes.map(({ label, route, isNew }) => (
            <Link to={route} key={route} onClick={() => isNew && handleNewOpen()}>
              <h6 className="footer-column-item">{intl.formatMessage(label)}</h6>
            </Link>
          ))}
        </div>
        <div className="footer-column">
          <h5 className="footer-column-title">{intl.formatMessage({ id: 'footer.contactUs' })}</h5>
          {contactLinks.map(({ label, email }, index) => (
            <a href={`mailto:${email}`} key={`contact-link-${index}`}>
              <h6 className="footer-column-item">{intl.formatMessage(label)}</h6>
            </a>
          ))}
        </div>
        <h6 className="footer-column-item copyright mobile-only">{COPYRIGHT_MESSAGE}</h6>
      </div>
      <div className="footer-column">
        <h5 className="footer-column-title">{intl.formatMessage({ id: 'footer.social' })}</h5>
        <SocialPlatforms />
      </div>
    </footer>
  );
};

export default Footer;
