import humps from 'humps';
// import CryptoJS from 'crypto-js';
import { updateSession, logout } from 'state/actions/userActions';
// import { getDecryptedValue } from 'utils/helpers';

const ACCESS_TOKEN = 'access-token';
const UID = 'uid';
const CLIENT = 'client';

const UNAUTHORIZED = 401;
// const APPLICATION_JSON = 'application/json';

// const generateSignature = (method, url, data) => {
//   const accessId = getDecryptedValue('accessId');
//   const secretKey = getDecryptedValue('secretKey');

//   if (!accessId || !secretKey) {
//     console.warn('Something Went Wrong!');
//     return null;
//   }

//   const fullUrl = `${process.env.API_URL}${url}`;
//   const message = `${method},${APPLICATION_JSON},${fullUrl}${
//     data ? `,${JSON.stringify(data)}` : ''
//   }`;

//   return CryptoJS.HmacSHA256(message, secretKey).toString();
// };

export default (store, client) => {
  const requestInterceptor = client.interceptors.request.use(config => {
    const { info } = store.getState().session;
    const { data, headers } = config;
    if (info) {
      const { token, client, uid } = info;
      if (token) {
        config.headers = {
          ...headers,
          [ACCESS_TOKEN]: token,
          client,
          uid
        };
      }
    }
    config.data = data ? humps.decamelizeKeys(data) : {};
    // if (!url.endsWith('/sign_in')) {
    //   const signature = generateSignature(method.toUpperCase(), url, config.data);
    //   if (signature) {
    //     config.headers.Signature = signature;
    //     config.headers.AccessId = localStorage.getItem('accessId');
    //   }
    // }
    return config;
  });

  const responseInterceptor = client.interceptors.response.use(
    async response => {
      const { headers, data } = response;
      const token = headers[ACCESS_TOKEN];
      if (token) {
        const session = {
          token,
          uid: headers[UID],
          client: headers[CLIENT]
        };
        store.dispatch(updateSession(session));
      }
      response.data = humps.camelizeKeys(data);
      return response;
    },
    error => {
      if (error.response && error.response.status === UNAUTHORIZED) {
        store.dispatch(logout());
      }

      return Promise.reject(error);
    }
  );

  return { requestInterceptor, responseInterceptor };
};
