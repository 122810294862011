import React from 'react';
import cn from 'classnames';
import { shape, string, bool, func } from 'prop-types';

import { routeWithProps, getFullName } from 'utils/helpers';
import { useNavigation } from 'hooks';
import useAnalytics from 'hooks/useAnalytics';
import { MIXPANEL_EVENTS } from 'constants/constants';
import { routesPaths } from 'constants/routesPaths';
import { serieShape } from 'constants/propTypesShapes';

import BackgroundOpacity from 'components/common/BackgroundOpacity/BackgroundOpacity';
import Button from 'components/common/Button/Button';
import IconWithBackground from 'components/common/IconWithBackground/IconWithBackground';
import { InfoIcon } from 'components/icons';
import OriginalTag from 'components/common/Brand/OriginalTag';

const FeaturedThumbnail = ({ serie, className, categoryName, showTitle, onClick }) => {
  const { goTo } = useNavigation();
  const { trackEvent } = useAnalytics();

  const {
    id,
    title,
    description,
    desktopImageUrl,
    celebrity: { firstName, lastName },
    logoImageUrl,
    firstEpisode: { id: episodeId }
  } = serie;

  const goToSerie = () => {
    trackEvent(MIXPANEL_EVENTS.homeHighlight, {
      category: categoryName,
      creatorName: getFullName(firstName, lastName),
      title
    });
    goTo(
      routeWithProps(routesPaths.series, {
        id,
        episode: episodeId
      })
    );
  };

  return (
    <div className={cn(className, 'featured-thumbnail')} onClick={onClick}>
      <BackgroundOpacity background={desktopImageUrl} opacity={0} />
      <div className="gradient" />
      <OriginalTag withBackground className="featured-thumbnail-tag" />
      <div className="featured-thumbnail-info">
        {showTitle ? (
          <h2 className="title">{title}</h2>
        ) : (
          <img src={logoImageUrl} alt={title} className="logo" />
        )}
        <div className="bottom-row">
          <div className="p1 description">{description}</div>
          <Button labelId="episodes.watch" type="secondary" size="xsmall" onClick={goToSerie} />
        </div>
      </div>
      <IconWithBackground icon={<InfoIcon />} className="info-icon" />
    </div>
  );
};

FeaturedThumbnail.propTypes = {
  serie: shape(serieShape),
  categoryName: string.isRequired,
  onClick: func.isRequired,
  className: string,
  showTitle: bool
};

export default FeaturedThumbnail;
