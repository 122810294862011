/* eslint-disable indent */
import React, { memo, cloneElement, useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { node, string, func, bool, number, oneOfType } from 'prop-types';
import cn from 'classnames';

import { useDisableScroll } from 'hooks';

import { zIndexModal, zIndexNegative } from 'styles/common/_constants.scss';

const Modal = ({
  children,
  className,
  isShowing,
  hide,
  leftAnimation,
  zIndex,
  disablePadding,
  preventClose, 
}) => {
  const [showContent, setShowContent] = useState(isShowing);

  const timeOut = useRef(null);


  useEffect(() => {
    if (!isShowing) {
      timeOut.current = setTimeout(() => {
        setShowContent(isShowing);
      }, 700);
    } else {
      clearTimeout(timeOut.current);
      setShowContent(isShowing);
    }
  }, [isShowing]);

  useDisableScroll(isShowing);

  
  const handleClose = () => {
    if (preventClose) {
      window.history.back();
    }else{
      hide();
    }
  };

  return window?.document
    ? ReactDOM.createPortal(
        <div
          className={cn(
            'modal',
            className,
            { viewable: isShowing },
            { 'left-animation': leftAnimation }
          )}
          style={{ zIndex: isShowing ? zIndex : zIndexNegative }}
          onClick={handleClose} 
        >
          {showContent && (
            <div
              className={cn('modal-content', { 'disable-padding': disablePadding })}
              onClick={(e) => e.stopPropagation()} 
            >
              {cloneElement(children, { hide: handleClose })}
            </div>
          )}
        </div>,
        document.getElementById('app')
      )
    : null;
};

Modal.propTypes = {
  children: node.isRequired,
  className: string,
  isShowing: bool.isRequired,
  hide: func.isRequired,
  leftAnimation: bool,
  zIndex: oneOfType([number, string]),
  disablePadding: bool,
  preventClose: bool, 
};

Modal.defaultProps = {
  zIndex: zIndexModal,
  preventClose: false, 
};

export default memo(Modal);
