import React from 'react';
import { number, string } from 'prop-types';

import { primaryColor, transparent } from 'styles/common/_constants.scss';

const AvatarHeaderWide = ({ width = 262, height = 140, className, color = primaryColor }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 262 140"
    fill={transparent}
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="logoTitle logoDesc"
    role="img"
    className={className}
  >
    <g clipPath="url(#clip0)">
      <rect width="262" height="140" fill="url(#pattern0)" />
      <rect x="-6" y="-6" width="68" height="24" fill={color} stroke="black" strokeWidth="12" />
      <mask
        id="path-3-outside-1"
        maskUnits="userSpaceOnUse"
        x="67"
        y="-12"
        width="207"
        height="140"
        fill="black"
      >
        <rect fill="white" x="67" y="-12" width="207" height="140" />
        <path fillRule="evenodd" clipRule="evenodd" d="M250 0H79V12H250V116H262V12V0H250Z" />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M250 0H79V12H250V116H262V12V0H250Z"
        fill={color}
      />
      <path
        d="M79 0V-12H67V0H79ZM79 12H67V24H79V12ZM250 12H262V0H250V12ZM250 116H238V128H250V116ZM262 116V128H274V116H262ZM262 0H274V-12H262V0ZM79 12H250V-12H79V12ZM91 12V0H67V12H91ZM250 0H79V24H250V0ZM262 116V12H238V116H262ZM262 104H250V128H262V104ZM250 12V116H274V12H250ZM250 0V12H274V0H250ZM250 12H262V-12H250V12Z"
        fill="black"
        mask="url(#path-3-outside-1)"
      />
      <mask
        id="path-5-outside-2"
        maskUnits="userSpaceOnUse"
        x="-12"
        y="12"
        width="286"
        height="140"
        fill="black"
      >
        <rect fill="white" x="-12" y="12" width="286" height="140" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 128V140H12H262V128L12 128L12 24H1.52116e-05L0 128Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 128V140H12H262V128L12 128L12 24H1.52116e-05L0 128Z"
        fill={color}
      />
      <path
        d="M0 140H-12V152H0V140ZM0 128H-12H0ZM262 140V152H274V140H262ZM262 128H274V116H262V128ZM12 128H-1.33514e-05L-1.4892e-05 140H12L12 128ZM12 24L24 24L24 12H12V24ZM1.52116e-05 24V12H-12L-12 24L1.52116e-05 24ZM12 140V128H-12V140H12ZM12 128H0V152H12V128ZM262 128H12V152H262V128ZM250 128V140H274V128H250ZM12 140L262 140V116L12 116L12 140ZM0 24L-1.33514e-05 128H24L24 24L0 24ZM1.52116e-05 36H12V12H1.52116e-05V36ZM12 128L12 24L-12 24L-12 128H12Z"
        fill="black"
        mask="url(#path-5-outside-2)"
      />
    </g>
  </svg>
);

AvatarHeaderWide.propTypes = {
  width: number,
  height: number,
  className: string,
  color: string
};

export default AvatarHeaderWide;
