import React, { useMemo } from 'react';
import { shape, func } from 'prop-types';
import { useIntl } from 'react-intl';

import { useIsMobile } from 'hooks';
import { getFullName, getShortPeriod } from 'utils/helpers';
import { celebrityShape, membershipPlan } from 'constants/propTypesShapes';

import BackgroundOpacity from 'components/common/BackgroundOpacity/BackgroundOpacity';
import ProfilePicture from 'components/ids/ProfilePicture/ProfilePicture';
import Close from 'components/common/Close/Close';
import Button from 'components/common/Button/Button';
import Crown from 'components/icons/membership/Crown';

const PROFILE_IMAGE_SIZE = 92;

const UnAuthMembershipHeader = ({ celebrity, hide, planDetails, showPayments }) => {
  const intl = useIntl();
  const { bannerUrl, profileImageUrl, firstName, lastName } = celebrity;
  const { price, period } = planDetails;
  const isMobile = useIsMobile();

  const fullName = useMemo(() => getFullName(firstName, lastName), [firstName, lastName]);
  return (
    <div className="unauth-membership-header">
      {!!bannerUrl && <BackgroundOpacity background={bannerUrl} opacity={0.5} />}
      <div className="top-gradient" />
      <div className="bottom-gradient" />
      <div className="unauth-membership-header-content">
        <ProfilePicture
          profileImage={profileImageUrl}
          width={PROFILE_IMAGE_SIZE}
          height={PROFILE_IMAGE_SIZE}
        />
        <div className="category-semibold celebrity-name">{fullName}</div>
        <h2 className="message">{intl.formatMessage({ id: 'user.needAccount' })}</h2>
        <Button
          size="small"
          type="primary"
          title={intl.formatMessage(
            {
              id: isMobile ? 'membership.subscribeCelebrity' : 'membership.subscribeCelebrityPrice'
            },
            { price, period: getShortPeriod(period), name: firstName }
          )}
          icon={<Crown width={20} height={20} />}
          onClick={showPayments}
        />
        <div className="p2">
          {intl.formatMessage({ id: 'membership.description' }, { name: fullName })}
        </div>
      </div>
      <div className="benefits-title bold">
        <h4 className="bold">{fullName}</h4>
        <h4 className="bold">{intl.formatMessage({ id: 'membership.celebrityBenefits' })}</h4>
      </div>
      <Close onClick={hide} />
    </div>
  );
};

UnAuthMembershipHeader.propTypes = {
  celebrity: shape(celebrityShape).isRequired,
  planDetails: shape(membershipPlan).isRequired,
  hide: func.isRequired,
  showPayments: func.isRequired
};

export default UnAuthMembershipHeader;
