import { useIntl } from 'react-intl';
import { calculateTimeLeft } from 'utils/date';
import { useState, useEffect } from 'react';

export default date => {
  const intl = useIntl();

  const padded = num => String(num).padStart(2, '0');

  const formattedHourMinSec = (hours, minutes, seconds) =>
    `${hours}:${padded(minutes)}:${padded(seconds)}`;

  const getDisplayTime = date => {
    const { days, hours, minutes, seconds } = calculateTimeLeft(date);
    if (seconds < 0) return null;
    return days < 1
      ? formattedHourMinSec(hours, minutes, seconds)
      : intl.formatMessage({ id: 'countdown.dayHour' }, { days, hours });
  };

  const [timeLeft, setTimeLeft] = useState(getDisplayTime(date));

  useEffect(() => {
    const { days } = calculateTimeLeft(date);
    const timer =
      days < 1 &&
      setInterval(() => {
        setTimeLeft(getDisplayTime(date));
      }, 1000);
    return () => {
      days < 1 && clearTimeout(timer);
    };
  }, []);

  return timeLeft;
};
