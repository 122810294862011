import React, { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { bool, func } from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import cn from 'classnames';
import { useLocation } from 'react-router-dom';

import { useModal, useNavigation } from 'hooks';
import usePayments from 'hooks/payments/usePayments';
import { zIndexModalThird } from 'styles/common/_constants.scss';

import ModalHeader from 'components/common/ModalHeader/ModalHeader';
import CreditCard from 'components/payments/CreditCard/CreditCard';
import EmptyMessage from 'components/common/EmptyMessage/EmptyMessage';
import Button from 'components/common/Button/Button';
import Modal from 'components/common/Modal/Modal';
import AddPaymentMethod from 'components/payments/AddPaymentMethod/AddPaymentMethod';
import Loading from 'components/common/Loading';

const PaymentMethods = ({ isModal, hide }) => {
  const intl = useIntl();
  const { getCreditCards, creditCards, getCardsLoading: loading } = usePayments();
  const { toggle, isShowing } = useModal();
  const { goBack } = useNavigation();
  const { state = {} } = useLocation();

  const defaultCard = creditCards.find(({ isDefault }) => isDefault);
  const otherCards = creditCards.filter(({ isDefault }) => !isDefault);

  const { backOnClose = false } = state;

  const showEmptyMessage = useMemo(() => !loading && isEmpty(creditCards), [loading, creditCards]);

  useEffect(() => {
    getCreditCards();
  }, []);

  return (
    <div className={cn('payment-methods', { 'payment-methods-modal': isModal })}>
      {isModal ? (
        <ModalHeader
          titleId="paymentMethod.title"
          hideModal={backOnClose ? goBack : hide}
          thirdItem={
            <Button
              labelId="general.add"
              type="secondary"
              size="xxsmall"
              lightFont
              onClick={toggle}
            />
          }
        />
      ) : (
        <div className="flex-spaced">
          <h1 className="title">{intl.formatMessage({ id: 'paymentMethod.title' })}</h1>
          <Button
            labelId="creditCard.addCard"
            type="secondary"
            size="xxsmall"
            lightFont
            onClick={toggle}
          />
        </div>
      )}
      {defaultCard && (
        <div className="active-card">
          <div className="p1">{intl.formatMessage({ id: 'creditCard.default.label' })}</div>
          <CreditCard card={defaultCard} isDefault reducedMask={isModal} />
        </div>
      )}
      {!isEmpty(otherCards) && (
        <div className="other-cards">
          <div className="p1">{intl.formatMessage({ id: 'creditCard.others' })}</div>
          {otherCards.map(card => (
            <CreditCard card={card} key={card.id} reducedMask={isModal} />
          ))}
        </div>
      )}
      {showEmptyMessage &&
        (isModal ? (
          <EmptyMessage titleId="creditCard.empty" />
        ) : (
          <h5 className="empty-title">{intl.formatMessage({ id: 'creditCard.empty' })}</h5>
        ))}
      {loading && <Loading />}
      <Modal hide={toggle} isShowing={isShowing} topModal zIndex={zIndexModalThird} leftAnimation>
        <AddPaymentMethod />
      </Modal>
    </div>
  );
};

PaymentMethods.propTypes = {
  isModal: bool,
  hide: func
};

export default PaymentMethods;
