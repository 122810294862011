import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { useSession, useAnalytics } from 'hooks';
import { routesPaths } from 'constants/routesPaths';
import { MIXPANEL_EVENTS } from 'constants/constants';

import Login from 'components/user/Login/Login';

const LoginPage = () => {
  const { authenticated } = useSession();
  const { trackEvent } = useAnalytics();

  if (authenticated) {
    return <Redirect to={routesPaths.home} />;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    trackEvent(MIXPANEL_EVENTS.login);
  }, [trackEvent]);

  return <Login />;
};

export default LoginPage;
