import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLoading, useStatus, ERROR, NOT_STARTED } from '@rootstrap/redux-tools';

import { getOneSeries } from 'state/actions/seriesActions';

export default () => {
  const { status } = useStatus(getOneSeries);
  const dispatch = useDispatch();

  const getSerie = id => {
    dispatch(getOneSeries(id));
  };

  return {
    serie: useSelector(({ series: { serie } }) => serie),
    loading: useLoading(getOneSeries),
    error: status === ERROR,
    status: status || NOT_STARTED,
    reset: useCallback(() => dispatch(getOneSeries.reset()), [dispatch]),
    getSerie
  };
};
