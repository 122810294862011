import React, { useEffect, useMemo } from 'react';
import { shape, func } from 'prop-types';
import { useIntl } from 'react-intl';

import Button from 'components/common/Button/Button';
import CreditCard from 'components/payments/CreditCard/CreditCard';
import NewCardForm from 'components/payments/NewCardForm/NewCardForm';

import { PURCHASE_TYPES } from 'constants/constants';
import { celebrityShape } from 'constants/propTypesShapes';

import { useClickOutside, useToast } from 'hooks';
import useMembership from 'hooks/celebrities/useMembership';
import useSubscribeContext from 'hooks/account/useSubscribeContext';
import usePayments from 'hooks/payments/usePayments';
import EventModalHeader from './EventModalHeader';
import useEventRegister from './useEventRegister';

const EventPurchaseModal = ({ celebrity, hide, event }) => {
  const ref = useClickOutside(hide);
  const { title, isMember, membersPrice, nonMembersPrice, webCoverImageUrl } = event;
  const intl = useIntl();
  const { showToast, showErrorToast } = useToast();
  const { getCreditCards, creditCards } = usePayments();
  const defaultCard = creditCards.find(({ isDefault }) => isDefault);
  const { goToChangePaymentMethod } = useSubscribeContext();
  const { membershipPlans } = useMembership();
  const { eventAd } = useEventRegister();
  const { purchaseType } = {};

  const subScribeEvents = async e => {
    e.preventDefault();
    try {
      const payload = {
        creditCardId: defaultCard.id,
        eventId: event.id
      };
      eventAd(payload);
      showToast('Register Event Successfully!');
      hide();
    } catch (error) {
      showErrorToast('Error while register event!');
    }
  };

  const MEMBERSHIP_DETAILS = membershipPlans[0];

  const PURCHASE_MEMBERSHIP_EPISODE = purchaseType === PURCHASE_TYPES.MEMBERSHIP_EPISODE;

  const PURCHASE_ITEMS = useMemo(() => {
    return PURCHASE_MEMBERSHIP_EPISODE
      ? [
          {
            title: intl.formatMessage({ id: 'membership.title' }),
            description: intl.formatMessage(
              { id: 'membership.price' },
              { price: MEMBERSHIP_DETAILS.price?.toFixed(2) }
            ),
            price: intl.formatMessage(
              { id: 'membership.monthly.price' },
              { price: MEMBERSHIP_DETAILS.price?.toFixed(2) }
            )
          },
          {
            title: intl.formatMessage({ id: 'general.episode' }),
            description: intl.formatMessage({ id: 'unlock.episode.payment' }),
            price: `${
              isMember ? `$${membersPrice?.toFixed(2)}` : `$${nonMembersPrice?.toFixed(2)}`
            }`,
            withBorder: true
          },
          {
            title: intl.formatMessage({ id: 'general.total' }),
            description: null,
            price: `${
              MEMBERSHIP_DETAILS.price + isMember
                ? `$${membersPrice?.toFixed(2)}`
                : `$${nonMembersPrice?.toFixed(2)}`
            }`,
            withBorder: true
          }
        ]
      : [];
  }, [
    MEMBERSHIP_DETAILS,
    PURCHASE_MEMBERSHIP_EPISODE,
    membersPrice,
    nonMembersPrice,
    isMember,
    intl
  ]);

  useEffect(() => {
    getCreditCards();
  }, []);

  return (
    <div ref={ref} className="purchase-modal">
      <EventModalHeader
        title={title}
        image={webCoverImageUrl}
        price={isMember ? membersPrice : nonMembersPrice}
        showProfile={!!celebrity}
        hide={hide}
        purchaseItems={PURCHASE_ITEMS}
      />
      {!defaultCard ? (
        <NewCardForm />
      ) : (
        <div className="purchase-modal-payment">
          <div className="checkout-header">
            <h4 className="h4-medium">{intl.formatMessage({ id: 'paymentMethod.title' })}</h4>
            <div className="secondary-action" onClick={goToChangePaymentMethod}>
              <h6 className="bold">{intl.formatMessage({ id: 'payments.changeMethod' })}</h6>
            </div>
          </div>
          <CreditCard card={defaultCard} isDefault />
          <div className="submit-container">
            <Button
              onClick={e => subScribeEvents(e)}
              labelId="membership.makePayment"
              type="primary"
              size="xsmall"
            />
          </div>
        </div>
      )}
    </div>
  );
};

EventPurchaseModal.propTypes = {
  celebrity: shape(celebrityShape).isRequired,
  hide: func
};

export default EventPurchaseModal;
