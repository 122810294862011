import React from 'react';
import { useIntl } from 'react-intl';
import { arrayOf, shape, string, oneOf, bool, func } from 'prop-types';
import cn from 'classnames';

import { celebrityShape } from 'constants/propTypesShapes';
import { useIsMobile } from 'hooks';

import ScrollingArrows from 'components/common/ScrollingArrows';
import FeaturedCelebrity from 'components/celebrity/FeaturedCelebrityItem/FeaturedCelebrity';
import FeaturedCelebrityReduced from 'components/celebrity/FeaturedCelebrityItem/FeaturedCelebrityReduced';

const FeaturedCelebrities = ({
  celebrities,
  titleId,
  smallTitle,
  className,
  size,
  mobileReduced,
  onCelebrityClick,
  showArrows = true
}) => {
  const intl = useIntl();
  const isMobile = useIsMobile();

  return celebrities?.length > 0 ? (
    <div className={cn('featured-celebrities', className)}>
      {!!titleId && (
        <div className="featured-celebrities-title">
          <h3 className={cn({ small: smallTitle })}>{intl.formatMessage({ id: titleId })}</h3>
        </div>
      )}
      <ScrollingArrows disabled={!showArrows} className="featured-celebrities-content">
        {celebrities.map(celebrity =>
          isMobile && mobileReduced ? (
            <FeaturedCelebrityReduced key={celebrity.id} celebrity={celebrity} size={size} />
          ) : (
            <FeaturedCelebrity
              key={celebrity.id}
              celebrity={celebrity}
              size={size}
              onClick={onCelebrityClick}
              hideTitle={mobileReduced}
            />
          )
        )}
      </ScrollingArrows>
    </div>
  ) : null;
};

FeaturedCelebrities.propTypes = {
  celebrities: arrayOf(shape(celebrityShape)),
  titleId: string,
  className: string,
  size: oneOf(['large', 'medium', 'small']),
  mobileReduced: bool,
  onCelebrityClick: func,
  showArrows: bool,
  smallTitle: bool
};

FeaturedCelebrities.defaultProps = {
  size: 'large'
};

export default FeaturedCelebrities;
