import React from 'react';
import { number, string } from 'prop-types';

import { secondaryColor } from 'styles/common/_constants.scss';

const FacebookIcon = ({ width = 10, height = 18, color = secondaryColor }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 11 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="logoTitle logoDesc"
    role="img"
  >
    <title id="logoTitle">Facebook</title>
    <desc id="logoDesc">Facebook logo</desc>
    <path
      d="M10 1.66666H7.50004C6.39497 1.66666 5.33516 2.10564 4.55376 2.88704C3.77236 3.66845 3.33337 4.72825 3.33337 5.83332V8.33332H0.833374V11.6667H3.33337V18.3333H6.66671V11.6667H9.16671L10 8.33332H6.66671V5.83332C6.66671 5.61231 6.75451 5.40035 6.91079 5.24407C7.06707 5.08779 7.27903 4.99999 7.50004 4.99999H10V1.66666Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

FacebookIcon.propTypes = {
  width: number,
  height: number,
  color: string
};

export default FacebookIcon;
